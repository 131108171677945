import { Button } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { RiExpandLeftRightLine } from 'react-icons/ri';
import {
  KanBanColumn as KanBanColumnInterface,
  PrsKanBanItem,
} from 'src/components/projects/projectForm/deliverablesAndKpi/kanban-view/kanban-view.utils';
import KanBanItem, { ItemTypes } from './kanban-item';

interface KanBanColumnProps extends Omit<KanBanColumnInterface, 'hideIfEmpty'> {
  colGroupFn?: (items: any[]) => { items: any[]; labelContent?: JSX.Element }[];
  onDropFn?: (item: PrsKanBanItem, columnId: string | number) => void;
}

const KanBanColumn: React.FC<KanBanColumnProps> = ({
  canCollapse = true,
  icon,
  id,
  isDndEnabled = false,
  items,
  title,
  onDropFn,
  colGroupFn,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [groups, setGroups] = useState<{ items: any[]; labelContent?: JSX.Element }[]>([]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
    canDrop: () => true,
    drop: (item: any) => onDropFn && onDropFn(item.colItem, id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  useEffect(() => {
    if (!items) return;

    if (items.length > 0) {
      const groupedItems = colGroupFn ? colGroupFn(items) : [{ items }];
      setGroups(groupedItems);
    } else {
      setGroups([{ items: [] }]);
    }
    if (canCollapse) {
      setIsCollapsed(items.length === 0);
    }
  }, [items, colGroupFn, canCollapse]);

  const handleCollapseToggle = () => {
    if (isCollapsed) {
      setIsCollapsed(false);
      setTimeout(() => setIsAnimating(false), 300);
    } else {
      setIsAnimating(true);
      setTimeout(() => setIsCollapsed(true), 300);
    }
  };

  return (
    <div
      ref={drop}
      className={classNames(
        'tw-flex tw-relative tw-flex-col tw-rounded-lg tw-shrink-0 tw-w-[335px] tw-p-2 tw-bg-zinc-100 tw-transition-all tw-duration-500 tw-ease-in-out',
        { '!tw-w-[40px]': isCollapsed },
      )}>
      {isCollapsed ? (
        <Button
          className="tw-mt-[4px]"
          onClick={handleCollapseToggle}
          type="text"
          size="small"
          icon={<RiExpandLeftRightLine />}
        />
      ) : (
        <div className="tw-shrink-0 tw-justify-between tw-flex tw-gap-2 tw-items-center tw-pb-2 tw-z-30 tw-text-base tw-py-1 tw-bg-zinc-100 tw-w-full">
          <div
            className={classNames('tw-flex tw-capitalize tw-gap-2 tw-font-semibold', {
              'tw-opacity-0 tw-pointer-events-none': isAnimating,
              'tw-opacity-100': !isAnimating && !isCollapsed,
            })}>
            {icon}
            <div className="tw-flex tw-truncate tw-gap-2">
              {title}
              <span className="tw-text-zinc-400">{items?.length || null}</span>
            </div>
          </div>
          <Button
            onClick={handleCollapseToggle}
            type="text"
            size="small"
            icon={<RiExpandLeftRightLine />}
          />
        </div>
      )}

      <div
        className={classNames(
          'tw-overflow-y-auto tw-gap-[6px] tw-flex tw-flex-col tw-grow tw-transition-opacity tw-duration-300 tw-ease-in-out',
          {
            'tw-opacity-0 tw-pointer-events-none': isAnimating,
            'tw-opacity-100': !isAnimating && !isCollapsed,
          },
        )}>
        {groups.map((group, index) => (
          <div
            key={index}
            className="tw-flex tw-flex-col tw-gap-2">
            {group.labelContent && <div>{group.labelContent}</div>}
            <div className="tw-w-full tw-flex tw-flex-col tw-gap-2">
              {group.items.length > 0 ? (
                group.items.map((item, i) => (
                  <KanBanItem
                    key={i}
                    isDndEnabled={isDndEnabled}
                    item={item}
                  />
                ))
              ) : (
                <div
                  className={classNames(
                    'tw-h-[200px] tw-text-rest tw-flex tw-items-center tw-justify-center tw-text-center',
                    {
                      'tw-hidden': isCollapsed,
                      'tw-opacity-0 tw-pointer-events-none': isAnimating,
                      'tw-opacity-100': !isAnimating && !isCollapsed,
                    },
                  )}>
                  Nessun elemento
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {isOver && (
        <div className="tw-absolute tw-rounded-lg tw-inset-0 tw-bg-primary/10 tw-border-2 tw-border-dashed tw-border-primary tw-z-50" />
      )}
    </div>
  );
};

export default KanBanColumn;
