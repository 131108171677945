import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Space, Table, Tooltip, Typography } from 'antd';
import { Component } from 'react';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { randomGenKey, statusClass } from '../../../shared/utils/functions';
import '../../matrix.scss';

class ZoomDataModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      searchedColumn: '',
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  generateObjectLink = (data, record) => {
    if (data.area === 'projects' || data.area === 'childProjects') {
      return (
        <Link
          to={{ pathname: `/progetti/id/${record.projectID}` }}
          target="_blank"
          style={{ color: 'black' }}>
          {record.title}
        </Link>
      );
    } else if (data.area === 'annualGoal') {
      return (
        <Link
          to={{ pathname: `/obiettiviannuali/details/id/${record.annualGoalID}` }}
          target="_blank"
          style={{ color: 'black' }}>
          {record.title}
        </Link>
      );
    } else if (data.area === 'longTermGoals' || data.area === 'longTermGoalsL2') {
      return (
        <Link
          to={{ pathname: `/obiettivialungoperiodo/details/id/${record.longTermGoalID}` }}
          target="_blank"
          style={{ color: 'black' }}>
          {record.title}
        </Link>
      );
    } else {
      return '-';
    }
  };

  modalTitle = (modalData, cycleName) => {
    if (
      (modalData.area === 'longTermGoals' || modalData.area === 'longTermGoalsL2') &&
      cycleName !== '' &&
      cycleName !== null
    ) {
      return (
        <Typography.Title level={4}>
          <>{modalData.label} </>
          <> - {cycleName}</>
        </Typography.Title>
      );
    } else {
      return <Typography.Title level={4}>{modalData.label}</Typography.Title>;
    }
  };

  render() {
    const { showZoomModal, handleZoomModalClose, t, modalData, xMatrixCycleName } = this.props;

    const columns = [
      {
        dataIndex: 'orderingValue',
        width: '50px',
        render: (text, record, index) => {
          return {
            children: (
              <Tooltip title={record.statusDescription}>
                <div
                  className={`xMatrix-square-label ${statusClass(record.status)}`}
                  key={index}>
                  {text}
                </div>
              </Tooltip>
            ),
          };
        },
      },
      {
        title: `${t('general.nome')}`,
        dataIndex: 'title',
        ellipsis: {
          showTitle: true,
        },
        width: '100%',
        showSorterTooltip: [true],
        ...this.getColumnSearchProps('title'),
        render: (text, record, index) =>
          // <Tooltip placement="bottom" title={title}>{title}</Tooltip>
          this.generateObjectLink(modalData, record),
      },
    ];

    if (modalData.area === 'projects' || modalData.area === 'childProjects') {
      columns.splice(1, 0, {
        title: `${t('proggetiPage.codice')}`,
        dataIndex: 'projectCode',
        width: '10%',
        className: 'firstCol',
        ellipsis: {
          showTitle: false,
        },
        render: (projectCode) => <Tooltip title={projectCode}>{projectCode}</Tooltip>,
      });
    }

    return (
      <Modal
        className="zoomModal"
        width={'80%'}
        title={this.modalTitle(modalData, xMatrixCycleName)}
        open={showZoomModal}
        footer={null}
        destroyOnClose={true}
        onCancel={handleZoomModalClose}>
        <Table
          id="zoomModalTable"
          size="small"
          columns={columns}
          dataSource={modalData.data}
          bordered={true}
          rowKey={randomGenKey}
          pagination={false}
        />
      </Modal>
    );
  }
}

export default ZoomDataModal;
