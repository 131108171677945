import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  DownloadOutlined,
  EditOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Input, message, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserListPageResponseDto } from 'src/connectors/backend';
import XlsxPopulate from 'xlsx-populate';
import { useAppSelector } from '../../redux/store';
import UsersService from '../../services/pages/usersService';
import TableLayout from '../shared/tableLayout';
import {
  checkUserIsLenovysOrCompanyAdmin,
  checkUserIsLenovysOrTechAdmin,
  checkUserIsLenovysTechAdmin,
} from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { notifyMessages } from '../shared/utils/notifyMessages';
import UploadUsers from './uploadUsers';

const fileExtension = '.xlsx';

const UserListPage: React.FC = () => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);

  const [uploadUsersModal, setUploadUsersModal] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingDownloadUsers, setLoadingDownloadUsers] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [usersList, setUsersList] = useState<UserListPageResponseDto[]>([]);
  const [filteredUsersList, setFilteredUsersList] = useState<UserListPageResponseDto[]>([]);

  useEffect(() => {
    retrieveUserListData();
  }, []);

  useEffect(() => {
    if (searchText) {
      const filteredList = usersList.filter((user) => user.fullName?.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredUsersList(filteredList);
    } else {
      setFilteredUsersList(usersList);
    }
  }, [searchText]);

  const retrieveUserListData = async () => {
    setLoadingData(true);
    try {
      const response = await UsersService.getUsersListData();
      if (response.data?.success) {
        setUsersList(response.data?.responseObject?.value || []);
        setFilteredUsersList(response.data?.responseObject?.value || []);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setLoadingData(false);
    }
  };

  const exportToCSV = async () => {
    setLoadingDownloadUsers(true);

    try {
      const newUserList = usersList.map(({ availability, dailyRate, divisionID, ...rest }) => rest);
      const fileName = `Users-${userData?.lastLoggedInCompanyName}`;
      const header = ['Type', 'Name', 'Job Title', 'Email', 'Company', 'Role', 'Is Disabled'];

      const workbook = await XlsxPopulate.fromBlankAsync();
      const sheet1 = workbook.sheet(0);
      const sheetData = [[...header], ...newUserList.map(Object.values)];
      sheet1.cell('A1').value(sheetData);

      const totalColumns = sheetData[0].length;
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style('bold', true);
      sheet1.range(`A1:${endColumn}1`).style('fill', 'BFBFBF');

      ['A', 'B', 'C', 'D', 'E', 'F', 'G'].forEach((col, index) => sheet1.column(col).width(30 - index * 5));

      const blob = await workbook.outputAsync();
      FileSaver.saveAs(blob, `${fileName}${fileExtension}`);
    } catch {
      message.error(notifyMessages.errorAccured);
    } finally {
      setLoadingDownloadUsers(false);
    }
  };

  const onRemoveUser = async (userId: string) => {
    try {
      const response = await UsersService.removeUser(userId);
      if (response.data?.success) {
        message.success(notifyMessages.deleteSuccess);
        retrieveUserListData();
      } else {
        message.error(notifyMessages.deleteFailed);
      }
    } catch {
      message.error(notifyMessages.deleteFailed);
    }
  };

  const isRoleTechOrLenovysAdmin = userData && checkUserIsLenovysTechAdmin(userData);

  const columns: ColumnsType<UserListPageResponseDto> = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'fullName',
      ellipsis: { showTitle: true },
      sorter: (a: any, b: any) => (a.fullName || '').localeCompare(b.fullName || ''),
    },
    {
      title: `${t('utentiPage.titoloLavoro')}`,
      dataIndex: 'jobTitle',
      sorter: (a: any, b: any) => (a.jobTitle || '').localeCompare(b.jobTitle || ''),
    },
    {
      title: `${t('general.email')}`,
      dataIndex: 'emailAddress',
      sorter: (a: any, b: any) => (a.emailAddress || '').localeCompare(b.emailAddress || ''),
      ellipsis: { showTitle: true },
    },
    {
      title: `${t('general.ruolo')}`,
      dataIndex: 'role',
      sorter: (a: any, b: any) => (a.role || '').localeCompare(b.role || ''),
    },
    {
      title: `${t('utentiPage.statoAccesso')}`,
      dataIndex: 'isDisabled',
      filters: [
        { text: `${t('general.si')}`, value: false },
        { text: `${t('general.no')}`, value: true },
      ],
      onFilter: (value, record) => record.isDisabled === value,
      render: (isDisabled: boolean) =>
        isDisabled ? <CloseOutlined style={{ color: '#ec1a24' }} /> : <CheckOutlined style={{ color: '#16a34a' }} />,
    },
    {
      key: 'action',
      width: 100,
      render: (_, record) => (
        <div className="tw-flex tw-gap-2">
          {!checkUserIsLenovysOrTechAdmin(userData) && (
            <Popconfirm
              title={t('utentiPage.removeUser')}
              icon={<WarningOutlined />}
              okText={t('general.si')}
              cancelText={t('general.no')}
              onConfirm={() => onRemoveUser(record.userID || '')}>
              <Button
                danger
                icon={<DeleteFilled />}
              />
            </Popconfirm>
          )}
          {checkUserIsLenovysOrCompanyAdmin(userData) && (
            <Button
              icon={<EditOutlined />}
              href={`/profile/${record.userID}`}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <TableLayout title={t('utentiPage.utenti')}>
      <TableLayout.Actions>
        {isRoleTechOrLenovysAdmin && (
          <>
            <UploadUsers
              open={uploadUsersModal}
              onCancel={() => setUploadUsersModal(false)}
              onOk={retrieveUserListData}
            />
            <Button
              icon={<UploadOutlined />}
              onClick={() => setUploadUsersModal(true)}>
              {t('buttons.carica')}
            </Button>
            <Button
              loading={loadingDownloadUsers}
              icon={<DownloadOutlined />}
              onClick={exportToCSV}>
              {t('buttons.scaricaUtenti')}
            </Button>
          </>
        )}

        <Input.Search
          placeholder={t('general.search')}
          allowClear
          onSearch={(e) => setSearchText(e)}
        />
      </TableLayout.Actions>
      <TableLayout.Content>
        <Table
          columns={columns}
          size="small"
          dataSource={filteredUsersList}
          rowKey={(obj) => obj.userID || ''}
          loading={loadingData}
          pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
        />
      </TableLayout.Content>
    </TableLayout>
  );
};

export default UserListPage;
