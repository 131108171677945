import { Form } from 'antd';
import moment from 'moment';
import React from 'react';
import '../../projects/projectForm/projectActivities/activities.scss';

import { Card, Col, DatePicker, Input, Modal, Row, Select, Typography } from 'antd';
import CommonService from '../../../services/commonService';
import ModalFooterButtons from '../../shared/components/modal/modalFooterButtons';
import { activityStatus, statusPickListCodes } from '../../shared/utils/constants';
import { areDatesValid, showErrorNotification } from '../../shared/utils/functions';
import { requiredFields } from '../../shared/utils/notifyMessages';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class UpdateActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      usersPickList: null,
    };
  }

  componentDidMount() {
    this.retrieveStatus();
    this.retrieveResponsibleUsersList();
  }

  async retrieveStatus() {
    const objectCode = statusPickListCodes.activityStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveResponsibleUsersList() {
    const { deliverableData } = this.props;
    await CommonService.getResponsibleUserList(deliverableData.projectID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let usr = [];
          let respData = resp.responseObject.value;
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          this.setState({ usersPickList: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  isStatusClosedOrCancel = (status) => {
    if (status) {
      if (status === activityStatus.closed || status === activityStatus.cancelled) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  editData = (values, activityID, deliverableId, projectId) => {
    this.props.handleUpdateActivity(values, activityID, deliverableId, projectId);
  };

  limitDateSelection(current, deliverableData) {
    // if (deliverableData) {
    //     if (deliverableData.parentDeliverableID) {
    //         let curr = moment(current).endOf('day');
    //         let startDate = moment(deliverableData.parentDeliverableStartDate).endOf('day');
    //         let endDate = moment(deliverableData.parentDeliverableEndDate).endOf('day');
    //         return ((curr < startDate) || (curr > endDate))
    //     }
    //     else {
    //         let curr = moment(current).endOf('day');
    //         let startDate = moment(deliverableData.projectStartDate).endOf('day');
    //         let endDate = moment(deliverableData.projectEndDate).endOf('day');
    //         return ((curr < startDate) || (curr > endDate))
    //     }
    // }
    // else {
    //     let curr = moment(current).endOf('day');
    //     let startDate = moment(deliverableData.projectStartDate).endOf('day');
    //     let endDate = moment(deliverableData.projectEndDate).endOf('day');
    //     return ((curr < startDate) || (curr > endDate))
    // }

    if (deliverableData) {
      let curr = moment(current).endOf('day');
      let startDate = moment(deliverableData.startDate).endOf('day');
      let endDate = moment(deliverableData.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else {
    }
  }

  checkValidityBeforeSave = (values, activityID, deliverableId, projectId) => {
    const { t } = this.props;
    if (areDatesValid(values.startDate, values.endDate)) {
      this.editData(values, activityID, deliverableId, projectId);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  render() {
    const { showEditModal, handleEditModalClose, loadingButton, modalData, deliverableId, deliverableData, t } =
      this.props;
    const { statusList, usersPickList } = this.state;

    let isClosedOrCancelled = this.isStatusClosedOrCancel(modalData.status);

    let isClosed = modalData.status === activityStatus.closed ? true : false;

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('leMieAttivitaPage.aggiornamentoAttivita')}
        open={showEditModal}
        destroyOnClose={true}
        onCancel={handleEditModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleEditModalClose}
            formId={'editDeliverableActivityFrom'}
          />,
        ]}>
        <Card>
          <Form
            id="editDeliverableActivityFrom"
            layout="vertical"
            {...formItemLayout}
            // onFinish={this.checkDatesValidity}
            onFinish={(values) =>
              this.checkValidityBeforeSave(values, modalData.activityID, modalData.deliverableID, modalData.projectId)
            }
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            {isClosed && (
              <Row
                gutter={{ lg: 24 }}
                className="row-margin">
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <div className="button-wrapper">
                    <Text strong>
                      {t('general.dataChiusura')}:{' '}
                      {modalData.actualCloseDate ? moment(modalData.actualCloseDate).format('DD/MM/YYYY') : '-'}
                    </Text>
                  </div>
                </Col>
              </Row>
            )}
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  className="main-container"
                  label={t('leMieAttivitaPage.soggetto')}
                  name="subject"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.subject}>
                  <Input disabled={isClosedOrCancelled} />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  className="main-container"
                  labelAlign="left"
                  label={t('general.responsabile')}
                  name="ownerID"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.ownerID}>
                  <Select
                    disabled={isClosedOrCancelled}
                    placeholder={t('general.seleziona')}
                    style={{ width: '100%' }}
                    options={usersPickList}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    loading={!usersPickList}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  className="main-container"
                  label={t('general.stato')}
                  name="status"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.status}>
                  <Select
                    disabled={isClosedOrCancelled}
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}>
                    {statusList &&
                      statusList.map((item) => (
                        <Option
                          value={item.statusID}
                          key={item.statusID}>
                          {item.statusDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  className="main-container"
                  label={t('general.start')}
                  name="startDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.startDate ? moment(modalData.startDate) : modalData.startDate}>
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    disabled={isClosedOrCancelled}
                    disabledDate={
                      deliverableData ? (current) => this.limitDateSelection(current, deliverableData) : null
                    }
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  className="main-container"
                  label={t('general.end')}
                  name="endDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.endDate ? moment(modalData.endDate) : modalData.endDate}>
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    disabled={isClosedOrCancelled}
                    disabledDate={
                      deliverableData ? (current) => this.limitDateSelection(current, deliverableData) : null
                    }
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  className="main-container"
                  label={t('general.descrizione')}
                  name="description"
                  initialValue={modalData.description}>
                  <TextArea
                    rows={4}
                    disabled={isClosedOrCancelled}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default UpdateActivity;
