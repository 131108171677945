import { DownloadOutlined, InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Col, message, Modal, Row, Space, Table, Tooltip, Typography, Upload } from 'antd';
import * as FileSaver from 'file-saver';
import React from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import { withTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import UsersService from '../../services/pages/usersService';
import '../kpiListPage/kpi.scss';
import { notifyMessages } from '../shared/utils/notifyMessages';

const { Text } = Typography;

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const ErrorList = ({ errorList }) => {
  return (
    <Space direction="vertical">
      {errorList.map((item, index) => (
        <Text key={index}> * {item.error}</Text>
      ))}
    </Space>
  );
};

class UploadUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingData: false,
      loadingExcelData: false,
      cols: [],
      rows: [],
      errorMessage: null,
      apiData: null,

      rowVlidaionErrors: [],
      showErrorsList: false,

      userCreationErrors: [],
      userCreationSuccess: false,
    };
  }

  componentDidMount() {
    //this.getExcelData();
  }

  checkFile(file) {
    const { errorMessage } = this.state;
    if (!file || !file[0]) {
      return;
    }
    const isExcel =
      file[0].type === 'application/vnd.ms-excel' ||
      file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
      this.setState({ errorMessage: 'Upload only excel files' });
    }
    const isLt2M = file[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.setState({ errorMessage: 'File must be less than 2 MB! ' });
    }
    return errorMessage;
  }

  fileHandler = (fileList) => {
    const { rowVlidaionErrors } = this.state;
    let fileObj = fileList;
    if (!fileObj) {
      this.setState({
        errorMessage: 'No files uploaded!',
      });
      return false;
    }

    if (
      !(
        fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      this.setState({
        errorMessage: 'Upload only excel files',
      });
      return false;
    }

    const isLt2M = fileObj.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.setState({ errorMessage: 'File must be less than 2 MB! ' });
      return false;
    }

    ExcelRenderer(fileObj, (err, resp) => {
      let t = this.props.t;

      if (err) {
        console.log(err);
      } else {
        let newRows = [];

        resp.rows.slice(1).forEach((row, index) => {
          let rowErrorObj = {};

          if (!row[0] && !row[1] && !row[2] && !row[3] && !row[4] && !row[5] && !row[6]) {
            return undefined;
          }

          if (!row[0]) {
            rowErrorObj['error'] = `${t('utentiPage.rigaNome')} ` + (index + 1) + ` ${t('utentiPage.nonVuoto')} `;
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (!row[1]) {
            rowErrorObj['error'] = `${t('utentiPage.rigaCognome')} ` + (index + 1) + ` ${t('utentiPage.nonVuoto')} `;
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (!row[2]) {
            rowErrorObj['error'] = `${t('utentiPage.rigaEmail')} ` + (index + 1) + ` ${t('utentiPage.nonVuoto')} `;
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (row[2]) {
            if (!emailPattern.test(row[2])) {
              rowErrorObj['error'] =
                `${t('utentiPage.rigaEmail')} ` + (index + 1) + ` ${t('utentiPage.nonValidEmail')} `;
              rowVlidaionErrors.push(rowErrorObj);
              rowErrorObj = {};
            }
          }

          if (!row[3]) {
            rowErrorObj['error'] = `${t('utentiPage.rigaRuolo')} ` + (index + 1) + ` ${t('utentiPage.nonVuoto')} `;
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (!row[4]) {
            rowErrorObj['error'] = `${t('utentiPage.rigaAzienda')} ` + (index + 1) + ` ${t('utentiPage.nonVuoto')} `;
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (row && row !== 'undefined') {
            newRows.push({
              key: index + 1,
              firstName: row[0],
              lastName: row[1],
              email: row[2],
              roleName: row[3],
              companyName: row[4],
              divisionName: row[5],
              userType: row[6],
            });
          }
        });
        if (newRows.length === 0) {
          this.setState({
            errorMessage: `${t('utentiPage.noDataOnFile')} `,
          });
          return false;
        } else {
          this.setState({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null,
          });
        }
      }
    });
    return false;
  };

  handleSubmit = async () => {
    debugger;
    const { rows, userCreationErrors } = this.state;
    let t = this.props.t;

    let payloadArray = [];

    rows.forEach(function (item) {
      let payloadObj = {};

      payloadObj['email'] = item.email;
      payloadObj['companyName'] = item.companyName;
      payloadObj['roleName'] = item.roleName;
      payloadObj['divisionName'] = item.divisionName;
      payloadObj['name'] = item.firstName;
      payloadObj['surname'] = item.lastName;
      payloadObj['userType'] = item.userType;

      payloadArray.push(payloadObj);
    });

    this.setState({ loadingButton: true });
    UsersService.uploadUsers(payloadArray)
      .then((response) => response.data)
      .then((resp) => {
        if (resp) {
          if (resp.length > 0) {
            resp.forEach(function (item, index) {
              let errorObj = {};
              errorObj['error'] =
                `${t('utentiPage.laRigaDelUtente')} ` +
                item.email +
                ` ${t('utentiPage.seguentiErrori')} ` +
                item.errorMessage;
              userCreationErrors.push(errorObj);
            });

            //message.error(notifyMessages.uploadFailed);
            this.setState({ loadingButton: false });
          } else {
            message.success(notifyMessages.uploadSuccess);
            this.props.onCancel();
            this.setState({ rows: [] });
            this.setState({ loadingButton: false });
            this.setState({ userCreationSuccess: true });
          }
        } else {
          message.error(notifyMessages.uploadFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.uploadFailed);
        this.setState({ loadingButton: false });
      });
  };

  cancellUpload = async () => {
    this.props.onCancel();

    this.setState({ rows: [] });
    this.setState({ rowVlidaionErrors: [] });
    this.setState({ showErrorsList: false });
    this.setState({ userCreationErrors: false });
  };

  exportToCSV = () => {
    const fileName = `Users`;

    let headers = [['firstName', 'lastName', 'email', 'roleName', 'companyName', 'divisionName', 'userType']];

    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, headers, { defval: '' });

    //column formatter
    let wsColsFormatter = [
      { wpx: 100 }, // column width
      { wpx: 100 }, // column width
      { wpx: 100 }, // column width
      { wpx: 100 }, // column width
      { wpx: 100 }, // column width
      { wpx: 100 }, // column width
      { wpx: 100 }, // column width
    ];

    ws['!cols'] = wsColsFormatter;

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  showHideErrorList = () => {
    const { showErrorsList } = this.state;
    this.setState({ showErrorsList: !showErrorsList });
  };

  render() {
    const {
      rows,
      loadingExcelData,
      errorMessage,
      loadingButton,
      showErrorsList,
      rowVlidaionErrors,
      userCreationErrors,
      userCreationSuccess,
    } = this.state;
    let t = this.props.t;

    const columns = [
      {
        title: '',
        dataIndex: 'key',
        width: '50px',
      },
      {
        title: `${t('general.nome')}`,
        dataIndex: 'firstName',
        ellipsis: {
          showTitle: true,
        },
      },
      {
        title: `${t('general.cognome')}`,
        dataIndex: 'lastName',
      },
      {
        title: `${t('general.email')}`,
        dataIndex: 'email',
        ellipsis: {
          showTitle: true,
        },
      },
      {
        title: `${t('general.ruolo')}`,
        dataIndex: 'roleName',
      },
      {
        title: `${t('general.azienda')}`,
        dataIndex: 'companyName',
      },
      {
        title: `${t('general.divisione')}`,
        dataIndex: 'divisionName',
      },
      {
        title: `${t('utentiPage.tipologiaUtente')}`,
        dataIndex: 'userType',
      },
    ];

    return (
      <Modal
        destroyOnClose={true}
        width={1300}
        maskClosable={false}
        onCancel={this.props.onCancel}
        onOk={this.props.retrieveUserListData}
        title="Carica utenti"
        footer={() => (
          <div className="tw-flex tw-items-center tw-gap-2 tw-justify-end">
            <>
              <Button onClick={this.cancellUpload}>{t('buttons.cancella')}</Button>

              <Button
                onClick={this.handleSubmit}
                loading={loadingButton}
                type="primary"
                disabled={
                  rows.length === 0 ||
                  (rowVlidaionErrors && rowVlidaionErrors.length > 0) ||
                  (userCreationErrors && userCreationErrors.length > 0)
                }>
                {t('buttons.salva')}
              </Button>
            </>
          </div>
        )}
        open={this.props.open}>
        <div className="tw-flex tw-justify-between tw-items-start">
          {rows.length < 1 && (
            <div className="tw-flex tw-gap-2 tw-items-center">
              <Upload
                name="file"
                maxCount={1}
                multiple={false}
                beforeUpload={this.fileHandler}
                onRemove={() => this.setState({ rows: [] })}>
                <Button icon={<UploadOutlined />}>{t('buttons.sleionaExcel')}</Button>
              </Upload>
              <p className="upload-text"> * {t('utentiPage.clickFileExcel')} </p>

              {errorMessage && <p className="upload-text-error"> {errorMessage} </p>}
            </div>
          )}
          <div className="tw-flex tw-items-center tw-gap-2">
            {rows.length < 1 && (
              <>
                <Button
                  loading={loadingExcelData}
                  icon={<DownloadOutlined />}
                  onClick={() => this.exportToCSV()}>
                  {t('buttons.scaricaModello')}
                </Button>
                <Tooltip
                  placement="top"
                  title={t('utentiPage.scaricaModelloExcel')}>
                  <InfoCircleOutlined style={{ color: '#928e8e', marginLeft: 10 }} />
                </Tooltip>
              </>
            )}
          </div>
        </div>

        <div className="tw-my-4">
          {userCreationSuccess && (
            <Alert
              message={t('utentiPage.utentiCreatiSuccess')}
              showIcon
              type="success"
            />
          )}

          {rowVlidaionErrors && rowVlidaionErrors.length > 0 && (
            <Alert
              message={t('general.alcuniDatiNonValidi')}
              showIcon
              description={showErrorsList ? <ErrorList errorList={rowVlidaionErrors} /> : ''}
              type="error"
              action={<Button onClick={this.showHideErrorList}>{t('buttons.dettagli')}</Button>}
            />
          )}

          {userCreationErrors && userCreationErrors.length > 0 && (
            <Alert
              message={t('general.alcuniDatiNonValidi')}
              showIcon
              description={showErrorsList ? <ErrorList errorList={userCreationErrors} /> : ''}
              type="error"
              action={<Button onClick={this.showHideErrorList}>{t('buttons.dettagli')}</Button>}
            />
          )}
        </div>

        {rows.length > 0 && (
          <Row gutter={{ lg: 24 }}>
            <Col xs={{ span: 24 }}>
              <Table
                dataSource={rows}
                columns={columns}
                size="small"
                pagination={{ pageSize: 50 }}
              />
            </Col>
          </Row>
        )}
      </Modal>
    );
  }
}

export default withTranslation()(UploadUsers);
