import {
  CheckSquareOutlined,
  DeleteOutlined,
  EditFilled,
  ExportOutlined,
  FilterOutlined,
  MinusSquareOutlined,
  PlusOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, DatePicker, Input, message, Popconfirm, Popover, Space, Table, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CommonService from '../../services/commonService';
import ActivityService from '../../services/pages/activitiesServices';
import '../projects/project.scss';
import TableLayout from '../shared/tableLayout';
import { activityStatus, formatOfDate, statusPickListCodes } from '../shared/utils/constants';
import {
  checkMyActivitiesStatus,
  projectTypeColumnValues,
  ResponsiblePopoverContent,
  validateGuid,
} from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import NewActivity from './newActivity';
import UpdateActivity from './updateActivity';

const { Text } = Typography;

class TeamLeaderActivities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingActivities: false,
      teamLeaderActivityList: null,
      teamLeaderActivityListFiltered: [],
      showEditModal: false,
      showCreateModal: false,
      modalData: null,
      startDate: null,
      endDate: null,
      clearFilters: false,
      statusListFilter: [],
      // filteredInfo: ["Draft", "Attivo" ]
      filteredInfo: [`${props.t('status.draft')}`, `${props.t('status.attivo')}`],
      activityTypeFilter: [
        {
          text: `${props.t('leMieAttivitaPage.activity')}`,
          value: 'activity',
        },
        {
          text: `${props.t('leMieAttivitaPage.macroActivity')}`,
          value: 'macroActivity',
        },
        {
          text: `${props.t('leMieAttivitaPage.deliverable')}`,
          value: 'deliverable',
        },
      ],
    };
  }

  componentWillMount() {
    this.retrieveMyActivityList();
    this.retrieveStatusList();
    this.filterActivities('all');
  }

  async retrieveStatusList() {
    const objectCode = statusPickListCodes.activityStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let obj = [];
          resp.responseObject.value.map((item) => {
            obj.push({ text: item.statusDescription, value: item.statusDescription });
          });
          this.setState({ statusListFilter: obj });
        } else {
        }
      })
      .catch((error) => {});
  }

  componentWillUnmount() {
    this.setState({ loadingActivities: false });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  toggleEditAtivityModal = (rowData) => {
    this.setState({ modalData: rowData }, () => {
      this.setState({ showEditModal: true });
    });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false });
    this.setState({ loadingButton: false });
  };

  handleUpdateActivity = (values, activityID, deliverableID, projectId) => {
    values['activityID'] = activityID;
    values['deliverableID'] = deliverableID;
    //values["projectId"] = projectId;
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);

    this.setState({ loadingButton: true });
    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleEditModalClose();
          message.success(notifyMessages.updateSuccess);
          this.retrieveMyActivityList(true);
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  handleCloseActivity = (record) => {
    let values = {};

    values['activityID'] = record.activityID;
    values['deliverableID'] = record.deliverableID;
    values['description'] = record.description;
    values['endDate'] = this.handleDateChange(record.endDate);
    values['ownerID'] = record.ownerID;
    values['projectID'] = record.projectID;
    values['startDate'] = this.handleDateChange(record.startDate);
    values['status'] = activityStatus.closed;
    values['subject'] = record.subject;

    this.setState({ loadingButton: true });
    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          //this.handleEditModalClose();
          this.setState({ loadingButton: false });
          message.success(notifyMessages.updateSuccess);
          this.retrieveMyActivityList(true);
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  toggleCreateAtivityModal = () => {
    this.setState({ showCreateModal: true });
  };

  handleCreateModalClose = () => {
    this.setState({ showCreateModal: false });
    this.setState({ loadingButton: false });
  };

  handleSaveActivity = (values) => {
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);

    this.setState({ loadingButton: true });
    ActivityService.insertActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleCreateModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveMyActivityList(true);
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  async retrieveMyActivityList(hideActivityPopUp) {
    await this.retrieveStatusList();

    const { startDate, endDate, clearFilters } = this.state;
    const { userData, acid } = this.props;

    let formatedStartDate = this.handleDateChange(startDate);
    let formatedEndDate = this.handleDateChange(endDate);

    if (clearFilters) {
      this.setState({ startDate: null });
      this.setState({ endDate: null });
      formatedStartDate = null;
      formatedEndDate = null;
    }

    this.setState({ loadingActivities: true });
    await ActivityService.getTeamLeaderActivities(formatedStartDate, formatedEndDate)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          this.setState({ teamLeaderActivityList: respData });
          this.setState({ teamLeaderActivityListFiltered: respData });
          this.setState({ loadingActivities: false });

          if (acid && validateGuid(acid)) {
            let findSelectedActivity = respData.filter((obj) => obj.activityID === acid);

            if (findSelectedActivity && findSelectedActivity.length > 0) {
              if (!hideActivityPopUp) {
                this.toggleEditAtivityModal(findSelectedActivity[0]);
              }
            }
          }
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingActivities: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingActivities: false });
      });
  }

  handleDateChange(date) {
    if (date) {
      let formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  onFilter = () => {
    this.setState({ clearFilters: false }, () => {
      this.retrieveMyActivityList(true);
    });
  };

  onClearFilter = () => {
    this.setState({ clearFilters: true }, () => {
      this.retrieveMyActivityList(true);
    });
  };

  onRemoveActivity = (id) => {
    ActivityService.removeActivity(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.retrieveMyActivityList(true);
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  dateFilters = () => {
    const { startDate, endDate } = this.state;
    let { t } = this.props;
    return (
      <>
        <DatePicker
          placeholder={t('general.start')}
          value={startDate}
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          onChange={(date) => this.onStartDateChange(date)}
        />
        <DatePicker
          placeholder={t('general.end')}
          value={endDate}
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          onChange={(date) => this.onEndDateChange(date)}
        />
        <Tooltip title={t('general.filter')}>
          <Button
            icon={<FilterOutlined />}
            onClick={() => this.onFilter()}
            disabled={!startDate && !endDate}
          />
        </Tooltip>
        <Tooltip title={t('general.clearFilters')}>
          <Button
            icon={<MinusSquareOutlined />}
            onClick={() => this.onClearFilter()}
          />
        </Tooltip>
      </>
    );
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters.statusDescription,
    });
  };

  filterActivities = (type) => {
    const { teamLeaderActivityList } = this.state;
    if (type === 'activities') {
      let filteredData =
        teamLeaderActivityList && teamLeaderActivityList.filter((obj) => obj.activityType === 'activity');
      this.setState({ teamLeaderActivityListFiltered: filteredData });
    } else if (type === 'prs') {
      let filteredData =
        teamLeaderActivityList &&
        teamLeaderActivityList.filter(
          (obj) => obj.activityType === 'deliverable' || obj.activityType === 'macroActivity',
        );
      this.setState({ teamLeaderActivityListFiltered: filteredData });
    } else {
      this.setState({ teamLeaderActivityListFiltered: teamLeaderActivityList });
    }
  };

  render() {
    const {
      teamLeaderActivityList,
      loadingActivities,
      loadingButton,
      showCreateModal,
      showEditModal,
      modalData,
      statusListFilter,
      activityTypeFilter,
      filteredInfo,
    } = this.state;
    let { t } = this.props;

    const ActivityName = ({ record, title }) => {
      return (
        <Text
          style={{ cursor: 'pointer' }}
          onClick={() => this.toggleEditAtivityModal(record)}>
          {title}
        </Text>
      );
    };

    const myActivitiesColumns = [
      {
        title: `${t('general.tipo')}`,
        dataIndex: 'activityType',
        width: '10%',
        showSorterTooltip: [false],
        filters: activityTypeFilter,
        onFilter: (value, record) => (record.activityType ? record.activityType.includes(value) : ''),
        render(title, record) {
          return {
            props: {
              style: {
                borderLeft: '7px solid' + checkMyActivitiesStatus(record),
                borderRadius: '5px 0 0 5px',
              },
            },
            // children: <TypeColumnValues recordData={record} t={t} />
            children: <Text>{projectTypeColumnValues(record, t)}</Text>,
          };
        },
      },
      {
        title: `${t('general.descrizione')}`,
        dataIndex: 'subject',
        overflowWrap: 'break-word',
        sorter: (a, b) => {
          a = a.subject || '';
          b = b.subject || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('subject'),
        render(title, record) {
          return (
            <ActivityName
              title={title}
              record={record}
            />
          );
        },
        // render(title, record) {
        //     return {
        //         props: {
        //             style: { borderLeft: '7px solid' + checkMyActivitiesStatus(record),
        //                 borderRadius: '5px 0 0 5px'}
        //         },
        //         children: <ActivityName title={title} record={record}/>
        //     };
        // }
      },
      {
        title: `${t('general.responsabile')}`,
        dataIndex: 'fullName',
        width: '10%',
        sorter: (a, b) => {
          a = a.fullName || '';
          b = b.fullName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (title, record) => (
          <Popover
            content={
              <ResponsiblePopoverContent
                data={record}
                t={t}
              />
            }
            title={title}>
            <span style={record.isGenericResponsabile == true ? { color: '#f2883b' } : {}}>{title}</span>
          </Popover>
        ),
      },
      {
        title: `${t('general.stato')}`,
        dataIndex: 'statusDescription',
        width: '14ch',
        key: 'statusDescription',
        sorter: (a, b) => {
          a = a.statusDescription || '';
          b = b.statusDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        filters: statusListFilter,
        defaultFilteredValue: filteredInfo || null,
        onFilter: (value, record) => (record.statusDescription ? record.statusDescription.includes(value) : ''),
        render(text, record) {
          return {
            props: {
              // style: { background: checkMyActivitiesStatus(record) }
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //     title: `${t('general.deliverable')}`,
      //     dataIndex: 'deliverableName',
      //     sorter: (a, b) => {
      //       a = a.deliverableName || '';
      //       b = b.deliverableName || '';
      //       return a.localeCompare(b)
      //     },
      //     showSorterTooltip: [false],
      //     ellipsis: {
      //       showTitle: false,
      //     },
      //     render: (text, record) => (
      //         <Tooltip  title={text}>
      //             <Link to={{ pathname: `/deliverables/id/${record.deliverableID}` }}>{text}</Link>
      //         </Tooltip>
      //       )
      //   },
      {
        title: `${t('general.progetto')}`,
        dataIndex: 'projectName',
        sorter: (a, b) => {
          a = a.projectName || '';
          b = b.projectName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip title={text}>
            <Link to={{ pathname: `/progetti/id/${record.projectID}` }}> {text} </Link>
          </Tooltip>
        ),
      },
      {
        title: `${t('general.start')}`,
        dataIndex: 'startDate',
        width: '150px',
        sorter: (a, b) => {
          a = a.startDate || '';
          b = b.startDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (text, record) => moment(text).format(formatOfDate),
      },
      {
        title: `${t('general.end')}`,
        dataIndex: 'endDate',
        width: '150px',
        sorter: (a, b) => {
          a = a.endDate || '';
          b = b.endDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (text, record) => moment(text).format(formatOfDate),
      },
      {
        title: `${t('general.dataChiusura')}`,
        dataIndex: 'actualCloseDate',
        width: '150px',
        sorter: (a, b) => {
          a = a.actualCloseDate || '';
          b = b.actualCloseDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (text) => (text ? moment(text).format(formatOfDate) : '-'),
      },
      {
        key: 'action',
        width: '150px',
        render: (_, record) => {
          const isClosed = record.status === activityStatus.closed;
          const quickClose = record.status === activityStatus.active || record.status === activityStatus.draft;

          if (record.deliverableID) {
            return (
              <Button
                icon={<ExportOutlined />}
                link={`/deliverables/id/${record.deliverableID}`}
              />
            );
          }

          if (!isClosed) {
            return (
              <div className="tw-flex tw-items-center tw-h-10 tw-gap-2">
                <Button
                  icon={<EditFilled />}
                  onClick={() => this.toggleEditAtivityModal(record)}
                  title={t('buttons.modificaAttivita')}
                />
                <Popconfirm
                  key="remove"
                  title={t('buttons.rimuoveAttivita')}
                  icon={<WarningOutlined />}
                  okText={t('general.si')}
                  cancelText={t('general.no')}
                  onConfirm={() => this.onRemoveActivity(record.activityID)}>
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    title={t('buttons.rimuoveAttivita')}
                  />
                </Popconfirm>
              </div>
            );
          }

          if (quickClose) {
            return (
              <Popconfirm
                key="close"
                placement="topRight"
                title={t('leMieAttivitaPage.chiudiQuestaAttivita')}
                icon={<WarningOutlined />}
                okText={t('general.si')}
                cancelText={t('general.no')}
                onConfirm={() => this.handleCloseActivity(record)}>
                <Button
                  icon={<CheckSquareOutlined />}
                  title={t('buttons.chiusuraRapida')}
                  style={{ color: '#16a34a' }}
                />
              </Popconfirm>
            );
          }

          return null;
        },
      },
    ];

    return (
      <>
        {showCreateModal && (
          <NewActivity
            showCreateModal={showCreateModal}
            handleCreateModalClose={this.handleCreateModalClose}
            handleSaveActivity={this.handleSaveActivity}
            loadingButton={loadingButton}
            t={t}
          />
        )}

        {showEditModal && (
          <UpdateActivity
            showEditModal={showEditModal}
            handleEditModalClose={this.handleEditModalClose}
            handleUpdateActivity={this.handleUpdateActivity}
            loadingButton={loadingButton}
            modalData={modalData}
            t={t}
          />
        )}

        <TableLayout title={t('proggetiPage.toDoProject')}>
          <TableLayout.Actions>
            {this.dateFilters()}

            <Space direction="horizontal">
              <Button
                type="dashed"
                onClick={() => this.filterActivities('activities')}>
                {t('proggetiPage.attivitaFilter')}
              </Button>
              <Button
                type="dashed"
                onClick={() => this.filterActivities('prs')}>
                {t('proggetiPage.prsFilter')}
              </Button>
              <Button
                type="dashed"
                onClick={() => this.filterActivities('all')}>
                {t('proggetiPage.allFilter')}
              </Button>
            </Space>

            {this.props.changeVisualizationButton}
            <Button
              type="primary"
              onClick={() => this.toggleCreateAtivityModal()}>
              <PlusOutlined />
              {t('buttons.aggiungiNuovo')}
            </Button>
          </TableLayout.Actions>
          <TableLayout.Content>
            <Table
              tableLayout="fixed"
              size="small"
              columns={myActivitiesColumns}
              dataSource={this.state.teamLeaderActivityListFiltered}
              rowKey={(obj) => obj.activityID}
              loading={loadingActivities}
              onChange={this.handleChange}
              pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
            />
          </TableLayout.Content>
        </TableLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(TeamLeaderActivities));
