import { Spin } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'src/redux/store';
import { userHasAccessProjectFields } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { projectStatusAsNumber } from '../shared/utils/constants';
import './mainA3.scss';
import Section1Form from './section1/section1';

const Section2 = React.lazy(() => import('./section2/section2'));
const Section3 = React.lazy(() => import('./section3/section3'));
const Section4 = React.lazy(() => import('./section4/section4'));

interface A3IndexProps {
  id: string;
}

const A3Index: React.FC<A3IndexProps> = ({ id }) => {
  const { t } = useTranslation();
  const currentProject = useAppSelector((state) => state.project.currentProject);

  const userData = useSelector((state: any) => state.userData.userData);

  useEffect(() => {
    scrollToSection();
  }, [currentProject]);

  const scrollToSection = () => {
    const sectionFragment = window.location.hash.slice(1);
    if (sectionFragment) {
      setTimeout(() => {
        const section = document.getElementById(sectionFragment);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
        }
      }, 1000);
    }
  };

  const hasUserAccess =
    currentProject && userData
      ? !userHasAccessProjectFields(userData) && currentProject.status === projectStatusAsNumber.completed
      : false;

  return (
    <div className="mainA3 a3-main">
      <div className="a3-main-body">
        <h1 className="tw-text-3xl tw-mb-10">{currentProject?.name}</h1>

        <div id="10.1">
          <Section1Form
            id={id}
            hasUserAccess={hasUserAccess}
          />
        </div>

        <div id="10.2">
          <Suspense fallback={<Spin size="large" />}>
            <Section2
              currentProject={currentProject}
              id={id}
              hasUserAccess={hasUserAccess}
              userData={userData}
              showGantt
            />
          </Suspense>
        </div>

        <div id="10.3">
          <Suspense fallback={<Spin size="large" />}>
            <Section3
              t={t}
              id={id}
              hasUserAccess={hasUserAccess}
            />
          </Suspense>
        </div>

        <div id="10.4">
          <Suspense fallback={<Spin size="large" />}>
            <Section4
              t={t}
              id={id}
              hasUserAccess={hasUserAccess}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default A3Index;
