import { Col, Row } from 'antd';
import React from 'react';
import { useAppSelector } from 'src/redux/store';
import Feed from '../../shared/feed/feed';
import { feedObjectType, feedRegardingObjectType } from '../../shared/utils/constants';

interface AnnualGoalFeedProps {
  annualGoalId: string | undefined;
  defaultCheckedFeedType: number;
  commentFeedsOnly: boolean;
  fileFeedsOnly: boolean;
  othersFeedsOnly: boolean;
}

const AnnualGoalFeed: React.FC<AnnualGoalFeedProps> = ({
  annualGoalId,
  defaultCheckedFeedType,
  commentFeedsOnly,
  fileFeedsOnly,
  othersFeedsOnly,
}) => {
  const userData = useAppSelector((state) => state.userData.userData);

  return (
    <Row gutter={{ lg: 24 }}>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}>
        <Feed
          commentFeedsOnly={commentFeedsOnly}
          fileFeedsOnly={fileFeedsOnly}
          defaultCheckedFeedType={defaultCheckedFeedType}
          othersFeedsOnly={othersFeedsOnly}
          objectId={annualGoalId}
          userData={userData}
          objectType={feedObjectType.obAnnoFeed}
          isProject={0}
          feedRegardingObjType={feedRegardingObjectType.annualGoal}
        />
      </Col>
    </Row>
  );
};

export default AnnualGoalFeed;
