import { EditOutlined } from '@ant-design/icons';
import { Button, message, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UploadKpiSpecifico from 'src/components/kpiListPage/uploadKpiSpecifico';
import { userHasAccessProjectFields } from 'src/components/shared/utils/authRolesProvilege/authRolesPrivilege';
import { notifyMessages } from 'src/components/shared/utils/notifyMessages';
import {
  GetKPISectionA3ResponseDto,
  GetProjectMembersResponseDto,
  GetProjectResponseDto,
  ProjectStatus,
} from 'src/connectors/backend/api';
import { useAppSelector } from 'src/redux/store';
import DeliverableService from 'src/services/pages/projectServices/deliverableServices';
import ProgettiService from 'src/services/pages/projectServices/progettiService';
import Section1Service from '../../../services/a3/section1Service';
import EditProjectKpi from '../../projects/projectForm/deliverablesAndKpi/editKpi';
import { italianNumberFormat, kpiSemaphoreColor } from '../../shared/utils/functions';
import { projectStatusAsNumber } from 'src/components/shared/utils/constants';

const { Text } = Typography;

interface IndicatoriTargetProps {
  currentProject: GetProjectResponseDto;
  isPresentationMode?: boolean;
  dynaFontClass?: string;
}

const IndicatoriTarget: React.FC<IndicatoriTargetProps> = ({
  currentProject,
  isPresentationMode = false,
  dynaFontClass = '',
}) => {
  const [kpiList, setKpiList] = useState<GetKPISectionA3ResponseDto[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [showEditKpiModal, setShowEditKpiModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<GetKPISectionA3ResponseDto | null>(null);
  const [showUploadKpiModal, setShowUploadKpiModal] = useState<boolean>(false);
  const [projectMembers, setProjectMembers] = useState<GetProjectMembersResponseDto[]>([]);
  const userData = useAppSelector((state) => state.userData.userData);

  const { t } = useTranslation();

  useEffect(() => {
    console.log('🚀 ~ retrieveKPIList ~ currentProject.projectID:', currentProject);

    if (currentProject?.projectID) {
      retrieveKPIList();
    }
  }, [currentProject]);

  useEffect(() => {
    if (showEditKpiModal) {
      getProjectMembers();
    }
  }, [showEditKpiModal]);

  const getProjectMembers = () => {
    if (!currentProject.projectID) {
      return;
    }
    setLoadingData(true);
    ProgettiService.getProjectTeamMembers(currentProject.projectID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setProjectMembers(resp.responseObject?.value || []);
          setLoadingData(false);
        } else {
          setLoadingData(false);
        }
      })
      .catch((error) => {
        setLoadingData(false);
      });
  };

  const retrieveKPIList = () => {
    if (!currentProject.projectID) {
      return;
    }

    setLoadingData(true);
    Section1Service.getKPI(currentProject.projectID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject?.value;
          console.log('🚀 ~ .then ~ respData:', respData);

          setKpiList(respData || []);
          setLoadingData(false);
        } else {
          setLoadingData(false);
        }
      })
      .catch((error) => {
        setLoadingData(false);
      });
  };

  const toggleEditKpiModal = (rowData: GetKPISectionA3ResponseDto) => {
    setSelectedRow(rowData);
    setShowEditKpiModal(!showEditKpiModal);
  };

  // const toggleUploadKpiSpecificoModal = (rowData: GetKPISectionA3ResponseDto) => {
  //   setShowUploadKpiModal(!showUploadKpiModal);
  //   setSelectedRow(rowData);
  // };

  // const removeKpi = (kpiProjectRelationShipID: string | null | undefined) => {
  //   if (!kpiProjectRelationShipID) {
  //     message.error((notifyMessages as any).deleteFailed);
  //     return;
  //   }

  //   DeliverableService.removeKpi(kpiProjectRelationShipID)
  //     .then((response) => response.data)
  //     .then((resp) => {
  //       if (resp.success) {
  //         message.success((notifyMessages as any).deleteSuccess);
  //         retrieveKPIList();
  //       } else {
  //         message.error((notifyMessages as any).deleteFailed);
  //       }
  //     })
  //     .catch((error) => {
  //       message.error((notifyMessages as any).deleteFailed);
  //     });
  // };

  const updateBowlingChart = (payload: any) => {
    DeliverableService.updateKpiBowlingChart(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success((notifyMessages as any).updateSuccess);
          setShowEditKpiModal(false);
          setSelectedRow(null);
          retrieveKPIList();
        } else {
          message.error((notifyMessages as any).updateFailed);
        }
      })
      .catch((error) => {
        message.error((notifyMessages as any).updateFailed);
      });
  };

  const kpiColumns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      className: 'twoRowEllipses',
      render: (text: string, record: GetKPISectionA3ResponseDto) => {
        return {
          props: {
            style: { borderLeft: `5px solid  ${kpiSemaphoreColor(record.semaforo)}` },
          },
          children: (
            <div>
              {record.showInA3 === 1 && (
                <Tooltip title={text}>
                  <Text strong>{record.name}</Text>{' '}
                </Tooltip>
              )}

              {record.showInA3 !== 1 && (
                <Tooltip title={text}>
                  <Text>{record.name}</Text>
                </Tooltip>
              )}
            </div>
          ),
        };
      },
    },

    {
      title: `${t('general.jumpOffPoint')}`,
      dataIndex: 'jumpOffPoint',
      key: 'jumpOffPoint',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record: GetKPISectionA3ResponseDto) => (
        <Text>{italianNumberFormat(record.jumpOffPoint)}</Text>
      ),
    },
    {
      title: `${t('kpiPage.finalTarget')}`,
      dataIndex: 'target',
      key: 'target',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record: GetKPISectionA3ResponseDto) => <Text>{italianNumberFormat(record.target)}</Text>,
    },
    {
      title: `${t('general.updateYtd')}`,
      dataIndex: 'updateYTD',
      key: 'updateYTD',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record: GetKPISectionA3ResponseDto) => (
        <Text>{italianNumberFormat(record.updateYTD)}</Text>
      ),
    },
    {
      title: `${t('general.targetYtd')}`,
      dataIndex: 'targetYTD',
      key: 'targetYTD',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record: GetKPISectionA3ResponseDto) => (
        <Text>{italianNumberFormat(record.targetYTD)}</Text>
      ),
    },
    {
      key: 'action',
      width: '50px',
      render: (_: string, record: GetKPISectionA3ResponseDto) => {
        const hasUserAccess =
          currentProject && userData
            ? !userHasAccessProjectFields(userData, currentProject.teamLeaderID, currentProject.sponsorID) &&
              currentProject.status === projectStatusAsNumber.completed
              ? true
              : false
            : null;

        return (
          <>
            <div className="tw-flex tw-items-center tw-gap-2">
              <Button
                icon={<EditOutlined />}
                onClick={() => toggleEditKpiModal(record)}></Button>
              {/* <Popconfirm
              placement="topRight"
              title={t('proggetiPage.rimuoveProgettoKpi')}
              onConfirm={() => removeKpi(record.kpiProjectRelationShipID)}
              okText={t('general.si')}
              cancelText={t('general.no')}>
              <Button icon={<DeleteOutlined />}></Button>
            </Popconfirm> */}

              {/* <Tooltip placement="top">
              <Button
                onClick={() => toggleUploadKpiSpecificoModal(record)}
                icon={<UploadOutlined />}></Button>
            </Tooltip> */}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        className={`${isPresentationMode ? dynaFontClass : ''}`}
        columns={kpiColumns}
        dataSource={kpiList}
        pagination={false}
        size="small"
        loading={loadingData}
        rowKey={(obj) => obj.kpiProjectRelationShipID || crypto.randomUUID()}
      />

      {showEditKpiModal && (
        <EditProjectKpi
          showEditKpiModal={showEditKpiModal}
          handleEditKpiModalClose={() => {
            setShowEditKpiModal(false);
            setSelectedRow(null);
          }}
          handleUpdateBowlingChart={updateBowlingChart}
          projectId={currentProject.projectID}
          kpiRowData={selectedRow}
          projectTeamMembers={() => projectMembers.map((member) => member.userID)}
          currentProject={currentProject}
        />
      )}

      {showUploadKpiModal && (
        <UploadKpiSpecifico
          showUploadKpiModal={showUploadKpiModal}
          handleUploadKpiModalClose={() => {
            setShowUploadKpiModal(false);
            setSelectedRow(null);
          }}
          projectId={currentProject.projectID}
          kpiRowData={selectedRow}
          projectName={currentProject.name}
        />
      )}
    </>
  );
};

export default IndicatoriTarget;
