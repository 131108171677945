import { BorderInnerOutlined, ContainerOutlined, FolderOpenOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import { Button, Space, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { GetUserCommitteeDto } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import UsersService from '../../services/pages/usersService';

const UserComities: React.FC<{
  userId?: string;
}> = ({ userId }) => {
  const userData = useAppSelector((state) => state.userData.userData);
  const { t } = useTranslation();

  const [loadingComities, setLoadingComities] = useState(false);
  const [userCommities, setUserCommities] = useState<GetUserCommitteeDto[]>([]);

  useEffect(() => {
    if ((userData && userData.userId) || userId) {
      retrieveUserCommities();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const retrieveUserCommities = async () => {
    setLoadingComities(true);
    try {
      const response = await UsersService.getUserCommities(userId || userData?.userId || '');
      const data = response.data;
      if (data.success) {
        setUserCommities(data.responseObject?.value || []);
      }
    } catch {
      // Handle errors if necessary
    } finally {
      setLoadingComities(false);
    }
  };

  const userCommitteeColumns: TableColumnsType<any> = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'committeeName',
      ellipsis: {
        showTitle: true,
      },
      sorter: (a: any, b: any) => a.committeeName.localeCompare(b.committeeName),
      showSorterTooltip: true,
      defaultSortOrder: 'ascend',
      render: (text: string, record: any) => (
        <Link
          to={`/committieDas/id/${record.committeeID}`}
          style={{ color: '#323232' }}>
          {record.committeeName}
        </Link>
      ),
    },
    {
      key: 'action3',
      width: '50px',
      render: (text: string, record: any) => (
        <Space>
          <Tooltip title={t('dashboardProgettiPage.dashboardProgetti')}>
            <Button
              icon={<BorderInnerOutlined />}
              href={`/progettihoshinkanri/${record.committeeID}`}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      key: 'action2',
      width: '50px',
      render: (text: string, record: any) => (
        <Space>
          <Tooltip title={t('comitatiPage.goToComitieDashboard')}>
            <Button
              icon={<FolderOpenOutlined />}
              href={`/committieDas/id/${record.committeeID}`}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      key: 'action2',
      width: '50px',
      render: (text: string, record: any) => (
        <>
          {record.repositoryLink && (
            <Space>
              <Tooltip title={t('comitatiPage.repositoryLink')}>
                <Button
                  href={record.repositoryLink}
                  target="_blank"
                  icon={<ContainerOutlined />}
                  rel="noreferrer"
                />
              </Tooltip>
            </Space>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      columns={userCommitteeColumns}
      dataSource={userCommities}
      rowKey={(obj: any) => obj.committeeID}
      size="small"
      loading={loadingComities}
      pagination={{ pageSize: 5 }}
    />
  );
};

export default UserComities;
