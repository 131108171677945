import { Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Routines from 'src/components/a3/section1/Routines';
import A3BlueTitle from 'src/components/A3BlueTitle';
import { useAppSelector } from 'src/redux/store';
import ProjectGoalEditor from '../../projects/projectGoalEditor/goalEditor';
import ReasonToAct from '../../projects/projectReasonToAct/reasonToAct';
import Loader2 from '../../shared/components/loader2/loader2';
import TableLayout from '../../shared/tableLayout';
import IndicatoriTarget from './indicatoriTarget';

interface Section1FormProps {
  id: string;
  hasUserAccess: boolean;
}

const Section1Form: React.FC<Section1FormProps> = ({ id, hasUserAccess }) => {
  const project = useAppSelector((state) => state.project.currentProject);

  const { t } = useTranslation();

  return (
    <div>
      <div>
        <h1 className="tw-text-3xl">{t('a3Page.motivoPerAgire')}</h1>

        {!project && <Loader2 />}
        {project && (
          <div className="tw-flex tw-flex-col tw-gap-8">
            {/* Motivi per agire e risultato atteso */}

            <Row
              gutter={32}
              className="tw-border-b">
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                className="tw-border-r tw-p-6">
                <Card
                  className="!tw-shadow-none"
                  bordered={false}>
                  <ReasonToAct
                    isEditable={!hasUserAccess}
                    isPresentationMode={false}
                    key={'groundToAct'}
                  />
                </Card>
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                className="tw-p-6">
                <Card
                  className="!tw-shadow-none"
                  bordered={false}>
                  <ProjectGoalEditor
                    isEditable={!hasUserAccess}
                    isPresentationMode={false}
                    key={'projectGoal'}
                  />
                </Card>
              </Col>
            </Row>

            <Routines
              currentProject={project}
              id={id}
            />

            {/* Indicatori e target */}

            <TableLayout
              title={
                <A3BlueTitle
                  title={t('a3Page.indicatoriTarget')}
                  href={`/progetti/id/${id}/?tab=8`}
                />
              }>
              <TableLayout.Content>
                <IndicatoriTarget currentProject={project} />
              </TableLayout.Content>
            </TableLayout>
          </div>
        )}
      </div>
    </div>
  );
};

export default Section1Form;
