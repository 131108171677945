import React from 'react';
import ComitieCalendar from './calendar';

interface CalendarIndexProps {
  comitieId?: string;
  comitieDetails: any;
}

const CalendarIndex: React.FC<CalendarIndexProps> = ({ comitieId, comitieDetails }) => {
  return (
    <div className="comitieCalendar">
      <ComitieCalendar
        comitieId={comitieId}
        comitieDetails={comitieDetails}
      />
    </div>
  );
};

export default CalendarIndex;
