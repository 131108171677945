import { Form } from 'antd';
import React from 'react';
import './companies.scss';

import { SaveOutlined, SyncOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Input, message, Row, Space, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import { history } from '../../../App';
import CompaniesListPageService from '../../../services/pages/companiesListPageServices';
import { notifyMessages, requiredFields } from '../../shared/utils/notifyMessages';

const { Text } = Typography;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const AlertTitle = ({ t }) => {
  return (
    <Space direction="horizontal">
      <SyncOutlined
        style={{ color: '#0f87dc', marginRight: 5 }}
        spin
      />
      <span>{t('societaPage.creazioneSocietaInCorso')}</span>
    </Space>
  );
};

const DBNameRequirments = ({ t }) => {
  return (
    <Space
      direction="vertical"
      id="warning-text">
      <Text
        type="warning"
        style={{ color: '#cc3e00' }}>
        * {t('societaPage.databaseNomeNoSpazi')}.{' '}
      </Text>
      <Text
        type="warning"
        style={{ color: '#cc3e00' }}>
        * {t('societaPage.darabaseNomeLungo')}.{' '}
      </Text>
      <Text
        type="warning"
        style={{ color: '#cc3e00' }}>
        * {t('notifications.dbNameStartWithNumber')}.{' '}
      </Text>
    </Space>
  );
};

class AddNewCompany extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      loadingButton: false,
      companyCreated: false,
      companyInProgress: false,
      companyCreatedFailed: false,
    };
  }

  componentDidMount() {}

  addData = (values) => {
    this.setState({ loadingButton: true });
    this.setState({ companyInProgress: true });

    CompaniesListPageService.addCompany(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ loadingButton: false });
          this.setState({ companyInProgress: false });
          this.setState({ companyCreated: true });
          message.success(notifyMessages.addSuccess, 3, () => {
            this.props.history.push('/settings?tab=0');
          });
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
          this.setState({ companyInProgress: false });
          this.setState({ companyCreatedFailed: true });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
        this.setState({ companyInProgress: false });
        this.setState({ companyCreatedFailed: true });
      });
  };

  backFunction = () => {
    history.goBack();
  };

  handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  onPasteEvent = (e) => {
    e.preventDefault();
    return false;
  };

  checkDbNameStartsWithCaracterOnly(dbName) {
    if (dbName) {
      return /^[A-Za-z]/.test(dbName);
    }
  }

  handleChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }
  };

  handleSave = (values) => {
    if (this.checkDbNameStartsWithCaracterOnly(values.companyShortName)) {
      this.addData(values);
    } else {
      message.error(notifyMessages.dbNameStartWithNumber);
    }
  };

  render() {
    const { loadingButton, companyCreated, companyInProgress, companyCreatedFailed } = this.state;
    let t = this.props.t;

    return (
      <div className="edit-company-wrapper">
        <div className="site-card-wrapper">{t('societaPage.aggiungiSocieta')}</div>
        <Card bordered={false}>
          <Form
            name="add_ob_lungo"
            layout="vertical"
            {...formItemLayout}
            onFinish={this.handleSave}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 4 }}
                sm={{ span: 4 }}
                md={{ span: 4 }}
                lg={{ span: 4 }}>
                {/* <Button
                  //htmlType="submit"
                  onClick={this.backFunction}
                  className="backButton">
                  <RollbackOutlined />
                  {t('buttons.indietro')}
                </Button> */}
              </Col>
              <Col
                className="gutter-row"
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 20 }}>
                <div className="button-wrapper">
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Button
                      className="companies-button"
                      loading={loadingButton}
                      type="primary"
                      htmlType="submit"
                      disabled={!this.state.fieldsChanged}>
                      <SaveOutlined /> {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                {companyInProgress && (
                  <Alert
                    message={<AlertTitle t={t} />}
                    type="info"
                    showIcon
                  />
                )}

                {companyCreated && (
                  <Alert
                    message={t('societaPage.creazioneSocietaSuccess')}
                    type="success"
                    showIcon
                    closable
                  />
                )}

                {companyCreatedFailed && (
                  <Alert
                    message={t('societaPage.creazioneSocietaFailed')}
                    type="error"
                    showIcon
                    closable
                  />
                )}
              </Col>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}>
                <Card title={t('general.informazioniGenerali')}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Form.Item
                        className="main-container"
                        label={t('societaPage.nomeAzienda')}
                        name="companyName"
                        rules={[{ required: true, message: requiredFields.required }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Form.Item
                        className="main-container"
                        extra={<DBNameRequirments t={t} />}
                        label={t('societaPage.nomeDatabase')}
                        name="companyShortName"
                        rules={[
                          { required: true, message: requiredFields.required },
                          { max: 30, message: `${t('societaPage.darabaseNomeLungo')}` },
                        ]}>
                        <Input
                          maxLength={30}
                          onKeyDown={this.handleKeyDown}
                          onChange={this.handleChange}
                          onPaste={this.onPasteEvent}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}
export default withTranslation()(AddNewCompany);
