import {
  HourglassOutlined,
  MailOutlined,
  PhoneOutlined,
  RiseOutlined,
  SaveOutlined,
  SolutionOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tabs,
  Typography,
  Upload,
  message,
} from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userRoles, userRolesCodes } from 'src/components/shared/utils/constants';
import {
  GetDivisionPicklistDto,
  GetFullProfileDto,
  GetRolePicklistDto,
  ObjectCodePicklistResponseDto,
} from 'src/connectors/backend';
import { useAppSelector } from '../../redux/store';
import CommonService from '../../services/commonService';
import DashboardService from '../../services/pages/dashboardService';
import UsersService from '../../services/pages/usersService';
import Loader from '../shared/components/loader/loader';
import {
  checkUserIsAdminUser,
  checkUserIsLenovysAdmin,
  checkUserIsNormalUser,
} from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { NoAccessMesssage, getCurrencySymbol } from '../shared/utils/functions';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';
import UserComities from './userComities';
import UserProjects from './userProjects';
import UserResourceWorkload from './userWorkload';

const { Option } = Select;

interface UserProfileProps {
  match: { params: { id: string; tab: string } };
}

const UserProfile: React.FC<UserProfileProps> = ({ match }) => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);
  const companyData = useAppSelector((state) => state.companyData.companyData);

  const [loadingButton, setLoadingButton] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);
  const [curentUserData, setCurentUserData] = useState<GetFullProfileDto | null>(null);
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [divisionsList, setDivisionsList] = useState<GetDivisionPicklistDto[]>([]);
  const [userTypes, setUserTypes] = useState<ObjectCodePicklistResponseDto[]>([]);
  const [roleList, setRoleList] = useState<GetRolePicklistDto[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(match.params.tab || '1');

  useEffect(() => {
    retrieveComponentData();
    retrieveUserProfileInfo();
    retrieveDivisions();
    retrieveUserType();
    retrieveRoles();
  }, [match.params.id]);

  const retrieveComponentData = () => {
    const { id } = match.params;
    const userId = id === '0' || id === undefined ? userData?.userId : id;
    if (!userId) {
      return;
    }
    setSelectedUserId(userId);
  };

  const retrieveUserProfileInfo = async () => {
    const { id } = match.params;
    const userId = id === '0' || id === undefined ? userData?.userId : id;

    if (!userId) {
      return;
    }

    if (userData && Object.keys(userData).length > 0) {
      try {
        const response = await UsersService.getUserProfile(userId, moment().format('YYYY'));
        if (response.data.success) {
          setCurentUserData(response.data.responseObject?.value || null);
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      } catch {
        message.error(notifyMessages.retrieveFailed);
      }
    }
  };

  const retrieveDivisions = async () => {
    try {
      const response = await DashboardService.getDivisionFilterData();
      if (response.data.success) {
        setDivisionsList(response.data?.responseObject?.value || []);
      }
    } catch {}
  };

  const retrieveUserType = async () => {
    try {
      const response = await CommonService.getCalculationTypeList('userType');
      if (response.data.success) {
        setUserTypes(response.data.responseObject?.value || []);
      }
    } catch {}
  };

  const retrieveRoles = async () => {
    try {
      const response = await DashboardService.getUserRole();
      if (response.data.success) {
        setRoleList(response.data.responseObject?.value || []);
      }
    } catch {}
  };

  const updateUserProfileInfo = async (values: any) => {
    setLoadingButton(true);

    if (!curentUserData?.userProfile?.userID) {
      return;
    }

    try {
      const response = await UsersService.updateUserData(
        curentUserData?.userProfile?.userID,
        values.type,
        values.roleID,
        values.isDisabled,
        values.jobTitle,
        values.dailyRate ?? undefined,
        values.emailAddress,
        uploadedFile,
        values.divisionID ?? undefined,
        values.phoneNumber,
        values.firstName,
        values.surname,
      );
      if (response.data.success) {
        message.success(notifyMessages.updateSuccess);
        retrieveUserProfileInfo();
        setFieldsChanged(false);
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  const removeUploadedFile = () => setUploadedFile(undefined);

  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const beforeUpload = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJpgOrPng) {
      message.error(requiredFields.uploadCheckFileType);
    }
    if (!isLt2M) {
      message.error(requiredFields.uploadCheckFileSize);
    }
    setUploadedFile(file);
    return isJpgOrPng && isLt2M;
  };

  if (!curentUserData) {
    return <Loader />;
  }

  if (curentUserData.userProfile?.isDeleted) {
    return <NoAccessMesssage />;
  }

  return (
    <>
      <Loader />

      {curentUserData && curentUserData.userProfile && !curentUserData.userProfile.isDeleted && (
        <Form
          name="user_profile"
          layout="vertical"
          onFinish={(values) => updateUserProfileInfo(values)}
          onValuesChange={() => {
            setFieldsChanged(true);
          }}>
          <Row>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 9 }}>
              <Card bordered={false}>
                <div className="tw-flex tw-items-center tw-justify-between">
                  <Typography.Title level={3}>
                    {selectedUserId === userData?.userId ? t('general.myProfile') : t('general.profile')}
                  </Typography.Title>
                  <Form.Item className="!tw-mb-0">
                    <Button
                      loading={loadingButton}
                      type="primary"
                      htmlType="submit"
                      className="tw-float-end"
                      disabled={!fieldsChanged}>
                      <SaveOutlined />
                      {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                </div>
                <div className="tw-flex tw-items-center tw-gap-4">
                  <div className="tw-shrink-0">
                    {curentUserData.userProfile.profilePhoto && (
                      <Avatar
                        className="avatar"
                        size={50}
                        icon={<UserOutlined />}
                        src={`${curentUserData.userProfile.profilePhoto}?${+new Date()}`}
                      />
                    )}
                    {!curentUserData.userProfile.profilePhoto && (
                      <Avatar
                        size={50}
                        icon={<UserOutlined />}
                      />
                    )}
                  </div>

                  <Form.Item
                    name="upload"
                    // label={t('general.image')}
                    // extra={t('utentiPage.caricaFotoProfilo')}
                    className="!tw-mb-0">
                    <Upload
                      accept="image/*"
                      maxCount={1}
                      listType="picture"
                      onChange={handleChange}
                      onRemove={() => removeUploadedFile()}
                      beforeUpload={(file) => {
                        setUploadedFile(file);
                        return false;
                      }}>
                      <Button
                        disabled={checkUserIsAdminUser(userData)}
                        icon={<UploadOutlined />}>
                        {t('general.cambiaImagine')}
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>

                <Divider />

                <Form.Item
                  label={t('general.nome')}
                  name="firstName"
                  initialValue={curentUserData.userProfile.name ?? 'ok'}>
                  <Input
                    prefix={<UserOutlined />}
                    disabled={checkUserIsAdminUser(userData)}
                  />
                </Form.Item>

                <Form.Item
                  label={t('general.cognome')}
                  name="surname"
                  initialValue={curentUserData.userProfile.surname}>
                  <Input
                    prefix={<UserOutlined />}
                    disabled={checkUserIsAdminUser(userData)}
                  />
                </Form.Item>

                <Form.Item
                  label={t('utentiPage.titoloLavoro')}
                  name="jobTitle"
                  initialValue={curentUserData.userProfile.jobTitle}>
                  <Input
                    prefix={<SolutionOutlined />}
                    disabled={checkUserIsAdminUser(userData)}
                  />
                </Form.Item>

                <Form.Item
                  label={t('general.ruolo')}
                  name="roleID"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={curentUserData.userProfile.roleID}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    disabled={checkUserIsAdminUser(userData)}>
                    {roleList &&
                      roleList.map((item) => (
                        <Option
                          value={item.roleID}
                          key={item.roleID}
                          disabled={
                            userData?.roleCode === userRolesCodes.companyAdmin &&
                            item.roleDescription === userRoles.companyAdmin
                              ? true
                              : false
                          }>
                          {item.roleDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t('utentiPage.divisioneReparto')}
                  name="divisionID"
                  hasFeedback
                  initialValue={curentUserData.userProfile.divisionID}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    disabled={checkUserIsAdminUser(userData)}>
                    {divisionsList &&
                      divisionsList.map((item) => (
                        <Option
                          disabled={item.hasAccess === 1 ? false : true}
                          value={item.divisionID}
                          key={item.divisionID}>
                          {item.divisionName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t('general.email')}
                  name="emailAddress"
                  initialValue={curentUserData.userProfile.emailAddress}>
                  <Input
                    prefix={<MailOutlined />}
                    disabled={true}
                  />
                </Form.Item>

                <Form.Item
                  label={t('utentiPage.recapitoTelefonico')}
                  name="phoneNumber"
                  initialValue={curentUserData.userProfile.phoneNumber}>
                  <Input
                    prefix={<PhoneOutlined />}
                    disabled={checkUserIsNormalUser(userData)}
                  />
                </Form.Item>

                <Form.Item
                  label={t('utentiPage.tipologiaUtente')}
                  name="type"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={curentUserData.userProfile.type}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    disabled={checkUserIsAdminUser(userData)}>
                    {userTypes &&
                      userTypes.map((item) => (
                        <Option
                          value={item.objectCodeListID}
                          key={item.objectCodeListID}>
                          {item.description}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <div
                  className="ant-row ant-form-item formInputs"
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div className="ant-col ant-col-10 ant-form-item-label ant-form-item-label-left">
                    <label>{t('utentiPage.disabilitatoAnno')}</label>
                  </div>
                  <div className="ant-col ant-col-14 ant-form-item-control">
                    <InputNumber
                      disabled={true}
                      value={curentUserData.userProfile.availability}
                      min={0}
                      prefix={<HourglassOutlined />}
                    />
                  </div>
                </div>

                <Form.Item
                  label={t('utentiPage.costoGiornalero')}
                  name="dailyRate"
                  initialValue={curentUserData.userProfile.dailyRate}>
                  <InputNumber
                    min={0}
                    prefix={<RiseOutlined />}
                    disabled={checkUserIsAdminUser(userData)}
                  />
                </Form.Item>

                <div
                  className="ant-row ant-form-item formInputs"
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div className="ant-col ant-col-10 ant-form-item-label ant-form-item-label-left">
                    <label>{t('utentiPage.valuta')}</label>
                  </div>
                  <div className="ant-col ant-col-14 ant-form-item-control">
                    <Input
                      value={companyData ? getCurrencySymbol(companyData.currency) + ' - ' + companyData.currency : ''}
                      disabled={true}
                    />
                  </div>
                </div>

                <Form.Item
                  className="tw-mt-2"
                  label={t('utentiPage.disabilitato')}
                  tooltip={t('utentiPage.disabilitatoInfoMessage')}
                  name="isDisabled"
                  valuePropName="checked"
                  initialValue={curentUserData.userProfile.isDisabled}>
                  <Switch
                    checkedChildren={t('general.si')}
                    unCheckedChildren={t('general.no')}
                    disabled={!checkUserIsLenovysAdmin(userData)}
                  />
                </Form.Item>
              </Card>
            </Col>

            <Col
              className="-tw-mt-3"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 15 }}>
              <Card bordered={false}>
                {/* <Row style={{ justifyContent: 'space-between' }}>
                  <Button
                    onClick={backFunction}
                    className="backButton">
                    <RollbackOutlined />
                    {t('buttons.indietro')}
                  </Button>
                </Row> */}

                {selectedUserId && (
                  <Tabs
                    defaultActiveKey={activeTab}
                    items={[
                      {
                        label: <>{t('general.progetti')}</>,
                        key: '1',
                        children: (
                          <UserProjects
                            toFilter={'all'}
                            userId={selectedUserId}
                          />
                        ),
                      },
                      {
                        label: <>{t('general.comitato')}</>,
                        key: '2',
                        children: <UserComities userId={selectedUserId} />,
                      },
                      {
                        label: <>{t('utentiPage.caricoPersonale')}</>,
                        key: '3',
                        children: <UserResourceWorkload userId={selectedUserId} />,
                      },
                    ]}></Tabs>
                )}
              </Card>
            </Col>
          </Row>
        </Form>
      )}

      {curentUserData && curentUserData.userProfile && curentUserData.userProfile.isDeleted && <NoAccessMesssage />}
    </>
  );
};

export default UserProfile;
