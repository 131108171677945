import { CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message, Space, Switch, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ObiettiviAnnoService from '../../services/pages/obiettiviAnnoService';
import TableLayout from '../shared/tableLayout';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './obietiviAnno.scss';

const YearObjectivesList: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [loadingData, setLoadingData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [obiettoAnnoListData, setObiettoAnnoListData] = useState<any[]>([]);
  const [obAnnualRelationCheck, setObAnnualRelationCheck] = useState(false);

  useEffect(() => {
    retrieveObiettivoAnnoData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obAnnualRelationCheck]);

  const retrieveObiettivoAnnoData = async () => {
    setLoadingData(true);
    try {
      const response = await ObiettiviAnnoService.getObiettiviAnnoList(obAnnualRelationCheck);
      if (response.data.success) {
        setObiettoAnnoListData(response.data.responseObject?.value || []);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setLoadingData(false);
    }
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />
    ),
    onFilter: (value: string, record: any) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const onNew = () => {
    history.push('/obiettiviannuali/new');
  };

  const onChecked = (checked: boolean) => {
    setObAnnualRelationCheck(checked);
  };

  const columns: any = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'description',
      width: '35%',
      ellipsis: { showTitle: true },
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
      ...getColumnSearchProps('description'),
      render: (title: string, record: any) => (
        <Tooltip title={title}>
          <Link to={`/obiettiviannuali/details/id/${record.annualGoalID}`}>{title}</Link>
        </Tooltip>
      ),
    },
    {
      title: `${t('general.responsabile')}`,
      dataIndex: 'ownerName',
      sorter: (a: any, b: any) => a.ownerName.localeCompare(b.ownerName),
      ...getColumnSearchProps('ownerName'),
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      width: '150px',
      sorter: (a: any, b: any) => a.statusDescription.localeCompare(b.statusDescription),
      ...getColumnSearchProps('statusDescription'),
    },
    {
      key: 'action',
      width: '50px',
      render: (_: any, record: any) => (
        <Button
          icon={<EditOutlined />}
          href={`/obiettiviannuali/details/id/${record.annualGoalID}`}
        />
      ),
    },
  ];

  return (
    <Card>
      <TableLayout title={t('obiettiviAnnoPage.obbietiviAnuali')}>
        <TableLayout.Actions>
          <Form.Item
            className="tw-mb-0 tw-mr-4"
            label={t('obiettiviAnnoPage.objSenzaRelazioni')}>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={false}
              onChange={onChecked}
            />
          </Form.Item>
          <Button
            type="primary"
            onClick={onNew}>
            <PlusOutlined />
            {t('buttons.aggiungiNuovo')}
          </Button>
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            columns={columns}
            size="small"
            dataSource={obiettoAnnoListData}
            rowKey={(record: any) => record.annualGoalID}
            loading={loadingData}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
          />
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
};

export default YearObjectivesList;
