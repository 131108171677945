import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  AnnualGoalApi,
  CommonApi,
  InsertNextYearXMatrixRequestDto,
  InsertXmatrixCycleRequestDto,
  InsertXMatrixRequestDto,
  InserXMatrixDivisionRequestDto,
  UpdateXMatrixByIDRequestDto,
  UpdateXMatrixCycleRequestDto,
  XMatrixApi,
  XMatrixFilters,
  XMatrixSecondLevelFilters,
} from 'src/connectors/backend';

const MatrixService = {
  getMatrixData: async function (xmatrixFilters: XMatrixFilters) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrix(xmatrixFilters));
  },

  getUsersXmatrix: async function () {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrixUsers());
  },

  getLongTermGoals: async function () {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixLongTermGoalPicklist());
  },

  getAnnualGoals: async function () {
    const api = await authenticatedApi(AnnualGoalApi);

    return trackPromise(api.annualGoalGetAnualGoalPicklist());
  },

  getNewXmatrixAnnualGoals: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId));
  },

  getNewXmatrixProjects: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetNewXmatrixProjectPicklist(xmatrixId));
  },

  getProjectByDivision: async function (divisionId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXmatrixDivisionParentProjectPicklist(divisionId));
  },

  getDivisionPicklist: async function (projectId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXmatrixDivisionPicklist());
  },

  getXmatrixPicklist: async function () {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrixPicklist());
  },

  addNewXmatrixNextYear: async function (payload: InsertNextYearXMatrixRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixInsertNextYearXMatrix(payload));
  },

  addNewXmatrix: async function (payload: InsertXMatrixRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixInsertXMatrix(payload));
  },

  //xmatrix filter apis

  getAnnualGoalsXmatrixFilter: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetAnnualGoalPicklistXMatrix(xmatrixId));
  },

  getLongTermGoalsXmatrixFilter: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetLongTermGoalPicklistXMatrix(xmatrixId));
  },

  getProjectXmatrixFilter: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetProjectPicklistXMatrix(xmatrixId));
  },

  getCommittieXmatrixFilter: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetCommitteePicklistXMatrix(xmatrixId));
  },

  getDivisionXmatrixFilter: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetDivisionPicklistXMatrix(xmatrixId));
  },

  // update xmatrix status
  updateXmatrixStatus: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateXMatrixStatus(xmatrixId));
  },

  deleteXmatrixDraft: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixDeleteDraftXMatrix(xmatrixId));
  },

  getXmatrixDivisionRootList: async function () {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetRootXMatrixDivisionList());
  },

  getSecondLevelXmatrixData: async function (filters: XMatrixSecondLevelFilters, xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetDivisionDataXMatrix(filters));
  },

  getSelectedXmatrixInfo: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrixInfo(xmatrixId));
  },

  getNewDivisionXMatrixAnnualGoalPicklist: async function (xmatrixId: string, divisionId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId, divisionId));
  },

  getNewDivisionXMatrixLongTermGoalPicklist: async function (xmatrixId: string, divisionId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId, divisionId));
  },

  getNewDivisionXMatrixProjectPicklist: async function (xmatrixId: string, divisionId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId, divisionId));
  },

  getTeamLaderSecondLevelFilterData: async function (divisionId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetNewDivisionTeamLeaderPicklist(divisionId));
  },

  getSponsorSecondLevelFilterData: async function (divisionId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetNewDivisionSponsorPicklist(divisionId));
  },

  getSecondaryProjectSecondLevelFilterData: async function (xmatrixId: string, divisionId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixId, divisionId));
  },

  // add new xmatrix division
  insertXmatrixDivision: async function (payload: InserXMatrixDivisionRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixInsertXMatrixDivision(payload));
  },

  // get matrix cycle
  getXmatrixCycleList: async function (companyId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrixCycle(companyId));
  },

  insertXmatrixCycle: async function (payload: InsertXmatrixCycleRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixInserXMatrixCycle(payload));
  },

  updateXmatrixCycle: async function (payload: UpdateXMatrixCycleRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateXMatrixCycle(payload));
  },

  removeXmatrixCycle: async function (xmatrixCycleId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixDeleteXMatrixCycle(xmatrixCycleId));
  },

  getXmatrixCycleById: async function (xmatrixCycleId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrixCycleByID(xmatrixCycleId));
  },

  getXmatrixOnEditData: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetXMatrixData(xmatrixId));
  },

  updateXmatrixData: async function (payload: UpdateXMatrixByIDRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateXMatrixData(payload));
  },

  getActiveXmatrix: async function () {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetActiveXMatrixInfo());
  },
};

export default MatrixService;
