import { FolderOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, message } from 'antd';
import { Component } from 'react';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import TableLayout from '../../shared/tableLayout';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import '../project.scss';

class ChildProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
      childProjectList: null,
    };
  }

  componentWillMount() {
    this.retrieveChildProjects();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
          {/* <Button
                    type="link"
                    onClick={() => {
                      confirm({ closeDropdown: false });
                      this.setState({
                        searchText: selectedKeys[0],
                        searchedColumn: dataIndex,
                      });
                    }}
                  >
                    Filter
                  </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    // onFilterDropdownVisibleChange: visible => {
    //   if (visible) {
    //     setTimeout(() => this.searchInput.select(), 100);
    //   }
    // },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  async retrieveChildProjects() {
    const { projectId } = this.props;
    this.setState({ loadingData: true });
    await ProgettiService.getChildProjects(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ childProjectList: resp.responseObject.value });
          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  render() {
    const { loadingData, childProjectList } = this.state;
    const { t } = this.props;

    const columns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'name',
        ellipsis: {
          showTitle: true,
        },
        sorter: (a, b) => {
          a = a.name || '';
          b = b.name || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('name'),
      },
      {
        title: `${t('proggetiPage.codice')}`,
        dataIndex: 'projectCode',
        sorter: (a, b) => {
          a = a.projectCode || '';
          b = b.projectCode || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
      },
      {
        key: 'action',
        width: '30px',
        render: (_, record) => (
          <Space>
            <Link
              to={{
                pathname: `/progetti/id/${record.projectID}`,
              }}>
              <FolderOutlined />
            </Link>
          </Space>
        ),
      },
    ];

    return (
      <TableLayout title={'Progetti figli'}>
        <TableLayout.Content>
          <Table
            columns={columns}
            dataSource={childProjectList}
            bordered={true}
            size="small"
            rowKey={(obj) => obj.projectID}
            loading={loadingData}
          />
        </TableLayout.Content>
      </TableLayout>
    );
  }
}

export default ChildProjects;
