import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { usePromiseTracker } from 'react-promise-tracker';
import './loader.scss';

export const Loader = () => {
  const { promiseInProgress } = usePromiseTracker();

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  return (
    promiseInProgress && (
      <div className="spinner tw-flex tw-justify-center tw-items-center">
        <Spin indicator={antIcon} />
        <p className="loading">Loading...</p>
      </div>
    )
  );
};

export default Loader;
