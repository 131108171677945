import { FC } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Unauthorized from '../components/shared/errorPages/unauthorized/unauthorized';
import AuthWrappedRoutes from './auth/authWrappedRoutes';

const RoutersList: FC = () => {
  return (
    <Router>
      <Switch>
        <Route
          path="/unauthorized"
          Component={Unauthorized}
        />
        <AuthWrappedRoutes />
      </Switch>
    </Router>
  );
};

export default RoutersList;
