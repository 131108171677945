import React, { Component, useState } from 'react';
import './bowlingChart.scss';

import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, Popover, Row, Space, Table, Tooltip, Typography } from 'antd';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { bowlingChartColumsValues, bowlingChartRowName } from '../shared/utils/constants';
import {
  capitalizeFirstLetter,
  checkBowlingChartCellColor,
  convertCamelCaseToNormal,
  numberWithCommas,
} from '../shared/utils/functions';

const { Text } = Typography;

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = (
    <InputNumber
      min={0}
      decimalSeparator=","
      precision="2"
    />
  );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    setSearchText('');
  };

  const isEditing = (record) => record.key === editingKey;

  // const checkProjectChildKpiCellColor = (record, monthName, monthValue) => {
  //     let data = props.propsData;

  //     if (record.parentType === kpiPuntualeAcumulato.puntuale) {

  //         if (record.name === bowlingChartRowName.actual) {
  //             let targetRow = data.filter(function (item) { return item.name === bowlingChartRowName.target && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID && item.projectID === record.projectID })[0];

  //             if (record.type === kpiCalculationType.valoreMinimo) {
  //                 // if (monthValue < targetRow[monthName] || monthValue === targetRow[monthName]) {
  //                 if (monthValue < targetRow.monthsList[monthName] || monthValue === targetRow.monthsList[monthName]) {
  //                     return greenColor
  //                 }
  //                 else if (monthValue > targetRow.monthsList[monthName]) {
  //                     return redColor
  //                 }
  //                 else {
  //                     return "#FFFFFF"
  //                 }
  //             }

  //             else {
  //                 if (monthValue > targetRow.monthsList[monthName] || monthValue === targetRow.monthsList[monthName]) {
  //                     return greenColor
  //                 }
  //                 else if (monthValue < targetRow.monthsList[monthName]) {
  //                     return redColor
  //                 }
  //                 else {
  //                     return "#FFFFFF"
  //                 }
  //             }

  //         }
  //         else {
  //             return;
  //         }
  //     }

  //     else if (record.parentType === kpiPuntualeAcumulato.acumulato) {

  //         if (record.name === bowlingChartRowName.actualYtd) {
  //             let targetYtdRow = data.filter(function (item) { return item.name === bowlingChartRowName.targetYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID && item.projectID === record.projectID })[0];

  //             if (record.type === kpiCalculationType.valoreMinimo) {

  //                 if (monthValue < targetYtdRow.monthsList[monthName] || monthValue === targetYtdRow.monthsList[monthName]) {
  //                     return greenColor
  //                 }
  //                 else if (monthValue > targetYtdRow.monthsList[monthName]) {
  //                     return redColor
  //                 }
  //                 else {
  //                     return "#FFFFFF"
  //                 }
  //             }
  //             else {
  //                 if (monthValue > targetYtdRow.monthsList[monthName] || monthValue === targetYtdRow.monthsList[monthName]) {
  //                     return greenColor
  //                 }
  //                 else if (monthValue < targetYtdRow.monthsList[monthName]) {
  //                     return redColor
  //                 }
  //                 else {
  //                     return "#FFFFFF"
  //                 }
  //             }
  //         }
  //         else {
  //             return;
  //         }

  //     }
  //     else return "#FFFFFF"
  // }

  const checkProjectChildKpiCellColor = (record, monthName, monthValue) => {
    let kpiData = props.propsData;
    let companyData = props.companyData;
    let companyKpiTolerance = companyData.kpiTolerance;
    let companyStartFromCurrentMonth = companyData.startFromCurrentMonth;

    let targetRow = kpiData.filter(function (item) {
      return (
        item.name === bowlingChartRowName.target &&
        item.kpiProjectRelationshipID === record.kpiProjectRelationshipID &&
        item.projectID === record.projectID
      );
    })[0];
    let targetYtdRow = kpiData.filter(function (item) {
      return (
        item.name === bowlingChartRowName.targetYtd &&
        item.kpiProjectRelationshipID === record.kpiProjectRelationshipID &&
        item.projectID === record.projectID
      );
    })[0];
    let actualYtdRow = kpiData.filter(function (item) {
      return (
        item.name === bowlingChartRowName.actualYtd &&
        item.kpiProjectRelationshipID === record.kpiProjectRelationshipID &&
        item.projectID === record.projectID
      );
    })[0];

    let cellColor = checkBowlingChartCellColor(
      record,
      monthName,
      monthValue,
      kpiData,
      companyKpiTolerance,
      companyStartFromCurrentMonth,
      targetRow,
      targetYtdRow,
      actualYtdRow,
    );
    return cellColor;
  };

  const renderedColumns = () => {
    let columns = [
      {
        ...(props.isTotal ? '' : getColumnSearchProps('kpiDetailName')),
        dataIndex: 'kpiDetailName',
        title: `${props.t('general.nome')} KPI`,
        fixed: 'left',
        width: '7%',
        editable: false,
        ellipsis: {
          showTitle: false,
        },
        render: (text, record, index) => {
          let val = (
            <Space direction="vertical">
              <Text
                style={{ width: 100 }}
                ellipsis={{ tooltip: text }}>
                {text}
              </Text>
              <Popover
                content={
                  <Space direction="vertical">
                    <Text>
                      <span className="header-text"> {props.t('kpiPage.cumulatoPuntuale')}: </span>
                      <u>
                        <b>{record.parentTypeDescription}</b>
                      </u>
                    </Text>
                    <Text>
                      <span className="header-text"> {props.t('kpiPage.tipoCalcolo')}: </span>
                      <u>
                        <b>{record.typeDescription}</b>
                      </u>
                    </Text>
                  </Space>
                }
                placement="right"
                trigger="hover">
                <InfoCircleOutlined style={{ fontSize: 13 }} />
              </Popover>

              {record.projectName && record.projectName !== '' && (
                <>
                  <Text
                    style={{ width: 100 }}
                    ellipsis={{ tooltip: record.projectName }}>
                    {record.projectName}
                  </Text>
                </>
              )}
            </Space>
          );
          const obj = {
            children: val,
            props: {},
          };
          if (index % 4 === 0) {
            obj.props.rowSpan = 4;
          } else {
            obj.props.rowSpan = 0;
          }
          return obj;
        },
      },

      {
        dataIndex: 'name',
        title: '',
        editable: false,
        width: '6%',
        ellipsis: {
          showTitle: false,
        },
        render(text, record) {
          return {
            props: {
              style: { borderBottom: record.name === bowlingChartColumsValues.targetYTD ? '2px solid #6f6f6f' : '' },
            },
            children: (
              <Tooltip title={convertCamelCaseToNormal(text)}>
                <Text>{convertCamelCaseToNormal(text)}</Text>
              </Tooltip>
            ),
          };
        },
      },
    ];

    const tabelData = props.propsData;

    if (tabelData && tabelData.length > 0) {
      let firstRow = tabelData[0];

      for (const item in firstRow.monthsList) {
        let currentMonth = moment().format('YYYY-MM');
        let monthName = moment(item).format('YYYY-MM');
        let month = moment(item).format('MMM YYYY');

        columns.push({
          align: 'right',
          dataIndex: monthName,
          editable: false,
          title: capitalizeFirstLetter(month),
          width: '6%',
          render(text, record) {
            return {
              props: {
                style: {
                  borderBottom: record.name === bowlingChartColumsValues.targetYTD ? '2px solid #6f6f6f' : '',
                  backgroundColor:
                    currentMonth > monthName || currentMonth === monthName
                      ? checkProjectChildKpiCellColor(record, item, record.monthsList[item])?.cellColor
                      : '',
                },
              },
              children: (
                <Text
                  style={{
                    color:
                      currentMonth > monthName || currentMonth === monthName
                        ? checkProjectChildKpiCellColor(record, item, record.monthsList[item])?.textColor
                        : '',
                  }}>
                  {numberWithCommas(record.monthsList[item])}
                </Text>
              ),
            };
          },
        });
      }

      return columns;
    }

    return columns;
  };

  const mergedColumns = renderedColumns().map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form
      form={form}
      component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        size="small"
        dataSource={props.propsData}
        columns={mergedColumns}
        pagination={false}
        scroll={{ x: 'calc(700px + 50%)' }}
        loading={props.loadingData}
        rowKey={(obj) => obj.key}
      />
    </Form>
  );
};

class ProjectChildKpisDetailModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingData: false,
      bowlingChartData: null,
      projectPicklist: null,
      selectedProjectId: null,
      clearFilters: false,
      selectedYear: null,
      showModal: false,
      modalData: null,
    };
  }

  componentWillMount() {
    // this.retrieveProjectFilterData();
    // this.getBowlingChartData();
  }

  onProjectChange = (option) => {
    this.setState({ selectedProjectId: option });
  };

  onFilter = () => {
    this.setState({ clearFilters: false }, () => {
      this.getBowlingChartData();
    });
  };

  onClearFilter = () => {
    this.setState({ selectedYear: 0, selectedProjectId: null, clearFilters: true }, () => {
      this.getBowlingChartData();
    });
  };

  onYearChange = (year) => {
    this.setState({ selectedYear: year });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  togleKpiDetailModal = (kpiData) => {
    this.setState({ showModal: true });
    this.setState({ modalData: kpiData });
  };

  render() {
    const { showModal, handleModalClose, loadingButton, kpiModalData, bowlingChartData, loadingData } = this.props;
    const { t, companyData } = this.props;

    return (
      <Modal
        className="add-kpi-modal"
        width={'99%'}
        title={kpiModalData.KPIDetailName}
        open={showModal}
        destroyOnClose={true}
        onCancel={handleModalClose}
        footer={[
          <div key="close_buttons">
            <Button onClick={handleModalClose}>{t('buttons.chiudi')}</Button>
          </div>,
        ]}>
        <>
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <EditableTable
                childKpis={true}
                t={t}
                companyData={companyData}
                propsData={bowlingChartData}
                loadingIcon={loadingButton}
                loadingData={loadingData}
                handleOpenModal={this.togleKpiDetailModal}
              />
            </Col>
          </Row>
        </>
      </Modal>
    );
  }
}

// export default ProjectChildKpisDetailModal

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
  companyData: state.companyData.companyData,
});

export default connect(mapStateToProps)(ProjectChildKpisDetailModal);
