import { EyeOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Divider, message, Popconfirm, Popover, Space, Tooltip, Typography } from 'antd';
import React, { useReducer } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import { notifyMessages } from '../utils/notifyMessages';
import './userBadge.scss';

const { Meta } = Card;
const { Text } = Typography;

const OnCallUserContent = ({ member, setUserOnCallAction }) => {
  const { t } = useTranslation();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  return (
    <span>
      <>
        {member.onCall === 1 && (
          <Popconfirm
            placement="topRight"
            title={t('utentiPage.rimuovereOnCall')}
            onConfirm={async () => {
              await setUserOnCallAction(member.userID, false);
              forceUpdate();
            }}
            okText={t('general.si')}
            cancelText={t('general.no')}>
            <Tooltip
              placement="bottom"
              title={t('utentiPage.utenteOnCall')}>
              <PhoneOutlined style={{ color: '#16a34a' }} />
            </Tooltip>
          </Popconfirm>
        )}

        {member.onCall !== 1 && (
          <Popconfirm
            placement="topRight"
            title={t('utentiPage.vuoiImpostareOnCall')}
            onConfirm={async () => {
              await setUserOnCallAction(member.userID, true);
              forceUpdate();
            }}
            okText={t('general.si')}
            cancelText={t('general.no')}>
            <Tooltip
              placement="bottom"
              title={t('utentiPage.impostareOnCall')}>
              <PhoneOutlined style={{ color: '#606060' }} />
            </Tooltip>
          </Popconfirm>
        )}
      </>
    </span>
  );
};

class UserBadge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ellipsis: true,
    };
  }

  componentDidMount() {}

  userPositionClass = (role) => {
    if (role === 'Sponsor') {
      return 'positionBarSponsor';
    } else if (role === 'Team Leader') {
      return 'positionBarLeader';
    } else {
      return 'positionBarMember';
    }
  };

  setUserOnCall = async (userId, onCall) => {
    const { projectId, user } = this.props;
    try {
      const { data } = await ProgettiService.setUserOnCall({ projectId, userId, onCall });
      message.success(notifyMessages.updateSuccess);
      user.onCall = data.onCall ? 1 : 0;
    } catch {
      message.error(notifyMessages.updateFailed);
    }
  };

  popOverContent = (member) => {
    return (
      <div>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 4 }}>
          {this.userAvatarImg()}
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 20 }}>
          <Space
            direction="vertical"
            style={{ lineHeight: '1' }}>
            <Text strong>{member.fullName}</Text>
            <Text>{member.role}</Text>
            <Text>{member.emailAddress}</Text>
            <Divider style={{ margin: '5px 0' }} />
            <span>
              <MailOutlined className="usrIcons" />
              <UserOutlined className="usrIcons" />
            </span>
          </Space>
        </Col>
      </div>
    );
  };

  userAvatarImg = () => {
    const { user } = this.props;

    return user.profilePhoto ? <Avatar src={user.profilePhoto} /> : <Avatar>{user.initials}</Avatar>;
  };

  render() {
    const { user } = this.props;
    const { ellipsis } = this.state;

    return (
      <div className="user-badge">
        <Card
          actions={[
            <OnCallUserContent
              member={user}
              setUserOnCallAction={this.setUserOnCall}
            />,
            <Popover
              trigger="hover"
              placement="left"
              content={() => this.popOverContent(user)}>
              <EyeOutlined key="view" />
            </Popover>,
          ]}>
          <Meta
            avatar={this.userAvatarImg()}
            title={
              <Text
                style={ellipsis ? { width: '100%' } : undefined}
                ellipsis={ellipsis ? { tooltip: user.fullName } : false}>
                {user.fullName}
              </Text>
            }
            description={user.role}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(UserBadge));
