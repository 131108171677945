import { FilterOutlined, MinusSquareOutlined } from '@ant-design/icons';
import { Button, Select, Switch } from 'antd';
import { Component } from 'react';
import { connect } from 'react-redux';
import MatrixService from '../../services/matrix/matrixService';
import DashboardService from '../../services/pages/dashboardService';
import './matrix.scss';
import XMatrixHeader from './xMatrixHeader';
import XMatrixLeftPanel from './xMatrixLeftPanel/xMatrixLeftPanel';
import XmatrixOptions from './xmatrixOptions';

const { Option } = Select;

class FiltersMatrixLocal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterHasValue: false,

      longTermGoalsPicklist: [],
      annualGoalsPicklist: [],
      teamLeaderPicklist: [],
      divisionPicklist: [],
      committiePicklist: [],
      projectPicklist: [],
      sponsorPicklist: [],
      selectedFiltersLabelList: [],
      includeSubProjects: false,
    };
  }

  componentDidMount() {
    const { selectedXmatrixInfo } = this.props;
    let localFiltersData = JSON.parse(localStorage.getItem('xmFilters'));
    let xmatrix = localFiltersData.XMatrixID ? localFiltersData.XMatrixID : selectedXmatrixInfo.xMatrixID;
    this.retrieveFilterComponentsData(xmatrix, localFiltersData);
  }

  retrieveFilterComponentsData = (xmatrix, localFiltersData) => {
    this.retrieveLongTermGoalsPicklist(xmatrix);
    this.retrieveAnnualGoalsPicklist(xmatrix);
    this.retrieveDivisions(xmatrix);
    this.retrieveCommitties(xmatrix);
    this.retrieveProjects(xmatrix);
    this.retrieveSponsors();
    this.retrieveTeamLeaders();
    this.preCheckLocalFilters(localFiltersData);
  };

  preCheckLocalFilters = (localFiltersData) => {
    for (const filter in localFiltersData) {
      if (filter === 'XMatrixID') {
        continue;
      } else if (localFiltersData[filter].length && localFiltersData[filter].length > 0) {
        let filterObj = { value: filter, label: this.checkFilterLabelName(filter) };
        this.addItemToFilterLabelList(filterObj);
      } else if (localFiltersData[filter] === true) {
        let filterObj = { value: filter, label: this.checkFilterLabelName(filter) };
        this.addItemToFilterLabelList(filterObj);
      } else {
        continue;
      }
    }
  };

  handleSubmit = () => {
    this.props.manageFilterApplied();
  };

  handleClear = () => {
    this.setState({ selectedFiltersLabelList: [] });
    this.props.manageFilterReset();
  };

  async retrieveLongTermGoalsPicklist(xmatrix) {
    await MatrixService.getLongTermGoalsXmatrixFilter(xmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ longTermGoalsPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveAnnualGoalsPicklist(xmatrix) {
    await MatrixService.getAnnualGoalsXmatrixFilter(xmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ annualGoalsPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveDivisions(xmatrix) {
    await MatrixService.getDivisionXmatrixFilter(xmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ divisionPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveCommitties(xmatrix) {
    await MatrixService.getCommittieXmatrixFilter(xmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ committiePicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveProjects(xmatrix) {
    await MatrixService.getProjectXmatrixFilter(xmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ projectPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveTeamLeaders() {
    await DashboardService.getTeamLaderFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;

          const usr = respData.map((item) => ({
            value: item.userID,
            label: item.fullName,
            key: item.userID,
            disabled: item.isDeleted,
          }));

          this.setState({ teamLeaderPicklist: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveSponsors() {
    await DashboardService.getSponsorFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;

          const usr = respData.map((item) => ({
            value: item.userID,
            label: item.fullName,
            key: item.userID,
            disabled: item.isDeleted,
          }));

          this.setState({ sponsorPicklist: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  checkFilterExistOnFilterLabelsList = (filterObj) => {
    let { selectedFiltersLabelList } = this.state;
    const found = selectedFiltersLabelList.some((el) => el.value === filterObj.value);
    return found;
  };

  addItemToFilterLabelList = (object) => {
    let { selectedFiltersLabelList } = this.state;
    if (!this.checkFilterExistOnFilterLabelsList(object)) {
      selectedFiltersLabelList.push(object);
    }
  };

  onFilterOptionsSelect = (values, filterName, filterLabel) => {
    if (values && values.length < 1) {
      this.removeSelectedFilter(filterLabel.value);
    }
    this.addItemToFilterLabelList(filterLabel);
    const filterObj = { filter: filterName, value: values };
    this.props.manageFiltersChanged(filterObj);
  };

  onFilterCheckOptionsSelect = (values, filterName, filterLabel) => {
    if (!values) {
      this.removeSelectedFilter(filterLabel.value);
    }
    this.addItemToFilterLabelList(filterLabel);
    const filterObj = { filter: filterName, value: values };
    this.props.manageFiltersChanged(filterObj);
  };

  clearSelectedFilterOnLabelList = (filterName) => {
    let { selectedFiltersLabelList } = this.state;
    if (filterName || filterName.length > 0) {
      let clearedFilteredList = selectedFiltersLabelList.filter(function (el) {
        return el.value !== filterName;
      });
      this.setState({ selectedFiltersLabelList: clearedFilteredList });
    }
  };

  checkFilterLabelName = (filter) => {
    const { t } = this.props;
    switch (filter) {
      case 'longTermGoalId':
        return t('general.obiettiviLungo');
      case 'annualGoalId':
        return t('general.obbietiviAnuali');
      case 'projectID':
        return t('general.progetti');
      case 'committeeId':
        return t('general.comitato');
      case 'divisionId':
        return t('general.divisione');
      case 'teamLeaderId':
        return t('general.teamLeader');
      case 'sponsorId':
        return t('general.sponsor');
      case 'includeSubProject':
        return t('xMatrixPage.includiSottoprogetti');
      case 'showAllUsers':
        return t('xMatrixPage.mostraTuitiUtenti');
      default:
        return '-';
    }
  };

  removeSelectedFilter = (filter) => {
    switch (filter) {
      case 'longTermGoalId':
        const longTermFilter = { filter: 'longTermGoalId', value: [] };
        this.props.manageFiltersChanged(longTermFilter);
        break;
      case 'annualGoalId':
        const annualGoalFilter = { filter: 'annualGoalId', value: [] };
        this.props.manageFiltersChanged(annualGoalFilter);
        break;
      case 'projectID':
        const projectFilter = { filter: 'projectID', value: [] };
        this.props.manageFiltersChanged(projectFilter);
        break;
      case 'committeeId':
        const commitieFilter = { filter: 'committeeId', value: [] };
        this.props.manageFiltersChanged(commitieFilter);
        break;
      case 'divisionId':
        const divisionFilter = { filter: 'divisionId', value: [] };
        this.props.manageFiltersChanged(divisionFilter);
        break;
      case 'teamLeaderId':
        const temLeaderFilter = { filter: 'teamLeaderId', value: [] };
        this.props.manageFiltersChanged(temLeaderFilter);
        break;
      case 'sponsorId':
        const sponsorFilter = { filter: 'sponsorId', value: [] };
        this.props.manageFiltersChanged(sponsorFilter);
        break;
      case 'includeSubProject':
        const sottoProgettiFilter = { filter: 'includeSubProject', value: false };
        this.props.manageFiltersChanged(sottoProgettiFilter);
        break;
      case 'showAllUsers':
        const tuttiUtentiFilter = { filter: 'showAllUsers', value: false };
        this.props.manageFiltersChanged(tuttiUtentiFilter);
        break;
      default:
        break;
    }

    this.clearSelectedFilterOnLabelList(filter);
    this.handleSubmit();
  };

  render() {
    const {
      loadingButton1,
      loadingButton2,
      t,
      activeXmatrixInfo,
      loadingRootList,
      xmatrixRootList,
      reloadMatrix,
      selectedXmatrixInfo,
      isSecondLevelXmatrix,
      reloadMatrixOnEdit,
      reloadXmInfo,
      filters,
      xmatrixData,
      userData,
    } = this.props;
    const {
      filterHasValue,
      longTermGoalsPicklist,
      annualGoalsPicklist,
      teamLeaderPicklist,
      projectPicklist,
      sponsorPicklist,
      divisionPicklist,
      committiePicklist,
    } = this.state;

    return (
      <div className="tw-flex tw-flex-col tw-w-full tw-shrink-0">
        <div className="tw-flex tw-py-2 tw-px-2 tw-border-b tw-bg-white tw-w-full tw-gap-8 tw-justify-between tw-items-center">
          <div className="tw-flex tw-gap-2 tw-items-center tw-shrink-0">
            <XMatrixLeftPanel
              activeXmatrixInfo={activeXmatrixInfo}
              loadingRootList={loadingRootList}
              xmatrixRootList={xmatrixRootList}
              t={t}
              reloadMatrix={reloadMatrix}
              localFilters={filters}
              userData={userData}
            />
            <XMatrixHeader
              reloadMatrix={reloadMatrix}
              selectedXmatrixInfo={selectedXmatrixInfo}
              isSecondLevelXmatrix={isSecondLevelXmatrix}
              t={t}
            />
          </div>
          {/* <span className={'menu filtriText'}>{t('xMatrixPage.xmatrixFiltri')}</span> */}

          <div className="tw-flex tw-items-center tw-flex-1 tw-justify-end tw-gap-2">
            <Select
              style={{ width: '100%' }}
              value={filters && filters.longTermGoalId ? filters.longTermGoalId : []}
              showSearch
              mode="multiple"
              id="select_r9"
              maxTagCount="responsive"
              placeholder={t('general.obiettiviLungo')}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(selectedRowKeys) =>
                this.onFilterOptionsSelect(selectedRowKeys, 'longTermGoalId', {
                  value: 'longTermGoalId',
                  label: t('general.obiettiviLungo'),
                })
              }>
              {longTermGoalsPicklist &&
                longTermGoalsPicklist.map((item) => (
                  <Option
                    value={item.longTermGoalID}
                    key={item.longTermGoalID}>
                    {item.title}
                  </Option>
                ))}
            </Select>
            <Select
              value={filters && filters.annualGoalId ? filters.annualGoalId : []}
              showSearch
              style={{ width: '100%' }}
              mode="multiple"
              id="select_r10"
              maxTagCount="responsive"
              placeholder={t('general.obbietiviAnuali')}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(selectedRowKeys) =>
                this.onFilterOptionsSelect(selectedRowKeys, 'annualGoalId', {
                  value: 'annualGoalId',
                  label: t('general.obbietiviAnuali'),
                })
              }>
              {annualGoalsPicklist &&
                annualGoalsPicklist.map((item) => (
                  <Option
                    value={item.annualGoalId}
                    key={item.annualGoalId}>
                    {item.description}
                  </Option>
                ))}
            </Select>
            <Select
              value={filters && filters.projectID ? filters.projectID : []}
              showSearch
              mode="multiple"
              id="select_r11"
              style={{ width: '100%' }}
              maxTagCount="responsive"
              placeholder={t('general.progetti')}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(selectedRowKeys) =>
                this.onFilterOptionsSelect(selectedRowKeys, 'projectID', {
                  value: 'projectID',
                  label: t('general.progetti'),
                })
              }>
              {projectPicklist &&
                projectPicklist.map((item) => (
                  <Option
                    className={item.isProjectRelatedXMatrix !== 1 ? 'projectOptionColor' : ''}
                    value={item.projectID}
                    key={item.projectID}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              style={{ width: '100%' }}
              value={filters && filters.committeeId ? filters.committeeId : []}
              showSearch
              mode="multiple"
              id="select_r12"
              maxTagCount="responsive"
              placeholder={t('general.comitato')}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(selectedRowKeys) =>
                this.onFilterOptionsSelect(selectedRowKeys, 'committeeId', {
                  value: 'committeeId',
                  label: t('general.comitato'),
                })
              }>
              {committiePicklist &&
                committiePicklist.map((item) => (
                  <Option
                    disabled={item.hasAccess === 1 ? false : true}
                    value={item.committeeID}
                    key={item.committeeID}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              style={{ width: '100%' }}
              value={filters && filters.divisionId ? filters.divisionId : []}
              showSearch
              mode="multiple"
              id="select_r13"
              maxTagCount="responsive"
              placeholder={t('general.divisione')}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(selectedRowKeys) =>
                this.onFilterOptionsSelect(selectedRowKeys, 'divisionId', {
                  value: 'divisionId',
                  label: t('general.divisione'),
                })
              }>
              {divisionPicklist &&
                divisionPicklist.map((item) => (
                  <Option
                    disabled={item.hasAccess === 1 ? false : true}
                    value={item.divisionID}
                    key={item.divisionID}>
                    {item.divisionName}
                  </Option>
                ))}
            </Select>
            <Select
              style={{ width: '100%' }}
              value={filters && filters.teamLeaderId ? filters.teamLeaderId : []}
              mode="multiple"
              id="teamLeaderSelect"
              maxTagCount="responsive"
              placeholder={t('general.teamLeader')}
              onChange={(selectedRowKeys) =>
                this.onFilterOptionsSelect(selectedRowKeys, 'teamLeaderId', {
                  value: 'teamLeaderId',
                  label: t('general.teamLeader'),
                })
              }
              options={teamLeaderPicklist}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
            {/* {teamLeaderPicklist && teamLeaderPicklist.map(item =>
                      <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                    )}

                  </Select> */}
            <Select
              style={{ width: '100%' }}
              value={filters && filters.sponsorId ? filters.sponsorId : []}
              mode="multiple"
              id="select_r15"
              maxTagCount="responsive"
              placeholder={t('general.sponsor')}
              onChange={(selectedRowKeys) =>
                this.onFilterOptionsSelect(selectedRowKeys, 'sponsorId', {
                  value: 'sponsorId',
                  label: t('general.sponsor'),
                })
              }
              options={sponsorPicklist}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
            {/* {sponsorPicklist && sponsorPicklist.map(item =>
                      <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                    )}

                  </Select> */}
            <div className="tw-flex tw-gap-2 tw-items-center">
              <span>{t('xMatrixPage.includiSottoprogetti')}</span>
              <Switch
                className="switch"
                checked={filters && filters.includeSubProject ? filters.includeSubProject : false}
                onChange={(checked) =>
                  this.onFilterCheckOptionsSelect(checked, 'includeSubProject', {
                    value: 'includeSubProject',
                    label: t('xMatrixPage.includiSottoprogetti'),
                  })
                }
              />
            </div>
            {/* <span>{t('xMatrixPage.mostraTuitiUtenti')}</span>
            <Switch
              className="switch"
              checked={filters && filters.showAllUsers ? filters.showAllUsers : false}
              onChange={(checked) =>
                this.onFilterCheckOptionsSelect(checked, 'showAllUsers', {
                  value: 'showAllUsers',
                  label: t('xMatrixPage.mostraTuitiUtenti'),
                })
              }
            /> */}
            <div className="tw-flex tw-items-center tw-gap-2">
              <Button
                loading={loadingButton1}
                disabled={!filterHasValue ? false : true}
                type="primary"
                icon={<FilterOutlined />}
                onClick={() => this.handleSubmit()}>
                {/* {t('general.filter')} */}
              </Button>
              <Button
                className="tw-mr-4"
                loading={loadingButton2}
                disabled={!filterHasValue ? false : true}
                type="dashed"
                icon={<MinusSquareOutlined />}
                onClick={() => this.handleClear()}>
                {/* {t('general.clearFilters')} */}
              </Button>
              <XmatrixOptions
                reloadMatrix={reloadMatrix}
                selectedXmatrixInfo={selectedXmatrixInfo}
                isSecondLevelXmatrix={isSecondLevelXmatrix}
                t={t}
                reloadMatrixOnEdit={reloadMatrixOnEdit}
                reloadXmInfo={reloadXmInfo}
                xmatrixData={xmatrixData}
              />
            </div>
          </div>
        </div>
        {/* <div className="tw-bg-zinc-100 empty:tw-hidden tw-border-b tw-px-2 tw-py-1">
          {selectedFiltersLabelList.map((item) => (
            <Button
              type="text"
              className="fixed-grey-button"
              key={item.value}>
              {item.label} <CloseOutlined onClick={() => this.removeSelectedFilter(item.value)} />
            </Button>
          ))}
        </div> */}
      </div>
    );
  }
}

// export default FiltersMatrixLocal;

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default connect(mapStateToProps)(FiltersMatrixLocal);
