import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languageList } from '../../../../i18n';

const getFlagEmoji = (code: string): string => {
  switch (code) {
    case 'it':
      return '🇮🇹';
    case 'en':
      return '🇺🇸';
    default:
      return '-';
  }
};

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const getCurrentLng = i18n.language;
  const [languageName, setLanguageName] = useState<string | undefined>(
    languageList?.find((item) => item.code === getCurrentLng)?.name,
  );

  const changeLanguage = (lng: string): void => {
    i18n.changeLanguage(lng);
    const lang = languageList?.find((item) => item.code === lng)?.name;
    setLanguageName(lang);
    window.location.reload();
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <li
          className="tw-w-full"
          onClick={() => changeLanguage('it')}>
          {getFlagEmoji('it')} IT
        </li>
      ),
      key: 2,
    },
    {
      label: (
        <li
          className="tw-w-full"
          onClick={() => changeLanguage('en')}>
          {getFlagEmoji('en')} EN
        </li>
      ),
      key: 1,
    },
  ];

  return (
    <div className="tw-flex tw-items-center navbar-button">
      <Dropdown
        menu={{ items }}
        placement="bottom"
        arrow>
        <Button
          type="text"
          style={{ color: 'white' }}
          onClick={(e) => e.preventDefault()}
          icon={<DownOutlined />}>
          {languageName?.slice(0, 2).toUpperCase()}
          <span className="tw-mr-1">{getFlagEmoji(languageName?.slice(0, 2).toLowerCase() || '')}</span>
        </Button>
      </Dropdown>
    </div>
  );
};

export default LanguageSwitcher;
