import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonalDashboardService from '../../../services/pages/personalDashboardService';
import Loader2 from '../../shared/components/loader2/loader2';
import { perTimeActivityChartOptions, perTimeDashboardActivityChartOptions } from '../chartsConfig';

interface PerTimeActivityChartProps {
  inDashboard?: boolean;
}

const PerTimeActivityChart: React.FC<PerTimeActivityChartProps> = ({ inDashboard = false }) => {
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(false);
  const [perTimeChart, setPerTimeChart] = useState<{
    chart: any;
    series?: any;
  }>(inDashboard ? perTimeDashboardActivityChartOptions : perTimeActivityChartOptions);

  useEffect(() => {
    getPerTimeChartData();
  }, []);

  const getPerTimeChartData = async () => {
    setLoadingData(true);
    try {
      const response = await PersonalDashboardService.getActivityPerTimeChartData();
      const resp = response.data;
      if (resp.success) {
        const seriesObj = {
          name: `${t('bachechaPersonalePage.attivita')}`,
          data:
            resp.responseObject?.value?.map((item: any) => ({
              name: item.activityStatusDescription,
              y: item.activityCount,
            })) || [],
        };
        setPerTimeChart((prevChart) => ({
          ...prevChart,
          series: seriesObj,
        }));
      }
    } catch (error) {
      console.error('Error fetching activity per time data', error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <>
      {loadingData && <Loader2 />}
      {perTimeChart ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={perTimeChart}
        />
      ) : (
        <p>No data</p>
      )}
    </>
  );
};

export default PerTimeActivityChart;
