import i18n from '../../../i18n';

// Define the shape of messages for type safety
interface NotifyMessages {
  errorAccured: string;
  retrieveFailed: string;
  updateSuccess: string;
  updateFailed: string;
  addSuccess: string;
  addFailed: string;
  deleteSuccess: string;
  deleteFailed: string;
  loadMenuFailed: string;
  deleteCommitieFailed: string;
  changeCompanySuccess: string;
  changeCompanyFailed: string;
  uploadSuccess: string;
  uploadFailed: string;
  actionSucces: string;
  mailSendSuccess: string;
  mailSendError: string;
  dbNameStartWithNumber: string;
}

const messages: Partial<NotifyMessages> = {};

function fillNotifyMessages() {
  messages.errorAccured = i18n.t('notifications.errorAccured');
  messages.retrieveFailed = i18n.t('notifications.retrieveFailed');
  messages.updateSuccess = i18n.t('notifications.updateSuccess');
  messages.updateFailed = i18n.t('notifications.updateFailed');
  messages.addSuccess = i18n.t('notifications.addSuccess');
  messages.addFailed = i18n.t('notifications.addFailed');
  messages.deleteSuccess = i18n.t('notifications.deleteSuccess');
  messages.deleteFailed = i18n.t('notifications.deleteFailed');
  messages.loadMenuFailed = i18n.t('notifications.loadMenuFailed');
  messages.deleteCommitieFailed = i18n.t('notifications.deleteCommitieFailed');
  messages.changeCompanySuccess = i18n.t('notifications.changeCompanySuccess');
  messages.changeCompanyFailed = i18n.t('notifications.changeCompanyFailed');
  messages.uploadSuccess = i18n.t('notifications.uploadSuccess');
  messages.uploadFailed = i18n.t('notifications.uploadFailed');
  messages.actionSucces = i18n.t('notifications.actionSuccess');
  messages.mailSendSuccess = i18n.t('notifications.mailSendSuccess');
  messages.mailSendError = i18n.t('notifications.mailSendError');
  messages.dbNameStartWithNumber = i18n.t('notifications.dbNameStartWithNumber');
}

fillNotifyMessages();
i18n.on('languageChanged init', () => {
  fillNotifyMessages();
});
export const notifyMessages = messages as NotifyMessages;

// Define the shape of required field messages for type safety
interface RequiredFieldsMessages {
  required: string;
  validateKpiName: string;
  validateProjectCode: string;
  uploadCheckFileType: string;
  uploadCheckFileSize: string;
  validateEndDate: string;
  validateRoutineDates: string;
  responsabileRequired: string;
}

const requiredFieldsMessages: Partial<RequiredFieldsMessages> = {};

function fillRequiredFields() {
  requiredFieldsMessages.required = i18n.t('notifications.required');
  requiredFieldsMessages.validateKpiName = i18n.t('notifications.validateKpiName');
  requiredFieldsMessages.validateProjectCode = i18n.t('notifications.validateProjectCode');
  requiredFieldsMessages.uploadCheckFileType = i18n.t('notifications.uploadCheckFileType');
  requiredFieldsMessages.uploadCheckFileSize = i18n.t('notifications.uploadCheckFileSize');
  requiredFieldsMessages.validateEndDate = i18n.t('notifications.validateEndDate');
  requiredFieldsMessages.validateRoutineDates = i18n.t('notifications.validateRoutineDates');
  requiredFieldsMessages.responsabileRequired = i18n.t('notifications.responsabileRequired');
}

fillRequiredFields();

i18n.on('languageChanged init', () => {
  fillRequiredFields();
});

export const requiredFields = requiredFieldsMessages as RequiredFieldsMessages;
