import { Form } from 'antd';
import React from 'react';
import './deliverable.scss';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Input, InputNumber, Modal, Popover, Row, Select, Switch, Typography, message } from 'antd';
import { connect } from 'react-redux';
import CommonService from '../../../../services/commonService';
import KpiService from '../../../../services/pages/kpiService';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { kpiPuntualeAcumulato, kpiType, objectCodes } from '../../../shared/utils/constants';
import { inputFormatter, inputParser } from '../../../shared/utils/functions';
import { notifyMessages, requiredFields } from '../../../shared/utils/notifyMessages';

const { Option } = Select;

const { Text } = Typography;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const NormalJumpOffPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPointNORMAL"
      initialValue={0}
      rules={[
        { required: true, message: requiredFields.required },
        {
          validator: async (_, jumpOffPoint) => {
            if (typeof jumpOffPoint !== 'number') {
              return Promise.reject(new Error(`${props.t('kpiPage.jopNumber')}`));
            }
          },
        },
      ]}>
      <InputNumber
        style={{ width: '100%' }}
        min="0"
        disabled={props.isRollup}
      />
    </Form.Item>
  );
};

const PercentageJumpOffPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPointPERCENTAGE"
      initialValue={0}
      rules={[
        { required: true, message: requiredFields.required },
        {
          validator: async (_, jumpOffPoint) => {
            if (jumpOffPoint > 100 || jumpOffPoint < 0 || isNaN(jumpOffPoint) || typeof jumpOffPoint !== 'number') {
              return Promise.reject(new Error(`${props.t('kpiPage.jopMax100Number')}`));
            }
          },
        },
      ]}>
      <InputNumber
        formatter={(value) => inputFormatter(value) + ' %'}
        parser={(x) => inputParser(x)}
        style={{ width: '100%' }}
        min={0}
        max={100}
        disabled={props.isRollup}
      />
    </Form.Item>
  );
};

const BooleanJumpOffPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPointBOOLEAN"
      valuePropName="checked"
      initialValue={false}>
      <Switch
        checkedChildren={props.t('kpiPage.fatto')}
        unCheckedChildren={props.t('kpiPage.nonFatto')}
        disabled={props.isRollup}
      />
    </Form.Item>
  );
};

const DisabledJumpOfPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPoint"
      initialValue={null}>
      <InputNumber
        value={null}
        style={{ width: '100%' }}
        min="0"
        disabled={true}
      />
    </Form.Item>
  );
};

class NewProjectKpi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      fieldsChangedNewKpi: false,
      loadingKpiButton: false,
      kpiList: null,
      kpiData: null,
      calculationTypeList: null,
      typeList: null,
      acumulatoPuntuale: null,
      jumpOffPointComponent: (
        <NormalJumpOffPoint
          jmp={1}
          isRollup={false}
          t={this.props.t}
        />
      ),
      newKpiForm: false,
      disableJumpOfPointResetGlobalKpi: false,
      selectedType: null,
      selectedCumulatoPuntuale: null,
    };
  }

  componentDidMount() {
    this.retrieveKpiList();
    this.retrieveCalculationType();
    this.retrieveType();
    this.retrieveAcumulatoPuntuale();
  }

  // Retrieve KPI list
  async retrieveKpiList() {
    const { projectId } = this.props;
    await ProgettiService.getKpiProjectPicklist(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ kpiList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  addKpiData = (values, projectId) => {
    this.props.handleKpiModalSave(values, projectId);
  };

  kpiInfo = (item) => {
    let { t } = this.props;
    return (
      <div>
        <p>
          {' '}
          <b>{t('kpiPage.tipoCalcolo')}: </b> {item.globaletype}
        </p>
        <p>
          {' '}
          <b>{t('kpiPage.cumulatoPuntuale')}: </b> {item.puntualetype}
        </p>
        <p>
          {' '}
          <b>{t('kpiPage.gerarchicho')}: </b> {item.isRollupKPI ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
        </p>
      </div>
    );
  };

  _validateKpiName = (_rule, value, callback) => {
    KpiService.checkIfKpiNameExist(value)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (resp.responseObject.value) {
            callback(requiredFields.validateKpiName);
          }
          callback();
        }
        callback();
      })
      .catch((error) => {
        message.error(notifyMessages.errorAccured);
      });
  };

  retrieveCalculationType() {
    const objectCode = objectCodes.calculationTypes;
    CommonService.getCalculationTypeList(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ calculationTypeList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve list of types
  retrieveType() {
    const objectCode = objectCodes.kpiType;
    CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ typeList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve acumulato/puntuale
  retrieveAcumulatoPuntuale() {
    const objectCode = objectCodes.acumulatoPuntuale;
    CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ acumulatoPuntuale: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  onCalculationTypeChange = (value, option) => {
    const { rollupChecked } = this.state;
    const jumpOffPointCalculationType = value;
    let { t } = this.props;

    if (jumpOffPointCalculationType === 1 || jumpOffPointCalculationType === 2) {
      this.setState({
        jumpOffPointComponent: (
          <NormalJumpOffPoint
            isRollup={rollupChecked}
            t={t}
          />
        ),
      });
    } else if (jumpOffPointCalculationType === 3) {
      this.setState({
        jumpOffPointComponent: (
          <PercentageJumpOffPoint
            isRollup={rollupChecked}
            t={t}
          />
        ),
      });
    } else if (jumpOffPointCalculationType === 4) {
      this.setState({
        jumpOffPointComponent: (
          <BooleanJumpOffPoint
            isRollup={rollupChecked}
            t={t}
          />
        ),
      });
    } else {
      <NormalJumpOffPoint t={t} />;
    }
  };

  createKpi = (values, projectId) => {
    const { disableJumpOfPointResetGlobalKpi } = this.state;

    Object.keys(values).forEach(function (fieldName) {
      if (
        fieldName === 'jumpOffPointBOOLEAN' ||
        fieldName === 'jumpOffPointPERCENTAGE' ||
        fieldName === 'jumpOffPointNORMAL'
      ) {
        if (fieldName === 'jumpOffPointBOOLEAN') {
          if (values[fieldName] === true) {
            values[fieldName] = 1;
          }
          if (values[fieldName] === false) {
            values[fieldName] = 0;
          }
        }
        values['jumpOffPoint'] = values[fieldName];
        delete values[fieldName];
      }
    });

    values['isRollupKPI'] = false;

    if (disableJumpOfPointResetGlobalKpi) {
      values['jumpOffPoint'] = 0;
    }

    this.setState({ loadingKpiButton: true });

    KpiService.addKpi(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          // message.success(notifyMessages.addSuccess);
          this.setState({ loadingKpiButton: false });

          let valuesObj = {};
          valuesObj['KPIDetailID'] = resp.responseObject.value.kpiDetailID;
          // valuesObj["projectID"] = projectId;

          this.props.handleKpiModalSave(valuesObj, projectId);
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingKpiButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingKpiButton: false });
      });
  };

  showKpiForm = () => {
    const { newKpiForm } = this.state;
    this.setState({ newKpiForm: !newKpiForm });
  };

  checkDisableJumpOfPointResetGlobale = () => {
    const { selectedType, selectedCumulatoPuntuale } = this.state;
    const { companyData } = this.props;
    if (
      companyData.resetGlobalKPI &&
      selectedCumulatoPuntuale === kpiPuntualeAcumulato.acumulato &&
      selectedType === kpiType.globale
    ) {
      this.setState({ disableJumpOfPointResetGlobalKpi: true });
    } else {
      this.setState({ disableJumpOfPointResetGlobalKpi: false });
    }
  };

  onCumulatoPuntualeChange = (value) => {
    this.setState({ selectedCumulatoPuntuale: value }, () => {
      this.checkDisableJumpOfPointResetGlobale();
    });
  };

  onTypeChange = (value) => {
    this.setState({ selectedType: value }, () => {
      this.checkDisableJumpOfPointResetGlobale();
    });
  };

  render() {
    const { showNewKpiModal, handleNewKpiModalClose, loadingButton, projectId, t } = this.props;
    const {
      kpiList,
      calculationTypeList,
      typeList,
      acumulatoPuntuale,
      jumpOffPointComponent,
      newKpiForm,
      loadingKpiButton,
      disableJumpOfPointResetGlobalKpi,
    } = this.state;
    let kpiSelectDataList = [];

    if (kpiList) {
      kpiList.forEach((item) => {
        const value = item.kpiDetailID;
        kpiSelectDataList.push({
          value,
          key: item.kpiDetailID,
          kpidetailid: item.kpiDetailID,
          label: item.name,
          name: item.name,
          globaletype: item.globaleType,
          puntualetype: item.puntualeType,
          isRollupKPI: item.isRollupKPI,
        });
      });
    }

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('kpiPage.aggiungiKpi')}
        open={showNewKpiModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleNewKpiModalClose}
        footer={[
          !newKpiForm ? (
            <ModalFooterButtons
              disabled={!this.state.fieldsChanged}
              loadingButton={loadingButton}
              modalClose={handleNewKpiModalClose}
              formId={'newProjectKpi'}
            />
          ) : (
            ''
          ),
        ]}>
        <Row gutter={{ lg: 24 }}>
          {!newKpiForm && (
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              style={{ textAlign: 'right' }}>
              <Button
                className="create-kpi"
                onClick={() => this.showKpiForm()}
                type="text">
                <PlusOutlined /> {t('buttons.creaNewKpi')}
              </Button>
            </Col>
          )}

          {newKpiForm && (
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              style={{ textAlign: 'right' }}>
              <Button
                className="create-kpi"
                onClick={() => this.showKpiForm()}
                type="text">
                <PlusOutlined /> {t('kpiPage.aggiungiKpi')}
              </Button>
            </Col>
          )}
        </Row>

        {!newKpiForm && (
          <Card>
            <Form
              id="newProjectKpi"
              layout="vertical"
              {...formItemLayout}
              onFinish={(values) => this.addKpiData(values, projectId)}
              onValuesChange={() => {
                this.setState({ fieldsChanged: true });
              }}>
              <Row
                gutter={{ lg: 24 }}
                className="row-margin">
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <Form.Item
                    className="main-container"
                    label={t('kpiPage.listaKpi')}
                    name="KPIDetailID"
                    hasFeedback
                    rules={[{ required: true, message: requiredFields.required }]}>
                    <Select
                      showSearch
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={t('general.seleziona')}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {kpiSelectDataList &&
                        kpiSelectDataList.map((item) => (
                          <Option
                            value={item.kpidetailid}
                            key={item.kpidetailid}>
                            {item.name}
                            <Popover
                              content={this.kpiInfo(item)}
                              title={t('xMatrixPage.kpiInfo')}>
                              <InfoCircleOutlined style={{ fontSize: 12, color: '#1f85e4', marginLeft: '15px' }} />
                            </Popover>
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        )}

        {newKpiForm && (
          <Row
            gutter={{ lg: 24 }}
            className="row-margin">
            <Form
              className="main-container"
              name="new_kpi"
              layout="vertical"
              {...formItemLayout}
              onFinish={(values) => this.createKpi(values, projectId)}
              onValuesChange={() => {
                this.setState({ fieldsChangedNewKpi: true });
              }}>
              <Row
                gutter={{ lg: 24 }}
                style={{ marginTop: 20 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <Card style={{ background: '#f6fbff' }}>
                    <Row gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <div className="button-wrapper">
                          <Form.Item
                            wrapperCol={{ span: 24 }}
                            className="main-container">
                            <Button
                              type="primary"
                              htmlType="submit"
                              disabled={!this.state.fieldsChangedNewKpi}
                              loading={loadingKpiButton}>
                              <SaveOutlined />
                              {t('buttons.salvaKpi')}
                            </Button>
                          </Form.Item>
                        </div>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Form.Item
                          className="main-container"
                          label={t('general.nome')}
                          name="name"
                          rules={[
                            { required: true, message: requiredFields.required },
                            {
                              validator: this._validateKpiName,
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          className="main-container"
                          label={t('kpiPage.tipoCalcolo')}
                          name="calculationType"
                          hasFeedback
                          rules={[{ required: true, message: requiredFields.required }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onChange={(value, option) => this.onCalculationTypeChange(value, option)}>
                            {calculationTypeList &&
                              calculationTypeList.map((item) => (
                                <Option
                                  value={item.objectCodeListID}
                                  key={item.objectCodeListID}>
                                  {item.description}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        {jumpOffPointComponent && !disableJumpOfPointResetGlobalKpi && jumpOffPointComponent}

                        {disableJumpOfPointResetGlobalKpi && <DisabledJumpOfPoint t={t} />}
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          className="main-container"
                          label={t('kpiPage.cumulatoPuntuale')}
                          name="parentType"
                          hasFeedback
                          rules={[{ required: true, message: requiredFields.required }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onChange={(value) => this.onCumulatoPuntualeChange(value)}>
                            {acumulatoPuntuale &&
                              acumulatoPuntuale.map((item) => (
                                <Option
                                  value={item.objectCodeListID}
                                  key={item.objectCodeListID}>
                                  {item.description}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          className="main-container"
                          label={t('kpiPage.unitaMisura')}
                          name="unitOfMeasure">
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          className="main-container"
                          label={t('general.actualYtd')}
                          name="actualYTD">
                          <InputNumber
                            min="0"
                            style={{ width: '100%' }}
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          className="main-container"
                          label={t('general.tipo')}
                          name="type"
                          hasFeedback
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={kpiType.specifico}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onChange={(value) => this.onTypeChange(value)}>
                            {typeList &&
                              typeList.map((item) => (
                                <Option
                                  value={item.objectCodeListID}
                                  key={item.objectCodeListID}>
                                  {item.description}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Row>
        )}
      </Modal>
    );
  }
}

// export default NewProjectKpi

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
  companyData: state.companyData.companyData,
});

export default connect(mapStateToProps)(NewProjectKpi);
