import { Alert, message } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar2Fill, CalendarScheduleLine, Group2Line } from 'src/assets/icons';
import A3BlueTitle from 'src/components/A3BlueTitle';
import NewRoutineModal from 'src/components/projects/projectForm/mainInfo/projectRoutine/newRoutineModal';
import UpdateRoutineModal from 'src/components/projects/projectForm/mainInfo/projectRoutine/updateRoutineModal';
import TableLayout from 'src/components/shared/tableLayout';
import { formatOfDate } from 'src/components/shared/utils/constants';
import { updateNotificationBell } from 'src/components/shared/utils/functions';
import { notifyMessages } from 'src/components/shared/utils/notifyMessages';
import { GetProjectMembersResponseDto, GetProjectResponseDto, ProjectRoutineResponseDto } from 'src/connectors/backend';
import Section1Service from 'src/services/a3/section1Service';
import ProgettiService from 'src/services/pages/projectServices/progettiService';
import RoutineService from 'src/services/pages/projectServices/routineServices';

const Routines: FC<{ id: string; currentProject: GetProjectResponseDto }> = ({ id, currentProject }) => {
  const [currentDataRoutine, setCurrentDataRoutine] = useState<ProjectRoutineResponseDto[]>([]);
  const [newRoutineModal, setNewRoutineModal] = useState(false);
  const [updateRoutineModal, setUpdateRoutineModal] = useState(false);
  const [usersList, setUsersList] = useState<GetProjectMembersResponseDto[]>([]);
  const [selectedRoutineData, setSelectedRoutineData] = useState<ProjectRoutineResponseDto | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    retrieveProjectRoutine();
    retrieveProjectUsers();
  }, [id]);

  const retrieveProjectUsers = async () => {
    await ProgettiService.getProjectTeamMembers(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setUsersList(resp.responseObject?.value || []);
        }
      })
      .catch((error) => {});
  };

  const retrieveProjectRoutine = () => {
    Section1Service.getProjectRoutine(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setCurrentDataRoutine(resp.responseObject?.value || []);
        }
      })
      .catch((error) => {});
  };

  const handleDateChange = (date: string) => {
    if (date) {
      let formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  };

  const handleTimeChange = (time: string) => {
    if (time) {
      let formatedTime = moment(time).format('HH:mm');
      return formatedTime;
    }
    return null;
  };

  const addNewRoutine = (values: any) => {
    values['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    values['projectID'] = id;
    values.startDate = handleDateChange(values.startDate);
    values.endDate = handleDateChange(values.endDate);

    values.startTime = handleTimeChange(values.startTime);
    values.endTime = handleTimeChange(values.endTime);
    values['extraUsersRoutine'] = values['extraUsersRoutine'];

    RoutineService.addProjectRoutine(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setNewRoutineModal(false);
          message.success((notifyMessages as any).addSuccess);
          retrieveProjectRoutine();
        } else {
          message.error((notifyMessages as any).addFailed);
        }
      })
      .catch((error) => {
        message.error((notifyMessages as any).addFailed);
      });
  };

  const updateRoutine = (values: any, projectRoutineID: string, projectID: string, activityData: any) => {
    values['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    values['projectRoutineID'] = projectRoutineID;
    values['projectID'] = projectID;
    values.startDate = handleDateChange(values.startDate);
    values.endDate = handleDateChange(values.endDate);
    values.startTime = handleTimeChange(values.startTime);
    values['outlookEventId'] = activityData.outlookEventId; //"AAMkAGQyOTg5NmRlLTc4ZTAtNDU0Ni1hNDZhLWJhODYxNTM4YjExZgBGAAAAAACm-F-liHhxT5DDWLNwtZ9rBwCg3ewhK5MHTpKFoGuR5xm6AAAAAAENAACg3ewhK5MHTpKFoGuR5xm6AAGmNJkEAAA="
    values['schedule'] = activityData.schedule;
    values['dayOfWeek'] = activityData.dayOfWeek;
    values['frequence'] = activityData.frequence;
    values['frequenceType'] = activityData.frequenceType;
    values['extraUsers'] = values['extraUsersRoutine'];

    RoutineService.updateProjectRoutine(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setUpdateRoutineModal(false);
          updateNotificationBell();
          message.success((notifyMessages as any).updateSuccess);
          retrieveProjectRoutine();
        } else {
          message.error((notifyMessages as any).updateFailed);
        }
      })
      .catch((error) => {
        message.error((notifyMessages as any).updateFailed);
      });
  };

  return (
    <TableLayout
      title={
        <A3BlueTitle
          title={t('proggetiPage.routneGestioneProgetto')}
          href={`/progetti/id/${id}/?tab=13`}
        />
      }>
      {/* <TableLayout.Actions>
        <Button
          type="primary"
          onClick={() => setNewRoutineModal(true)}>
          {t('buttons.aggiungiNuovo')}
        </Button>
      </TableLayout.Actions> */}
      <TableLayout.Content>
        <div className="tw-flex tw-pb-4 tw-flex-nowrap tw-w-full tw-min-w-full tw-gap-6 tw-overflow-x-auto">
          {currentDataRoutine?.length ? (
            currentDataRoutine.map((r, i) => (
              <button
                key={i}
                onClick={() => {
                  setSelectedRoutineData(r);
                  setUpdateRoutineModal(true);
                }}
                className="tw-bg-zinc-100 hover:tw-bg-zinc-200/50 tw-transition-all hover:tw-shadow-md tw-border tw-rounded-md tw-w-64 tw-shrink-0 border-b">
                <div className="tw-flex tw-justify-between tw-gap-2 tw-border-b tw-w-full tw-p-2 tw-items-center">
                  <h4 className="tw-text-lg tw-truncate">{r?.name}</h4>
                  <span className="tw-text-lg tw-bg-white tw-text-zinc-500 tw-rounded-md tw-py-0.5 tw-px-2">
                    {r?.duration}h
                  </span>
                </div>

                <div className="tw-flex tw-text-sm tw-flex-col tw-gap-1 tw-p-2">
                  <div className="tw-flex tw-gap-2 tw-items-center">
                    <Group2Line />
                    {r?.whoDescription}
                  </div>
                  <div className="tw-flex tw-text-rest tw-gap-2 tw-items-center">
                    <CalendarScheduleLine />
                    <div>
                      {r?.schedule} - {r?.scheduleDescription}
                    </div>
                  </div>
                  <div className="tw-flex tw-text-rest tw-gap-2 tw-items-center">
                    <Calendar2Fill />
                    <div>
                      {moment(r.startDate).format(formatOfDate)} {`<->`} {moment(r.endDate).format(formatOfDate)}
                    </div>
                  </div>
                </div>
              </button>
            ))
          ) : (
            <Alert
              banner
              showIcon={false}
              className="tw-w-full tw-text-center"
              message={t('proggetiPage.noRoutine')}
              type="info"
            />
          )}
        </div>

        {newRoutineModal && (
          <NewRoutineModal
            showNewRoutineModal={newRoutineModal}
            handleNewRoutineModalClose={() => setNewRoutineModal(false)}
            addNewRoutine={addNewRoutine}
            projectId={id}
            t={t}
            endDateProject={currentProject?.endDate}
            startDateProject={currentProject?.startDate}
            users={usersList}
          />
        )}

        {updateRoutineModal && (
          <UpdateRoutineModal
            showEditRoutineModal={updateRoutineModal}
            handleUpdateRoutineModalClose={() => setUpdateRoutineModal(false)}
            updateRoutine={updateRoutine}
            projectId={id}
            routineData={selectedRoutineData}
            t={t}
            endDateProject={currentProject?.endDate}
            startDateProject={currentProject?.startDate}
            users={usersList}
          />
        )}
      </TableLayout.Content>
    </TableLayout>
  );
};

export default Routines;
