import { Form } from 'antd';
import { FC, useState } from 'react';
import './divisions';

import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, message, Row, Space, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DivisionService from '../../services/pages/divisionServices';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const AddNewDivision: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [fieldsChanged, setFieldsChanged] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File>();

  const removeUploadedFile = () => {
    setUploadedFile(undefined);
  };

  const addData = async (values: { name: string; ownerId?: string }) => {
    setLoadingButton(true);

    try {
      await DivisionService.create(values.name, values.ownerId, uploadedFile);
      message.success(notifyMessages.addSuccess);
      history.push('/divisions');
    } catch {
      message.error(notifyMessages.addFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <div className="edit-division-wrapper">
      <div className="site-card-wrapper">{t('divisioniPage.aggiungiDivisione')}</div>
      <Card>
        <Form
          name="add_ob_lungo"
          layout="vertical"
          {...formItemLayout}
          onFinish={addData}
          onValuesChange={() => {
            setFieldsChanged(true);
          }}>
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <div className="button-wrapper">
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Button
                    loading={loadingButton}
                    type="primary"
                    className="tw-float-end"
                    htmlType="submit"
                    disabled={!fieldsChanged}>
                    <SaveOutlined /> {t('buttons.salva')}
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <Card title={t('general.informazioniGenerali')}>
                <Row gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}>
                    <Form.Item
                      label={t('general.nome')}
                      name="name"
                      rules={[{ required: true, message: requiredFields.required }]}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}>
                    <UserSelectDropDown
                      disabled={false}
                      allowClear={true}
                      fieldLabel={t('general.responsabile')}
                      t={t}
                      formName={'ownerId'}
                      required={false}
                      initValue={null}
                      selectSize={null}
                    />
                    {/* <Form.Item
                                                    style={{ marginBottom: 5 }}
                                                    labelAlign="left"                                                    
                                                    label={t('general.responsabile')}
                                                    name="ownerID"   
                                                >
                                                   
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        placeholder={t('general.seleziona')}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{ width: "100%" }}
                                                    >
                                                        {responsabilePickList && responsabilePickList.map(item =>
                                                            <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                                        )}
                                                    </Select>
                                                </Form.Item> */}
                  </Col>
                </Row>

                <Row gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Form.Item name={t('general.image')}>
                      <Space
                        direction="vertical"
                        style={{ width: '100%' }}>
                        <div className="ant-col ant-col-24 ant-form-item-label">
                          <label className="ant-form-item">{t('divisioniPage.immagineDivisione')}</label>
                        </div>

                        <Upload
                          accept="image/*"
                          maxCount={1}
                          listType="picture"
                          onRemove={removeUploadedFile}
                          beforeUpload={(file) => {
                            setUploadedFile(file);
                            return false;
                          }}>
                          <Button icon={<UploadOutlined />}>{t('general.upload')}</Button>
                        </Upload>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
  // }
};

export default AddNewDivision;
