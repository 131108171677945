import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonalDashboardService from '../../../services/pages/personalDashboardService';
import Loader2 from '../../shared/components/loader2/loader2';
import { perStatusActivityChartOptions, perStatusDashboardActivityChartOptions } from '../chartsConfig';

interface PerStatusActivityChartProps {
  inDashboard?: boolean;
}

const PerStatusActivityChart: React.FC<PerStatusActivityChartProps> = ({ inDashboard = false }) => {
  const { t } = useTranslation();

  const [loadingData, setLoadingData] = useState(false);
  const [perStatusChart, setPerStatusChart] = useState<{
    chart: any;
    series?: any;
  }>(inDashboard ? perStatusDashboardActivityChartOptions : perStatusActivityChartOptions);

  useEffect(() => {
    getPerStatusChartData();
  }, []);

  const getPerStatusChartData = async () => {
    setLoadingData(true);
    try {
      const response = await PersonalDashboardService.getActivityPerStatusChartData();
      const resp = response.data;
      if (resp.success) {
        const seriesObj = {
          name: `${t('bachechaPersonalePage.attivita')}`,
          data:
            resp.responseObject?.value?.map((item: any) => ({
              name: item.statusDescription,
              y: item.activityNumber,
            })) || [],
        };
        setPerStatusChart((prevChart) => ({
          ...prevChart,
          series: seriesObj,
        }));
      }
    } catch (error) {
      console.error('Error fetching activity per status data', error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <>
      {loadingData && <Loader2 />}
      {perStatusChart ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={perStatusChart}
        />
      ) : (
        <p>No data</p>
      )}
    </>
  );
};

export default PerStatusActivityChart;
