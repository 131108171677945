import { Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/store';
import { StackIcon } from '../../assets/icons/index';
import {
  DeliverableIcon,
  PhaseIcon,
  TaskIcon,
} from '../projects/projectForm/deliverablesAndKpi/kanban-view/components/icons';
import TableLayout from '../shared/tableLayout';
import UserComities from '../users/userComities';
import UserProjects from '../users/userProjects';
import PersonalDashboardActivity from './activityChart/activityChart';
import PersonalDashboardDeliverables from './deliverableChart/deliverablesChart';
import PersonalDashboardPhases from './phasesChart/phasesChart';
import PersonalDashboardProgetti from './progettiChart/progettiChart';

const PersonalDashboard: React.FC<{
  onActiveTabChange: (key: string) => void;
}> = ({ onActiveTabChange }) => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      {/* Title */}
      <div className="tw-flex tw-flex-col tw-border-b tw-border-zinc-200 tw-py-5">
        <span className="tw-text-zinc-400 tw-capitalize">{moment(new Date()).format('dddd, DD MMMM YYYY')}</span>
        <h1 className="tw-text-3xl tw-font-medium">
          {t('general.greeting')}, {userData?.fullName || ''}
        </h1>
      </div>

      <div className="tw-grid xl:tw-grid-cols-2 tw-border-b">
        {/* projects table */}
        <div className="tw-border-r tw-border-zinc-200 tw-pb-8 tw-flex tw-flex-col tw-gap-2 tw-grow tw-shrink-0 tw-relative">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-h-14 tw-ml-3">
            <div className="tw-size-6 tw-rounded-md tw-bg-red-500 tw-flex tw-items-center tw-justify-center">
              <StackIcon />
            </div>
            <span className="tw-font-semibold tw-text-xl">{t('homepage.progetti')}</span>
          </div>
          <div className="tw-h-full tw-flex tw-flex-col [&_.xm-filter-wrapper]:tw-absolute [&_.xm-filter-wrapper]:tw-top-1 [&_.xm-filter-wrapper]:tw-right-1">
            <div className="tw-m-2">
              <UserProjects toFilter={'tmtl'} />
            </div>
          </div>
        </div>

        {/* projects sponsor table */}
        <div className="tw-border-zinc-200 tw-pb-8 tw-flex tw-flex-col tw-gap-2 tw-grow tw-shrink-0 tw-relative">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-h-14 tw-ml-3">
            <div className="tw-size-6 tw-rounded-md tw-bg-red-500 tw-flex tw-items-center tw-justify-center">
              <StackIcon />
            </div>
            <span className="tw-font-semibold tw-text-xl">{t('homepage.onlySponsor')}</span>
          </div>
          <div className="tw-h-full tw-flex tw-flex-col [&_.xm-filter-wrapper]:tw-absolute [&_.xm-filter-wrapper]:tw-top-1 [&_.xm-filter-wrapper]:tw-right-1">
            <div className="tw-m-2">
              <UserProjects toFilter={'sponsor'} />
            </div>
          </div>
        </div>
      </div>

      <div className="tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-overflow-hidden">
        {/* projects pie chart */}
        <div className="projects-chart tw-border-r tw-border-zinc-200 tw-flex tw-flex-col tw-grow tw-shrink-0 tw-relative">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-ml-3">
            <div className="tw-size-6 tw-rounded-md tw-bg-red-500 tw-flex tw-items-center tw-justify-center">
              <StackIcon />
            </div>
            <span className="tw-font-semibold tw-text-xl">{t('general.progetti')}</span>
          </div>
          <PersonalDashboardProgetti />
        </div>

        {/* phases pie chart */}
        <div className="phases-chart tw-border-r tw-border-zinc-200 tw-flex tw-flex-col tw-grow tw-shrink-0 tw-relative">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-ml-3">
            <PhaseIcon />
            <span className="tw-font-semibold tw-text-xl">{t('general.phases')}</span>
          </div>
          <PersonalDashboardPhases />
        </div>

        {/* deliverables pie chart */}
        <div className="deliverables-chart tw-border-r tw-border-zinc-200 tw-flex tw-flex-col tw-grow tw-shrink-0 tw-relative">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-ml-3">
            <DeliverableIcon />
            <span className="tw-font-semibold tw-text-xl">{t('bachechaPersonalePage.macroAttivitaDeliverable')}</span>
          </div>
          <PersonalDashboardDeliverables />
        </div>

        {/* activity pie chart */}
        <div className="activities-chart tw-border-zinc-200 tw-flex tw-flex-col tw-grow tw-shrink-0 tw-relative">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-ml-3">
            <TaskIcon />
            <span className="tw-font-semibold tw-text-xl">{t('bachechaPersonalePage.attivita')}</span>
          </div>
          <PersonalDashboardActivity
            activeTabChange={onActiveTabChange}
            inDashboard={true}
          />
        </div>
      </div>

      {/* feed summary  */}
      {/* <Row gutter={{ lg: 24 }}>
          <Col
            className="tw-pt-4 tw-flex tw-flex-col tw-gap-2 tw-pb-20"
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            md={{ span: 22 }}
            lg={{ span: 22 }}>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-ml-3">
              <span className="tw-font-bold tw-text-xl">{t('bachechaPersonalePage.sommarioFeed')}</span>
            </div>

            <Card >
              <PersonalDashboardFeed
                userData={userData}
                t={this.props.t}
              />
            </Card>
          </Col>
        </Row> */}

      {/* <Row gutter={{ lg: 24 }}>           
           
            {!isTeamMember &&
              <Col className="gutter-row tw-mb-4" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 23 }} lg={{ span: 10 }}>
                <Text className='menu'>{t('bachechaPersonalePage.sommarioKpi')}</Text>
                <Card className="sub540-card-body"  >
                  <PersonalDashboardKpi t={this.props.t} />
                </Card>
              </Col>
            }            
           
          </Row> */}

      {/* Committee Table */}
      <TableLayout title={<Typography.Title level={3}>{t('homepage.comitati')}</Typography.Title>}>
        <TableLayout.Content>
          <UserComities />
        </TableLayout.Content>
      </TableLayout>
    </div>
  );
};

export default PersonalDashboard;
