import { CheckCircleOutlined, MenuOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Menu, Skeleton, Typography, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'src/redux/store';
import MatrixService from '../../../services/matrix/matrixService';
import { userIsAdminOrSteering } from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import NewSecondLevelXM from './newSecondLevelXmatrix';

const { SubMenu } = Menu;
const { Text } = Typography;

export default function XmatrixLeftPanel({ activeXmatrixInfo, loadingRootList, xmatrixRootList, reloadMatrix }) {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);
  const history = useHistory();
  const [panelVisibility, setPanelVisibility] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [selectedModalXmatrixData, setSelectedModalXmatrixData] = useState(null);

  const showLeftPanel = () => {
    setPanelVisibility(true);
  };

  const hideLeftPanel = () => {
    setPanelVisibility(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setLoadingButton(false);
  };

  const toggleModal = (xMatrix) => {
    setShowModal(!showModal);
    setSelectedModalXmatrixData(xMatrix);
  };

  const addSecondLevelXM = (values) => {
    setLoadingButton(true);
    MatrixService.insertXmatrixDivision(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          handleModalClose();
          hideLeftPanel();
          localStorage.setItem('xmFiltersL2', JSON.stringify({}));
          history.push(`/indeep/id/${resp.responseObject.value.xMatrixID}`);
        } else {
          message.error(notifyMessages.addFailed);
          setLoadingButton(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        setLoadingButton(false);
      });
  };

  const navigateToXmatrix = (selectedXmatrixId) => {
    let localFiltersData = JSON.parse(localStorage.getItem('xmFilters'));

    if (localFiltersData && localFiltersData.XMatrixID) {
      if (localFiltersData.XMatrixID !== selectedXmatrixId) {
        localStorage.setItem('xmFilters', JSON.stringify({}));
      }
    }

    history.push(`/xmatrix/${selectedXmatrixId}`);
    reloadMatrix();
    hideLeftPanel();
  };

  const navigateToXmatrixSecondLevel = (selectedXmatrixId) => {
    let localFiltersData = JSON.parse(localStorage.getItem('xmFiltersL2'));
    if (localFiltersData && localFiltersData.XMatrixID) {
      if (localFiltersData.XMatrixID !== selectedXmatrixId) {
        localStorage.setItem('xmFiltersL2', JSON.stringify({}));
      }
    }

    history.push(`/indeep/id/${selectedXmatrixId}`);
    reloadMatrix();
    hideLeftPanel();
  };

  return (
    <>
      {showModal && (
        <NewSecondLevelXM
          loadingButton={loadingButton}
          showModal={showModal}
          handleModalClose={handleModalClose}
          handleModalSave={addSecondLevelXM}
          //activeXmatrixInfo = {activeXmatrixInfo}
          selectedXmatrixData={selectedModalXmatrixData}
          // selectedXmatrixValue={selectedXmatrixValue}
          // lastXmatrixYear={lastXmatrixYear}
          t={t}
        />
      )}

      <Button
        data-testid="openLeftPanelBtn"
        type="dashed"
        onClick={showLeftPanel}
        className="leftPanel">
        <MenuOutlined />
      </Button>

      <Drawer
        title={t('xMatrixPage.elencoXmatrix')}
        placement="left"
        onClose={hideLeftPanel}
        open={panelVisibility}>
        {activeXmatrixInfo && (
          <Skeleton
            loading={loadingRootList}
            active
            title
            paragraph
            avatar>
            {xmatrixRootList && (
              <Menu
                defaultOpenKeys={[activeXmatrixInfo?.xMatrixID]}
                mode="inline"
                id="leftMenu">
                {xmatrixRootList.map((xMatrix, index) => {
                  return (
                    <SubMenu
                      key={xMatrix.xmatrixID}
                      icon={
                        activeXmatrixInfo?.xMatrixID === xMatrix.xmatrixID ? (
                          <CheckCircleOutlined className="active-xmatrix" />
                        ) : (
                          <CheckCircleOutlined className="no-active-xmatrix" />
                        )
                      }
                      title={<Text onClick={() => navigateToXmatrix(xMatrix.xmatrixID)}>{xMatrix.hkCode}</Text>}>
                      {xMatrix.childList.map((subDivision) => {
                        return (
                          <Menu.Item key={subDivision.xmatrixID}>
                            <Text onClick={() => navigateToXmatrixSecondLevel(subDivision.xmatrixID)}>
                              {subDivision.hkCode}
                            </Text>
                          </Menu.Item>
                        );
                      })}

                      {userIsAdminOrSteering(userData) && (
                        <Menu.Item
                          icon={<PlusCircleOutlined />}
                          key={xMatrix.xmatrixID + index}
                          onClick={() => toggleModal(xMatrix)}>
                          {t('xMatrixPage.nuovoSotolivello')}
                        </Menu.Item>
                      )}
                    </SubMenu>
                  );
                })}
              </Menu>
            )}
          </Skeleton>
        )}
      </Drawer>
    </>
  );
}

// export default XmatrixLeftPanel;
