import { Form } from 'antd';
import { Component } from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Modal, Row, Select } from 'antd';
import CommonService from '../../../../services/commonService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { objectCodes, statusPickListCodes } from '../../../shared/utils/constants';
import { requiredFields } from '../../../shared/utils/notifyMessages';
import './riskAndPrecaution.scss';

const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class AddCriticModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      loadingButton: false,
      severityList: null,
    };
  }

  componentDidMount() {
    this.retrieveStatus();
    this.retrieveSeverity();
  }

  async retrieveStatus() {
    const objectCode = statusPickListCodes.criticStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveSeverity() {
    const objectCode = objectCodes.criticSeverity;
    await CommonService.getSeverityPickList(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ severityList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  addData = (values, projectId) => {
    this.props.handleCriticModalSave(values, projectId);
  };

  render() {
    const { showCriticModal, handleCriticModalClose, loadingButton, projectId, t } = this.props;
    const { statusList, severityList } = this.state;

    return (
      <Modal
        className="add-critic-modal"
        width={800}
        title={t('proggetiPage.aggiungiNuovoCriticita')}
        open={showCriticModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleCriticModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleCriticModalClose}
            formId={'newProjectCritic'}
          />,
        ]}>
        <Card className="overflow">
          <Form
            id="newProjectCritic"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.addData(values, projectId)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}>
                <Card title={t('proggetiPage.informazioniCriticita')}>
                  <Row
                    gutter={{ lg: 24 }}
                    className="row-margin">
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Form.Item
                        label={t('proggetiPage.nomeCriticita')}
                        name="riskDescription"
                        rules={[{ required: true, message: requiredFields.required }]}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <Form.Item
                        label={t('general.stato')}
                        name="status"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}>
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}>
                          {statusList &&
                            statusList.map((item) => (
                              <Option
                                value={item.statusID}
                                key={item.statusID}>
                                {item.statusDescription}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <Form.Item
                        label={t('general.priorita')}
                        name="severity"
                        hasFeedback>
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}>
                          {severityList &&
                            severityList.map((item) => (
                              <Option
                                value={item.objectCodeListID}
                                key={item.objectCodeListID}>
                                {item.description}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 16 }}
                      lg={{ span: 24 }}>
                      <Form.Item
                        label={t('general.commenti')}
                        name="riskComment">
                        <TextArea
                          rows={5}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}>
                <Card title={t('proggetiPage.contromisureIdentificate')}>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 16 }}
                    lg={{ span: 24 }}>
                    <Form.List
                      name="precautions"
                      // rules={[
                      //     {
                      //         validator: async (_, precautions) => {
                      //             if (!precautions || precautions.length < 1) {
                      //                 return Promise.reject(new Error('At least 1 contromisure'));
                      //             }
                      //         },
                      //     },
                      // ]}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              {...formItemLayout}
                              label={`${t('proggetiPage.contromisure')} ` + (index + 1)}
                              required={false}
                              key={field.key}>
                              <Form.Item
                                {...field}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: requiredFields.required,
                                  },
                                ]}
                                noStyle>
                                <Input
                                  placeholder={t('general.nome')}
                                  style={{ width: '80%' }}
                                />
                              </Form.Item>
                              {fields.length > 0 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ width: '60%' }}
                              icon={<PlusOutlined />}>
                              {t('buttons.aggiungiContromisure')}
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default AddCriticModal;
