import { Form } from 'antd';
import { add, addHours, differenceInMinutes, endOfDay, parse, parseISO } from 'date-fns';
import { Component } from 'react';

import { Card, Col, DatePicker, Input, InputNumber, Modal, Radio, Row, Select, Space, Switch, Typography } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TimeRangePicker from 'src/components/shared/TimeRangePicker';
import CommonService from '../../../../../services/commonService';
import ProgettiService from '../../../../../services/pages/projectServices/progettiService';
import ModalFooterButtons from '../../../../shared/components/modal/modalFooterButtons';
import { formItemLayout, objectCodes, projectRoutineProgram } from '../../../../shared/utils/constants';
import { areDatesValid, isDateBetween, showErrorNotification } from '../../../../shared/utils/functions';
import { requiredFields } from '../../../../shared/utils/notifyMessages';

dayjs.extend(customParseFormat);
const { Text } = Typography;

const TIME_FORMAT = 'HH:mm';
const DATE_FORMAT = 'DD/MM/YYYY';

const weekDays = [
  { label: 'Monday', value: '1' },
  { label: 'Tuesday', value: '2' },
  { label: 'Wednesday', value: '3' },

  { label: 'Thursday', value: '4' },
  { label: 'Friday', value: '5' },
  { label: 'Saturday', value: '6' },
  { label: 'Sunday', value: '7' },
];

class NewRoutineModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      users: null,
      loadingButton: false,
      feedTypes: null,
      selectedType: null,
      routineOrganisers: null,
      routineScheduleType: null,
      startTime: null,
      endTime: null,
      startDate: new Date(),
      endDate: null,
      selectedMeetigProgram: null,
      weekOfRecurrence: null,
      dayOfRecurrence: null,
      dailySelectDays: null, //dayofweek
      frequence: null,
      selectedUsers: [],
      usersList: null,
      isMandatory: false,
      integrateInOutlook: false,
      integrateInTeams: false,
      isFrozen: false,
    };
  }

  componentDidMount() {
    this.retrieveRoutineOrganiser();
    this.retrieveRoutineScheduleType();
    this.retrieveProjectUsers();
  }

  async retrieveRoutineOrganiser() {
    const objectCode = objectCodes.routineOrganiser;

    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ routineOrganisers: resp.responseObject.value });
        }
      })
      .catch((error) => {});
  }

  async retrieveRoutineScheduleType() {
    const objectCode = objectCodes.routineSchedule;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let data = resp.responseObject.value;
          this.setState({ routineScheduleType: data });
        }
      })
      .catch((error) => {});
  }

  integrateInTeamsChange(val) {
    this.setState({ integrateInTeams: val });
    const inOutLook = this.state.integrateInOutlook;

    if (val) {
      this.setState({ isMandatory: true });
    } else if (!val && !inOutLook) {
      this.setState({ isMandatory: false });
    }
  }

  integrateInOutLookChange(val) {
    this.setState({ integrateInOutlook: val });
    const inTeams = this.state.integrateInTeams;

    if (val) {
      this.setState({ isMandatory: true });
    } else if (!val && !inTeams) {
      this.setState({ isMandatory: false });
    }
  }

  toggleCreateMeetings(type) {
    this.setState({ isFrozen: type === 2 });
  }

  checkValidityBeforeUpdate = (routine, projectId) => {
    const { t } = this.props;
    const { selectedUsers, endTime, startTime, selectedMeetigProgram, dayOfRecurrence, dailySelectDays, frequence } =
      this.state;

    routine.startTime = startTime;
    routine.endTime = endTime;
    routine.duration = (differenceInMinutes(endTime, startTime, { roundingMethod: 'round' }) / 60).toFixed(2);
    routine.schedule = selectedMeetigProgram;
    routine.frequenceType = selectedMeetigProgram;
    routine.frequence = frequence;
    routine.dayOfWeek = dailySelectDays;

    if (!areDatesValid(routine.startDate, routine.endDate)) {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
      return;
    }

    this.props.addNewRoutine(routine, projectId);
  };

  onStartTimeChange = (value) => {
    const newStartTime = parse(value, TIME_FORMAT, new Date());
    const newEndTime = addHours(newStartTime, 1);

    this.setState({
      startTime: newStartTime,
      endTime: new Date(newEndTime),
    });
  };

  onEndTimeChange = (value) => {
    const newEndTime = parse(value, TIME_FORMAT, new Date());

    this.setState({
      endTime: newEndTime,
    });
  };

  onStartDateChange = (newStartDate) => {
    this.setState({
      startDate: newStartDate.toDate(),
      endDate: newStartDate.toDate(),
    });
  };

  onEndDateChange = (newEndDate) => {
    this.setState({ endDate: newEndDate.toDate() });
  };

  disableEndDate = (current) => {
    const { selectedMeetigProgram, startDate } = this.state;
    const { endDateProject } = this.props;

    const currentEnd = endOfDay(current.toDate());
    const startDateEnd = endOfDay(startDate);
    const projectEndDate = endOfDay(parseISO(endDateProject));

    if (selectedMeetigProgram !== null) {
      const whatToAdd = {
        days: +(selectedMeetigProgram === projectRoutineProgram.daily),
        weeks: +(selectedMeetigProgram === projectRoutineProgram.weekly),
        months: +(selectedMeetigProgram === projectRoutineProgram.monthly),
      };

      return !isDateBetween(currentEnd, add(startDateEnd, whatToAdd), projectEndDate);
    } else {
      // current in [start + 1, projectEnd]
      return !isDateBetween(currentEnd, add(startDateEnd, { days: 1 }), projectEndDate);
    }
  };

  disableStartDate = (current) => {
    const { endDateProject, startDateProject } = this.props;

    const currentEnd = endOfDay(current.toDate());
    const projectStartDate = endOfDay(parseISO(startDateProject));
    const projectEndDate = endOfDay(parseISO(endDateProject));

    return !isDateBetween(currentEnd, projectStartDate, projectEndDate);
  };

  onProgramSelect = (program) => {
    this.setState({ selectedMeetigProgram: +program });
  };

  onDayOfRecurrenceSelect = (value) => {
    this.setState({ dayOfRecurrence: value });
  };

  onDailySelectDays = (value) => {
    if (value === '8') {
      this.setState({
        dailySelectDays: 8,
        frequence: 0,
      });
    } else {
      this.setState({
        dailySelectDays: 0,
        frequence: Number.parseInt(value),
      });
    }
  };

  onDayOfWeekRecurrenceSelect = (value) => {
    this.setState({ dailySelectDays: value });
  };

  onFrequenceSelect = (value) => {
    this.setState({ frequence: value });
  };

  onSelectChange = (users) => {
    this.setState({ users });
  };

  onSelectedUser = (id) => {};

  async retrieveProjectUsers() {
    this.setState({ isDataLoading: true });
    const { projectId } = this.props;
    await ProgettiService.getProjectTeamMembers(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ usersList: resp.responseObject.value });
          this.setState({ isDataLoading: false });
        }
      })
      .catch((error) => {});
  }

  render() {
    const { users, showNewRoutineModal, handleNewRoutineModalClose, loadingButton, projectId, t } = this.props;
    const {
      selectedUsers,
      routineOrganisers,
      routineScheduleType,
      startTime,
      endTime,
      selectedMeetigProgram,
      startDate,
      usersList,
      isMandatory,
      isFrozen,
    } = this.state;

    const unique = users.filter((a, i) => users.findIndex((s) => a.fullName === s.fullName) === i);
    const uniqueUsr = unique.map((user) => {
      return {
        value: user.userID,
        label: user.fullName,
        key: user.userID,
        disabled: user.isDeleted,
      };
    });

    return (
      <Modal
        className="add-critic-modal"
        width={800}
        title={t('proggetiPage.aggiungiRoutineProgetto')}
        open={showNewRoutineModal}
        destroyOnClose={true}
        onCancel={handleNewRoutineModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleNewRoutineModalClose}
            formId={'newProjectRoutine'}
          />,
        ]}>
        <Card className="overflow">
          <Form
            id="newProjectRoutine"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.checkValidityBeforeUpdate(values, projectId)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}>
                <Form.Item
                  label={t('general.nome')}
                  name="name"
                  rules={[{ required: true, message: requiredFields.required }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            {/* Second row: type and participants */}
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              {/* Type field */}
              <Col
                className="gutter-row"
                xs={{ span: 6 }}
                sm={{ span: 6 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}>
                <Form.Item
                  label={t('proggetiPage.newRoutineType')}
                  name="who"
                  hasFeedback
                  // rules={ this.state.isMandatory? [{ required: true, message: requiredFields.required }] : ""}
                >
                  <Radio.Group onChange={(value) => this.toggleCreateMeetings(value.target.value)}>
                    <Space direction="vertical">
                      {routineOrganisers &&
                        routineOrganisers.map((type) => (
                          <Radio
                            key={type.objectCodeListID}
                            value={type.objectCodeListID}>
                            {type.description}
                          </Radio>
                        ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>

              {/* Participants field */}
              <Col
                className="gutter-row"
                xs={{ span: 18 }}
                sm={{ span: 18 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}>
                <Form.Item
                  className="main-container"
                  name="extraUsersRoutine"
                  label={t('general.partecipanti')}
                  hasFeedback
                  // rules={ this.state.isMandatory? [{ required: true, message: requiredFields.required }] : ""}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={t('general.utenti')}
                    onChange={(value) => this.onSelectedUser(value)}
                    options={uniqueUsr}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* Third row: start and end time */}
            <TimeRangePicker
              isRequired={this.state.isMandatory}
              startTime={startTime}
              endTime={endTime}
              onStartTimeChange={(value) => this.onStartTimeChange(value)}
              onEndTimeChange={(value) => this.onEndTimeChange(value)}
            />

            {/* Fourth row: start date and end date */}
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  label={t('general.dataInizio')}
                  name="startDate"
                  rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}>
                  <DatePicker
                    value={startDate}
                    onChange={(date) => this.onStartDateChange(date)}
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                    disabledDate={(current) => this.disableStartDate(current)}
                  />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  label={t('general.dataFine')}
                  name="endDate">
                  <DatePicker
                    disabled={selectedMeetigProgram !== null}
                    style={{ width: '100%' }}
                    onChange={(date) => this.onEndDateChange(date)}
                    format={DATE_FORMAT}
                    disabledDate={(current) => this.disableEndDate(current)}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* Fifth row: recurrence section */}
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}>
                <Form.Item
                  label={t('proggetiPage.pianificazione')}
                  name="endTime">
                  <Radio.Group onChange={(value) => this.onProgramSelect(value.target.value)}>
                    <Space direction="vertical">
                      {routineScheduleType &&
                        routineScheduleType.map((item) => (
                          <Radio
                            key={item.objectCodeListID}
                            value={item.objectCodeListID}>
                            {item.description}
                          </Radio>
                        ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>

              {this.state.selectedMeetigProgram !== null && (
                <Col
                  xs={{ span: 16 }}
                  sm={{ span: 16 }}
                  md={{ span: 16 }}
                  lg={{ span: 16 }}>
                  <Form.Item label={'Dettagli ricorrenza'}>
                    {this.state.selectedMeetigProgram === projectRoutineProgram.daily && (
                      <Radio.Group onChange={(value) => this.onDailySelectDays(value.target.value)}>
                        <Space direction="vertical">
                          <Row
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            className="tw-flex tw-items-center tw-gap-2">
                            <Radio value="1">Every</Radio>
                            <Form.Item style={{ marginBottom: '0px' }}>
                              <InputNumber
                                onChange={(value) => this.onDailySelectDays(value)}
                                defaultValue={1}
                                min={1}
                                max={7}
                              />
                            </Form.Item>
                            <Text style={{ right: '3px' }}>day(s)</Text>
                          </Row>
                          <Radio value="8">Every weekday</Radio>
                        </Space>
                      </Radio.Group>
                    )}

                    {this.state.selectedMeetigProgram === projectRoutineProgram.weekly && (
                      <Form.Item
                        name="frequency"
                        rules={[{ required: true, message: 'Please input the frequency!' }]}>
                        <Row>
                          <Text style={{ marginRight: 5, marginTop: 4 }}>
                            <span style={{ color: 'red' }}>*</span> Recur every
                          </Text>
                          <InputNumber onChange={(value) => this.onFrequenceSelect(value)} />
                          <Text style={{ marginLeft: 5, marginTop: 4 }}>weeks(s) on:</Text>
                        </Row>
                        <Row>
                          <Radio.Group onChange={(value) => this.onDayOfWeekRecurrenceSelect(value?.target.value)}>
                            <Space direction="vertical">
                              {weekDays.map((item, i) => (
                                <Col
                                  xs={{ span: 8 }}
                                  md={{ span: 6 }}
                                  key={i}>
                                  <Radio value={item.value}>{item.label}</Radio>
                                </Col>
                              ))}
                            </Space>
                          </Radio.Group>
                        </Row>
                      </Form.Item>
                    )}

                    {this.state.selectedMeetigProgram === projectRoutineProgram.monthly && (
                      <Radio.Group>
                        <Space direction="vertical">
                          <Radio value="1">
                            <Row
                              xs={{ span: 24 }}
                              md={{ span: 24 }}
                              className="tw-flex tw-items-center tw-gap-x-2">
                              <Text>Day</Text>
                              <Form.Item
                                style={{ marginBottom: '0px' }}
                                name="dayOfWeek">
                                <InputNumber
                                  onChange={(value) => this.onDayOfWeekRecurrenceSelect(value)}
                                  defaultValue={1}
                                />
                              </Form.Item>
                              <Text>of every</Text>
                              <Form.Item
                                style={{ marginBottom: '0px' }}
                                name="frequence">
                                <InputNumber onChange={(value) => this.onFrequenceSelect(value)} />
                              </Form.Item>
                              <Text>month(s)</Text>
                            </Row>
                          </Radio>
                          <Row></Row>
                        </Space>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              )}
            </Row>

            {/* Last row: meetings switches */}
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col className="gutter-row">
                <Form.Item
                  label={t('proggetiPage.createReunioneOutlook')}
                  name="integrateInOutlook"
                  valuePropName="checked"
                  initialValue={false}>
                  <Switch
                    onClick={(val) => this.integrateInOutLookChange(val)}
                    disabled={this.state.isFrozen}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row">
                <Form.Item
                  label={t('proggetiPage.createMeetingOnTeams')}
                  name="integrateInTeams"
                  valuePropName="checked"
                  initialValue={false}>
                  <Switch
                    onClick={(val) => this.integrateInTeamsChange(val)}
                    disabled={this.state.isFrozen}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default NewRoutineModal;
