import { Col, Empty, Row } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Component } from 'react';
import CommitieService from '../../../../services/pages/commitieServices';
import { perStatusActivityChartOptions, perTimeActivityChartOptions } from '../../../personalDashbard/chartsConfig';
import Loader2 from '../../../shared/components/loader2/loader2';

class ComitieActivityChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingStatusChart: false,
      loadingIntimeChart: false,
      statusChartHasData: [],
      inTimeChartHasData: [],
      statusChartData: perStatusActivityChartOptions,
      inTimeChartData: perTimeActivityChartOptions,
    };
  }

  componentDidMount() {
    this.getPerStatusChartData();
    this.getPerTimeChartData();
  }

  async getPerStatusChartData() {
    let { t, comitieId } = this.props;
    this.setState({ loadingStatusChart: true });
    await CommitieService.getCommitteeSummaryPerStatus(comitieId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          this.setState({ statusChartHasData: respData });

          let seriesObj = {};
          let dataObj = [];
          seriesObj['name'] = `${t('bachechaPersonalePage.attivita')}`;
          respData.map((item) => dataObj.push({ name: item.statusDescription, y: item.activityNumber }));
          seriesObj['data'] = dataObj;
          this.setState({ statusChartData: { ...this.state.statusChartData, series: seriesObj } });
          this.setState({ loadingStatusChart: false });
        } else {
          this.setState({ loadingStatusChart: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingStatusChart: false });
      });
  }

  async getPerTimeChartData() {
    let { t, comitieId } = this.props;
    this.setState({ loadingIntimeChart: true });
    await CommitieService.getCommitteeSummaryPerTime(comitieId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          this.setState({ inTimeChartHasData: respData });

          let seriesObj = {};
          let dataObj = [];
          seriesObj['name'] = `${t('bachechaPersonalePage.attivita')}`;
          respData.map((item, index) => dataObj.push({ name: item.activityStatusDescription, y: item.activityCount }));
          seriesObj['data'] = dataObj;
          this.setState({ inTimeChartData: { ...this.state.inTimeChartData, series: seriesObj } });
          this.setState({ loadingIntimeChart: false });
        } else {
          this.setState({ loadingIntimeChart: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingIntimeChart: false });
      });
  }

  render() {
    let { t } = this.props;
    let {
      statusChartData,
      loadingStatusChart,
      loadingIntimeChart,
      inTimeChartData,
      statusChartHasData,
      inTimeChartHasData,
    } = this.state;

    return (
      <>
        <Row gutter={32}>
          <Col
            xs={24}
            md={12}
            className="tw-border-r">
            <span className="tw-text-zinc-900 tw-leading-6 tw-font-semibold">
              {t('bachechaPersonalePage.perStatus')}
            </span>
            {loadingStatusChart && <Loader2 />}
            {statusChartHasData.length > 0 && (
              <HighchartsReact
                highcharts={Highcharts}
                options={statusChartData}
              />
            )}

            {statusChartHasData.length <= 0 && <Empty />}
          </Col>

          <Col
            xs={24}
            md={12}>
            <span className="tw-text-zinc-900 tw-leading-6 tw-font-semibold">
              {t('bachechaPersonalePage.inTempoRitardo')}
            </span>
            {loadingIntimeChart && <Loader2 />}
            {inTimeChartHasData.length > 0 && (
              <HighchartsReact
                highcharts={Highcharts}
                options={inTimeChartData}
              />
            )}
            {inTimeChartHasData.length <= 0 && <Empty />}
          </Col>
        </Row>
      </>
    );
  }
}

export default ComitieActivityChart;
