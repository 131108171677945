import {
  authenticatedApi,
  GetFilterbyXMatrixRequest,
  GetResourceWorkloadSummaryRequest,
  PersonalDashboardApi,
} from 'src/config/connectors';
import i18n from '../../i18n';
import { trackPromise } from 'react-promise-tracker';

const PersonalDashboardService = {
  getDeliverablePerStatusChartData: async function () {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetDeliverableSummaryperStatus(currentLanguageCode));
  },

  getDeliverablePerTimeChartData: async function () {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetDeliverableSummaryperTime(currentLanguageCode));
  },

  getPersonalDashboardPhases: async function () {
    try {
      const api = await authenticatedApi(PersonalDashboardApi);

      return trackPromise(api.personalDashboardGetPersonalDashboardPhases());
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  },

  getActivityPerStatusChartData: async function () {
    const currentLng = i18n.language;
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetActivitySummaryperStatus(currentLng));
  },

  getActivityPerTimeChartData: async function () {
    const currentLng = i18n.language;
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetActivitySummaryperTime(currentLng));
  },

  getKpiSummary: async function (xmatrixId: string) {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetKPISummary(xmatrixId));
  },

  getFeedSummary: async function (userId: string) {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetFeedSummary(userId));
  },

  getXmatrixSummary: async function (selectedXmatrixId: string) {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetXMatrixSummary(selectedXmatrixId));
  },

  getXmatrixFilterChartData: async function (payload: GetFilterbyXMatrixRequest) {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetFilterbyXMatrix(payload));
  },

  getResourceWorkloadData: async function (payload: GetResourceWorkloadSummaryRequest) {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetResourceWorkloadSummary(payload));
  },

  getUsersPicklistPersonalDashboard: async function () {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetUserSelectPersonalDashboard());
  },

  getTeamLeaderPicklistPersonalDashboard: async function () {
    const api = await authenticatedApi(PersonalDashboardApi);

    return trackPromise(api.personalDashboardGetTeamLeaderPicklistPersonalDashboard());
  },
};

export default PersonalDashboardService;
