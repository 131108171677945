import React from 'react';
import { FaCogs, FaLeaf, FaLightbulb, FaUsb } from 'react-icons/fa';
import { IoLogoUsd } from 'react-icons/io5';

interface CategoryIconProps {
  iconName: string;
}

const CategoryIcon: React.FC<CategoryIconProps> = ({ iconName }) => {
  const iconMap: Record<string, JSX.Element> = {
    'fa-usd': <IoLogoUsd />,
    'fa-cogs': <FaCogs />,
    'fa-leaf': <FaLeaf />,
    'fa-lightbulb-o': <FaLightbulb />,
    'fa-usb': <FaUsb />,
  };

  return <div className="tw-shrink-0">{iconMap[iconName] || <span>{`Icon "${iconName}" not found`}</span>}</div>;
};

export default CategoryIcon;
