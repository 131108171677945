import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetXMatrixInfoResponseDto } from 'src/connectors/backend';
import MatrixService from 'src/services/matrix/matrixService';

interface ActiveXMatrixState {
  loading: boolean;
  activeXMatrix: GetXMatrixInfoResponseDto | null;
  error: string | null;
}

const initialState: ActiveXMatrixState = {
  loading: false,
  activeXMatrix: null,
  error: null,
};

export const getActiveXmatrix = createAsyncThunk<GetXMatrixInfoResponseDto, void>(
  'activeXMatrix/getActiveXmatrix',
  async (_, { rejectWithValue }) => {
    try {
      const response = await MatrixService.getActiveXmatrix();

      if (!response.data.success) {
        return rejectWithValue('Failed to retrieve xMatrix data');
      }

      const activeXmatrix = response.data.responseObject?.value;

      if (!activeXmatrix) {
        return rejectWithValue('No active xMatrix found');
      }

      return activeXmatrix;
    } catch (error) {
      return rejectWithValue(error || 'An error occurred while fetching xMatrix data');
    }
  },
);

const activeXMatrixSlice = createSlice({
  name: 'activeXMatrix',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveXmatrix.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getActiveXmatrix.fulfilled, (state, action) => {
        state.loading = false;
        state.activeXMatrix = action.payload;
      })
      .addCase(getActiveXmatrix.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default activeXMatrixSlice.reducer;
