import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ModalFooterButtonsProps {
  disabled: boolean;
  modalClose: () => void;
  loadingButton: boolean;
  formId: string;
}

const ModalFooterButtons: React.FC<ModalFooterButtonsProps> = ({ disabled, modalClose, loadingButton, formId }) => {
  const { t } = useTranslation();

  return (
    <div className="tw-flex tw-justify-end tw-gap-2">
      <Button
        onClick={modalClose}
        key="close">
        {t('buttons.annulla')}
      </Button>
      <Button
        form={formId}
        loading={loadingButton}
        type="primary"
        htmlType="submit"
        key="submit"
        disabled={disabled}>
        {t('buttons.salva')}
      </Button>
    </div>
  );
};

export default ModalFooterButtons;
