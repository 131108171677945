import { DownCircleOutlined, FolderOpenOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Divider, Row, Space, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectDto, ProjectStatus } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import {
  BudgetTooltip,
  ProjectBudgetSemaphore,
  ProjectQualitySemaphore,
  ProjectTimeSemaphore,
  QualityTooltip,
  TimeTooltip,
} from '../shared/semaphores/semaphores';
import { semaphoreStatus } from '../shared/utils/constants';
import './dashboard.scss';

const { Text, Paragraph } = Typography;

interface ProjectCardProps {
  project: ProjectDto;
  expanded: boolean;
}

const checkProjectSemaphoreColor = (status: any) => {
  switch (status) {
    case semaphoreStatus.s1:
      return 'projectGreenColorIndicator';
    case semaphoreStatus.s2:
      return 'projectYellowColorIndicator';
    case semaphoreStatus.s3:
      return 'projectRedColorIndicator';
    default:
      return 'projectGreyColorIndicator';
  }
};

interface CardExtrasProps {
  status: string;
  project: ProjectDto;
  handleToggle: () => void;
  isActive: boolean;
  expanded: boolean;
}

const CardExtras: React.FC<CardExtrasProps> = ({ status, project, handleToggle, isActive, expanded }) => {
  const { t } = useTranslation();
  const { companyData } = useAppSelector((state) => state.companyData);

  return (
    <div className="tw-flex tw-justify-between tw-gap-2">
      <div className="tw-flex tw-items-center tw-gap-2">
        <Tooltip
          placement="top"
          title={
            <TimeTooltip
              data={project}
              t={t}
            />
          }>
          <div className={checkProjectSemaphoreColor(project.timeSemaphore)}>
            <ProjectTimeSemaphore data={project} />
          </div>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <BudgetTooltip
              data={project}
              t={t}
              companyData={companyData}
            />
          }>
          <div className={checkProjectSemaphoreColor(project.budgetSemaphore)}>
            <ProjectBudgetSemaphore data={project} />
          </div>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <QualityTooltip
              data={project}
              t={t}
            />
          }>
          <div className={checkProjectSemaphoreColor(project.qualitySemaphore)}>
            <ProjectQualitySemaphore data={project} />
          </div>
        </Tooltip>
      </div>

      <div className="tw-flex tw-items-center tw-gap-2">
        <Tooltip title={t('proggetiPage.passaProgetto')}>
          <Button href={`/progetti/id/${project.id}`}>
            <FolderOpenOutlined />
          </Button>
        </Tooltip>
        {isActive && !expanded ? (
          <Button onClick={handleToggle}>
            <UpCircleOutlined />
          </Button>
        ) : (
          <Button onClick={handleToggle}>
            <DownCircleOutlined />
          </Button>
        )}
      </div>
    </div>
  );
};

const checkUpdatedWeek = (updatedDate: string) => {
  const currentWeek = moment().isoWeek();
  const updatedWeek = moment(updatedDate, 'MMDDYYYY').isoWeek();
  return updatedWeek === currentWeek;
};

const ProjectCard: FC<ProjectCardProps> = ({ project, expanded }) => {
  const { t } = useTranslation();

  const [isActive, setIsActive] = useState<boolean>(expanded);

  useEffect(() => {
    setIsActive(expanded);
  }, [expanded]);

  const handleToggle = () => {
    setIsActive((prevIsActive) => !prevIsActive);
  };

  const formattedDate = moment(project.lastUpdated).format('MMDDYYYY');
  const updatedWeek = moment(formattedDate, 'MMDDYYYY').isoWeek();

  const teamLeader = project.teamLeader;
  const sponsor = project.sponsor;

  return (
    <Card
      className={`[&_.ant-card-body]:tw-flex-1 [&_.ant-card-body]:tw-bg-zinc-50 [&_.ant-card-body]:tw-p-2 tw-flex tw-flex-col tw-min-h-full${
        isActive && expanded ? ' expand ant-card-body-minHeight' : ''
      }`}>
      <div className="tw-flex tw-flex-1 tw-flex-col tw-p-4">
        <div className="tw-mb-4">
          <CardExtras
            handleToggle={handleToggle}
            status={project.statusDescription || ''}
            project={project}
            isActive={isActive}
            expanded={expanded}
          />

          <h3 className="tw-mt-4 tw-text-2xl tw-font-semibold">{project.code}</h3>
        </div>

        <div className="tw-my-2">
          <Tag>{project.statusDescription}</Tag>
        </div>

        {teamLeader && (
          <div className="tw-mb-2">
            <Space direction="horizontal">
              {teamLeader.profilePhoto ? (
                <Avatar
                  size="small"
                  src={teamLeader.profilePhoto}
                />
              ) : (
                <Avatar size="small">{teamLeader.initials || '-'}</Avatar>
              )}
              <Text>{teamLeader.fullName}</Text>
            </Space>
          </div>
        )}

        <div className="tw-min-h-20">
          <Tooltip title={project.name}>
            <Typography.Title
              level={4}
              ellipsis={{ rows: 2 }}>
              {project.name}
            </Typography.Title>
          </Tooltip>
        </div>

        <div>
          {checkUpdatedWeek(formattedDate) ? (
            <p style={{ color: '#16a34a' }}>
              {t('proggetiPage.settimanaAggiornamento')}: {updatedWeek} |{' '}
              {moment(new Date(project.lastUpdated || '')).year()}
            </p>
          ) : (
            <p style={{ color: '#ef4444' }}>
              {t('proggetiPage.settimanaAggiornamento')}: {updatedWeek} |{' '}
              {moment(new Date(project.lastUpdated || '')).year()}
            </p>
          )}
        </div>

        {(expanded || isActive) && (
          <>
            <Divider className="cardDivider !tw-my-4" />
            {sponsor && (
              <Space
                direction="horizontal"
                className="tw-ml-2">
                {sponsor.profilePhoto ? (
                  <Avatar
                    size="small"
                    src={sponsor.profilePhoto}
                  />
                ) : (
                  <Avatar size="small">{sponsor.initials || '-'}</Avatar>
                )}
                <Text style={{ fontSize: '16px', color: '#012c60' }}>{sponsor.fullName}</Text>
                <Divider type="vertical" />
                <Text style={{ color: '#9b9a9a' }}>{sponsor.jobTitle}</Text>
              </Space>
            )}

            <span className="wrap-meta tw-mt-4">
              <p className="subtitle">{t('proggetiPage.progettoPrincipale')}:</p>
              <Tooltip
                placement="top"
                title={project.parentProjectName}>
                <Paragraph
                  className="description"
                  ellipsis={{ rows: 1 }}>
                  {project.parentProjectName}
                </Paragraph>
              </Tooltip>
            </span>

            <span className="wrap-meta">
              <p className="subtitle">{t('general.divisione')}:</p>
              <Paragraph
                className="description"
                ellipsis={{ rows: 1 }}>
                {project.divisionName}
              </Paragraph>
            </span>

            <div className="tw-grid tw-grid-cols-2">
              <span className="wrap-meta">
                <p className="subtitle">{t('general.start')}:</p>
                <p className="description">{moment(project.startDate).format('DD/MM/YYYY')}</p>
              </span>

              {project.endDate && project.lastUpdated && project.statusId && (
                <span className="wrap-meta">
                  {project.endDate > project.lastUpdated && project.statusId === ProjectStatus.Active ? (
                    <span className="subtitle">{t('general.end')}:</span>
                  ) : (
                    <Row style={{ alignItems: 'center' }}>
                      <span className="subtitle">{t('general.end')}</span>
                      <span className="delay"> </span>
                    </Row>
                  )}
                  <span className="description">{moment(project.endDate).format('DD/MM/YYYY')}</span>
                </span>
              )}
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default ProjectCard;
