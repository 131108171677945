import { Form } from 'antd';
import { Component } from 'react';

import { Card, DatePicker, Divider, Modal, Row, Transfer } from 'antd';
import moment from 'moment';
import MatrixService from '../../services/matrix/matrixService';
import Loader2 from '../shared/components/loader2/loader2';
import ModalFooterButtons from '../shared/components/modal/modalFooterButtons';
import { requiredFields } from '../shared/utils/notifyMessages';
import './matrix.scss';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class AddXmatrixNextYear extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      loadingButton: false,
      annualGoalList: [],
      loadingAnnualGoalList: false,
      loadingProjectList: false,
      annualGoalTargetKeys: [],
      projectGoalList: [],
      projectTargetKeys: [],
    };
  }

  componentDidMount() {
    this.retrieveAnnualGoalsPicklist();
    this.retrieveProjects();
  }

  async retrieveAnnualGoalsPicklist() {
    const { selectedXmatrixValue } = this.props;
    this.setState({ loadingAnnualGoalList: true });
    await MatrixService.getNewXmatrixAnnualGoals(selectedXmatrixValue)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const mockData = resp.responseObject.value.map((item) => ({
            key: item.annualGoalID,
            description: item.description,
            orderingValue: item.orderingValue,
          }));
          this.setState({ annualGoalList: mockData });
          this.setState({ loadingAnnualGoalList: false });
        } else {
          this.setState({ loadingAnnualGoalList: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingAnnualGoalList: false });
      });
  }

  async retrieveProjects() {
    const { selectedXmatrixValue } = this.props;

    this.setState({ loadingProjectList: true });
    await MatrixService.getNewXmatrixProjects(selectedXmatrixValue)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const mockData = resp.responseObject.value.map((item) => ({
            key: item.projectID,
            description: item.name,
            projectCode: item.projectCode,
          }));
          this.setState({ projectGoalList: mockData });
          this.setState({ loadingProjectList: false });
        } else {
          this.setState({ loadingProjectList: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingProjectList: false });
      });
  }

  handleChangeAnnualGoal = (targetKeys) => {
    this.setState({ annualGoalTargetKeys: targetKeys });
  };

  handleChangeProject = (targetKeys) => {
    this.setState({ projectTargetKeys: targetKeys });
  };

  disablePreviousYears = (current, lastXmatrixYear) => {
    const { xMatrixYearsList } = this.props;
    let curr = moment(current).year().toString();
    return xMatrixYearsList.find((dt) => dt === curr);
  };

  addData = (values) => {
    const { annualGoalTargetKeys, projectTargetKeys } = this.state;
    values.year = moment(values.year).startOf('year').format('YYYY-MM-DD');
    values['projectIDs'] = projectTargetKeys;
    values['annualGoalID'] = annualGoalTargetKeys;
    this.props.handleModalSave(values);
  };

  render() {
    const { showModal, handleModalClose, loadingButton, lastXmatrixYear, t } = this.props;
    const {
      annualGoalList,
      annualGoalTargetKeys,
      projectGoalList,
      projectTargetKeys,
      loadingAnnualGoalList,
      loadingProjectList,
    } = this.state;

    return (
      <Modal
        className="mew_xmatrix_modal"
        width={800}
        title={t('xMatrixPage.nuovaAnnoSuccesivoXm')}
        open={showModal}
        destroyOnClose={true}
        onCancel={handleModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleModalClose}
            formId={'newNextYearXmatrix'}
          />,
        ]}>
        <Card>
          <Form
            className="tw-flex tw-flex-col tw-items-center"
            id="newNextYearXmatrix"
            name="add_xmatrix"
            layout="vertical"
            {...formItemLayout}
            onFinish={this.addData}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Form.Item
              label={t('general.anno')}
              name="year"
              className="!tw-mb-0 tw-w-full"
              hasFeedback
              rules={[{ required: true, message: requiredFields.required }]}>
              <DatePicker
                className="tw-h-[38px]"
                placeholder={t('general.seleziona')}
                picker="year"
                style={{ width: '100%' }}
                format="YYYY"
                disabledDate={(current) => this.disablePreviousYears(current, lastXmatrixYear)}
              />
            </Form.Item>

            <Row
              gutter={{ lg: 24 }}
              className="tw-flex tw-w-full">
              <Divider plain>{t('general.obbietiviAnuali')}</Divider>
              {loadingAnnualGoalList && <Loader2 />}

              {!loadingAnnualGoalList && (
                <Transfer
                  className="tw-flex tw-items-center"
                  dataSource={annualGoalList}
                  titles={['', `${t('xMatrixPage.nuovoXmatrix')}`]}
                  showSearch
                  listStyle={{
                    width: 355,
                    height: 300,
                  }}
                  targetKeys={annualGoalTargetKeys}
                  onChange={this.handleChangeAnnualGoal}
                  render={(item) => `${item.description} (${item.orderingValue})`}
                  oneWay={true}
                  locale={{
                    itemUnit: `${t('general.obbietiviAnuali')}`,
                    itemsUnit: `${t('general.obbietiviAnuali')}`,
                    notFoundContent: `${t('general.elencoVuoto')}`,
                    searchPlaceholder: `${t('buttons.cerca')}`,
                  }}
                />
              )}
            </Row>

            <Row
              gutter={{ lg: 24 }}
              className="tw-flex tw-w-full">
              <Divider plain>{t('general.progetti')}</Divider>
              {loadingProjectList && <Loader2 />}

              {!loadingProjectList && (
                <Transfer
                  className="tw-flex tw-items-center"
                  dataSource={projectGoalList}
                  titles={['', `${t('xMatrixPage.nuovoXmatrix')}`]}
                  showSearch
                  listStyle={{
                    width: 355,
                    height: 300,
                  }}
                  targetKeys={projectTargetKeys}
                  onChange={this.handleChangeProject}
                  render={(item) => `${item.description} (${item.projectCode})`}
                  oneWay={true}
                  locale={{
                    itemUnit: `${t('general.progetti')}`,
                    itemsUnit: `${t('general.progetti')}`,
                    notFoundContent: `${t('general.elencoVuoto')}`,
                    searchPlaceholder: `${t('buttons.cerca')}`,
                  }}
                />
              )}
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default AddXmatrixNextYear;
