import { Form } from 'antd';
import { useEffect, useState } from 'react';

import {
  CloseOutlined,
  ContainerOutlined,
  DeleteOutlined,
  DesktopOutlined,
  ExclamationCircleOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Input, Modal, Row, Select, Space, Switch, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import UserSelectDropDown from '../../../shared/components/userSelect/userSelect';
import { formItemLayout, xmatrixStatus } from '../../../shared/utils/constants';
import { requiredFields } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';

const { TextArea } = Input;
const { Option } = Select;

const EditProjectModal = (props) => {
  const {
    show,
    handleModalCancel,
    data,
    commities,
    statuses,
    divisionsList,
    loadingButton,
    selectedXmatrixInfo,
    isSecondLevelXmatrix,
  } = props;

  const { t } = useTranslation();

  const [isMandatory, setIsMandatory] = useState(false);
  const [isStatusActive, setIsStatusActive] = useState(false);
  const [visible, setVisible] = useState(false);
  const [projectLink, setProjectLink] = useState(null);
  const [fieldsChanged, setFieldsChanged] = useState(false);

  useEffect(() => {
    getProjectLink();
  }, []);

  const getProjectLink = async () => {
    await ProgettiService.getProjectLink(data.projectID).then((response) => {
      const resp = response.data;
      if (resp.success) {
        setProjectLink(resp.responseObject.value);
      }
    });
  };

  const onStatusChange = (value, option) => {
    if (option.children === 'Attivo') {
      setIsMandatory(true);
      setIsStatusActive(true);
    } else {
      setIsMandatory(false);
      setIsStatusActive(false);
    }
  };

  const updateData = (values, id) => {
    props.handleSave(values, id);
  };

  const removeProject = (id, DeleteProject1) => {
    setVisible(false);
    props.removeProject(id, DeleteProject1);
  };

  const removeProjectDraft = (id, DeleteProject1) => {
    setVisible(false);
    props.removeProjectDraft(id, DeleteProject1);
  };

  const openPopup = () => {
    setVisible(true);
  };

  const cancelPopup = () => {
    setVisible(false);
  };

  return (
    <Modal
      className="edit_ob_modal"
      width={800}
      title={`${data.title}`}
      open={show}
      destroyOnClose={true}
      onCancel={handleModalCancel}
      footer={[
        <div
          key="buttonsProgetti"
          className="tw-flex tw-w-full tw-justify-between">
          <Space direction="horizontal">
            <Tooltip title={t('proggetiPage.passaProgetto')}>
              <Button
                icon={<FolderOpenOutlined />}
                href={`/progetti/id/${data.projectID}`}
              />
            </Tooltip>
            <Tooltip title={t('a3Page.paginaPresentazioneA3')}>
              <Button
                icon={<DesktopOutlined />}
                href={`/progetti/id/${data.projectID}?tab=15&presentationMode=true`}
              />
            </Tooltip>
            {projectLink && projectLink.link && (
              <Tooltip title={t('a3Page.repositoriProgetto')}>
                <a
                  href={projectLink.link}
                  target="_blank"
                  rel="noreferrer">
                  <Button icon={<ContainerOutlined />} />
                </a>
              </Tooltip>
            )}
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={openPopup}>
              {t('buttons.rimozioneProgetto')}
            </Button>
          </Space>

          <ModalFooterButtons
            disabled={!fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleModalCancel}
            formId={'editProgettoXmatrix'}
          />
        </div>,
      ]}>
      <Card>
        <Form
          id="editProgettoXmatrix"
          name="edit_progetti"
          layout="vertical"
          {...formItemLayout}
          onFinish={(values) => updateData(values, data.projectID)}
          onValuesChange={() => setFieldsChanged(true)}>
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row mod-buttons"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              {selectedXmatrixInfo && selectedXmatrixInfo.status !== xmatrixStatus.draft && (
                <Modal
                  open={visible}
                  title={t('proggetiPage.eliminaProgetto')}
                  icon={<ExclamationCircleOutlined />}
                  closable={false}
                  onCancel={cancelPopup}
                  footer={[
                    <Button
                      type="dashed"
                      key="back"
                      icon={<CloseOutlined />}
                      onClick={cancelPopup}>
                      {t('buttons.cancella')}
                    </Button>,
                    <Button
                      key="Only_Remove"
                      type="primary"
                      onClick={() => removeProject(data.projectID, false)}>
                      {t('buttons.onlyrimozioneProgetto')}
                    </Button>,
                    <Button
                      key="delete"
                      type="primary"
                      icon={<DeleteOutlined />}
                      onClick={() => removeProject(data.projectID, true)}>
                      {t('buttons.elimina')}
                    </Button>,
                  ]}
                />
              )}

              {selectedXmatrixInfo && selectedXmatrixInfo.status === xmatrixStatus.draft && (
                <Modal
                  open={visible}
                  title={t('proggetiPage.eliminaProgetto')}
                  icon={<ExclamationCircleOutlined />}
                  closable={false}
                  onCancel={cancelPopup}
                  footer={[
                    <Button
                      type="dashed"
                      key="back"
                      icon={<CloseOutlined />}
                      onClick={cancelPopup}>
                      {t('buttons.cancella')}
                    </Button>,
                    <Button
                      key="Only_Remove"
                      type="primary"
                      onClick={() => removeProjectDraft(data.projectID, false)}>
                      {t('buttons.onlyrimozioneProgetto')}
                    </Button>,
                    <Button
                      key="delete"
                      type="primary"
                      icon={<DeleteOutlined />}
                      onClick={() => removeProjectDraft(data.projectID, true)}>
                      {t('buttons.elimina')}
                    </Button>,
                  ]}
                />
              )}
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <Form.Item
                label={t('proggetiPage.nomeProgetto')}
                name="name"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={data.title}>
                <Input />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}>
              <Form.Item
                extra={t('proggetiPage.codiceLungeza')}
                label={t('proggetiPage.codiceProgetto')}
                name="projectCode"
                rules={[
                  { required: true, message: requiredFields.required },
                  { max: 10, message: `${t('proggetiPage.codiceLungeza')}` },
                ]}
                initialValue={data.projectCode}>
                <Input maxLength={10} />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}>
              <Form.Item
                label={t('general.stato')}
                name="status"
                hasFeedback
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={data.status}>
                <Select
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}
                  onChange={(value, option) => onStatusChange(value, option)}>
                  {statuses &&
                    statuses.map((item) => (
                      <Option
                        value={item.statusID}
                        key={item.statusID}>
                        {item.statusDescription}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}>
              <Form.Item
                label={t('proggetiPage.revisioneEsterna')}
                name="hasExternalReview"
                valuePropName="checked"
                initialValue={data.hasExternalReview}>
                <Switch />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}>
              <UserSelectDropDown
                disabled={false}
                allowClear={false}
                fieldLabel={t('general.teamLeader')}
                t={t}
                formName={'teamLeaderID'}
                required={isMandatory || isStatusActive ? true : false}
                initValue={data.teamLeaderID}
              />
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}>
              <UserSelectDropDown
                disabled={false}
                allowClear={false}
                fieldLabel={t('general.sponsor')}
                t={t}
                formName={'sponsorID'}
                required={isMandatory || isStatusActive ? true : false}
                initValue={data.sponsorID}
              />
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}>
              <Form.Item
                label={t('general.comitato')}
                name="committeeID"
                hasFeedback
                initialValue={data.committeeID}>
                <Select
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {commities &&
                    commities.map((item) => (
                      <Option
                        disabled={item.hasAccess === 1 ? false : true}
                        value={item.committeeID}
                        key={item.committeeID}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}>
              <Form.Item
                label={t('general.divisione')}
                name="divisionID"
                hasFeedback
                initialValue={data.divisionID}>
                <Select
                  disabled={isSecondLevelXmatrix ? true : false}
                  placeholder={t('general.seleziona')}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {divisionsList &&
                    divisionsList.map((item) => (
                      <Option
                        disabled={item.hasAccess === 1 ? false : true}
                        value={item.divisionID}
                        key={item.divisionID}>
                        {item.divisionName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <Form.Item
                label={t('general.nota')}
                name="note"
                initialValue={data.note}>
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default EditProjectModal;
