import React, { Component, useState } from 'react';
import './bowlingChart.scss';

import {
  FilterOutlined,
  FolderOpenOutlined,
  InfoCircleOutlined,
  MinusSquareOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Popover,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import MatrixService from '../../services/matrix/matrixService';
import DashboardService from '../../services/pages/dashboardService';
import DeliverableService from '../../services/pages/projectServices/deliverableServices';
import TableLayout from '../shared/tableLayout';
import { bowlingChartColumsValues, bowlingChartRowName } from '../shared/utils/constants';
import {
  capitalizeFirstLetter,
  checkBowlingChartCellColor,
  convertCamelCaseToNormal,
  numberWithCommas,
} from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import ProjectChildKpisDetailModal from './projectKpiChildDetailsModal';

const { Text } = Typography;
const { Option } = Select;

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = (
    <InputNumber
      min={0}
      decimalSeparator=","
      precision="2"
    />
  );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    setSearchText('');
  };

  const isEditing = (record) => record.key === editingKey;

  // const checkCellColor = (record, monthName, monthValue) => {
  //     let data = props.propsData;
  //     if (record.parentType === kpiPuntualeAcumulato.puntuale) {

  //         if (record.name === bowlingChartRowName.actual) {
  //             let targetRow = data.filter(function (item) { return item.name === bowlingChartRowName.target && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID })[0];

  //             if (record.type === kpiCalculationType.valoreMinimo) {
  //                 if (monthValue < targetRow.monthsList[monthName] || monthValue === targetRow.monthsList[monthName]) {
  //                     return greenColor
  //                 }
  //                 else if (monthValue > targetRow.monthsList[monthName]) {
  //                     return redColor
  //                 }
  //                 else {
  //                     return "#FFFFFF"
  //                 }
  //             }

  //             else {
  //                 if (monthValue > targetRow.monthsList[monthName] || monthValue === targetRow.monthsList[monthName]) {
  //                     return greenColor
  //                 }
  //                 else if (monthValue < targetRow.monthsList[monthName]) {
  //                     return redColor
  //                 }
  //                 else {
  //                     return "#FFFFFF"
  //                 }
  //             }

  //         }
  //         else {
  //             return;
  //         }
  //     }

  //     else if (record.parentType === kpiPuntualeAcumulato.acumulato) {

  //         if (record.name === bowlingChartRowName.actualYtd) {
  //             let targetYtdRow = data.filter(function (item) { return item.name === bowlingChartRowName.targetYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID })[0];

  //             if (record.type === kpiCalculationType.valoreMinimo) {

  //                 if (monthValue < targetYtdRow.monthsList[monthName] || monthValue === targetYtdRow.monthsList[monthName]) {
  //                     return greenColor
  //                 }
  //                 else if (monthValue > targetYtdRow.monthsList[monthName]) {
  //                     return redColor
  //                 }
  //                 else {
  //                     return "#FFFFFF"
  //                 }
  //             }
  //             else {
  //                 if (monthValue > targetYtdRow.monthsList[monthName] || monthValue === targetYtdRow.monthsList[monthName]) {
  //                     return greenColor
  //                 }
  //                 else if (monthValue < targetYtdRow.monthsList[monthName]) {
  //                     return redColor
  //                 }
  //                 else {
  //                     return "#FFFFFF"
  //                 }
  //             }
  //         }
  //         else {
  //             return;
  //         }

  //     }
  //     else return "#FFFFFF";
  // }

  const checkCellColor = (record, monthName, monthValue) => {
    let kpiData = props.propsData;
    let companyData = props.companyData;
    let companyKpiTolerance = companyData.kpiTolerance;
    let companyStartFromCurrentMonth = companyData.startFromCurrentMonth;

    let targetRow = kpiData.filter(function (item) {
      return (
        item.name === bowlingChartRowName.target && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID
      );
    })[0];
    let targetYtdRow = kpiData.filter(function (item) {
      return (
        item.name === bowlingChartRowName.targetYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID
      );
    })[0];
    let actualYtdRow = kpiData.filter(function (item) {
      return (
        item.name === bowlingChartRowName.actualYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID
      );
    })[0];

    let cellColor = checkBowlingChartCellColor(
      record,
      monthName,
      monthValue,
      kpiData,
      companyKpiTolerance,
      companyStartFromCurrentMonth,
      targetRow,
      targetYtdRow,
      actualYtdRow,
    );

    return cellColor;
  };

  const renderedColumns = () => {
    let columns = [
      {
        ...(props.isTotal ? '' : getColumnSearchProps('kpiDetailName')),
        dataIndex: 'kpiDetailName',
        title: `${props.t('general.nome')} KPI`,
        fixed: 'left',
        width: '7%',
        editable: false,
        ellipsis: {
          showTitle: false,
        },
        render: (text, record, index) => {
          let val = (
            <Space direction="vertical">
              <Tooltip title={text}>{text}</Tooltip>
              <Popover
                content={
                  <Space direction="vertical">
                    <Text style={{ fontSize: 14 }}>
                      <span className="header-text"> {props.t('kpiPage.cumulatoPuntuale')}: </span>
                      <u>
                        <b>{record.parentTypeDescription}</b>
                      </u>
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      <span className="header-text"> {props.t('kpiPage.tipoCalcolo')}: </span>
                      <u>
                        <b>{record.typeDescription}</b>
                      </u>
                    </Text>
                  </Space>
                }
                placement="right"
                trigger="click">
                <InfoCircleOutlined style={{ fontSize: 13 }} />
              </Popover>

              {record.isRollupKPI && (
                <Tag onClick={() => props.handleOpenModal(record)}>
                  <Text style={{ color: '#16a34a' }}>{props.t('kpiPage.gerarchicho')}</Text>
                  <FolderOpenOutlined />
                </Tag>
              )}
            </Space>
          );
          const obj = {
            children: val,
            props: {},
          };
          if (index % 4 === 0) {
            obj.props.rowSpan = 4;
          } else {
            obj.props.rowSpan = 0;
          }
          return obj;
        },
      },
      {
        dataIndex: 'name',
        title: '',
        editable: false,
        width: '6%',
        ellipsis: {
          showTitle: false,
        },
        render(text, record) {
          return {
            props: {
              style: { borderBottom: record.name === bowlingChartColumsValues.targetYTD ? '4px solid #d6d3d1' : '' },
            },
            children: (
              <Tooltip title={convertCamelCaseToNormal(text)}>
                <Text>{convertCamelCaseToNormal(text)}</Text>
              </Tooltip>
            ),
          };
        },
      },
    ];

    const tabelData = props.propsData;

    if (tabelData && tabelData.length > 0) {
      // let firstRow = tabelData[0];
      let firstRow = tabelData.find((element) => Object.keys(element.monthsList).length > 0);

      for (const item in firstRow.monthsList) {
        let currentMonth = moment().format('YYYY-MM');
        let monthName = moment(item).format('YYYY-MM');
        let month = moment(item).format('MMM YYYY');

        columns.push({
          align: 'right',
          //className: col === currentMonth ? "currentMonthBorderLineIndicator" : "",
          dataIndex: monthName,
          title: capitalizeFirstLetter(month),
          editable: false,
          width: '6%',
          render(text, record) {
            return {
              props: {
                style: {
                  borderBottom: record.name === bowlingChartColumsValues.targetYTD ? '4px solid #d6d3d1' : '',
                  backgroundColor:
                    currentMonth > monthName || currentMonth === monthName
                      ? checkCellColor(record, item, record.monthsList[item])?.cellColor
                      : '',
                },
              },
              children: (
                <Text
                  style={{
                    color:
                      currentMonth > monthName || currentMonth === monthName
                        ? checkCellColor(record, item, record.monthsList[item])?.textColor
                        : '',
                  }}>
                  {numberWithCommas(record.monthsList[item])}
                </Text>
              ),
            };
          },
        });
      }

      return columns;
    }

    return columns;
  };

  const mergedColumns = renderedColumns().map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form
      form={form}
      component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        size="small"
        dataSource={props.propsData}
        columns={mergedColumns}
        pagination={false}
        loading={props.loadingData}
        rowKey={(obj) => obj.key}
      />
    </Form>
  );
};

class BowlingChartList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingData: false,
      bowlingChartData: null,
      projectPicklist: null,
      selectedProjectId: null,
      clearFilters: false,
      selectedYear: moment().format('YYYY'),
      showModal: false,
      modalData: null,
      bowlingChartDataParent: [],
      activeXmatrixInfo: null,
      selectedFilterXmatrixId: null,
    };
  }

  componentDidMount() {
    this.retrieveComponentData();
    this.retrieveProjectFilterData();
  }

  retrieveComponentData = async () => {
    await this.xmatrixPicklist();
  };

  async retrieveProjectFilterData() {
    await DashboardService.getProjectFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ projectPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getBowlingChartData() {
    const { selectedYear, selectedProjectId, selectedFilterXmatrixId, activeXmatrixInfo } = this.state;
    let currentYear = moment().format('YYYY');
    let formatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;

    this.setState({ loadingData: true });
    await DeliverableService.getBowlingChartListData(selectedProjectId, formatedYear)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let data = resp.responseObject.value;

          data.map((item, index) => {
            item['key'] = index.toString();
          });

          let parentData = data.filter((item) => {
            return item.parentKPIProjectRelationshipID === null;
          });

          data.sort((a, b) => a.kpiDetailName.localeCompare(b.kpiDetailName));
          this.setState({ bowlingChartData: data });

          parentData.sort((a, b) => a.kpiDetailName.localeCompare(b.kpiDetailName));
          this.setState({ bowlingChartDataParent: parentData });

          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  getDefaultXmatrix = (xmatrixPicklist) => {
    let defaultXmatrixData = xmatrixPicklist
      ? xmatrixPicklist.find((x) => x.status === 1 && x.parentXMatrixID === null)
      : null;
    // this.setState({ activeXmatrixInfo: defaultXmatrixData })

    this.setState({ activeXmatrixInfo: defaultXmatrixData }, () => {
      this.getBowlingChartData();
    });
  };

  async xmatrixPicklist() {
    await MatrixService.getXmatrixPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.getDefaultXmatrix(resp.responseObject.value);
        } else {
        }
      })
      .catch((error) => {});
  }

  onProjectChange = (option) => {
    this.setState({ selectedProjectId: option });
  };

  onFilter = () => {
    this.setState({ clearFilters: false }, () => {
      this.getBowlingChartData();
    });
  };

  onClearFilter = () => {
    const { activeXmatrixInfo } = this.state;
    this.setState(
      {
        selectedYear: moment().format('YYYY'),
        selectedFilterXmatrixId: activeXmatrixInfo?.xMatrixID,
        selectedProjectId: null,
        clearFilters: true,
      },
      () => {
        this.getBowlingChartData();
      },
    );
  };

  onYearChange = (year) => {
    this.setState({ selectedYear: year });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  togleKpiDetailModal = (kpiData) => {
    this.setState({ showModal: true });
    this.setState({ modalData: kpiData });
  };

  applyXmatrixFilter = (selectedXmatrixId) => {
    this.setState({ selectedFilterXmatrixId: selectedXmatrixId }, () => {
      this.getBowlingChartData();
    });
  };

  render() {
    const {
      loadingData,
      loadingButton,
      bowlingChartData,
      selectedProjectId,
      projectPicklist,
      selectedYear,
      showModal,
      modalData,
      bowlingChartDataParent,
      activeXmatrixInfo,
    } = this.state;

    const { t, xmatrixRootList, loadingRootList, companyData } = this.props;

    return (
      <>
        {showModal && (
          <ProjectChildKpisDetailModal
            showModal={showModal}
            handleModalClose={this.handleModalClose}
            kpiModalData={modalData}
            bowlingChartData={bowlingChartData.filter((item) => {
              return item.parentKPIProjectRelationshipID === modalData.kpiProjectRelationshipID;
            })}
            loadingIcon={loadingButton}
            loadingData={loadingData}
            t={t}
          />
        )}

        <TableLayout className="tw-mt-4">
          <TableLayout.Actions>
            {/* <XmatrixFilterPanel
                                    activeXmatrixInfo={activeXmatrixInfo}
                                    loadingRootList={loadingRootList}
                                    xmatrixRootList={xmatrixRootList}
                                    t={t}
                                    filterByXmatrix={this.applyXmatrixFilter}
                                /> */}

            <DatePicker
              allowClear={false}
              placeholder={t('general.anno')}
              picker="year"
              value={moment(selectedYear, 'YYYY')}
              style={{ width: '100%' }}
              format="YYYY"
              onChange={(date) => this.onYearChange(date)}
            />

            <Select
              value={selectedProjectId}
              style={{ width: '100%', minWidth: '400px' }}
              allowClear
              showSearch
              optionFilterProp="name"
              placeholder={t('general.progetti')}
              id="filterByProject"
              onChange={(option) => this.onProjectChange(option)}
              filterOption={(input, option) => option?.name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}>
              {projectPicklist &&
                projectPicklist.map(
                  (item) => (
                    <Option
                      name={item.name}
                      className={item.isProjectRelatedXMatrix !== 1 ? 'projectOptionColor' : ''}
                      value={item.projectID}
                      key={item.projectID}>
                      <Tooltip
                        placement="left"
                        title={item.name}>
                        {item.name}
                      </Tooltip>
                    </Option>
                  ),
                  // <Option value={item.projectID} key={item.projectID}>{item.name}</Option>
                )}
            </Select>

            <Tooltip title={t('general.filter')}>
              <Button
                data-testid="filterBtn"
                className="tw-shrink-0"
                type="dashed"
                icon={<FilterOutlined />}
                onClick={() => this.onFilter()}
                disabled={!selectedYear && !selectedProjectId}
              />
            </Tooltip>
            <Tooltip title={t('general.clearFilters')}>
              <Button
                className="tw-shrink-0"
                type="dashed"
                icon={<MinusSquareOutlined />}
                onClick={() => this.onClearFilter()}
                disabled={selectedYear || selectedProjectId ? false : true}
              />
            </Tooltip>
          </TableLayout.Actions>

          <TableLayout.Content>
            <EditableTable
              t={t}
              companyData={companyData}
              propsData={bowlingChartDataParent}
              loadingIcon={loadingButton}
              loadingData={loadingData}
              handleOpenModal={this.togleKpiDetailModal}
            />
          </TableLayout.Content>
        </TableLayout>
      </>
    );
  }
}

// export default BowlingChartList

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
  companyData: state.companyData.companyData,
});

export default connect(mapStateToProps)(BowlingChartList);
