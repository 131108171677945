import { Form } from 'antd';
import { useEffect, useState } from 'react';
import CommonService from '../../../../services/commonService';

import { Select } from 'antd';
import { requiredFields } from '../../utils/notifyMessages';

const UserSelectDropDown = ({ disabled, allowClear, t, formName, required, initValue, fieldLabel, selectSize }) => {
  const [usersSelect, setUsersSelect] = useState(null);

  useEffect(() => {
    retrieveUsersList();
  }, []);

  const retrieveUsersList = async () => {
    await CommonService.getUsersPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          const usr = respData.map((item) => ({
            value: item.userID,
            label: item.fullName,
            key: item.userID,
            disabled: item.isDeleted,
          }));
          setUsersSelect(usr);
        } else {
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <Form.Item
        labelAlign="left"
        label={fieldLabel}
        name={formName}
        rules={required ? [{ required: true, message: requiredFields.required }] : []}
        initialValue={initValue}>
        <Select
          style={{ width: '100%' }}
          size={selectSize}
          placeholder={t('general.seleziona')}
          allowClear={allowClear}
          disabled={disabled}
          options={usersSelect}
          showSearch
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          loading={!usersSelect}
        />
      </Form.Item>
    </>
  );
};
export default UserSelectDropDown;
