import { Card, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetRootXMatrixDivisionListResponseDto } from 'src/connectors/backend';
import MatrixService from '../../services/matrix/matrixService';
import { addQueryParam, getQueryParam } from '../../utils/url-utils';
import TableLayout from '../shared/tableLayout';
import ObAnualBowlingChartList from './obAnualBowlingChart';
import BowlingChartList from './projectBowlingChart';

const BowlingChartIndex: React.FC = () => {
  const { t } = useTranslation();

  const [xmatrixRootList, setXmatrixRootList] = useState<GetRootXMatrixDivisionListResponseDto[] | null>(null);
  const [loadingRootList, setLoadingRootList] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('1');

  useEffect(() => {
    setActiveTab(getQueryParam('tab') || '1');
    getXmatrixRootList();
  }, []);

  const getXmatrixRootList = async () => {
    setLoadingRootList(true);
    try {
      const response = await MatrixService.getXmatrixDivisionRootList();
      const resp = response.data;

      if (resp?.success && resp?.responseObject?.value) {
        setXmatrixRootList(resp.responseObject.value);
      }
    } catch (error) {
      console.error('Error fetching Xmatrix Root List', error);
    } finally {
      setLoadingRootList(false);
    }
  };

  const tabsItems = [
    {
      label: <>{t('general.progetti')}</>,
      key: '1',
      children: (
        <BowlingChartList
          t={t}
          xmatrixRootList={xmatrixRootList}
          loadingRootList={loadingRootList}
        />
      ),
    },
    {
      label: <>{t('general.obiettiviAnno')}</>,
      key: '2',
      children: (
        <ObAnualBowlingChartList
          t={t}
          xmatrixRootList={xmatrixRootList}
          loadingRootList={loadingRootList}
        />
      ),
    },
  ];

  return (
    <Card>
      <TableLayout
        className="project-wrapper"
        title={t('bowlingChartPage.bowlingChart')}>
        <TableLayout.Content>
          <Tabs
            onChange={(key) => {
              addQueryParam('tab', key);
              setActiveTab(key);
            }}
            activeKey={activeTab}
            items={tabsItems}
            defaultActiveKey="1"
          />
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
};

export default BowlingChartIndex;
