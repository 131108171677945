import { Button, Dropdown, MenuProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/store';
import { LifebuoyIcon } from '../../../../assets/icons';

const HelpSection: FC = () => {
  const { t } = useTranslation();
  const companyData = useAppSelector((state) => state.companyData.companyData);
  const [supportEmailLink, setSupportEmailLink] = useState<string | null>(null);
  const [redirectLinkURL, setRedirectLinkURL] = useState<string | null>();

  useEffect(() => {
    if (companyData) {
      setRedirectLinkURL(companyData.redirectLink);
      setSupportEmailLink(
        `mailto:${companyData?.supportMail}?subject=Neym ["${companyData?.companyName}"] - ${t('headerMenu.subject')}`,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  const items: MenuProps['items'] = [
    {
      label: (
        <a
          href={redirectLinkURL || '#'}
          target="blank">
          {t('headerMenu.tutorial')}
        </a>
      ),
      key: 2,
    },
    { label: <a href={supportEmailLink || '#'}>{t('headerMenu.contact')}</a>, key: 1 },
  ];

  return (
    <div className="tw-items-center tw-flex navbar-button">
      <Dropdown menu={{ items }}>
        <Button
          type="text"
          style={{ color: 'white' }}
          onClick={(e) => e.preventDefault()}
          icon={<LifebuoyIcon />}></Button>
      </Dropdown>
    </div>
  );
};

export default HelpSection;
