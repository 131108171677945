import {
  CommentOutlined,
  EditOutlined,
  FilterOutlined,
  LeftCircleOutlined,
  MinusSquareOutlined,
  RightCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Avatar, Button, DatePicker, Divider, Input, Select, Space, Table, Tooltip, message } from 'antd';
import moment from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import DashboardService from '../../../services/pages/dashboardService';
import KpiService from '../../../services/pages/kpiService';
import { addQueryParam, clearQueryParams, getQueryParam } from '../../../utils/url-utils';
import TableLayout from '../../shared/tableLayout';
import { emptyGuid, kpiTypeRealtion } from '../../shared/utils/constants';
import { italianNumberFormat, kpiSemaphoreColor } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import EditKpiCommentModal from '../kpiDetailCommentModal';

const monthFormat = 'MMMM';
const KpiSemaphore = ({ kpi }) => {
  return (
    <div className="kpiSemaphore">
      <Avatar
        size={'small'}
        style={{ backgroundColor: kpiSemaphoreColor(kpi.semaforo) }}
      />
    </div>
  );
};

const labelRender = (props) => {
  const { label } = props;
  return (
    <Tooltip
      title={label}
      placement="top">
      <span className="tw-shrink tw-max-w-16 tw-truncate">{label?.replace('...', '')}</span>
    </Tooltip>
  );
};

class ProjectKpiSintesi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      searchedColumn: '',
      kpiList: null,
      loadingData: false,
      selectedXmatrixValue: this.props.activeXmatrixInfo?.xMatrixID,
      selectedXmatrixInfo: this.props.activeXmatrixInfo,
      selectedMonth: moment(),
      selectedProject: this.props.projectId && this.props.projectId !== '0' ? this.props.projectId : '',
      selectedAnnualGoal: [],
      selectedYear: moment().format('YYYY'),
      projectPicklist: [],
      projectPicklistLength: 0,
      enableDetailsClick: false,
      showCommentModal: false,
      loadingSaveComment: false,
      selectedRowData: null,
      nextObject: -1,
      previousObject: -1,
      disableNextButton: false,
      disablePreviousButton: true,
      loadingDisabled: false,
    };
  }

  componentDidMount() {
    this.retrieveProjectFilterData();

    this.setState({
      selectedYear: getQueryParam('selectedYear')
        ? moment(getQueryParam('selectedYear')).format('YYYY')
        : moment().format('YYYY'),
      selectedMonth: getQueryParam('selectedMonth') || moment(),
    });

    if (this.props.projectId === undefined) this.onProjectChange(getQueryParam('selectedProject'));

    setTimeout(() => {
      this.onFilter();
    }, 1000);
  }

  async retrieveProjectFilterData() {
    await DashboardService.getProjectFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;

          const sortedData = respData.sort(function (a, b) {
            return new Date(a.projectCode) - new Date(b.projectCode);
          });

          this.setState({ projectPicklist: sortedData });
          this.setState({ projectPicklistLength: respData?.length });
        } else {
        }
      })
      .catch((error) => {});
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',

    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  getDataBySelectedXmatrix = (xMatrixId) => {
    this.retrieveKpiList(xMatrixId);
  };

  async retrieveKpiList(xMatrixId) {
    const { selectedMonth, selectedProject, selectedXmatrixValue, selectedYear } = this.state;
    let currentYear = moment().format('YYYY');
    let formatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;

    let formatedMonth = moment(selectedMonth, 'YYYY/MM/DD').month() + 1;

    let xmatrix = xMatrixId ? xMatrixId : selectedXmatrixValue;

    // if (selectedProject && selectedProject?.length > 0) {
    //   this.setState({ enableDetailsClick: true })
    // }
    // else {
    //   this.setState({ enableDetailsClick: false })
    // }

    this.setState({ loadingData: true });
    this.setState({ loadingDisabled: true });
    await KpiService.getKpiProjectSintesi(formatedMonth, selectedProject, xmatrix, formatedYear)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;

          let parentData = respData.filter((item) => {
            return item.parentID === null;
          });

          this.setState({ kpiList: parentData });
          this.setState({ loadingData: false });
          this.setState({ loadingDisabled: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
          this.setState({ loadingDisabled: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
        this.setState({ loadingDisabled: false });
      });
  }

  setSelectedXmatrix = (xMatrixId, selectedXmatrix) => {
    let selectedXmatrixYear = this.getSelectedXmatrixYear(selectedXmatrix);

    this.setState({ selectedXmatrixValue: xMatrixId, selectedXmatrixInfo: selectedXmatrix }, () => {
      this.setState({ selectedMonth: moment(selectedXmatrixYear) });
    });
  };

  getSelectedXmatrixYear = (selectedXmatrix) => {
    const { xmatrixRootList } = this.props;
    let secondLevelXmatrixYear = null;

    if (selectedXmatrix.parentXMatrixID && selectedXmatrix.parentXMatrixID !== emptyGuid) {
      let parentXmatrix = xmatrixRootList.filter((obj) => obj.xmatrixID === selectedXmatrix.parentXMatrixID)[0];
      secondLevelXmatrixYear = parentXmatrix.xMatrixYear;
    } else {
      secondLevelXmatrixYear = selectedXmatrix.xMatrixYear;
    }

    return secondLevelXmatrixYear;
  };

  onMonthChange = (month) => {
    this.setState({ selectedMonth: month });
    addQueryParam('selectedMonth', month);
  };

  onProjectChange = (option) => {
    const { projectPicklist, projectPicklistLength } = this.state;

    this.setState({ selectedProject: option });
    addQueryParam('selectedProject', option);

    let selectedOptionIndex = projectPicklist.findIndex(function (item) {
      return item.projectID === option;
    });

    this.setState({ nextObject: selectedOptionIndex, previousObject: selectedOptionIndex - 1 });

    if (selectedOptionIndex > 0) {
      this.setState({ disablePreviousButton: false, disableNextButton: false });
    }

    if (selectedOptionIndex === 0) {
      this.setState({ disablePreviousButton: true, disableNextButton: false });
    }

    if (selectedOptionIndex === projectPicklistLength - 1) {
      this.setState({ disableNextButton: true });
    }
  };

  onFilter = () => {
    this.retrieveKpiList();
  };

  onClearFilter = () => {
    const { activeXmatrixInfo } = this.props;

    clearQueryParams();

    this.setState(
      {
        selectedMonth: moment(),
        selectedYear: moment().format('YYYY'),
        selectedXmatrixValue: activeXmatrixInfo?.xMatrixID,
        selectedProject: [],
        disablePreviousButton: true,
        nextObject: -1,
        previousObject: -1,
      },
      () => {
        this.retrieveKpiList();
      },
    );
  };

  disableMonths = (current, selectedXmatrixInfo) => {
    let selectedXmatrixYear = this.getSelectedXmatrixYear(selectedXmatrixInfo);

    let curr = moment(current).year();
    let xmatrixYear = moment(selectedXmatrixYear).year();
    return curr < xmatrixYear || curr > xmatrixYear;
  };

  onYearChange = (year) => {
    this.setState({ selectedYear: year });
    addQueryParam('selectedYear', year);
  };

  handleKpiCommentClose = () => {
    this.setState({ showCommentModal: false });
    this.setState({ loadingSaveComment: false });
  };

  toggleKpiComments = (rowData) => {
    this.setState({ selectedRowData: rowData }, () => {
      this.setState({ showCommentModal: true });
    });
  };

  handleSaveComments = (values) => {
    const { selectedProject } = this.state;

    values['ObjectTypeID'] = selectedProject;
    values['Type'] = kpiTypeRealtion.project;

    this.setState({ loadingSaveComment: true });
    KpiService.onCommentSave(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleKpiCommentClose();
          message.success(notifyMessages.updateSuccess);
          this.retrieveKpiList();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingSaveComment: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingSaveComment: false });
      });
  };

  onNextObject = () => {
    const { projectPicklist, selectedProject, nextObject, projectPicklistLength } = this.state;

    if (nextObject > -1) {
      let nextElement = projectPicklist[nextObject + 1];
      this.setState({ previousObject: nextObject });
      this.setState({ nextObject: nextObject + 1 });
      this.setState({ disablePreviousButton: false });
      if (nextObject + 1 === projectPicklistLength - 1) {
        this.setState({ disableNextButton: true });
      }
      this.setState({ selectedProject: nextElement.projectID }, () => {
        this.onFilter();
      });
    } else {
      let currentIndex = projectPicklist.findIndex(function (item) {
        return item.projectID === selectedProject;
      });

      if (currentIndex === -1) {
        let nextElement = projectPicklist[0];
        this.setState({ nextObject: 0 });
        this.setState({ selectedProject: nextElement.projectID }, () => {
          this.onFilter();
        });
      }

      if (currentIndex !== -1) {
        let nextElement = projectPicklist[currentIndex];
        this.setState({ nextObject: currentIndex });
        this.setState({ selectedProject: nextElement.projectID }, () => {
          this.onFilter();
        });
      }
    }
  };

  onPreviousObject = () => {
    const { projectPicklist, previousObject } = this.state;

    if (previousObject > -1) {
      let nextElement = projectPicklist[previousObject];
      this.setState({ previousObject: previousObject - 1 });
      this.setState({ nextObject: previousObject });
      this.setState({ disableNextButton: false });
      if (previousObject === 0) {
        this.setState({ disablePreviousButton: true });
      }
      this.setState({ selectedProject: nextElement.projectID }, () => {
        this.onFilter();
      });
    }
  };

  render() {
    const {
      kpiList,
      loadingData,
      selectedMonth,
      projectPicklist,
      selectedProject,
      selectedXmatrixInfo,
      selectedXmatrixValue,
      selectedYear,
      enableDetailsClick,
      selectedAnnualGoal,
      showCommentModal,
      loadingSaveComment,
      selectedRowData,
      disableNextButton,
      disablePreviousButton,
      loadingDisabled,
    } = this.state;
    const { t, xmatrixRootList, loadingRootList, activeXmatrixInfo, currentActiveLanguage } = this.props;

    let formatedYear = moment(selectedYear).format('YYYY');
    let formatedMonth = moment(selectedMonth, 'YYYY/MM/DD').month() + 1;

    const kpiColumns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'kpiName',
        ellipsis: {
          showTitle: true,
        },
        key: 'kpiName',
        align: 'left',
        width: '30%',
        ...this.getColumnSearchProps('kpiName'),
        render: (text, record) => {
          let kpiDetailsUrlPath = `/kpiDashboard/kdid/${record.kpiDetailID}/`;
          kpiDetailsUrlPath = kpiDetailsUrlPath.concat(selectedProject?.length > 0 ? `${selectedProject}/` : '0/');
          kpiDetailsUrlPath = kpiDetailsUrlPath.concat(
            selectedAnnualGoal?.length > 0 ? `${selectedAnnualGoal}/` : '0/',
          );
          kpiDetailsUrlPath = kpiDetailsUrlPath.concat(selectedXmatrixValue ? `${selectedXmatrixValue}/` : '0/');
          kpiDetailsUrlPath = kpiDetailsUrlPath.concat(selectedYear ? `${formatedYear}/` : '0/');
          kpiDetailsUrlPath = kpiDetailsUrlPath.concat(selectedMonth ? `${formatedMonth}/` : '0/');
          kpiDetailsUrlPath = kpiDetailsUrlPath.concat('p/');

          return (
            <Space>
              <Link
                style={{ cursor: 'pointer' }}
                to={{ pathname: kpiDetailsUrlPath }}>
                {text}
              </Link>
            </Space>
          );

          // if (enableDetailsClick) {
          //   return (<Space ><Link style={{ cursor: "pointer" }} to={{ pathname: kpiDetailsUrlPath }}>{text}</Link></Space>)
          // }
          // else {
          //   return (<Text>{text}</Text>)
          // }
        },
      },

      {
        title: `${t('kpiPage.unitaMisura')}`,
        dataIndex: 'measureUnitName',
        key: 'measureUnitName',
        align: 'right',
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: `${t('general.targetYtd')}`,
        dataIndex: 'targetYTD',
        key: 'targetYTD',
        align: 'right',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => <>{italianNumberFormat(record.targetYTD)}</>,
      },
      {
        title: `${t('general.actualYtd')}`,
        dataIndex: 'actualYTD',
        key: 'actualYTD',
        align: 'right',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => <>{italianNumberFormat(record.actualYTD)}</>,
      },
      {
        title: `${t('kpiDashboard.semaphore')}`,
        dataIndex: 'semaforo',
        key: 'semaforo',
        align: 'center',
        ellipsis: {
          showTitle: false,
        },
        render: (title, record) => <KpiSemaphore kpi={record} />,
      },
      {
        title: `${t('general.commenti')}`,
        dataIndex: 'notes',
        key: 'notes',
        align: 'center',
        ellipsis: {
          showTitle: false,
        },
        render: (_, record) => {
          return (
            <Space direction="horizontal">
              <EditOutlined onClick={() => this.toggleKpiComments(record)} />
              {record.notes && record.notes !== '<p></p>' && (
                <>
                  <Divider type="vartical" />
                  <CommentOutlined style={{ color: '#6abf6a', marginLeft: '10px' }} />
                </>
              )}
            </Space>
          );

          // if (enableDetailsClick) {
          //   return (
          //     <Space direction='horizontal'>
          //       <EditOutlined onClick={() => this.toggleKpiComments(record)} />
          //       {(record.notes && record.notes !== "<p></p>") && (
          //         <>
          //           <Divider type='vartical' />
          //           <CommentOutlined style={{ color: "#6abf6a", marginLeft: "10px" }} />
          //         </>
          //       )}
          //     </Space>
          //   )
          // }
          // else {
          //   return ("")
          // }
        },
      },
      {
        title: `${t('kpiPage.annualTarget')}`,
        dataIndex: 'finalTarget',
        key: 'finalTarget',
        align: 'right',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => <>{italianNumberFormat(record.finalTarget)}</>,
      },
    ];

    const projPick = projectPicklist.reduce((res, item) => {
      res.push({ value: item.projectID, label: `${'(' + item.projectCode + ') ' + item.name}` });
      return res;
    }, []);

    return (
      <>
        {showCommentModal && selectedRowData && (
          <EditKpiCommentModal
            showCommentModal={showCommentModal}
            handleCommentModalClose={this.handleKpiCommentClose}
            onCommentUpdate={this.handleSaveComments}
            loadingCommentSave={loadingSaveComment}
            t={t}
            rowData={selectedRowData}
          />
        )}

        <TableLayout className="project-card-wrapper">
          <TableLayout.Actions>
            <DatePicker
              allowClear={false}
              placeholder={t('general.anno')}
              picker="year"
              style={{ width: '100%' }}
              value={moment(selectedYear, 'YYYY')}
              format="YYYY"
              onChange={(date) => this.onYearChange(date)}
            />
            <DatePicker
              style={{ width: '100%' }}
              allowClear={false}
              value={moment(selectedMonth)}
              format={monthFormat}
              placeholder={t('general.mese')}
              picker="month"
              onChange={(date) => this.onMonthChange(date)}
              disabledDate={(current) => this.disableMonths(current, selectedXmatrixInfo)}
            />
            <Select
              value={selectedProject === '' ? null : selectedProject}
              placeholder={t('general.progetti')}
              onChange={(option) => this.onProjectChange(option)}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              showSearch
              labelRender={labelRender}
              className="tw-shrink-0 tw-w-[500px]"
              options={projPick}
              optionRender={(option) => (
                <Space>
                  <Tooltip
                    title={option.label}
                    placement="top">
                    <span className="tw-shrink tw-max-w-16 tw-truncate">{option.label}</span>
                  </Tooltip>
                </Space>
              )}
              // dropdownMatchSelectWidth={true}
            />

            <Tooltip title={t('general.filter')}>
              <Button
                className="tw-shrink-0"
                disabled={loadingDisabled}
                icon={<FilterOutlined />}
                onClick={() => this.onFilter()}
              />
            </Tooltip>
            <Tooltip title={t('general.clearFilters')}>
              <Button
                disabled={loadingDisabled}
                className="tw-shrink-0"
                icon={<MinusSquareOutlined />}
                onClick={() => this.onClearFilter()}
              />
            </Tooltip>
            <div className="tw-ml-8 tw-flex tw-gap-2">
              <Tooltip title={t('general.previous')}>
                <Button
                  disabled={disablePreviousButton || loadingDisabled}
                  icon={<LeftCircleOutlined />}
                  className="tw-shrink-0"
                  onClick={() => this.onPreviousObject()}
                />
              </Tooltip>
              <Tooltip title={t('general.next')}>
                <Button
                  className="tw-shrink-0"
                  disabled={disableNextButton || loadingDisabled}
                  icon={<RightCircleOutlined />}
                  onClick={() => this.onNextObject()}
                />
              </Tooltip>
            </div>
          </TableLayout.Actions>
          {/* <XmatrixFilterPanel
            activeXmatrixInfo={activeXmatrixInfo}
            loadingRootList={loadingRootList}
            xmatrixRootList={xmatrixRootList}
            t={t}
            onXmatrixSelect={this.setSelectedXmatrix}
          /> */}

          {/* <XMatrixFilterPanel
              activeXmatrixInfo={activeXmatrixInfo}
              onXmatrixSelect={this.getDataBySelectedXmatrix}
            /> */}

          {/* <Text>{t('general.xmatrix')}: <b>{selectedXmatrixInfo ? selectedXmatrixInfo.hkCode : ""}</b></Text> */}

          <TableLayout.Content>
            <Table
              className="kpiSintesiTable"
              size="small"
              loading={loadingData}
              columns={kpiColumns}
              dataSource={kpiList}
              rowKey={(obj) => obj.kpiDetailID}
              pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
            />
          </TableLayout.Content>
        </TableLayout>
      </>
    );
  }
}

export default ProjectKpiSintesi;
