import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetProjectResponseDto } from 'src/connectors/backend';
import ProgettiService from 'src/services/pages/projectServices/progettiService';

interface ProjectState {
  currentProject: GetProjectResponseDto | null;
  loading: boolean;
  error: string | null;
}

const initialState: ProjectState = {
  currentProject: null,
  loading: false,
  error: null,
};

export const getProjectById = createAsyncThunk<GetProjectResponseDto, { projectId: string }, { rejectValue: string }>(
  'project/getProjectById',
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const response = await ProgettiService.getProgettiByiD(projectId);
      const { success, responseObject } = response.data;

      if (!success || !responseObject?.value) {
        return rejectWithValue('Failed to fetch project data or data is undefined');
      }

      return responseObject.value;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  },
);

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProject: (state, action) => {
      state.currentProject = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProjectById.fulfilled, (state, { payload }) => {
        state.currentProject = payload;
        state.loading = false;
      })
      .addCase(getProjectById.rejected, (state, { payload }) => {
        state.error = payload as string;
        state.loading = false;
      });
  },
});

export const { setProject } = projectSlice.actions;
export default projectSlice.reducer;
