import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface CustomPageHeaderProps {
  title: string;
  subtitle?: string;
  actions?: React.ReactNode;
}

const CustomPageHeader: React.FC<CustomPageHeaderProps> = ({ title, subtitle, actions }) => {
  const history = useHistory();

  return (
    <div
      style={{
        padding: '16px',
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #d9d9d9',
      }}>
      <Button
        onClick={() => history.goBack()}
        className="tw-mr-4"></Button>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <h1 style={{ margin: '0 0 8px 0' }}>{title}</h1>
        {subtitle && <small style={{ color: '#666' }}>{subtitle}</small>}
      </div>
      {actions && <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>{actions}</div>}
    </div>
  );
};

export default CustomPageHeader;
