import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetRootXMatrixDivisionListResponseDto, GetXMatrixInfoResponseDto } from 'src/connectors/backend';
import MatrixService from '../../../../services/matrix/matrixService';

interface XmatrixDropDownFilterProps {
  preSelectedXmatrixId?: string;
  activeXmatrixInfo: GetXMatrixInfoResponseDto | null;
  onXmatrixSelect: (selectedXmatrix: { label: string; value: string }) => void;
}

const XmatrixDropDownFilter: React.FC<XmatrixDropDownFilterProps> = ({
  preSelectedXmatrixId,
  activeXmatrixInfo,
  onXmatrixSelect,
}) => {
  const { t } = useTranslation();

  const [xMatrixList, setXMatrixList] = useState<GetRootXMatrixDivisionListResponseDto[]>([]);
  const [options, setOptions] = useState<MenuProps['items']>([]);
  const [loadingRootList, setLoadingRootList] = useState(false);
  const [selectedXmatrixInfo, setSelectedXmatrixInfo] = useState<{ label: string; value: string } | null>(null);

  useEffect(() => {
    void getXmatrixRootList();
    if (preSelectedXmatrixId) {
      handleDropdownItemClick({ key: preSelectedXmatrixId });
    }
  }, [activeXmatrixInfo]);

  const getXmatrixRootList = async () => {
    setLoadingRootList(true);

    try {
      const response = await MatrixService.getXmatrixDivisionRootList();
      const data = response.data;

      if (data?.success && data.responseObject?.value) {
        const respData = data.responseObject.value;
        setXMatrixList(respData);

        if (respData?.length > 0) {
          setOptions(buildXmatrixOptions(respData));
        }
      }
    } catch (error) {
      console.error('Error fetching xmatrix root list:', error);
    } finally {
      setLoadingRootList(false);
    }
  };

  const buildXmatrixOptions = (respData: GetRootXMatrixDivisionListResponseDto[]): MenuProps['items'] => {
    return respData?.map((item) => {
      const parentOption = {
        key: item.xmatrixID,
        label: item.hkCode,
        className: item.xmatrixID === activeXmatrixInfo?.xMatrixID ? 'active-xmatrix-dropdown' : '',
      };

      if (item.childList && item.childList?.length > 0) {
        const childOptions = item.childList.map((child) => ({
          key: child.xmatrixID,
          label: child.hkCode,
        }));

        return {
          ...parentOption,
          children: [{ key: item.xmatrixID, label: item.hkCode }, ...childOptions],
        };
      }

      return parentOption;
    });
  };

  const recursiveSearchXmatrixArray = (arr: any[], targetId: string): any | null => {
    for (const item of arr) {
      if (item.xmatrixID === targetId) {
        return item;
      }
      if (item.childList?.length > 0) {
        const result = recursiveSearchXmatrixArray(item.childList, targetId);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const handleDropdownItemClick = (item: { key: string }) => {
    const xmatrix = recursiveSearchXmatrixArray(xMatrixList, item.key);

    if (xmatrix) {
      const selectedXmatrix = { label: xmatrix.hkCode, value: xmatrix.xmatrixID };
      setSelectedXmatrixInfo(selectedXmatrix);
      onXmatrixSelect(selectedXmatrix);
    }
  };

  return (
    <>
      {xMatrixList.length > 0 && activeXmatrixInfo && (
        <Dropdown.Button
          trigger={['click']}
          icon={<DownOutlined />}
          loading={loadingRootList}
          menu={{
            onClick: handleDropdownItemClick,
            items: options,
          }}>
          <Space direction="horizontal">
            {t('general.xmatrix')}:{selectedXmatrixInfo ? selectedXmatrixInfo.label : activeXmatrixInfo.hkCode}
          </Space>
        </Dropdown.Button>
      )}
    </>
  );
};

export default XmatrixDropDownFilter;
