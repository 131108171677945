import { BrowserHistory } from '@remix-run/router';
import { ConfigProvider, Spin } from 'antd';
import en_US from 'antd/locale/en_US';
import itIT from 'antd/locale/it_IT';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getActiveXmatrix } from 'src/redux/slices/activeXMatrixSlice';
import { retrieveCompanyData } from 'src/redux/slices/companySlice';
import { retrieveFeedNotificationList } from 'src/redux/slices/feedNotificationsSlice';
import { retrieveNotificationList } from 'src/redux/slices/notificationsSlice';
import { retrieveUserData } from 'src/redux/slices/userSlice';
import { retrieveSelectedXmatrix } from 'src/redux/slices/xMatrixSlice';
import { useAppSelector } from 'src/redux/store';
import RoutersList from './config/router-config';

const createBrowserHistory = require('history').createBrowserHistory;
export const history: BrowserHistory = createBrowserHistory({ forceRefresh: true });

const App = () => {
  const userData = useAppSelector((state) => state.userData.userData);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  useEffect(() => {
    void dispatch(retrieveUserData());
    void dispatch(retrieveCompanyData());
    void dispatch(retrieveSelectedXmatrix());
    void dispatch(retrieveNotificationList());
    void dispatch(retrieveFeedNotificationList());
    void dispatch(getActiveXmatrix());
  }, []);

  if (!userData)
    return (
      <div className="tw-bg-white tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-center">
        <Spin size="large" />
      </div>
    );

  return (
    <ConfigProvider
      componentSize="large"
      locale={localStorage.getItem('i18nextLng') === 'it' ? itIT : en_US}
      theme={{
        components: {
          Layout: {
            bodyBg: 'white',
            headerHeight: '60px',
            headerBg:
              'linear-gradient(90deg, rgba(0, 102, 255, 0.00) 0%, rgba(0, 102, 255, 0.50) 100%), var(--Brand-Brand-Dark, #003293);',
          },
          Input: {
            controlOutlineWidth: 3,
            controlOutline: 'rgba(249, 115, 22, 0.30)',
            activeBorderColor: '#F97316',
            hoverBorderColor: 'black',
          },
          Select: {
            controlOutlineWidth: 3,
            controlOutline: 'rgba(249, 115, 22, 0.30)',
          },
          Dropdown: {
            controlOutlineWidth: 3,
            controlOutline: 'rgba(249, 115, 22, 0.30)',
          },
          Modal: {
            // titleFontSize: '20px',
            titleColor: 'black',
          },
          Menu: {
            itemSelectedBg: '#E0E7FF',
          },
        },
        token: {
          colorPrimary: '#0066FF',
          // borderRadius: '6px', ! this broke the tooltip arrow

          colorBgContainer: 'white',
        },
      }}>
      <RoutersList />;
    </ConfigProvider>
  );
};
export default App;
