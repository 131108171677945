import { trackPromise } from 'react-promise-tracker';
import {
  authenticatedApi,
  CommonApi,
  ProjectApi,
  ProjectDashboardApi,
  QueryProjectFilters,
  UserApi,
  XMatrixApi,
} from 'src/config/connectors';
import { statusPickListCodes } from '../../components/shared/utils/constants';
import { acquireToken } from '../../config/auth/clientAuthProvider';
import axiosInstance from '../../config/axiosConfig/interceptors';
import i18n from '../../i18n';

const DashboardService = {
  getDashboardData: async function (
    filters: { [s: string]: unknown } | ArrayLike<unknown>,
    XmatrixID: any,
    selectNotRelatedProjects: any,
  ) {
    const languageCode = i18n.language;
    const { accessToken } = (await acquireToken()) as { accessToken: string };
    const queryString = Object.entries({ ...filters, XmatrixID, selectNotRelatedProjects, languageCode })
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const optionsVal = {
      method: 'GET',
      url: `api/ProjectDashboard/GetProjectDashboards?${queryString}`,
      data: filters,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return trackPromise(axiosInstance(optionsVal));
  },

  getTeamLaderFilterData: async function () {
    const api = await authenticatedApi(ProjectDashboardApi);

    return trackPromise(api.projectDashboardGetTeamLeaderPicklist());
  },

  getStatusFilterData: async function () {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetStatusPicklist(statusPickListCodes.statusProjects, currentLanguageCode));
  },

  getDivisionFilterData: async function () {
    const api = await authenticatedApi(ProjectDashboardApi);

    return trackPromise(api.projectDashboardGetDivisionPicklist());
  },

  getCommittiesFilterData: async function () {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteePicklist());
  },

  getSemaphoreFilterData: async function () {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(ProjectDashboardApi);

    return trackPromise(api.projectDashboardGetSemaphorePicklist(currentLanguageCode));
  },

  getParentProjectFilterData: async function (xmatrixId: string) {
    const api = await authenticatedApi(ProjectDashboardApi);

    return trackPromise(api.projectDashboardGetParentProjectPicklist(xmatrixId));
  },

  getUserRole: async function () {
    const api = await authenticatedApi(UserApi);

    return trackPromise(api.userGetRolePicklist());
  },

  getProjectFilterData: async function () {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectPicklist());
  },

  getSponsorFilterData: async function () {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetSponsorPicklist());
  },

  getProjectCodeData: async function () {
    const api = await authenticatedApi(ProjectDashboardApi);

    return trackPromise(api.projectDashboardGetProjectCodes());
  },

  getDashboardProjects: async function (data: QueryProjectFilters) {
    const api = await authenticatedApi(ProjectDashboardApi);

    return trackPromise(api.projectDashboardQueryProjects(data));
  },
};

export default DashboardService;
