import { LogoutOutlined } from '@ant-design/icons';
import { Table, Tabs, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActivityService from '../../../services/pages/activitiesServices';
import PersonalDashboardService from '../../../services/pages/personalDashboardService';
import { formatOfDate } from '../../shared/utils/constants';
import { perStatusActivityChartOptions } from '../chartsConfig';
import PerStatusActvityChart from './perStatusChart';
import PerTimeActivityChart from './perTimeChart';

interface PersonalDashboardActivityProps {
  activeTabChange: (key: string) => void;
  inDashboard?: boolean;
}

const PersonalDashboardActivity: React.FC<PersonalDashboardActivityProps> = ({ activeTabChange, inDashboard }) => {
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingActivitiesList, setLoadingActivitiesList] = useState<boolean>(true);
  const [activitiesList, setActivitiesList] = useState<any[]>([]);
  const [perStatusChart, setPerStatusChart] = useState<any>(perStatusActivityChartOptions);
  const { t } = useTranslation();

  useEffect(() => {
    getPerStatusChartData();
    retrieveActivitiesList();
  }, []);

  const retrieveActivitiesList = async () => {
    setLoadingActivitiesList(true);
    try {
      const response = await ActivityService.getMyActivities();
      const resp = response.data;
      if (resp.success) {
        setActivitiesList(resp.responseObject?.value || []);
      }
    } catch (error) {
      console.error('Error retrieving activities list:', error);
    } finally {
      setLoadingActivitiesList(false);
    }
  };

  const getPerStatusChartData = async () => {
    setLoadingData(true);
    try {
      const response = await PersonalDashboardService.getActivityPerStatusChartData();
      const resp = response.data;
      if (resp.success) {
        const seriesObj = {
          name: t('bachechaPersonalePage.attivita'),
          data:
            resp.responseObject?.value?.map((item: any) => ({
              name: item.statusDescription,
              y: item.activityNumber,
            })) || [],
        };
        setPerStatusChart((prevChart: any) => ({
          ...prevChart,
          series: [seriesObj],
        }));
      }
    } catch (error) {
      console.error('Error retrieving activity status chart data:', error);
    } finally {
      setLoadingData(false);
    }
  };

  const onLinkRedirect = () => {
    activeTabChange('2');
  };

  const activitiesColumns = [
    {
      title: t('general.nome'),
      dataIndex: 'subject',
      sorter: (a: any, b: any) => a.subject.localeCompare(b.subject),
      ellipsis: {
        showTitle: true,
      },
      showSorterTooltip: true,
    },
    {
      title: t('general.stato'),
      dataIndex: 'statusDescription',
      render: (text: string) => <Tag>{text}</Tag>,
    },
    {
      title: t('general.end'),
      dataIndex: 'endDate',
      width: '100px',
      render: (text: string) => (text ? moment(text).format(formatOfDate) : ''),
    },
  ];

  return (
    <>
      <Tabs
        className="tw-px-2"
        defaultActiveKey="1"
        tabBarExtraContent={
          <LogoutOutlined
            className="hrefIcon"
            onClick={() => onLinkRedirect()}
          />
        }
        items={[
          {
            label: t('bachechaPersonalePage.perStatus'),
            key: '1',
            children: <PerStatusActvityChart inDashboard={inDashboard} />,
          },
          {
            label: t('bachechaPersonalePage.inTempoRitardo'),
            key: '2',
            children: <PerTimeActivityChart inDashboard={inDashboard} />,
          },
        ]}
      />
      <Table
        className="table-full-height bottom-paginator"
        columns={activitiesColumns}
        dataSource={activitiesList}
        rowKey={(_, i) => i!.toString()}
        loading={loadingActivitiesList}
        size="small"
        pagination={{ defaultPageSize: 5, pageSizeOptions: ['5', '10', '20', '50'] }}
      />
    </>
  );
};

export default PersonalDashboardActivity;
