import { EditOutlined } from '@ant-design/icons';
import { Button, message, Table, Tooltip } from 'antd';
import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import FixedSelectedAsset from 'src/components/xMatrix/FixedSelectedAsset';
import ObLongService from '../../../../services/matrix/obLongService';
import { statusClass } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';
import EditLongObjectiveModal from './editLongObjectiveModal';

interface BigObjectivesProps {
  longTermGoals: any[];
  selectedXmatrix: any;
  selectedRow: (record: any, rowIndex: number) => void;
  onRowClick: (id: string) => void;
  reloadMatrix: () => void;
  selection: 'primary' | 'secondary';
  selectedIds: string[];
}

const BigObjectives: FC<BigObjectivesProps> = ({
  longTermGoals,
  selectedXmatrix,
  selectedRow,
  onRowClick,
  reloadMatrix,
  selection,
  selectedIds,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const rowRefs = useRef<any[]>([]);

  useEffect(() => {
    if (selection !== 'primary') {
      setSelectedRecord(null);
    }
  }, [selection]);

  // Toggle the modal open/close and set modal data
  const toggleModal = (rowData?: any) => {
    setOpenModal((prev) => !prev);
    setModalData(rowData || {});
  };

  // Close modal handler
  const handleModalCancel = () => {
    setOpenModal(false);
    setLoadingButton(false);
  };

  // Update data handler
  const updateData = (values: any, id: any) => {
    setLoadingButton(true);
    values['longTermGoalID'] = id;
    ObLongService.updateObiettivoLungoxMartix(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          handleModalCancel();
          reloadMatrix();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch(() => {
        message.error(notifyMessages.updateFailed);
      });
  };

  // Row select handler
  const onRowSelect = (record: any, index: number) => {
    onRowClick(record.longTermGoalID);
    setSelectedRecord(record);
    setSelectedIndex(index);
  };

  // Define table columns
  const columns = [
    {
      render: (text: any, record: any, index: number) => {
        return {
          children: (
            <div
              ref={(el) => (rowRefs.current[index] = el)}
              onClick={() => onRowSelect(record, index)}
              className={`tw-flex tw-px-1 matrix-row tw-h-[31px] matrix-row tw-items-center tw-justify-between ${classNames(
                {
                  'tw-bg-primary selected-row': selection === 'primary' && selectedIds?.includes(record.longTermGoalID),
                  'tw-bg-primary/30 selected-row':
                    selection === 'secondary' && selectedIds?.includes(record.longTermGoalID),
                },
              )}`}>
              <div className="tw-flex tw-items-center tw-truncate">
                <Tooltip title={record.statusDescription}>
                  <div
                    className={`xMatrix-square-label ${statusClass(record.status)}`}
                    key={index}>
                    {record.orderingValue}
                  </div>
                </Tooltip>

                <Tooltip title={record.title}>
                  <p className="tw-text-sm tw-w-full tw-truncate">{record.title}</p>
                </Tooltip>
              </div>
              <Button
                type="text"
                className="tw-shrink-0"
                icon={<EditOutlined />}
                onClick={() => toggleModal(record)}></Button>
            </div>
          ),
        };
      },
    },
  ];

  return (
    <>
      <EditLongObjectiveModal
        show={openModal}
        // toggle={toggleModal}
        handleModalCancel={handleModalCancel}
        data={modalData}
        handleSave={updateData}
        loadingButton={loadingButton}
        selectedXmatrix={selectedXmatrix}
      />

      {selectedRecord && (
        <FixedSelectedAsset
          side="bottom"
          listOfRefs={rowRefs}
          selectedIndex={selectedIndex}>
          <div className={`xMatrix-square-label ${statusClass(selectedRecord.status)}`}>
            {selectedRecord.orderingValue}
          </div>
          <span>{selectedRecord.title}</span>
        </FixedSelectedAsset>
      )}

      <Table
        className="[&_table]:tw-h-full"
        dataSource={longTermGoals}
        pagination={false}
        bordered
        size="small"
        showHeader={false}
        columns={columns}
        rowKey={(obj: any) => 'xz' + obj.longTermGoalID}></Table>
    </>
  );
};

export default BigObjectives;
