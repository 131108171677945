import { trackPromise } from 'react-promise-tracker';
import {
  authenticatedApi,
  InsertProjectCostDetailRequestDto,
  ProjectApi,
  UpdatePlaningProjectRequest,
  UpdateProjectCostDetailRequestDto,
  UpdateProjectCostRequest,
  UpdateProjectTotalCostRequest,
  UpdateUserPlanning,
  UpdateUserTimePlanning,
  WorkloadContollerApi,
} from 'src/config/connectors';

const ResourceWorkloadService = {
  // get project resource workload
  getProjectWorkloadData: async function (projectId: string) {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerGetTeamPlanningProject(projectId));
  },

  // get project resource workload
  getTotalProjectWorkloadData: async function (projectId: string) {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerGetTotalTeamPlanningProject(projectId));
  },

  updateProjectResourceWorkloadData: async function (payload: UpdatePlaningProjectRequest) {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerUpdateTeamPlanningProject(payload));
  },

  // get  resource workload
  getResourceWorkloadData: async function (selectedYear: string) {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerGetResourceWorkload(selectedYear));
  },

  // get  resource workload
  getTotalResourceWorkloadData: async function (selectedYear: string) {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerGetTotalResourceWorkload(selectedYear));
  },

  // get user resource workload
  getUserWorkloadData: async function (userID: string, selectedYear: string) {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerGetUserPlanningProject(userID, selectedYear));
  },

  updateUserPlanning: async function (payload: UpdateUserPlanning) {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerUpdateUserPlanningProject(payload));
  },

  getUserTimePlanning: async function (selectedYear: string, userId: string, XMatrixID: string) {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerGetUserTimePlanning(selectedYear, userId, XMatrixID));
  },

  getProjectTimePlanning: async function (
    selectedYear: string,
    userId: string,
    porjectID: string,
    getProjectTimePlanning: string,
  ) {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(
      api.workloadContollerGetProjectTimePlanning(selectedYear, userId, porjectID, getProjectTimePlanning),
    );
  },

  updateTimePlanning: async function (payload: UpdateUserTimePlanning) {
    const api = await authenticatedApi(WorkloadContollerApi);

    return trackPromise(api.workloadContollerUpdateUserTimePlanning(payload));
  },

  getProjectFilterData: async function (userId: string, xmatrixID: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetTeamLeaderProject(userId, xmatrixID));
  },

  getProjectCostData: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectCostChart(projectId));
  },

  getTotalProjectCost: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetTotalCostProjectChart(projectId));
  },

  getTeamProjectCost: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetTotalTeamCostProjectChart(projectId));
  },

  getProjectCostDetails: async function (projectCostDetailID: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectCostDetail(projectCostDetailID));
  },

  insertProjectCost: async function (payload: InsertProjectCostDetailRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectInsertProjectCostDetail(payload));
  },

  updateProjectCost: async function (payload: UpdateProjectCostRequest) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateProjectCost(payload));
  },

  updateProjectCostDetails: async function (payload: UpdateProjectCostDetailRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateProjectCostDetail(payload));
  },

  updateProjectTotalCost: async function (payload: UpdateProjectTotalCostRequest) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateProjectTotalCost(payload));
  },

  removeProjectCost: async function (id: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectDeleteProjectCost(id));
  },
};

export default ResourceWorkloadService;
