import { Form } from 'antd';
import { Component } from 'react';

import { Comment } from '@ant-design/compatible';
import {
  CommentOutlined,
  DeleteOutlined,
  ExclamationOutlined,
  LikeFilled,
  LikeOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SelectOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Collapse, Empty, Input, message, Popconfirm, Space, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import Loader2 from '../components/loader2/loader2';
import './feed.scss';

import { animateScroll } from 'react-scroll';
import CommonService from '../../../services/commonService';
import AddFeedItem from './newFeedItem';

import { useTranslation, withTranslation } from 'react-i18next';
import FeedService from '../../../services/pages/projectServices/feedServices';
import { updateFeedNotificationBell } from '../../shared/utils/functions';
import TableLayout from '../tableLayout';
import { feedItemsTypes, objectCodes } from '../utils/constants';
import { handleDateConvert, handleTimeConvert } from '../utils/functions';
import { notifyMessages } from '../utils/notifyMessages';
import EditFeedItem from './editFeedItem';

const { Panel } = Collapse;
const { Text, Paragraph } = Typography;
const { TextArea, Search } = Input;

export const CustomHeader = (props) => {
  const { acivityData, onLikeFeed, onClick, hasUserAccess, toggleEditModal, removeFeed } = props;
  const { t } = useTranslation();

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="table-avatar">
          <Avatar
            className="tw-bg-primary"
            size={'small'}>
            {acivityData.initials}
          </Avatar>
          <p>{acivityData.fullName}</p>
        </div>

        <div className="tw-flex tw-gap-2 tw-justify-end tw-items-center">
          {!hasUserAccess && (
            <>
              {acivityData.priority === 1 && <ExclamationOutlined style={{ color: '#EC1919' }} />}
              {acivityData.isLikedByMe === 0 && (
                <Button
                  onClick={onLikeFeed}
                  icon={<LikeOutlined style={{ color: '#808080' }} />}
                />
              )}
              {acivityData.isLikedByMe === 1 && <Button icon={<LikeFilled style={{ color: '#4D86C3' }} />} />}

              {acivityData.isGenerated === false && (
                <Popconfirm
                  placement="topRight"
                  title={t('feed.eliminareFeed')}
                  onConfirm={removeFeed}
                  okText={t('general.si')}
                  cancelText={t('general.no')}>
                  <Button icon={<DeleteOutlined style={{ color: '#808080' }} />} />
                </Popconfirm>
              )}

              <Button
                onClick={onClick}
                icon={<CommentOutlined />}>
                {t('feed.comment')}
              </Button>

              {/* <div>
                {acivityData.activityType === feedItemsTypes.notifiche && (
                  <FeedNotificationBell activityId={acivityData.activityID} />
                )}
                {acivityData.activityType === feedItemsTypes.comment && <CommentOutlined />}
                {acivityData.activityType === feedItemsTypes.file && <FileTextOutlined />}
                {acivityData.activityType === feedItemsTypes.meeting && <TeamOutlined />}
              </div> */}
            </>
          )}
        </div>
      </div>

      <Space
        direction="horizontal"
        className="content">
        <Typography.Title level={4}>
          {acivityData.subject}{' '}
          {acivityData.activityType === feedItemsTypes.meeting && (
            <Tooltip
              title={`${t('feed.inizia')}: ${moment(acivityData.scheduledStart).format('DD/MM/YYYY')} | ${moment(acivityData.startTime, 'HH:mm:ss').format('hh:mm A')}
                            - ${t('feed.fine')}: ${moment(acivityData.scheduledEnd).format('DD/MM/YYYY')} | ${moment(acivityData.endTime, 'HH:mm:ss').format('hh:mm A')}`}>
              <QuestionCircleOutlined className="tw-ml-2" />
            </Tooltip>
          )}
        </Typography.Title>
      </Space>
      <Space direction="horizontal">
        {acivityData.isGenerated === true && <p>{acivityData.description}</p>}

        {acivityData.isGenerated === false && (
          <Text
            underline
            className="description"
            onClick={toggleEditModal}>
            {t('general.detagli')}
          </Text>
        )}

        {acivityData.activityType === feedItemsTypes.file && (
          <a
            href={acivityData.documentURL}
            target="_blank"
            rel="noreferrer">
            <SelectOutlined style={{ color: '#808080' }} />
          </a>
        )}
      </Space>
      <Text type="secondary">
        {acivityData.createdOn ? moment(acivityData.createdOn).format('DD/MM/YYYY HH:mm') : ''}
      </Text>
    </div>
  );
};

export const CustomPanel = (props) => {
  const { children, header, onClick, onLikeFeed, acivityData, hasUserAccess, toggleEditModal, removeFeed, t, ...rest } =
    props;
  const customHeader = (
    <CustomHeader
      t={t}
      onClick={onClick}
      onLikeFeed={onLikeFeed}
      toggleEditModal={toggleEditModal}
      removeFeed={removeFeed}
      acivityData={acivityData}
      hasUserAccess={hasUserAccess}>
      {header}
    </CustomHeader>
  );
  return (
    <Panel
      {...rest}
      header={customHeader}>
      {children}
    </Panel>
  );
};

const CommentField = ({ onChange, onSubmit, postingComment, commentValue, t }) => (
  <>
    <Form.Item>
      <TextArea
        rows={3}
        onChange={onChange}
        value={commentValue}
        id="commentField"
        allowClear
      />
    </Form.Item>
    <Form.Item>
      <Button
        key="post-comment"
        htmlType="submit"
        loading={postingComment}
        onClick={onSubmit}
        type="primary">
        {t('feed.postaCommento')}
      </Button>
    </Form.Item>
  </>
);

function sortDateLatest(a, b) {
  var dateA = new Date(a.createdOn || '').getTime();
  var dateB = new Date(b.createdOn || '').getTime();
  return dateA > dateB ? 1 : -1;
}

function sortDateOldest(a, b) {
  var dateA = new Date(a.createdOn || '').getTime();
  var dateB = new Date(b.createdOn || '').getTime();
  return dateA < dateB ? 1 : -1;
}

class Feed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      isDataLoading: false,
      loadingActivities: false,
      activityList: null,
      feedList: null,
      fullFeedList: null,
      activeKey: null,
      ellipsis: true,
      postingComment: false,
      commentValue: '',
      fieldsChanged: false,
      feedFilterData: null,
      sortDatesLatest: false,
      showNewFeedModal: false,
      showEditModal: false,
      modalData: null,
    };
  }

  scrollToBottom(feedId) {
    animateScroll.scrollToBottom({
      containerId: feedId,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.userData !== prevProps.userData) {
      this.retrieveFeed();
    }
  }

  componentWillMount() {
    this.retrieveFeedFilterPicklist();
    this.retrieveFeed();
  }

  componentWillUnmount() {
    this.setState({ isDataLoading: false });
  }

  async retrieveFeedFilterPicklist() {
    const { commentFeedsOnly, fileFeedsOnly, othersFeedsOnly } = this.props;
    const objectCode = objectCodes.feedFilter;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          let pickListData = [];

          if (commentFeedsOnly) {
            pickListData = respData.filter((item) => item.objectCodeListID === feedItemsTypes.comment);
            this.setState({ feedFilterData: pickListData });
          } else if (fileFeedsOnly) {
            pickListData = respData.filter((item) => item.objectCodeListID === feedItemsTypes.file);
            this.setState({ feedFilterData: pickListData });
          } else if (othersFeedsOnly) {
            pickListData = respData.filter(
              (item) =>
                item.objectCodeListID !== feedItemsTypes.file && item.objectCodeListID !== feedItemsTypes.comment,
            );
            this.setState({ feedFilterData: pickListData });
          } else {
            this.setState({ feedFilterData: respData });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveFeed() {
    this.setState({ isDataLoading: true });
    const { objectId, userData, isProject, commentFeedsOnly, fileFeedsOnly, othersFeedsOnly } = this.props;

    if (userData !== undefined && userData.hasOwnProperty('userId')) {
      await FeedService.getFeedData(objectId, userData.userId, isProject)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            let respData = resp.responseObject.value;

            if (commentFeedsOnly) {
              let data = respData.filter((item) => item.activityType === feedItemsTypes.comment);
              this.setState({ feedList: data });
              this.setState({ fullFeedList: data });
            } else if (fileFeedsOnly) {
              let data = respData.filter((item) => item.activityType === feedItemsTypes.file);
              this.setState({ feedList: data });
              this.setState({ fullFeedList: data });
            } else if (othersFeedsOnly) {
              let data = respData.filter(
                (item) => item.activityType !== feedItemsTypes.file && item.activityType !== feedItemsTypes.comment,
              );
              this.setState({ feedList: data });
              this.setState({ fullFeedList: data });
            } else {
              this.setState({ feedList: respData });
              this.setState({ fullFeedList: respData });
            }

            this.setState({ isDataLoading: false });
          } else {
            this.setState({ isDataLoading: false });
          }
        })
        .catch((error) => {
          this.setState({ isDataLoading: false });
        });
    }
  }

  getFeedActivity = (feedId) => {
    if (feedId === this.state.activeKey) {
      this.setState({ activeKey: null });
    } else {
      this.setState({ activeKey: feedId });

      this.setState({ loadingActivities: true });

      FeedService.getFeedActivity(feedId)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            this.setState({ activityList: resp.responseObject.value });
            this.setState({ loadingActivities: false });
            // updateScroll(feedId);
            this.scrollToBottom(feedId);
          } else {
            this.setState({ loadingActivities: false });
          }
        })
        .catch((error) => {
          this.setState({ loadingActivities: false });
        });
    }
  };

  reloadFeedActivity = (feedId) => {
    this.setState({ loadingActivities: true });
    FeedService.getFeedActivity(feedId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ activityList: resp.responseObject.value });
          this.setState({ loadingActivities: false });
          this.scrollToBottom(feedId);
        } else {
          this.setState({ loadingActivities: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingActivities: false });
      });
  };

  handleCommentPost = (e, parentActivityId, regardingObjectID, regardingObjectTypeCode, subject) => {
    e.preventDefault();
    if (!this.state.commentValue) {
      return;
    }
    this.setState({ postingComment: true });
    let obj = {};
    const { userData } = this.props;
    obj['parentActivityID'] = parentActivityId;
    obj['createdBy'] = userData.userId;
    obj['description'] = this.state.commentValue;
    obj['regardingObjectID'] = regardingObjectID;
    obj['regardingObjectTypeCode'] = regardingObjectTypeCode;
    obj['subject'] = subject;

    FeedService.addFeedComment(obj)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ postingComment: false });
          this.reloadFeedActivity(parentActivityId);
          updateFeedNotificationBell();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ postingComment: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ postingComment: false });
      });
  };

  handleCommentChange = (e) => {
    this.setState({ commentValue: e.target.value });
  };

  onFeedSearch = (value) => {
    const { fullFeedList } = this.state;
    if (value) {
      if (fullFeedList) {
        this.setState({ isDataLoading: true });
        const res = fullFeedList.filter((obj) => obj.subject.toLowerCase().includes(value.toLowerCase()));
        this.setState({ feedList: res });
        this.setState({ isDataLoading: false });
      } else {
        return;
      }
    } else {
      this.setState({ feedList: fullFeedList });
    }
  };

  onFeedFilter = (value) => {
    const { fullFeedList } = this.state;
    if (value.length > 0) {
      if (fullFeedList) {
        this.setState({ isDataLoading: true });
        const res = fullFeedList.filter((obj) => value.includes(obj.activityType));
        this.setState({ feedList: res });
        this.setState({ isDataLoading: false });
      } else {
        return;
      }
    } else {
      this.setState({ feedList: fullFeedList });
    }
  };

  onFeedSortDateLatest = () => {
    let { fullFeedList, feedList } = this.state;

    if (fullFeedList) {
      this.setState({ isDataLoading: true });
      let res = feedList.sort(sortDateLatest);
      this.setState({ feedList: res });
      this.setState({ isDataLoading: false });
      this.setState({ sortDatesLatest: true });
    } else {
      return;
    }
  };

  onFeedSortDateOldest = () => {
    let { fullFeedList, feedList } = this.state;

    if (fullFeedList) {
      this.setState({ isDataLoading: true });
      let res = feedList.sort(sortDateOldest);
      this.setState({ feedList: res });
      this.setState({ isDataLoading: false });
      this.setState({ sortDatesLatest: false });
    } else {
      return;
    }
  };

  likeFeed = (activityId) => {
    let obj = {};
    const { userData } = this.props;
    obj['activityID'] = activityId;
    obj['userID'] = userData.userId;
    FeedService.addLike(obj)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ isDataLoading: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ isDataLoading: false });
      });
  };

  toggleEditModal = (rowData) => {
    this.setState({ showEditModal: true });
    this.setState({ modalData: rowData });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false });
    this.setState({ loadingButton: false });
  };

  handleNewFeedModalClose = () => {
    this.setState({ showNewFeedModal: false });
    this.setState({ loadingButton: false });
  };

  toggleNewFeedModal = () => {
    this.setState({ showNewFeedModal: true });
  };

  addNewCommentFeed = (values) => {
    this.setState({ loadingButton: true });
    FeedService.addFeedComment(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleNewFeedModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  addNewFileFeed = (values) => {
    this.setState({ loadingButton: true });
    FeedService.addFeedFile(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleNewFeedModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  addNewMeetingFeed = (values) => {
    values['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ loadingButton: true });
    FeedService.addFeedMeeting(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleNewFeedModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  handleUpdateFeed = (values, activityData) => {
    values['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    values['activityID'] = activityData.activityID;
    values['activityType'] = activityData.activityType;
    values.priority = values.priority ? 1 : 0;
    values['outlookEventId'] = activityData.outlookEventId;

    if (typeof values.startTime !== 'string') {
      values.startTime = handleTimeConvert(values.startTime);
    }

    if (typeof values.endTime !== 'string') {
      values.endTime = handleTimeConvert(values.endTime);
    }

    values.scheduledStart = handleDateConvert(values.scheduledStart);
    values.scheduledEnd = handleDateConvert(values.scheduledEnd);

    this.setState({ loadingButton: true });
    FeedService.updateFeedItem(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleEditModalClose();
          message.success(notifyMessages.updateSuccess);
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  removeFeed = (id, type, outlookEventId) => {
    FeedService.removeFeedItem(id, type, outlookEventId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  defaultSelectedCategory = () => {
    let defaultSelectedOptions = [];
    const { commentFeedsOnly, fileFeedsOnly, othersFeedsOnly } = this.props;
    if (commentFeedsOnly) {
      defaultSelectedOptions.push(feedItemsTypes.comment);
      return defaultSelectedOptions;
    } else if (fileFeedsOnly) {
      defaultSelectedOptions.push(feedItemsTypes.file);
      return defaultSelectedOptions;
    } else if (othersFeedsOnly) {
      defaultSelectedOptions.push(feedItemsTypes.notifiche);
      defaultSelectedOptions.push(feedItemsTypes.meeting);
      return defaultSelectedOptions;
    } else {
      return defaultSelectedOptions;
    }
  };

  render() {
    const {
      showEditModal,
      modalData,
      feedList,
      isDataLoading,
      activeKey,
      ellipsis,
      postingComment,
      commentValue,
      activityList,
      loadingActivities,
      sortDatesLatest,
      showNewFeedModal,
      loadingButton,
    } = this.state;
    const { userData, objectId, hasUserAccess, feedRegardingObjType } = this.props;
    let t = this.props.t;

    return (
      <div className="feed">
        <>
          <AddFeedItem
            defaultCheckedFeedType={this.props.defaultCheckedFeedType}
            showNewFeedModal={showNewFeedModal}
            handleNewFeedModalClose={this.handleNewFeedModalClose}
            addNewCommentFeed={this.addNewCommentFeed}
            addNewFileFeed={this.addNewFileFeed}
            addNewMeetingFeed={this.addNewMeetingFeed}
            loadingButton={loadingButton}
            objectId={objectId}
            userData={userData}
            regardingObjectType={feedRegardingObjType}
            t={t}
          />

          {showEditModal && (
            <EditFeedItem
              showEditModal={showEditModal}
              handleEditModalClose={this.handleEditModalClose}
              handleUpdateFeed={this.handleUpdateFeed}
              loadingSave={loadingButton}
              modalData={modalData}
              userData={userData}
              t={t}
            />
          )}

          <TableLayout
            title={
              this.props.defaultCheckedFeedType === 2
                ? t('general.commenti')
                : this.props.defaultCheckedFeedType === 3
                  ? t('proggetiPage.file')
                  : t('proggetiPage.notificeRiunioni')
            }>
            <TableLayout.Actions>
              {sortDatesLatest && (
                <Button
                  type="text"
                  icon={<SortDescendingOutlined />}
                  onClick={() => this.onFeedSortDateOldest()}
                />
              )}
              {!sortDatesLatest && (
                <Button
                  type="text"
                  icon={<SortAscendingOutlined />}
                  onClick={() => this.onFeedSortDateLatest()}
                />
              )}
              {/* <Select
                showSearch={false}
                maxTagCount="responsive"
                mode="multiple"
                style={{ width: '100%' }}
                allowClear
                placeholder={t('general.categoria')}
                onChange={this.onFeedFilter}
                defaultValue={() => this.defaultSelectedCategory()}>
                {feedFilterData &&
                  feedFilterData.map((item) => (
                    <Option
                      value={item.objectCodeListID}
                      key={item.objectCodeListID}>
                      {item.description}
                    </Option>
                  ))}
              </Select> */}
              <Search
                placeholder={t('buttons.cerca')}
                allowClear
                onSearch={this.onFeedSearch}
              />
              {!hasUserAccess ? (
                <div className="filters-container">
                  <Button
                    type="primary"
                    onClick={this.toggleNewFeedModal}
                    icon={<PlusOutlined />}>
                    {t('buttons.aggiungiNuovo')}
                  </Button>
                </div>
              ) : null}
            </TableLayout.Actions>
            <TableLayout.Content>
              <div className="tw-flex tw-gap-2 tw-flex-col">
                {isDataLoading && <Loader2 />}
                {(!feedList || feedList.length < 1) && <Empty />}
                {feedList &&
                  feedList.map((activity, index) => (
                    <Collapse
                      className="!tw-bg-zinc-100 !tw-border-0 [&_.ant-collapse-expand-icon]:!tw-hidden"
                      activeKey={activeKey}
                      key={index}>
                      <CustomPanel
                        onClick={() => this.getFeedActivity(activity.activityID)}
                        onLikeFeed={() => this.likeFeed(activity.activityID)}
                        key={activity.activityID}
                        acivityData={activity}
                        hasUserAccess={hasUserAccess}
                        toggleEditModal={() => this.toggleEditModal(activity)}
                        removeFeed={() =>
                          this.removeFeed(activity.activityID, activity.activityType, activity.outlookEventId)
                        }
                        t={t}>
                        <div
                          key={index}
                          id={activity.activityID}>
                          {loadingActivities && <Loader2 />}
                          {activityList &&
                            activityList.length > 0 &&
                            activityList.map((comment) => (
                              <div key={comment.activityID}>
                                <Comment
                                  author={comment.fullName}
                                  key={comment.activityID}
                                  avatar={
                                    <Tooltip title={comment.fullName}>
                                      <Avatar size={'small'}>{comment.initials}</Avatar>
                                    </Tooltip>
                                  }
                                  content={
                                    <Paragraph
                                      ellipsis={ellipsis ? { rows: 1, expandable: true, symbol: 'more' } : false}
                                      style={{ fontSize: 12 }}>
                                      {comment.description}
                                    </Paragraph>
                                  }
                                  datetime={
                                    <span>
                                      {comment.createdOn ? moment(comment.createdOn).format('DD/MM/YYYY HH:mm') : ''}
                                    </span>
                                  }
                                />
                              </div>
                            ))}
                          <Comment
                            avatar={<Avatar className="tw-bg-primary">{userData.userInitials}</Avatar>}
                            content={
                              <CommentField
                                onChange={this.handleCommentChange}
                                onSubmit={(e) =>
                                  this.handleCommentPost(
                                    e,
                                    activity.activityID,
                                    activity.regardingObjectID,
                                    activity.regardingObjectTypeCode,
                                    activity.subject,
                                  )
                                }
                                submitting={postingComment}
                                value={commentValue}
                                P
                                postingComment={postingComment}
                                t={t}
                              />
                            }
                          />
                        </div>
                      </CustomPanel>
                    </Collapse>
                  ))}
              </div>
            </TableLayout.Content>
          </TableLayout>
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(Feed));
