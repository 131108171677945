import { Statistic } from 'antd';
import { useEffect, useState } from 'react';
import PersonalDashboardService from '../../services/pages/personalDashboardService';
import Loader2 from '../shared/components/loader2/loader2';

const PersonalDashboarXmatrix = ({ t, selectedXmatrixValue, activeXmatrixInfo }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [xmatrixData, setXmatrixData] = useState(null);

  useEffect(() => {
    if (activeXmatrixInfo) {
      getXmatrixData();
    }
  }, [selectedXmatrixValue, activeXmatrixInfo]);

  const getXmatrixData = async () => {
    let xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

    setLoadingData(true);
    await PersonalDashboardService.getXmatrixSummary(xmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setXmatrixData(resp.responseObject.value);
          setLoadingData(false);
        } else {
          setLoadingData(false);
        }
      })
      .catch((error) => {
        setLoadingData(false);
      });
  };

  return (
    <>
      {loadingData && <Loader2 />}

      {xmatrixData && !loadingData && (
        <>
          <div className="tw-w-full tw-grid tw-gap-4 tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-5">
            <Statistic
              className="brand-statistic tw-border-b"
              title={t('bachechaPersonalePage.numObjAnnoAttivi')}
              value={xmatrixData.totalActiveAnnualGoal ? xmatrixData.totalActiveAnnualGoal : ''}
            />
            <Statistic
              className="brand-statistic tw-border-b"
              title={t('bachechaPersonalePage.numObjLungoAttivi')}
              value={xmatrixData.totalActiveLongTermGoal ? xmatrixData.totalActiveLongTermGoal : ''}
            />
            <Statistic
              className="brand-statistic tw-border-b"
              title={t('bachechaPersonalePage.progettiInCorso')}
              value={xmatrixData.totalActiveProject ? xmatrixData.totalActiveProject : ''}
            />
            <Statistic
              className="brand-statistic tw-border-b"
              title={t('bachechaPersonalePage.giornateAssegnate')}
              value={xmatrixData.totalDays ? xmatrixData.totalDays : ''}
            />
            <Statistic
              className="brand-statistic tw-border-b"
              title={t('bachechaPersonalePage.risorseAssegnate')}
              value={xmatrixData.totalProjectMembers ? xmatrixData.totalProjectMembers : ''}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PersonalDashboarXmatrix;
