import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonalDashboardService from '../../../services/pages/personalDashboardService';
import Loader2 from '../../shared/components/loader2/loader2';
import { perTimeDeliverableChartOptions } from '../chartsConfig';

const PerTimeDeliverableChart: React.FC = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [perTimeChart, setPerTimeChart] = useState<{
    chart: any;
    series: any;
  }>(perTimeDeliverableChartOptions);
  const { t } = useTranslation();

  useEffect(() => {
    getPerTimeChartData();
  }, []);

  const getPerTimeChartData = async () => {
    setLoadingData(true);

    try {
      const response = await PersonalDashboardService.getDeliverablePerTimeChartData();
      const resp = response?.data;

      if (resp?.success && resp.responseObject?.value) {
        const seriesObj = {
          name: `${t('bachechaPersonalePage.deliverable')}`,
          data: resp.responseObject.value.map((item: any) => ({
            name: item.deliverableStatusDescription,
            y: item.deliverableCount,
          })),
        };

        setPerTimeChart((prevChart) => ({
          ...prevChart,
          series: seriesObj,
        }));
      }
    } catch (error) {
      console.error('Error fetching deliverable per time chart data:', error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <>
      {loadingData && <Loader2 />}
      {perTimeChart ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={perTimeChart}
        />
      ) : (
        <p>No data</p>
      )}
    </>
  );
};

export default PerTimeDeliverableChart;
