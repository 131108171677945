import { ContainerOutlined, PlusCircleOutlined, PlusOutlined, SelectOutlined } from '@ant-design/icons';
import { Button, message, Table } from 'antd';
import Parser from 'html-react-parser';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FeedService from '../../../../services/pages/projectServices/feedServices';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import AddFeedItem from '../../../shared/feed/newFeedItem';
import TableLayout from '../../../shared/tableLayout';
import { feedItemsTypes, feedRegardingObjectType } from '../../../shared/utils/constants';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../project.scss';
import NewDocumentationLinkModal from './addNewDocumentationLinkModal';

class ProjectFiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
      feedFilesList: null,
      showCreateModal: false,
      loadingInsertLink: false,
      loadingProjectLink: false,
      projectLink: null,
    };
  }

  componentDidMount() {
    this.retrieveFeed();
    this.getProjectLink();
  }

  async retrieveFeed() {
    this.setState({ loadingData: true });
    const { projectId, userData } = this.props;

    if (userData !== undefined && userData.hasOwnProperty('userId')) {
      await FeedService.getFeedData(projectId, userData.userId, 1)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            let data = resp.responseObject.value;
            this.setState({ feedFilesList: data.filter((obj) => obj.activityType === feedItemsTypes.file) });
            this.setState({ loadingData: false });
          } else {
            this.setState({ loadingData: false });
          }
        })
        .catch((error) => {
          this.setState({ loadingData: false });
        });
    }
  }

  async getProjectLink() {
    this.setState({ loadingProjectLink: true });
    const { projectId } = this.props;
    await ProgettiService.getProjectLink(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let data = resp.responseObject.value;
          this.setState({ projectLink: data });
        } else {
          this.setState({ loadinloadingProjectLinkgData: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingProjectLink: false });
      });
  }

  handleModalClose = () => {
    this.setState({ showCreateModal: false });
    this.setState({ loadingInsertLink: false });
  };

  toggleInsertLinkModal = () => {
    this.setState({ showCreateModal: true });
  };

  toggleNewFeedModal = () => {
    this.setState({ showNewFeedModal: true });
  };

  handleInsertLink = (values) => {
    const { projectId } = this.props;
    const { projectLink } = this.state;

    values['projectID'] = projectId;
    values['projectRepositoryID'] =
      projectLink && projectLink.projectRepositoryID ? projectLink.projectRepositoryID : null;

    this.setState({ loadingInsertLink: true });
    ProgettiService.updateProjectLink(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingInsertLink: false });
          this.handleModalClose();
          this.getProjectLink();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingInsertLink: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingInsertLink: false });
      });
  };

  handleNewFeedModalClose = () => {
    this.setState({ showNewFeedModal: false });
    this.setState({ loadingSave: false });
  };

  addNewFileFeed = (values) => {
    this.setState({ loadingSave: true });
    FeedService.addFeedFile({
      ...values,
      regardingObjectTypeCode: feedRegardingObjectType.file,
    })
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleNewFeedModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveFeed();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingSave: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingSave: false });
      });
  };

  render() {
    const { loadingData, feedFilesList, showCreateModal, loadingInsertLink, loadingProjectLink, projectLink } =
      this.state;
    const { t, projectId, currentProject, isPresentationMode, dynaFontClass, hasUserAccess } = this.props;

    const columns = [
      {
        title: `${t('general.link')}`,
        dataIndex: 'documentURL',
        render: (text, record) => {
          if (record.documentURL === '' || record.documentURL === null) {
            return;
          } else {
            return (
              <a
                href={record.documentURL}
                target="_blank"
                rel="noreferrer">
                <SelectOutlined style={{ color: '#808080' }} /> {t('proggetiPage.goToFile')}
              </a>
            );
          }
        },
      },
      {
        title: `${t('general.soggetto')}`,
        dataIndex: 'subject',
        showSorterTooltip: [false],
        sorter: (a, b) => {
          a = a.subject || '';
          b = b.subject || '';
          return a.localeCompare(b);
        },
      },
      {
        title: `${t('general.descrizione')}`,
        dataIndex: 'description',
        render: (text, record) => <>{Parser(record.description ? record.description : '')}</>,
      },
    ];

    return (
      <>
        <AddFeedItem
          showNewFeedModal={this.state.showNewFeedModal}
          handleNewFeedModalClose={this.handleNewFeedModalClose}
          addNewFileFeed={this.addNewFileFeed}
          loadingButton={this.state.loadingSave}
          objectId={this.props.objectId}
          defaultCheckedFeedType={3}
          userData={this.props.userData}
          regardingObjectType={this.props.feedRegardingObjType}
          t={t}
          isCommitie={false}
        />

        {showCreateModal && (
          <NewDocumentationLinkModal
            showModal={showCreateModal}
            handleModalClose={this.handleModalClose}
            handleInsertLink={this.handleInsertLink}
            loadingInsertLink={loadingInsertLink}
            projectID={projectId}
            currentProject={currentProject}
            t={t}
            projectLinkData={projectLink}
          />
        )}

        <TableLayout
          title={
            !isPresentationMode ? (
              t('proggetiPage.projectFiles')
            ) : (
              <h1 className={'tw-font-semibold !tw-text-3xl !tw-mb-4'}>{t('proggetiPage.projectFiles')}</h1>
            )
          }>
          <TableLayout.Actions>
            {projectLink && projectLink.link && (
              <Button
                type="link"
                icon={<ContainerOutlined />}>
                <a
                  href={projectLink.link}
                  target="_blank"
                  rel="noopener noreferrer external">
                  {t('proggetiPage.projectFilesRepo')}
                </a>
              </Button>
            )}
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => this.toggleInsertLinkModal()}>
              {t('proggetiPage.addProjectFileRepo')}
            </Button>

            {!hasUserAccess ? (
              <Button
                type="primary"
                onClick={this.toggleNewFeedModal}
                icon={<PlusOutlined />}>
                {t('buttons.aggiungiNuovo')}
              </Button>
            ) : null}
          </TableLayout.Actions>
          <TableLayout.Content>
            <Table
              className={`${isPresentationMode ? dynaFontClass : ''} table-height`}
              size="small"
              columns={columns}
              dataSource={feedFilesList}
              rowKey={(obj) => obj.activityID}
              // scroll={{ x: 'calc(600px + 50%)' }}
              loading={loadingData}
              pagination={{ showSizeChanger: false, defaultPageSize: 10 }}
            />
          </TableLayout.Content>
        </TableLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(ProjectFiles));
