import { Form } from 'antd';
import moment from 'moment';
import React from 'react';
import './activities.scss';

import { Card, Col, DatePicker, Input, Modal, Row, Select, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import CommonService from '../../../../services/commonService';
import DeliverablePageService from '../../../../services/pages/deliverableService';
import DeliverableService from '../../../../services/pages/projectServices/deliverableServices';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { activityStatus, statusPickListCodes } from '../../../shared/utils/constants';
import { areDatesValid, showErrorNotification } from '../../../shared/utils/functions';
import { requiredFields } from '../../../shared/utils/notifyMessages';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

class EditProjectActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      deliverablePickList: null,
      ownerPicklist: null,
      selectedDeliverableData: null,
    };
  }

  componentDidMount() {
    const { projectID } = this.props;
    this.retrieveStatus();
    this.retrieveDeliverablePickList(projectID);
    this.retrieveResponsibleUsersList(projectID);
  }

  async retrieveResponsibleUsersList(projectId) {
    await CommonService.getResponsibleUserList(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let usr = [];
          let respData = resp.responseObject.value;
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          this.setState({ ownerPicklist: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveDeliverablePickList(projectId) {
    await DeliverableService.getDeliverableProjectPicklist(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ deliverablePickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveStatus() {
    const objectCode = statusPickListCodes.activityStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  isStatusClosedOrCancel = (status) => {
    if (status) {
      if (status === activityStatus.closed || status === activityStatus.cancelled) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  editData = (values, activityID, projectID) => {
    this.props.handleUpdateProjectActivity(values, activityID, projectID);
  };

  getSelectedDeliverableData = (id) => {
    DeliverablePageService.getDeliverableById(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedDeliverableData: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  onDeliverableChange = (option) => {
    if (option) {
      this.getSelectedDeliverableData(option);
    }
  };

  disabledDate(current, projectStartDate, projectEndDate, modalData) {
    const { selectedDeliverableData } = this.state;

    // if (modalData.deliverableID) {
    //     let curr = moment(current).endOf('day');
    //     let startDate = moment(modalData.deliverableStartDate).endOf('day');
    //     let endDate = moment(modalData.deliverableEndDate).endOf('day');
    //     return ((curr < startDate) || (curr > endDate))
    // }
    // else {
    //     let curr = moment(current).endOf('day');
    //     let startDate = moment(projectStartDate).endOf('day');
    //     let endDate = moment(projectEndDate).endOf('day');
    //     return ((curr < startDate) || (curr > endDate))

    // }

    if (selectedDeliverableData) {
      if (selectedDeliverableData.parentDeliverableID) {
        let curr = moment(current).endOf('day');
        let startDate = moment(selectedDeliverableData.parentDeliverableStartDate).endOf('day');
        let endDate = moment(selectedDeliverableData.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        let curr = moment(current).endOf('day');
        let startDate = moment(projectStartDate).endOf('day');
        let endDate = moment(projectEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    } else {
      if (modalData.parentDeliverableID) {
        let curr = moment(current).endOf('day');
        let startDate = moment(modalData.parentDeliverableStartDate).endOf('day');
        let endDate = moment(modalData.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        let curr = moment(current).endOf('day');
        let startDate = moment(projectStartDate).endOf('day');
        let endDate = moment(projectEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    }
  }

  checkValidityBeforeSave = (values, activityID, projectID) => {
    const { t } = this.props;
    if (areDatesValid(values.startDate, values.endDate)) {
      this.editData(values, activityID, projectID);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  render() {
    const { showEditModal, handleEditModalClose, loadingButton, modalData, projectID, projectName, currentProject, t } =
      this.props;
    const { statusList, deliverablePickList, ownerPicklist } = this.state;

    let isClosedOrCancelled = this.isStatusClosedOrCancel(modalData.status);

    let isClosed = modalData.status === activityStatus.closed ? true : false;

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('leMieAttivitaPage.aggiornamentoAttivita')}
        open={showEditModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleEditModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleEditModalClose}
            formId={'editProjectActivityForm'}
          />,
        ]}>
        <Card>
          <Form
            id="editProjectActivityForm"
            layout="vertical"
            // onFinish={this.checkDatesValidity}
            onFinish={(values) => this.checkValidityBeforeSave(values, modalData.activityID, projectID)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            {isClosed && (
              <Row
                gutter={{ lg: 24 }}
                className="row-margin">
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <div className="button-wrapper">
                    <Text strong>
                      {t('general.dataChiusura')}:{' '}
                      {modalData.actualCloseDate ? moment(modalData.actualCloseDate).format('DD/MM/YYYY') : '-'}
                    </Text>
                  </div>
                </Col>
              </Row>
            )}
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item label={t('general.progetto')}>
                  <Input
                    disabled={true}
                    value={projectName}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  label={t('leMieAttivitaPage.soggetto')}
                  name="subject"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.subject}>
                  <Input disabled={isClosedOrCancelled} />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 24 }}>
                <Form.Item
                  style={{ marginBottom: 5 }}
                  labelAlign="left"
                  label={t('general.deliverable')}
                  name="deliverableId"
                  hasFeedback
                  initialValue={modalData.deliverableID}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    disabled={isClosedOrCancelled}
                    onChange={(option) => this.onDeliverableChange(option)}>
                    {deliverablePickList &&
                      deliverablePickList.map((item) => (
                        <Option
                          value={item.deliverableID}
                          key={item.deliverableID}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  labelAlign="left"
                  label={t('general.responsabile')}
                  name="ownerID"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.ownerID}>
                  <Select
                    disabled={isClosedOrCancelled}
                    placeholder={t('general.seleziona')}
                    style={{ width: '100%' }}
                    options={ownerPicklist}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    loading={!ownerPicklist}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  label={t('general.stato')}
                  name="status"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.status}>
                  <Select
                    disabled={isClosedOrCancelled}
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}>
                    {statusList &&
                      statusList.map((item) => (
                        <Option
                          value={item.statusID}
                          key={item.statusID}>
                          {item.statusDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  label={t('general.start')}
                  name="startDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.startDate ? moment(modalData.startDate) : modalData.startDate}>
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    disabled={isClosedOrCancelled}
                    placeholder="Seleziona"
                    disabledDate={(current) =>
                      this.disabledDate(current, currentProject.startDate, currentProject.endDate, modalData)
                    }
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  label={t('general.end')}
                  name="endDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={modalData.endDate ? moment(modalData.endDate) : modalData.endDate}>
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    disabled={isClosedOrCancelled}
                    placeholder="Seleziona"
                    disabledDate={(current) =>
                      this.disabledDate(current, currentProject.startDate, currentProject.endDate, modalData)
                    }
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  label={t('general.descrizione')}
                  name="description"
                  initialValue={modalData.description}>
                  <TextArea
                    rows={4}
                    disabled={isClosedOrCancelled}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default withTranslation()(EditProjectActivity);
