import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  CompanyApi,
  GeneralApi,
  UpdateAlertConfigurationRequestDto,
  UpdateCompanyRequestDto,
} from 'src/connectors/backend';

const CompanyService = {
  getCompanyData: async () => {
    const api = await authenticatedApi(GeneralApi);

    return trackPromise(api.generalGetCompany());
  },
  getCompanyNotifications: async (companyId: string) => {
    const api = await authenticatedApi(CompanyApi);

    return trackPromise(api.companyGetAlertConfiguration(companyId));
  },

  updateCompanyData: async function (payload: {
    budgetAlertEdge?: number | null;
    budgetAlertSelection?: number;
    companyName?: string;
    currency?: string;
    daysOfNotice?: number | null;
    implementationDaysOfNotice?: number;
    kpiTolerance?: number;
    logo?: File;
    mission?: string;
    nextStepsDaysOfNotice?: number;
    noticeStatus?: number | null;
    qualityAlertEdge?: number | null;
    redirectLink?: string;
    resetGlobalKpi?: boolean;
    sendNotification?: boolean | null;
    startFromCurrentMonth?: boolean;
    supportMail?: string;
    timeAlertEdge?: number | null;
    values?: string;
    vision?: string;
  }) {
    const api = await authenticatedApi(CompanyApi);

    return trackPromise(
      api.companyUpdateCompanyProfile(
        payload.mission,
        payload.vision,
        payload.logo,
        payload.values,
        payload.timeAlertEdge,
        payload.budgetAlertEdge,
        payload.qualityAlertEdge,
        payload.companyName,
        payload.currency,
        payload.budgetAlertSelection,
        payload.sendNotification,
        payload.daysOfNotice,
        payload.noticeStatus,
        payload.redirectLink,
        payload.supportMail,
        payload.nextStepsDaysOfNotice,
        payload.implementationDaysOfNotice,
        payload.kpiTolerance,
        payload.startFromCurrentMonth,
        payload.resetGlobalKpi,
      ),
    );
  },

  updateCompanyLicense: async function (payload: UpdateCompanyRequestDto) {
    const api = await authenticatedApi(GeneralApi);

    return trackPromise(api.generalUpdateCompany(payload));
  },
  insertUserNextYearPlan: async function (year: string) {
    const api = await authenticatedApi(CompanyApi);

    return trackPromise(api.companyInsertNextYearUserActivityPlanning(year));
  },
  updateCompanyNotificationsAlert: async function (payload: UpdateAlertConfigurationRequestDto) {
    const api = await authenticatedApi(CompanyApi);

    return trackPromise(api.companyUpdateAlertConfiguration(payload));
  },
};

export default CompanyService;
