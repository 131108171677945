import { Component } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Col, Form, Input, InputNumber, message, Modal, Popover, Row, Select, Switch } from 'antd';
import { withTranslation } from 'react-i18next';
import CommonService from '../../../../services/commonService';
import KpiService from '../../../../services/pages/kpiService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { formItemLayout, kpiPuntualeAcumulato, objectCodes } from '../../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';

const { Option } = Select;

const NormalJumpOffPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPointNORMAL"
      initialValue={0}
      rules={[
        { required: true, message: requiredFields.required },
        {
          validator: async (_, jumpOffPoint) => {
            if (typeof jumpOffPoint !== 'number') {
              return Promise.reject(new Error(`${props.t('kpiPage.jopNumber')}`));
            }
          },
        },
      ]}>
      <InputNumber
        style={{ width: '100%' }}
        min="0"
      />
    </Form.Item>
  );
};

const PercentageJumpOffPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPointPERCENTAGE"
      initialValue={0}
      rules={[
        { required: true, message: requiredFields.required },
        {
          validator: async (_, jumpOffPoint) => {
            if (jumpOffPoint > 100 || jumpOffPoint < 0 || isNaN(jumpOffPoint) || typeof jumpOffPoint !== 'number') {
              return Promise.reject(new Error(`${props.t('kpiPage.jopMax100Number')}`));
            }
          },
        },
      ]}>
      <InputNumber
        style={{ width: '100%' }}
        min={0}
        max={100}
        formatter={(value) => `${value} %`}
      />
    </Form.Item>
  );
};

const BooleanJumpOffPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPointBOOLEAN"
      valuePropName="checked"
      initialValue={false}>
      <Switch
        checkedChildren={props.t('kpiPage.fatto')}
        unCheckedChildren={props.t('kpiPage.nonFatto')}
      />
    </Form.Item>
  );
};

const DisabledJumpOfPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPoint"
      initialValue={null}>
      <InputNumber
        value={null}
        style={{ width: '100%' }}
        min="0"
        disabled={true}
      />
    </Form.Item>
  );
};

class AddKpiModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged2: false,
      fieldsChanged: false,
      items: null,
      name: null,
      isNew: false,
      isNewKpi: false,
      calculationTypeList: null,
      typeList: null,
      acumulatoPuntuale: null,
      jumpOffPointComponent: (
        <NormalJumpOffPoint
          jmp={1}
          t={this.props.t}
        />
      ),
      disableJumpOfPointResetGlobalKpi: false,
    };
  }

  // Init of component after rendering
  componentDidMount() {
    this.retrieveKpiData();
    this.retrieveCalculationType();
    this.retrieveType();
    this.retrieveAcumulatoPuntuale();
  }

  createRandomId() {
    const min = 1;
    const max = 999999;
    const rand = min + Math.random() * (max - min);
    return rand;
  }

  // Retrieve list of calculation types
  retrieveCalculationType() {
    const objectCode = objectCodes.calculationTypes;
    CommonService.getCalculationTypeList(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ calculationTypeList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve list of types
  retrieveType() {
    const objectCode = objectCodes.kpiType;
    CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ typeList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve acumulato/puntuale
  retrieveAcumulatoPuntuale() {
    const objectCode = objectCodes.acumulatoPuntuale;
    CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ acumulatoPuntuale: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve KPI data
  retrieveKpiData() {
    const { selectedXmatrix } = this.props;
    KpiService.getKpiList(selectedXmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ items: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  addData = (values, name, isNewKpi) => {
    const { disableJumpOfPointResetGlobalKpi } = this.state;
    Object.keys(values).forEach(function (fieldName) {
      if (
        fieldName === 'jumpOffPointBOOLEAN' ||
        fieldName === 'jumpOffPointPERCENTAGE' ||
        fieldName === 'jumpOffPointNORMAL'
      ) {
        if (fieldName === 'jumpOffPointBOOLEAN') {
          if (values[fieldName] === true) {
            values[fieldName] = 1;
          }
          if (values[fieldName] === false) {
            values[fieldName] = 0;
          }
        }
        values['jumpOffPoint'] = values[fieldName];
        delete values[fieldName];
      }
    });

    if (disableJumpOfPointResetGlobalKpi) {
      values['jumpOffPoint'] = 0;
    }

    this.props.handleObKpiSave(values, name, isNewKpi);
  };

  onNameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  addItem = () => {
    const { items, name } = this.state;
    let fakeId = this.createRandomId();
    let obj = { name: name, kpiDetailID: fakeId };
    this.setState({
      items: [...items, obj],
      name: name,
      isNew: true,
    });
  };

  _validateKpiName = (_rule, value, callback) => {
    KpiService.checkIfKpiNameExist(value)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (resp.responseObject.value) {
            callback(requiredFields.validateKpiName);
          }
          callback();
        }
        callback();
      })
      .catch((error) => {
        message.error(notifyMessages.errorAccured);
      });
  };

  onCalculationTypeChange = (value, option) => {
    const { t } = this.props;
    const jumpOffPointCalculationType = value;

    if (jumpOffPointCalculationType === 1 || jumpOffPointCalculationType === 2) {
      this.setState({
        jumpOffPointComponent: <NormalJumpOffPoint t={t} />,
      });
    } else if (jumpOffPointCalculationType === 3) {
      this.setState({
        jumpOffPointComponent: <PercentageJumpOffPoint t={t} />,
      });
    } else if (jumpOffPointCalculationType === 4) {
      this.setState({
        jumpOffPointComponent: <BooleanJumpOffPoint t={t} />,
      });
    } else {
      <NormalJumpOffPoint t={t} />;
    }
  };

  onKpiSelect = (option) => {
    const { isNewKpi } = this.state;
    if (option && option === 'newKpi') {
      this.setState({ isNewKpi: !isNewKpi });
    } else {
      this.setState({ isNewKpi: false });
    }
  };

  kpiInfo = (item) => {
    const { t } = this.props;
    return (
      <div>
        <p>
          {' '}
          <b>{t('kpiPage.tipoCalcolo')}: </b> {item.globaleType}
        </p>
        <p>
          {' '}
          <b>{t('kpiPage.cumulatoPuntuale')}: </b> {item.puntualeType}
        </p>
      </div>
    );
  };

  checkDisableJumpOfPointResetGlobale = () => {
    const { selectedCumulatoPuntuale } = this.state;
    const { companyData } = this.props;
    if (companyData.resetGlobalKPI && selectedCumulatoPuntuale === kpiPuntualeAcumulato.acumulato) {
      this.setState({ disableJumpOfPointResetGlobalKpi: true });
    } else {
      this.setState({ disableJumpOfPointResetGlobalKpi: false });
    }
  };

  onCumulatoPuntualeChange = (value) => {
    this.setState({ selectedCumulatoPuntuale: value }, () => {
      this.checkDisableJumpOfPointResetGlobale();
    });
  };

  render() {
    const { showObKpi, handleObKpiClose, loadingButton, t } = this.props;

    const {
      items,
      isNewKpi,
      calculationTypeList,
      acumulatoPuntuale,
      jumpOffPointComponent,
      disableJumpOfPointResetGlobalKpi,
    } = this.state;

    return (
      <Modal
        className="add_ob_modal"
        width={800}
        title={t('kpiPage.aggiungiKpi')}
        open={showObKpi}
        destroyOnClose={true}
        onCancel={handleObKpiClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleObKpiClose}
            formId={'newKpiXmatrix'}
          />,
        ]}>
        <Card>
          <Form
            id="newKpiXmatrix"
            name="add_ob_lungo"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.addData(values, this.state.name, isNewKpi)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  name="kpiDetailID"
                  label={t('xMatrixPage.selezionareKpi')}
                  hasFeedback
                  rules={[
                    {
                      required: isNewKpi ? false : true,
                      message: requiredFields.required,
                    },
                  ]}>
                  <Select
                    showSearch
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(option) => this.onKpiSelect(option)}>
                    {items &&
                      items.map((item) => (
                        <Option
                          value={item.kpiDetailID}
                          key={item.kpiDetailID}>
                          {item.name}
                          <Popover
                            content={this.kpiInfo(item)}
                            title={t('xMatrixPage.kpiInfo')}>
                            <InfoCircleOutlined
                              style={{
                                fontSize: 12,
                                color: '#1f85e4',
                                marginLeft: '15px',
                              }}
                            />
                          </Popover>
                        </Option>
                      ))}
                    <Option
                      value={'newKpi'}
                      key={'newKpi'}
                      style={{ backgroundColor: '#f5f5e7' }}>
                      {t('kpiPage.aggiungiKpi')}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {isNewKpi && (
              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <Card title={t('general.informazioniGenerali')}>
                    <Row gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Form.Item
                          label={t('general.nome')}
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: requiredFields.required,
                            },
                            {
                              validator: this._validateKpiName,
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={t('kpiPage.tipoCalcolo')}
                          name="calculationType"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: requiredFields.required,
                            },
                          ]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onChange={(value, option) => this.onCalculationTypeChange(value, option)}>
                            {calculationTypeList &&
                              calculationTypeList.map((item) => (
                                <Option
                                  value={item.objectCodeListID}
                                  key={item.objectCodeListID}>
                                  {item.description}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        {jumpOffPointComponent && !disableJumpOfPointResetGlobalKpi && jumpOffPointComponent}

                        {disableJumpOfPointResetGlobalKpi && <DisabledJumpOfPoint t={t} />}
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={t('kpiPage.cumulatoPuntuale')}
                          name="parentType"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: requiredFields.required,
                            },
                          ]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onChange={(value) => this.onCumulatoPuntualeChange(value)}>
                            {acumulatoPuntuale &&
                              acumulatoPuntuale.map((item) => (
                                <Option
                                  value={item.objectCodeListID}
                                  key={item.objectCodeListID}>
                                  {item.description}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default withTranslation()(AddKpiModal);
