import { useEffect, useState } from 'react';

import {
  ContainerOutlined,
  DownOutlined,
  DownloadOutlined,
  EditFilled,
  PlusOutlined,
  SearchOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, Space, Switch, Table, Tooltip, message } from 'antd';
import { FilterDropdownProps, Key } from 'antd/es/table/interface';
import { ColumnType } from 'antd/lib/table';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  GetProjectListPageResponseDto,
  GetXMatrixInfoResponseDto,
  GetXMatrixPicklistResponseDto,
  InsertProjectXMatrixRequestDto,
  ProjectDto,
  ProjectParentship,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import * as XLSX from 'xlsx';
import MatrixService from '../../../services/matrix/matrixService';
import ObProgettiService from '../../../services/matrix/obProgettiService';
import { addQueryParam, getQueryParam } from '../../../utils/url-utils';
import TableLayout from '../../shared/tableLayout';
import { formatOfDate, projectStatusAsNumber } from '../../shared/utils/constants';
import { capitalizeFirstLetter, handleDateConvert, isTeamMember } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import QuickAddProgettiModal from '../../xMatrix/data_tables/center/addQuickProgettiModal';
import ProjectsTimeline from '../projectsTimelineViewPage/projectsTimeline';
import Search from 'antd/es/transfer/search';
import { MenuProps } from 'antd/lib';
import DashboardService from 'src/services/pages/dashboardService';
import TreeXmatrixFilter from 'src/components/shared/components/treeXmatrixFilter/treeXmatrixFilter';

enum ViewType {
  timeline = 'timeline',
  kanban = 'kanban',
  list = 'default',
}

const ProjectListIndex = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const userData = useAppSelector((state) => state.userData.userData);

  const [view, setView] = useState<ViewType>((getQueryParam('list') || ViewType.timeline) as ViewType);
  const [loadingData, setLoadingData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [projectList, setProjectList] = useState<ProjectDto[]>([]);
  const [showProjectsWithoutRelations, setShowProjectsWithoutRelations] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [showQuickProjectModal, setShowQuickProjectModal] = useState(false);
  const [loadingQuickSave, setLoadingQuickSave] = useState(false);
  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState<GetXMatrixInfoResponseDto | null>(null);
  const [selectedXmatrixIds, setSelectedXmatrixIds] = useState<string[]>([]);
  const [xmatrixPicklist, setXmatrixPicklist] = useState<GetXMatrixPicklistResponseDto[]>([]);
  const [searchByName, setSearchByName] = useState('');
  const [filteredProjectList, setFilteredProjectList] = useState<ProjectDto[]>([]);

  const isRoleTeamMember = userData && Object.keys(userData).length > 0 && isTeamMember(userData);
  const activeXmatrix = useAppSelector((state) => state.activeXMatrix.activeXMatrix);

  useEffect(() => {
    if (activeXmatrix?.xMatrixID) {
      setActiveXmatrixInfo(activeXmatrix);
      setSelectedXmatrixIds([activeXmatrix?.xMatrixID]);
    }

    void fetchXmatrixPicklist();

  }, [activeXmatrix]);

  useEffect(() => {
    if (selectedXmatrixIds.length > 0) {
      void fetchProjects(selectedXmatrixIds);
    }

  }, [selectedXmatrixIds, showProjectsWithoutRelations]);

  useEffect(() => {
    setFilteredProjectList(projectList.filter((project) => !!project.name?.toLowerCase().includes(searchByName ?? '')));
  }, [searchByName, projectList]);

  const fetchXmatrixPicklist = async () => {
    const response = await MatrixService.getXmatrixPicklist();
    const resp = response.data;

    if (resp.success) {
      const respData = resp.responseObject?.value as GetXMatrixPicklistResponseDto[];
      setXmatrixPicklist(respData);
    }
  };

  const fetchProjects = async (xMatrixIds: string[]) => {
    setLoadingData(true);

    try {
      const { data } = await DashboardService.getDashboardProjects({
        xMatrixIds,
        projectParentship: ProjectParentship.All,
        selectNotRelatedProjects: showProjectsWithoutRelations,
      });
      setProjectList(data);
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }

    setLoadingData(false);
  };

  const handleSearch = (selectedKeys: Key[], dataIndex: string) => {
    setSearchText(selectedKeys[0] as string);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: FilterDropdownProps['clearFilters']) => {
    if (clearFilters !== undefined) {
      clearFilters();
    }

    setSearchText('');
  };

  const onNew = () => {
    if (activeXmatrixInfo?.xMatrixID) {
      history.push(`/progetti/new/${activeXmatrixInfo?.xMatrixID}`);
    }
  };

  const handleQuickProgettiClose = () => {
    setShowQuickProjectModal(false);
    setLoadingQuickSave(false);
  };

  const toggleCreateQuickProjectModal = () => {
    setShowQuickProjectModal(!showQuickProjectModal);
  };

  const addQuickProject = async (values: InsertProjectXMatrixRequestDto) => {
    const xmatrixStartDate = moment(activeXmatrixInfo?.referencePeriod).startOf('year');
    const xmatrixEndDate = moment(activeXmatrixInfo?.referencePeriod).endOf('year');

    setLoadingQuickSave(true);

    try {
      const { data } = await ObProgettiService.addObProgetti({
        ...values,
        xMatrixID: activeXmatrixInfo?.xMatrixID as string,
        secondaryProject: false,
        status: projectStatusAsNumber.draft,
        fastCreation: true,
        hasExternalReview: false,
        startDate: handleDateConvert(xmatrixStartDate),
        endDate: handleDateConvert(xmatrixEndDate),
      });

      if (!data.success) {
        throw new Error('Creation failed');
      }

      void message.success(notifyMessages.addSuccess);
      handleQuickProgettiClose();
      await fetchProjects(selectedXmatrixIds); // update project list
    } catch {
      void message.error(notifyMessages.addFailed);
    } finally {
      setLoadingQuickSave(false);
    }
  };

  const viewOptions: MenuProps = {
    items: [
      {
        label: (
          <li
            onClick={() => {
              addQueryParam('list', ViewType.list);
              setView(ViewType.list);
            }}>
            Lista
          </li>
        ),
        key: 'list',
      },
      {
        label: (
          <li
            onClick={() => {
              addQueryParam('list', ViewType.timeline);
              setView(ViewType.timeline);
            }}>
            Timeline
          </li>
        ),
        key: 'timeline',
      },
      {
        label: <li>Kanban</li>,
        disabled: true,
        key: 'kanban',
      },
    ],
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />
    ),
    onFilter: (value: any, record: Record<string, any>) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnType<ProjectDto>[] = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
      fixed: 'left',
      width: '400px',
      showSorterTooltip: false,
      ...getColumnSearchProps('name'),
    },
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      showSorterTooltip: true,
      ...getColumnSearchProps('projectCode'),
      defaultSortOrder: 'ascend',
    },
    {
      title: `${t('proggetiPage.inizio')}`,
      dataIndex: 'startDate',
      sorter: (a, b) => (a.startDate || '').localeCompare(b.startDate || ''),
      showSorterTooltip: false,
      render: (text, record) => moment(text).format(formatOfDate),
    },
    {
      title: `${t('proggetiPage.fine')}`,
      dataIndex: 'endDate',
      sorter: (a, b) => (a.endDate || '').localeCompare(b.endDate || ''),
      showSorterTooltip: false,
      render: (text, record) => moment(text).format(formatOfDate),
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      sorter: (a, b) => (a.statusDescription || '').localeCompare(b.statusDescription || ''),
      showSorterTooltip: true,
      ...getColumnSearchProps('statusDescription'),
    },
    {
      title: `${t('general.comitato')}`,
      dataIndex: ['committee', 'name'],
      ellipsis: {
        showTitle: true,
      },
      sorter: (a, b) => (a.committee?.name || '').localeCompare(b.committee?.name || ''),
      showSorterTooltip: true,
      ...getColumnSearchProps('committeeName'),
    },
    {
      title: `${t('general.teamLeader')}`,
      dataIndex: ['teamLeader', 'fullName'],
      sorter: (a, b) => (a.teamLeader?.fullName || '').localeCompare(b.teamLeader?.fullName || ''),
      showSorterTooltip: true,
      ...getColumnSearchProps('teamLeaderFullName'),
    },
    {
      title: `${t('general.sponsor')}`,
      dataIndex: ['sponsor', 'fullName'],
      sorter: (a, b) => (a.sponsor?.fullName || '').localeCompare(b.sponsor?.fullName || ''),
      showSorterTooltip: true,
      ...getColumnSearchProps('sponsorFullName'),
    },
    {
      title: `${t('general.divisione')}`,
      dataIndex: ['division', 'name'],
      sorter: (a, b) => (a.division?.name || '').localeCompare(b.division?.name || ''),
      showSorterTooltip: true,
      ...getColumnSearchProps('divisionName'),
    },
    {
      key: 'action',
      render: (_, record) => {
        return (
          <>
            <div className="tw-flex tw-justify-end tw-flex-row tw-gap-2">
              {record.projectRepositoryLink && (
                <Tooltip title={t('a3Page.repositoriProgetto')}>
                  <Button
                    type="text"
                    icon={<ContainerOutlined />}
                    href={record.projectRepositoryLink}
                    target="_blank"
                    rel="noreferrer"></Button>
                </Tooltip>
              )}
              <Button
                icon={<EditFilled />}
                href={`/progetti/id/${record.id}`}
              />
            </div>
          </>
        );
      },
    },
  ];

  const exportProjectList = (projectList: GetProjectListPageResponseDto[]) => {
    if (!projectList?.length) {
      message.warning(t('general.noDataFound'));
      return;
    }

    setLoadingExport(true);

    const formattedData = projectList.map((item) => ({
      projectCode: item.projectCode,
      name: item.name,
      parentProjectCode: item.parentProjectCode,
      parentName: item.parentName,
      committeeName: item.committeeName,
      divisionName: item.divisionName,
      statusDescription: item.statusDescription,
      startDate: item.startDate ? moment(item.startDate).format('DD/MM/YYYY') : '',
      endDate: item.endDate ? moment(item.endDate).format('DD/MM/YYYY') : '',
      teamLeaderFullName: item.teamLeaderFullName,
      teamLeaderEmailAddress: item.teamLeaderEmailAddress,
      sponsorFullName: item.sponsorFullName,
      sponsorEmailAddress: item.sponsorEmailAddress,
      teamMembersName: item.teamMembersName,
      teamMembersEmail: item.teamMembersEmail,
    }));

    const headers = [
      [
        'Codice',
        'Nome',
        'Codice Padre',
        'Nome Padre',
        'Comitato',
        'Divisione',
        'Stato',
        'Data Inizio',
        'Data Fine',
        'Team Leader',
        'Team Leader Email',
        'Sponsor',
        'Sponsor Email',
        'Nome Team Members',
        'Email Team Members',
      ],
    ];

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = t('general.progetti');

    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, formattedData, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, headers);
    ws['!cols'] = Array.from({ length: 30 }, () => ({ wpx: 120 }));

    const wb = { Sheets: { Progetti: ws }, SheetNames: ['Progetti'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
    setLoadingExport(false);
  };

  type NewProjectDropdownProps = {
    disabled: boolean;
    onNew: () => void;
    onFastNew: () => void;
  };

  const optionsMenu = (props: NewProjectDropdownProps) => {
    let items = [
      {
        label: <li onClick={props.onNew}>{t('proggetiPage.aggiungiNuovoProgetto')}</li>,
        key: 1,
      },
      {
        label: <li onClick={props.onFastNew}>{t('xMatrixPage.quickAddProject')}</li>,
        key: 2,
      },
    ];
    return { items };
  };
  const NewProjectOptions = (props: NewProjectDropdownProps) => (
    <Dropdown
      key="more"
      disabled={props.disabled}
      overlayClassName="new-project-dropdown"
      menu={optionsMenu(props)}
      className="matrixComponentsPrint">
      <Button
        icon={<PlusOutlined />}
        type="primary">
        {t('buttons.aggiungiNuovo')}
      </Button>
    </Dropdown>
  );

  const toggleProjectsWithoutRelations = async () => {
    setShowProjectsWithoutRelations(!showProjectsWithoutRelations);
  };

  const onTreeXmatrixChange = (value: string[]) => {
    setSelectedXmatrixIds(value);
  };

  return (
    <>
      <QuickAddProgettiModal
        showQuickObProgetti={showQuickProjectModal}
        handleQuickObProgettiClose={handleQuickProgettiClose}
        handleQuickProgettiSave={addQuickProject}
        loadingButton={loadingQuickSave}
        t={t}
      />

      <TableLayout
        title={t('headerMenu.progetti')}
        className="[&_.ant-select]:tw-h-[40px] tw-p-6 tw-flex tw-flex-col tw-h-full tw-overflow-hidden [&_.ant-select-selector]:!tw-rounded-lg">
        <TableLayout.Actions>
          {/* change type of view */}
          <Dropdown
            key="more"
            placement="bottomLeft"
            menu={viewOptions}>
            <Button
              data-testid="setViewBtn"
              className="tw-border-0 tw-shadow-none">
              <TableOutlined />
              {view === ViewType.list ? 'Lista' : capitalizeFirstLetter(view)}
              <DownOutlined />
            </Button>
          </Dropdown>

          {xmatrixPicklist.length > 0 && (
            <TreeXmatrixFilter
              selectedXmatrixIds={selectedXmatrixIds}
              xmatrixPicklist={xmatrixPicklist}
              onTreeXmatrixChange={onTreeXmatrixChange} />
          )}

          {view === ViewType.list && (
            <div className="tw-flex tw-items-center tw-gap-1">
              <Switch
                loading={loadingData}
                onClick={() => toggleProjectsWithoutRelations()}
              />
              <span className="tw-w-60">{t('proggetiPage.progettiSenzaRelazione')}</span>
            </div>
          )}

          {/* export */}
          {projectList.length > 0 && (
            <Button
              loading={loadingExport}
              onClick={() => exportProjectList(projectList)}
              icon={<DownloadOutlined />}>
              {t('proggetiPage.exportProjectList')}
            </Button>
          )}

          {/* search box */}
          {view === ViewType.list && (
            <Search
              value={searchByName}
              onChange={(e) => {
                setSearchByName(e.currentTarget.value.trim().toLowerCase());
              }}
              placeholder={t('general.search')}
            />
          )}

          {/* add new project button */}
          {!isRoleTeamMember && (
            <NewProjectOptions
              disabled={!activeXmatrixInfo?.xMatrixID}
              onNew={onNew}
              onFastNew={toggleCreateQuickProjectModal}
            />
          )}
        </TableLayout.Actions>
        <TableLayout.Content>
          {view === ViewType.timeline && (
            <ProjectsTimeline selectedXmatrixIds={selectedXmatrixIds} />
          )}

          {view === ViewType.list && (
            <Table
              sticky
              size="small"
              columns={columns}
              data-test="projects-table"
              dataSource={filteredProjectList}
              rowKey={(obj) => obj.id}
              scroll={{ x: 'calc(600px + 50%)' }}
              loading={loadingData}
              pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
            />
          )}
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default ProjectListIndex;
