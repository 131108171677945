import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import { CommonApi, GeneralApi, UpdateCompanyRequestDto, UpdateUserCompanyRequest, UserApi } from 'src/connectors/backend';
import { acquireToken } from '../../config/auth/clientAuthProvider';
import axiosInstance from '../../config/axiosConfig/interceptors';
import i18n from '../../i18n';

const UsersService = {
  // get user profile data
  getUserProfile: async function (id: string, year: string) {
    const api = await authenticatedApi(UserApi);

    return trackPromise(api.userGetFullProfile(id, year));
  },

  // update user profile data
  updateUserData: async function (
    id: string,
    type: number,
    roleId: string,
    isDisabled: boolean,
    jobTitle?: string,
    dailyRate?: number | null,
    emailAddress?: string,
    photo?: File,
    divisionId?: string | null,
    phoneNumber?: string,
    firstName?: string,
    surname?: string,
    availability?: number | null,
    departament?: string,
  ) {
    const api = await authenticatedApi(UserApi);

    return trackPromise(
      api.userUpdateUser(
        id,
        type,
        jobTitle,
        availability,
        departament,
        dailyRate,
        emailAddress,
        photo,
        divisionId,
        phoneNumber,
        firstName,
        surname,
        roleId,
        isDisabled,
      ),
    );
  },

  // get user profile data
  getUserBadge: async function (userIds: string[]) {
    const api = await authenticatedApi(UserApi);

    return trackPromise(api.userGetUserInfo(userIds));
  },

  // get user profile data
  getSystemUser: async () => {
    const generalApi = await authenticatedApi(GeneralApi);

    return await trackPromise(generalApi.generalGetConnectionModel());
  },

  // update user
  updateUserLogedInCompany: async (payload: UpdateUserCompanyRequest) => {
    const generalApi = await authenticatedApi(GeneralApi);

    return await trackPromise(generalApi.generalUpdateUserCompany(payload));
  },

  getUsersListData: async function () {
    const api = await authenticatedApi(UserApi);

    return trackPromise(api.userGetUserListPageList());
  },

  getUserListOnFeed: async function () {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetUserSelect());
  },

  uploadUsers: function (payload: any) {
    const data = acquireToken().then((tokenResponse) => {
      const optionsVal = {
        method: 'POST',
        url: `api/User/InviteUser`,
        data: payload,
        headers: {
          Authorization: `Bearer ${tokenResponse?.accessToken}`,
        },
      };
      const apiResponse = axiosInstance(optionsVal);
      return apiResponse;
    });
    return data;
  },

  getUserProjects: async function (xmatrixID: string, userId: string) {
    const currentLang = i18n.language;
    const api = await authenticatedApi(UserApi);

    return trackPromise(api.userGetUserProject(userId, xmatrixID, currentLang));
  },

  getUserCommities: async function (userId: string) {
    const api = await authenticatedApi(UserApi);

    return trackPromise(api.userGetUserCommittee(userId));
  },

  getUsersCountByRole: async function () {
    const api = await authenticatedApi(UserApi);

    return trackPromise(api.userGetTotalActiveUsersByRole());
  },

  removeUser: async function (userId: string) {
    const api = await authenticatedApi(UserApi);

    return trackPromise(api.userDeleteUserFromCompany(userId));
  },
};

export default UsersService;
