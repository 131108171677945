import {
  CloseCircleOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  MinusSquareOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import * as FileSaver from 'file-saver';
import moment from 'moment';

import React from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import * as XLSX from 'xlsx';
import KpiService from '../../../services/pages/kpiService';
import '../../kpiListPage/kpi.scss';
import { capitalizeFirstLetterKpiMonths } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';

const { Text, Title } = Typography;

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const ErrorList = ({ errorList }) => {
  return (
    <Space direction="vertical">
      {errorList.map((item) => (
        <Text> * {item.error}</Text>
      ))}
    </Space>
  );
};

const ModalHeader = (props) => {
  return (
    <Space direction="vertical">
      <Title
        style={{ marginBottom: 1 }}
        level={5}>
        KPI: <span>{props.data.name}</span>
      </Title>
      <span>
        <Text style={{ fontSize: 14 }}>
          <span className="header-text"> {props.t('kpiPage.cumulatoPuntuale')}: </span>
          <u>{props.data.parentTypeDescription}</u>
        </Text>
        <Divider type="vertical" />
        <Text style={{ fontSize: 14 }}>
          <span className="header-text"> {props.t('kpiPage.tipoCalcolo')}: </span>
          <u>{props.data.calculationTypeDescription}</u>
        </Text>
        <Divider type="vertical" />
      </span>
    </Space>
  );
};

class UploadKpiAnualGoal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingData: false,
      loadingExcelData: false,
      cols: [],
      rows: [],
      errorMessage: null,
      kpiApiData: null,

      clearFilters: false,
      selectedYear: moment().format('YYYY'),

      rowVlidaionErrors: [],
      showErrorsList: false,
      dataIsertedSuccess: false,
    };
  }

  componentDidMount() {
    this.getExcelData();
  }

  async getExcelData() {
    const { kpiRowData } = this.props;
    const { selectedYear } = this.state;

    this.setState({ loadingExcelData: true });
    await KpiService.getKpiSpecificoExcelDataAnualGoal(kpiRowData.annualGoalKPIRelationShipID, selectedYear)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ kpiApiData: resp.responseObject.value });
          this.setState({ loadingExcelData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingExcelData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingExcelData: false });
      });
  }

  checkFile(file) {
    const { errorMessage } = this.state;
    if (!file || !file[0]) {
      return;
    }
    const isExcel =
      file[0].type === 'application/vnd.ms-excel' ||
      file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
      this.setState({ errorMessage: 'Carica solo file excel' });
    }
    const isLt2M = file[0].size / 1024 / 1024 < 5;
    if (!isLt2M) {
      this.setState({ errorMessage: 'Il file deve essere inferiore a 5 MB! ' });
    }
    return errorMessage;
  }

  fileHandler = (fileList) => {
    const { rowVlidaionErrors } = this.state;
    const { anualGoalId } = this.props;

    let fileObj = fileList;
    if (!fileObj) {
      this.setState({
        errorMessage: 'Nessun file caricato!',
      });
      return false;
    }

    if (
      !(
        fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      this.setState({
        errorMessage: 'Carica solo file excel',
      });
      return false;
    }

    const isLt2M = fileObj.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.setState({ errorMessage: 'Il file deve essere inferiore a 2 MB! ' });
      return false;
    }
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let newRows = [];
        resp.rows.slice(1).map((row, index) => {
          let rowErrorObj = {};

          if (row[0] !== anualGoalId && index === 0) {
            rowErrorObj['error'] = 'KPI selezionato è associato ad un altro Obiettivo Annuale';
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (isNaN(row[4])) {
            rowErrorObj['error'] = 'Il valore del ACTUAL alla riga ' + (index + 1) + ' non è valido.';
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (row[3] === null || row[3] === undefined) {
            rowErrorObj['error'] = 'Il valore del MESE alla riga ' + (index + 1) + ' non è valido.';
            rowVlidaionErrors.push(rowErrorObj);
            rowErrorObj = {};
          }

          if (row && row !== 'undefined') {
            newRows.push({
              key: index + 1,
              annualGoalKPIRelationshipId: row[1],
              name: row[2],
              targetMonth: row[3],
              actual: row[4],
              unitOfMeasure: row[5],
            });
          }
        });
        if (newRows.length === 0) {
          this.setState({
            errorMessage: 'Nessun dato trovato nel file! ',
          });
          return false;
        } else {
          this.setState({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null,
          });
        }
      }
    });
    return false;
  };

  handleSubmit = async () => {
    const { rows } = this.state;

    let payloadArray = [];

    rows.forEach(function (item, index) {
      let payloadObj = {};

      payloadObj['actual'] = item.actual;
      payloadObj['unitOfMeasure'] = item.unitOfMeasure;
      payloadObj['annualGoalKPIRelationshipId'] = item.annualGoalKPIRelationshipId;
      payloadObj['referencePeriod'] = item.targetMonth;

      payloadArray.push(payloadObj);
    });

    this.setState({ loadingButton: true });
    KpiService.updateSpecificlKpiFromExcelAnualGoal(payloadArray)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ rows: [] });
          this.setState({ dataIsertedSuccess: true });
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  onClearFilter = () => {
    let currentYear = moment().format('YYYY');
    this.setState({ selectedYear: currentYear, clearFilters: true }, () => {
      this.getExcelData();
      this.cancellUpload();
    });
  };

  onYearChange = (year) => {
    let formatedYear = moment(year).format('YYYY');
    this.setState({ selectedYear: formatedYear }, () => {
      this.getExcelData();
      this.cancellUpload();
    });
  };

  disablePreviousYears = (current) => {
    return current && (current > moment().add(10, 'year') || current < moment());
  };

  exportToCSV = (kpiApiData) => {
    const { kpiRowData, anualGoalName } = this.props;

    const fileName = `KPI_${kpiRowData.name}_${anualGoalName}`;

    if (kpiApiData) {
      const ws = XLSX.utils.json_to_sheet(kpiApiData);

      //column formatter
      let wsColsFormatter = [
        { hidden: true }, // hidde first column
        { hidden: true }, // hidde first column
        { wpx: 100 }, // column width
        { wpx: 100 }, // column width
        { wpx: 100 }, // column width
        { wpx: 100 }, // column width
      ];

      ws['!cols'] = wsColsFormatter;

      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  cancellUpload = async () => {
    this.setState({ rows: [] });
    this.setState({ rowVlidaionErrors: [] });
    this.setState({ dataIsertedSuccess: false });
  };

  showHideErrorList = () => {
    const { showErrorsList } = this.state;
    this.setState({ showErrorsList: !showErrorsList });
  };

  render() {
    const { showUploadKpiModal, handleUploadKpiModalClose, kpiRowData, t } = this.props;

    const {
      rows,
      kpiApiData,
      loadingExcelData,
      errorMessage,
      selectedYear,
      loadingButton,
      showErrorsList,
      rowVlidaionErrors,
      dataIsertedSuccess,
    } = this.state;

    const columns = [
      {
        title: '',
        dataIndex: 'key',
      },
      {
        title: `${t('general.nome')}}`,
        dataIndex: 'name',
        ellipsis: {
          showTitle: true,
        },
      },
      {
        title: `${t('general.mese')}}`,
        dataIndex: 'targetMonth',
        render: (text) => capitalizeFirstLetterKpiMonths(text ? moment(text).format('MMM YYYY') : null),
      },
      {
        title: `${t('general.actual')}}`,
        dataIndex: 'actual',
      },
      {
        title: `${t('kpiPage.unitaMisura')}}`,
        dataIndex: 'unitOfMeasure',
      },
    ];

    return (
      <Modal
        className="edit-kpi-modal"
        // width={1100}
        title={
          <ModalHeader
            data={kpiRowData}
            t={t}
          />
        }
        open={showUploadKpiModal}
        destroyOnClose={true}
        onCancel={handleUploadKpiModalClose}
        width={'100%'}
        footer={[
          <div key="close_buttons">
            <Button onClick={handleUploadKpiModalClose}>{t('buttons.chiudi')}</Button>
          </div>,
        ]}>
        <Card>
          <Row
            gutter={{ lg: 24 }}
            style={{ marginBottom: 10 }}>
            <Col
              className="gutter-row"
              xs={{ span: 8 }}
              sm={{ span: 6 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}>
              {/* <DatePicker allowClear={false} placeholder="Anno" picker="year" value={moment(selectedYear)} style={{ width: "100%" }} format="YYYY" onChange={(date) => this.onYearChange(date)} disabledDate={(current) => this.disablePreviousYears(current)} /> */}
              <DatePicker
                allowClear={false}
                placeholder="Anno"
                picker="year"
                value={moment(selectedYear)}
                style={{ width: '100%' }}
                format="YYYY"
                onChange={(date) => this.onYearChange(date)}
              />
            </Col>
            <Col
              xs={{ span: 4 }}
              sm={{ span: 4 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}>
              {/* <Tooltip title="Filter">
                            <Button type="dashed" icon={<FilterOutlined />} onClick={() => this.onFilter()} disabled={!selectedYear} />
                        </Tooltip> */}
              <Tooltip title={t('general.clearFilters')}>
                <Button
                  type="dashed"
                  icon={<MinusSquareOutlined />}
                  onClick={() => this.onClearFilter()}
                  disabled={selectedYear ? false : true}
                />
              </Tooltip>
            </Col>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}>
              <Button
                type="primary"
                loading={loadingExcelData}
                icon={<DownloadOutlined />}
                onClick={() => this.exportToCSV(kpiApiData)}>
                {t('buttons.scaricaModello')}
              </Button>
              <Tooltip
                placement="top"
                title={t('obiettiviAnnoPage.scaricaExcelKpi')}>
                <InfoCircleOutlined style={{ color: '#928e8e', marginLeft: 10 }} />
              </Tooltip>
            </Col>
          </Row>

          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              {/* <div className="button-end">                                       

                                    <Button 
                                    type="primary" 
                                    icon={<DownloadOutlined />} 
                                    onClick={(e) => this.exportToCSV(kpiApiData)}
                                    >Download</Button>
                               
                            </div> */}

              <div className="button-wrapper">
                {rows.length > 0 && (
                  <>
                    <Button
                      onClick={this.handleSubmit}
                      type="primary"
                      style={{ marginBottom: 16, marginLeft: 10 }}
                      loading={loadingButton}
                      disabled={rowVlidaionErrors && rowVlidaionErrors.length > 0}
                      className="tw-float-end">
                      {t('buttons.salva')}
                    </Button>

                    <Button
                      onClick={this.cancellUpload}
                      type="ghost"
                      style={{ marginBottom: 16, marginLeft: 10 }}
                      icon={<CloseCircleOutlined />}
                      className="long-term-button remove">
                      {t('buttons.cancella')}
                    </Button>
                  </>
                )}
              </div>
            </Col>
          </Row>

          {rowVlidaionErrors && rowVlidaionErrors.length > 0 && (
            <Alert
              message={t('general.alcuniDatiNonValidi')}
              showIcon
              description={showErrorsList ? <ErrorList errorList={rowVlidaionErrors} /> : ''}
              //description={showErrorsList ? "<ErrorList errorList={rowVlidaionErrors}" : ""}
              type="error"
              action={
                <Button
                  danger
                  onClick={this.showHideErrorList}>
                  {t('buttons.dettagli')}
                </Button>
              }
            />
          )}

          {rows.length < 1 && (
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Upload
                  name="file"
                  maxCount={1}
                  multiple={false}
                  beforeUpload={this.fileHandler}
                  onRemove={() => this.setState({ rows: [] })}>
                  <Button icon={<UploadOutlined />}>{t('buttons.sleionaExcel')}</Button>
                </Upload>
                <p className="upload-text"> * {t('obiettiviAnnoPage.clickFileExcel')} </p>

                {errorMessage && <p className="upload-text-error"> {errorMessage}</p>}
              </Col>
            </Row>
          )}

          {dataIsertedSuccess && (
            <Alert
              message={t('obiettiviAnnoPage.datiCaricatiSuccesso')}
              showIcon
              type="success"
            />
          )}

          {rows.length > 0 && (
            <Row
              gutter={{ lg: 24 }}
              style={{ marginTop: 10 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Table
                  className="table-height"
                  dataSource={rows}
                  columns={columns}
                  bordered={true}
                  scroll={{ x: 'calc(700px + 50%)' }}
                  size="small"
                  pagination={{ pageSize: 20 }}
                  // loading={loadingData}
                />
              </Col>
            </Row>
          )}
        </Card>
      </Modal>
    );
  }
}

export default UploadKpiAnualGoal;
