import { Component } from 'react';

import { Form, Input, Modal } from 'antd';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { requiredFields } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class QuickAddProgettiModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  _validateProjectCode = (_rule, value, callback) => {
    ProgettiService.checkIfProjectCodeExist(value)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (resp.responseObject.value) {
            callback(requiredFields.validateProjectCode);
          }
          callback();
        }
        callback();
      })
      .catch((error) => {});
  };

  addData = (values) => {
    this.props.handleQuickProgettiSave(values);
  };

  render() {
    const { showQuickObProgetti, handleQuickObProgettiClose, loadingButton, t } = this.props;

    return (
      <Modal
        className="add_ob_modal"
        width={800}
        title={t('xMatrixPage.quickAddProject')}
        open={showQuickObProgetti}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleQuickObProgettiClose}
        footer={[
          <ModalFooterButtons
            key={'modalButtons'}
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleQuickObProgettiClose}
            formId={'newProgettoXmatrix'}
          />,
        ]}>
        <Form
          id="newProgettoXmatrix"
          name="add_ob_project"
          layout="vertical"
          {...formItemLayout}
          onFinish={(values) => this.addData(values)}
          onValuesChange={() => {
            this.setState({ fieldsChanged: true });
          }}>
          <Form.Item
            label={t('proggetiPage.nomeProgetto')}
            name="name"
            rules={[{ required: true, message: requiredFields.required }]}>
            <Input />
          </Form.Item>

          <Form.Item
            extra={t('proggetiPage.codiceLungeza')}
            label={t('proggetiPage.codiceProgetto')}
            name="projectCode"
            rules={[
              {
                required: true,
                message: requiredFields.required,
              },
              { max: 10, message: `${t('proggetiPage.codiceLungeza')}` },
              {
                validator: this._validateProjectCode,
              },
            ]}>
            <Input maxLength={10} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default QuickAddProgettiModal;
