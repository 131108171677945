import { RightOutlined } from '@ant-design/icons';
import { Button, Card, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import notFoundImg from '../../../../assets/notFound.png';

const { Paragraph } = Typography;

const NF404: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="tw-h-screen tw-fixed tw-bg-white tw-inset-0 tw-z-[9999] tw-flex tw-items-center tw-justify-center">
      <Card className="tw-max-w-[450px]">
        <div className="tw-flex tw-items-center tw-justify-center tw-flex-col">
          <div>
            <Typography.Title level={2}>404 - {t('Common.pageNotFound')}</Typography.Title>
            <Paragraph>{t('Common.pageNotFoundDesc')}</Paragraph>
            <Button
              href="/"
              type="primary"
              icon={<RightOutlined />}>
              {t('general.tornaHomePage')}
            </Button>
          </div>
          <img
            className="tw-mt-8 tw-max-w-[200px]"
            src={notFoundImg}
            alt="Page not found"
          />
        </div>
      </Card>
    </div>
  );
};

export default NF404;
