import { CloseOutlined, ContainerOutlined, FolderOpenOutlined, LeftOutlined } from '@ant-design/icons';
import { Avatar, Button, Drawer, message, Popover, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useAppSelector } from 'src/redux/store';
import { history } from '../../App';
import { StickyNoteIcon } from '../../assets/icons';
import HeaderService from '../../services/a3/headerService';
import ProgettiService from '../../services/pages/projectServices/progettiService';
import {
  BudgetSemaphore,
  BudgetTooltip,
  QualitySemaphore,
  QualityTooltip,
  TimeSemaphore,
  TimeTooltip,
} from '../shared/semaphores/semaphores';
import { projectStatusAsNumber } from '../shared/utils/constants';
import { notifyMessages } from '../shared/utils/notifyMessages';
import { editFieldObjectData } from './projectForm/mainInfo/projectForm';
import TextEditor from './projectForm/mainInfo/TextEditor';
// import '../a3/mainA3.scss';

const { Text } = Typography;

const CurrentProjectHeader = ({ id, companyData, presentationModeTemplate }) => {
  const currentProject = useAppSelector((state) => state.project.currentProject);
  const [teamList, setTeamList] = useState([]);
  const [projectLink, setProjectLink] = useState(null);
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [fieldObject, setFieldObject] = useState(null);
  const [loadingEditorButton, setLoadingEditorButton] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getTeamList();
    getProjectLink();
  }, [id]);

  const getProjectLink = async () => {
    try {
      const response = await ProgettiService.getProjectLink(id);
      const resp = response.data;
      if (resp.success) {
        setProjectLink(resp.responseObject.value);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getTeamList = async () => {
    try {
      const response = await HeaderService.getMemberList(id);
      const resp = response.data;
      if (resp.success) {
        setTeamList(resp.responseObject.value);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleEditorModal = (fieldData) => {
    setIsNoteOpen(!isNoteOpen);
    setFieldObject(fieldData);
  };

  const updateFieldToObject = async (value) => {
    const payloadObj = {
      projectId: currentProject.projectID,
      projectFieldName: 'finishedActivitiesNote',
      projectFieldValue: value,
    };

    try {
      const response = await ProgettiService.updateEditorField(payloadObj);
      const resp = response.data;
      if (resp.success) {
        message.success(notifyMessages.updateSuccess);
        setIsNoteOpen(false);
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingEditorButton(false);
    }
  };

  const renderTeamMembersList = () => (
    <div className="tw-pointer-events-none">
      <h4 className="tw-text-xl tw-font-bold tw-mb-2">Team</h4>
      <ul className="tw-flex tw-flex-col tw-gap-2">
        {teamList.length > 0 &&
          teamList.map((team, index) => {
            if (team.onCall === 0 || team.onCall === null) {
              return (
                <li
                  className="tw-bg-zinc-100 tw-p-2"
                  key={index}>
                  {team.fullName}
                </li>
              );
            }
          })}
      </ul>
      <h4 className="tw-text-xl tw-mt-8 tw-font-bold tw-mb-2">On Call</h4>
      <ul className="tw-flex tw-flex-col tw-gap-2">
        {teamList.length > 0 &&
          teamList.map((team, index) => {
            if (team.onCall === 1) {
              return (
                <li
                  className="tw-bg-zinc-100 tw-p-2"
                  key={index}>
                  {team.fullName}
                </li>
              );
            }
          })}
      </ul>
    </div>
  );

  const checkProjectEndDateValidity = (headerData) => {
    if (headerData) {
      let currentDate = moment().endOf('day');
      let projectEndDate = moment(headerData.endDate).endOf('day');
      return projectEndDate < currentDate && headerData.status === projectStatusAsNumber.active;
    }
    return false;
  };

  const formatedDate = currentProject ? moment(currentProject.lastUpdated).format('MMDDYYYY') : null;
  const updatedWeek = moment(formatedDate, 'MMDDYYYY').isoWeek();

  return (
    <div className="a3-main tw-bg-white tw-overflow-hidden tw-shrink-0 tw-gap-8 tw-border-b tw-flex tw-items-center tw-justify-between tw-min-h-6 tw-max-h-14 tw-py-2 tw-px-3">
      {currentProject && teamList && (
        <div className="tw-flex tw-gap-2 tw-items-center tw-shrink-0">
          <Button
            onClick={history.goBack}
            type="ghost"
            icon={<LeftOutlined />}></Button>
          <div className="company-logo tw-h-10 tw-bg-primary tw-w-10 tw-rounded-md tw-overflow-hidden">
            {companyData.logo && (
              <img
                src={`${companyData.logo}?${+new Date()}`}
                className="tw-w-full tw-h-full tw-object-cover"
                alt="Logo"
              />
            )}
          </div>
          <Tooltip
            placement="bottom"
            title={currentProject.name}>
            <Text className="tw-text-xl md:tw-max-w-[400px] xl:tw-max-w-[400px] 2xl:tw-max-w-[500px] tw-truncate tw-font-semibold">
              {currentProject.name}
            </Text>
          </Tooltip>
          <Text className="tw-text-zinc-400 tw-shrink-0 tw-text-xl tw-hidden xl:tw-block">
            {currentProject?.projectCode}
          </Text>
        </div>
      )}

      <div className="tw-flex tw-overflow-x-auto tw-items-center tw-gap-2">
        {currentProject.parentProjectID && (
          <Tooltip
            placement="bottom"
            title={t('proggetiPage.progettoPrincipale')}>
            <Button
              type="dashed"
              href={`/progetti/id/${currentProject.parentProjectID}`}>
              <FolderOpenOutlined />
              {currentProject.parentProjectName}
            </Button>
          </Tooltip>
        )}

        <div className="tw-flex tw-bg-zinc-100 tw-gap-1 tw-items-center tw-rounded-full tw-h-10 tw-px-1 tw-pr-4">
          <Avatar
            className="tw-rounded-full"
            size={'default'}>
            {currentProject?.sponsorFullName ? currentProject.sponsorFullName[0] : '-'}
          </Avatar>
          <div className="tw-flex-col tw-w-24 tw-justify-center tw-flex">
            <span className="tw-font-bold tw-truncate tw-text-xs tw-text-orange-500 tw-uppercase">
              {t('general.sponsor')}
            </span>
            <span className="-tw-mt-1 tw-truncate">{currentProject?.sponsorFullName || '-'}</span>
          </div>
        </div>

        <div className="tw-flex tw-bg-zinc-100 tw-gap-1 tw-items-center tw-rounded-full tw-h-10 tw-px-1 tw-pr-4">
          <Avatar
            className="tw-rounded-full"
            size={'default'}>
            {currentProject?.teamLeaderFullName ? currentProject.teamLeaderFullName[0] : '-'}
          </Avatar>
          <div className="tw-flex-col tw-w-24 tw-justify-center tw-flex">
            <span className="tw-font-bold tw-truncate tw-text-xs tw-text-blue-500 tw-uppercase">
              {t('general.teamLeader')}
            </span>
            <span className="-tw-mt-1 tw-truncate">{currentProject?.teamLeaderFullName || '-'}</span>
          </div>
        </div>

        <Avatar.Group max={{ count: 3 }}>
          {teamList &&
            teamList.map((item, index) => (
              <Popover
                key={index}
                content={renderTeamMembersList()}
                trigger="click">
                <Avatar className="tw-bg-primary">{item.userInitials}</Avatar>
              </Popover>
            ))}
          <Popover
            content={renderTeamMembersList()}
            trigger="click">
            <Avatar className="tw-bg-zinc-100 tw-font-bold tw-text-black">...</Avatar>
          </Popover>
        </Avatar.Group>

        {/* Semaphores */}
        {currentProject && (
          <div className="tw-flex tw-items-center tw-gap-2">
            <Tooltip
              placement="bottom"
              title={
                <TimeTooltip
                  data={currentProject}
                  t={t}
                />
              }>
              <div>
                <TimeSemaphore data={currentProject} />
              </div>
            </Tooltip>
            <Tooltip
              placement="bottom"
              title={
                <BudgetTooltip
                  data={currentProject}
                  t={t}
                  companyData={companyData}
                />
              }>
              <div>
                <BudgetSemaphore data={currentProject} />
              </div>
            </Tooltip>
            <Tooltip
              placement="bottom"
              title={
                <QualityTooltip
                  data={currentProject}
                  t={t}
                />
              }>
              <div>
                <QualitySemaphore data={currentProject} />
              </div>
            </Tooltip>
          </div>
        )}

        <div className="tw-bg-zinc-100 tw-h-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-gap-2 tw-px-4 tw-shrink-0">
          <span>{currentProject?.startDate ? moment(currentProject.startDate).format('DD/MM/YYYY') : ''}</span>
          <span>{`<->`}</span>
          <span>{currentProject?.endDate ? moment(currentProject.endDate).format('DD/MM/YYYY') : ''}</span>
        </div>

        {/* <div className="tw-flex tw-flex-col tw-rounded-full tw-truncate tw-bg-zinc-100 tw-px-4 tw-py-1 tw-max-h-10 tw-shrink-0">
          <span className="tw-text-xs tw-truncate tw-w-full"> {t('proggetiPage.aggiornamento')} </span>
          <p className="tw-text-sm">
            {currentData?.lastUpdated ? moment(currentData.lastUpdated).format('DD/MM/YYYY') : ''}
          </p>
        </div> */}

        {/* <span>
          {checkUpdatedWeek(formatedDate) ? (
            <div className="tw-flex tw-flex-col tw-rounded-full tw-truncate tw-bg-zinc-100 tw-px-4 tw-py-1 tw-max-h-10">
              <span className="tw-text-xs tw-truncate tw-block">
                {t('proggetiPage.settimanaAggiornamento')}: {updatedWeek ? updatedWeek : ''}
              </span>
              <p className="tw-text-sm">
                {' '}
                {currentData?.lastUpdated ? moment(new Date(currentData.lastUpdated), 'MMDDYYYY').year() : ''}
              </p>
            </div>
          ) : (
            <div className="tw-flex tw-flex-col tw-rounded-full tw-truncate tw-bg-zinc-100 tw-px-4 tw-py-1 tw-max-h-10">
              <span className="tw-text-xs tw-truncate tw-block">
                {t('proggetiPage.settimanaAggiornamento')}:
              </span>
              <p className="tw-text-sm">
                {updatedWeek ? updatedWeek : ''} |{' '}
                {currentData?.lastUpdated ? moment(new Date(currentData.lastUpdated), 'MMDDYYYY').year() : ''}
              </p>
            </div>
          )}
        </span> */}

        {projectLink && projectLink.link && (
          <Tooltip
            placement="bottom"
            title={t('a3Page.repositoriProgetto')}>
            <Button
              type="dashed"
              className="tw-shrink-0"
              href={projectLink.link}
              icon={<ContainerOutlined />}
              target="_blank"></Button>
          </Tooltip>
        )}

        <Tooltip
          placement="bottom"
          title={t('a3Page.note')}>
          <Button
            icon={<StickyNoteIcon />}
            onClick={() => {
              setIsNoteOpen(true);
              toggleEditorModal(
                editFieldObjectData('finishedActivitiesNote', currentProject.finishedActivitiesNote, t),
              );
            }}></Button>
        </Tooltip>

        <Drawer
          title="Note"
          closable={false}
          width={500}
          classNames={{
            mask: '!tw-bg-white/40',
            content: 'glass-effect',
          }}
          extra={
            <Button
              onClick={() => setIsNoteOpen(false)}
              icon={<CloseOutlined />}
            />
          }
          onClose={() => setIsNoteOpen(false)}
          open={isNoteOpen}>
          <TextEditor
            handleEditorSave={updateFieldToObject}
            data={fieldObject}
            loadingEditorButton={loadingEditorButton}
          />
        </Drawer>

        {/* Presentation */}
        {presentationModeTemplate}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  companyData: state.companyData.companyData,
});

export default connect(mapStateToProps)(CurrentProjectHeader);
