import React, { useContext, useEffect, useRef, useState } from 'react';

import { DeleteOutlined, MenuOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Transfer,
  Typography,
} from 'antd';
import { arrayMoveImmutable } from 'array-move';
import BraftEditor from 'braft-editor';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import MatrixService from '../../services/matrix/matrixService';
import CommitieService from '../../services/pages/commitieServices';
import Loader2 from '../shared/components/loader2/loader2';
import { richTectEditoControlls } from '../shared/utils/constants';
import { generateNewGuid } from '../shared/utils/functions';

const { Panel } = Collapse;

const { TextArea } = Input;
const { Text, Title } = Typography;

const EditableContext = React.createContext(null);

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  inputType,
  handleSave,
  handleFieldChange,
  handleFormErrors,
  agendaRows,
  setAgendaRows,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleFormErrors([]);
      toggleEdit();
      // Parse input time and duration
      const inputTime = values.Input ? values.Input : record.Input;
      const durationMinutes = values.Duration ? parseFloat(values.Duration) || 0 : record.Duration;
      const durationHours = durationMinutes / 60;

      if (inputTime && durationHours) {
        // Convert input time to minutes
        const [inputHours, inputMinutes] = inputTime.split(':').map(Number);
        const inputMinutesTotal = inputHours * 60 + inputMinutes;

        // Calculate output time in minutes
        let outputMinutesTotal = inputMinutesTotal + durationHours * 60;

        // Handle wrap-around to the next day
        if (outputMinutesTotal >= 1440) {
          outputMinutesTotal -= 1440; // Subtract 24 hours (1440 minutes)
        }

        // Convert output time back to HH:mm format
        const outputHours = Math.floor(outputMinutesTotal / 60);
        const outputMinutes = outputMinutesTotal % 60;
        const outputTime = `${String(outputHours).padStart(2, '0')}:${String(outputMinutes).padStart(2, '0')}`;
        // Update the "Output" field for the current row
        const updatedAgendaRows = agendaRows.map((item) => {
          if (item.ID === record.ID) {
            return {
              ...item,
              ...values,
              Output: outputTime, // Update the "Output" field
            };
          }
          return item;
        });

        // Update the "Input" and "Output" fields for all rows below
        const currentIndex = updatedAgendaRows.findIndex((item) => item.ID === record.ID);
        if (currentIndex !== -1) {
          let currentInputTime = outputTime;
          for (let i = currentIndex + 1; i < updatedAgendaRows.length; i++) {
            const row = updatedAgendaRows[i];
            const rowDurationHours = parseFloat(row.Duration / 60) || 0;
            const [rowInputHours, rowInputMinutes] = currentInputTime.split(':').map(Number);
            let rowInputMinutesTotal = rowInputHours * 60 + rowInputMinutes;
            rowInputMinutesTotal = (rowInputMinutesTotal + rowDurationHours * 60) % 1440;
            const rowOutputHours = Math.floor(rowInputMinutesTotal / 60);
            const rowOutputMinutes = rowInputMinutesTotal % 60;
            const rowOutputTime = `${String(rowOutputHours).padStart(2, '0')}:${String(rowOutputMinutes).padStart(2, '0')}`;
            row.Input = currentInputTime;
            row.Output = rowOutputTime;
            currentInputTime = rowOutputTime;
          }
        }

        setAgendaRows(updatedAgendaRows); // Update the agendaRows state
        handleSave({
          ...record,
          ...values,
          Output: outputTime, // Include "Output" in the saved data
        });
      } else {
        handleSave({
          ...record,
          ...values,
        });
      }
    } catch (errInfo) {
      handleFormErrors(errInfo);
    }
  };

  const onKeyPress = (e) => {
    const specialCharRegex = new RegExp('[0-9:]');
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!specialCharRegex.test(pressedKey)) {
      e.preventDefault();
      return false;
    }
  };

  const onPateAction = (e) => {
    e.preventDefault();
    return false;
  };

  let childNode = children;

  if (editable) {
    const inputNode =
      inputType === 'number' ? (
        <InputNumber
          min={0}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          onMouseLeave={save}
        />
      ) : // : <TextArea rows={2} ref={inputRef} onPressEnter={save} onBlur={save} onMouseLeave={save} />
      dataIndex === 'Input' || dataIndex === 'Output' ? (
        <Input
          rows={2}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          style={{ width: '100px' }}
          onMouseLeave={save}
          onKeyPress={onKeyPress}
          onPaste={onPateAction}
        />
      ) : (
        <TextArea
          rows={2}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          onMouseLeave={save}
        />
      );

    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}>
        {inputNode}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}>
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

function AddDefaultAgendaModal({
  showModal,
  handleModalClose,
  saveDefaultAgenda,
  committeeID,
  loadingSave,
  loadingSaveClose,
  commitieData,
  agendaComitieId,
  agendaSessionId,
  isDefault,
  loadingSendMail,
  data,
  getExpiryDate,
  handleSession,
}) {
  const { t } = useTranslation();
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [defaultAgenda, setDefaultAgenda] = useState(null);
  const [loadingAgenda, setLoadingAgenda] = useState(false);
  const [agendaRows, setAgendaRows] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  const [agendaScope, setAgendaScope] = useState(null);
  const [agendaDuration, setAgendaDuration] = useState(null);
  const [agendaFrequency, setAgendaFrequency] = useState(null);
  const [agendaNotes, setAgendaNotes] = useState(null);
  const [lastAgendaRow, setLastAgendaRow] = useState(null);
  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);

  const [targetKeysProjects, setTargetKeysProjects] = useState([]);
  const [targetKeysRelatesProjects, setTargetKeysRelatesProjects] = useState([]);
  const [comitieProjects, setComitieProjects] = useState([]);
  const [comitieRelatedProjects, setComitieRelatedProjects] = useState([]);
  const [subComitieRelatedProjects, setSubComitieRelatedProjects] = useState([]);
  const [totalDuration, setTotalDuration] = useState(0);

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const [form] = Form.useForm();
    const index = agendaRows.findIndex((x) => x.ID === restProps['data-row-key']);
    return (
      <Form
        form={form}
        component={false}>
        <EditableContext.Provider value={form}>
          <SortableItem
            index={index}
            {...restProps}
          />
        </EditableContext.Provider>
      </Form>
    );
  };

  useEffect(() => {
    getComitieProjects();
  }, [activeXmatrixInfo]);

  useEffect(() => {
    getActiveXmatrix();
    getDefaultAgenda(agendaComitieId, agendaSessionId);
  }, []);

  useEffect(() => {
    getTotalDuration();
  }, [agendaRows]);

  const getTotalDuration = async () => {
    if (agendaRows) {
      const newTotalDuration = agendaRows.reduce((total, row) => total + (row.Duration / 60 || 0), 0);
      setTotalDuration(newTotalDuration);
      // totalDurationSession( agendaRows.reduce((total, row) => total + ((row.Duration) || 0), 0))
    }
  };

  const getActiveXmatrix = async () => {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setActiveXmatrixInfo(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getComitieProjects = async () => {
    if (activeXmatrixInfo) {
      let xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

      await CommitieService.getComitieProjectsAndSubProjetcst(committeeID, agendaSessionId, xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            let respData = resp.responseObject.value;
            setComitieProjects(respData);

            let comitieProjects = respData.filter((el) => el.subCommitteeProject !== true);
            setComitieRelatedProjects(comitieProjects);

            let subComitieProjects = respData.filter((el) => el.subCommitteeProject === true);
            setSubComitieRelatedProjects(subComitieProjects);
          } else {
          }
        })
        .catch((error) => {});
    } else {
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(agendaRows.slice(), oldIndex, newIndex).filter((el) => !!el);
      reArrangeRows(newData);

      setFieldsChanged(true);
    }
  };

  const reArrangeRows = (newData) => {
    if (!isDefault) {
      // Initialize currentInput with the start time of the session
      let currentInput = data.startTime.slice(0, 5);
      let outputTime;
      const updatedAgendaRows = newData.map((row, index) => {
        if (index !== 0) {
          currentInput = outputTime;
        }
        const durationMinutes = parseFloat(row.Duration) || 0;
        const durationHours = durationMinutes / 60;

        // Convert input time to minutes
        const [inputHours, inputMinutes] = currentInput.split(':').map(Number);
        const inputMinutesTotal = inputHours * 60 + inputMinutes;

        // Calculate output time in minutes
        let outputMinutesTotal = inputMinutesTotal + durationHours * 60;

        // Handle wrap-around to the next day
        if (outputMinutesTotal >= 1440) {
          outputMinutesTotal -= 1440; // Subtract 24 hours (1440 minutes)
        }

        // Convert output time back to HH:mm format
        const outputHours = Math.floor(outputMinutesTotal / 60);
        const outputMinutes = outputMinutesTotal % 60;
        outputTime = `${String(outputHours).padStart(2, '0')}:${String(outputMinutes).padStart(2, '0')}`;

        if (index === 0) {
          // For the first row, use the initial input time
          currentInput = data.startTime.slice(0, 5);
        }

        return {
          ...row,
          Input: currentInput,
          Output: outputTime,
        };
      });

      setAgendaRows(updatedAgendaRows);
      setLastAgendaRow(getLastAgendaRow(updatedAgendaRows));
      setFieldsChanged(true);
    } else {
      setAgendaRows(newData);
      setAgendaRows(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const handleDelete = (key) => {
    const newData = agendaRows.filter((item) => item.ID !== key);
    reArrangeRows(newData);
    if (newData.length) setLastAgendaRow(lastAgendaRow - 1);
    setFieldsChanged(true);
  };

  const defaultColumns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'ID',
      dataIndex: 'OrderString',
      editable: false,
      width: '10px',
      className: 'drag-visible',
      render: (text, record) => {
        return agendaRows.indexOf(record) + 1;
      },
    },
    {
      title: `${t('comitatiPage.agendaPoints')}`,
      dataIndex: 'Description',
      editable: true,
    },
    {
      title: `${t('comitatiPage.freq')}`,
      dataIndex: 'Frequency',
      editable: true,
    },
    {
      title: `${t('comitatiPage.min')}`,
      dataIndex: 'Duration',
      editable: true,
    },
    {
      title: `${t('comitatiPage.in')}`,
      dataIndex: 'Input',
      editable: true,
    },
    {
      title: `${t('comitatiPage.out')}`,
      dataIndex: 'Output',
      editable: true,
    },
    {
      title: `${t('comitatiPage.accountable')}`,
      dataIndex: 'Accountable',
      editable: true,
      width: '170px',
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) =>
        agendaRows.length >= 1 ? (
          <Popconfirm
            title={t('comitatiPage.removeRow')}
            onConfirm={() => handleDelete(record.ID)}>
            <DeleteOutlined />
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = {
      ID: generateNewGuid(),
      AgendaHeaderID: defaultAgenda.id,
      OrderString: lastAgendaRow + 1,
      Description: '',
      Frequency: '',
      Duration: '',
      Input: '', // Input will be set to the Output of the last row
      Output: '',
      Accountable: '',
    };
    if (!isDefault) {
      if (agendaRows.length === 0 && data) {
        newData.Input = data.startTime.slice(0, 5);
      } else {
        if (agendaRows.length > 0)
          // Set the Input of the new row to the Output of the last row
          newData.Input = agendaRows[agendaRows.length - 1].Output;
      }
    }

    if (isDefault) {
      if (agendaRows.length === 0 && data) {
        newData.Input = data.startTime.slice(0, 5);
      } else {
        if (agendaRows.length > 0)
          // Set the Input of the new row to the Output of the last row
          newData.Input = agendaRows[agendaRows.length - 1].Output;
      }
    }

    setAgendaRows([...agendaRows, newData]);
    setLastAgendaRow(lastAgendaRow + 1);
    setTotalDuration(totalDuration + newData.Duration / 60);
    setFieldsChanged(true);
  };

  const handleSave = (row) => {
    const newData = [...agendaRows];
    const index = newData.findIndex((item) => row.ID === item.ID);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setAgendaRows(newData);
    setFieldsChanged(true);
  };

  const components = {
    body: {
      wrapper: DraggableContainer,
      row: DraggableBodyRow,
      cell: EditableCell,
    },
  };

  const agendaRowColumns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.dataIndex === 'Duration' ? 'number' : 'text',
        handleSave,
        agendaRows,
        setAgendaRows,
        // handleFieldChange: (value) => {handleFieldChange(value, col.dataIndex, record)},
        handleFormErrors,
      }),
    };
  });

  const getLastAgendaRow = (rows) => {
    if (rows && rows.length > 0) {
      const len = rows.length;
      return len;
    }
  };

  const getDefaultAgenda = (comId, sesionId) => {
    setLoadingAgenda(true);
    CommitieService.getAgenda(comId, sesionId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setLoadingAgenda(false);
          let respData = resp.responseObject.value;

          setDefaultAgenda(respData);
          let jsonParsedRows = JSON.parse(respData.agendaItems);
          let agendaRowsData = jsonParsedRows && jsonParsedRows.length > 0 ? jsonParsedRows : [];
          // Update the first row's startTime field
          if (agendaRowsData.length > 0 && data) {
            // agendaRowsData[0].Input = data.startTime.slice(0, 5);
            // const [inputHours, inputMinutes] =  agendaRowsData[0].Input.split(':').map(Number);
            // const inputMinutesTotal = inputHours * 60 + inputMinutes;

            // // Calculate output time in minutes
            // let outputMinutesTotal = inputMinutesTotal + agendaRowsData[0].Duration;

            // // Handle wrap-around to the next day
            // if (outputMinutesTotal >= 1440) {
            //     outputMinutesTotal -= 1440; // Subtract 24 hours (1440 minutes)
            // }

            // // Convert output time back to HH:mm format
            // const outputHours = Math.floor(outputMinutesTotal / 60);
            // const outputMinutes = outputMinutesTotal % 60;
            // const outputTime = `${String(outputHours).padStart(2, '0')}:${String(outputMinutes).padStart(2, '0')}`;
            // agendaRowsData[0].Output = outputTime;
            reArrangeRows(agendaRowsData);
          } else setAgendaRows(agendaRowsData);

          setAgendaScope(respData.scope);
          setAgendaDuration(respData.duration);
          setAgendaFrequency(respData.frequency);
          setAgendaNotes(respData.notes);

          let actualSelectedCommitteeProjects = JSON.parse(respData.committeeProjects);
          if (actualSelectedCommitteeProjects && actualSelectedCommitteeProjects.length > 0) {
            setTargetKeysProjects(actualSelectedCommitteeProjects.map((a) => a.ProjectID.toLowerCase()));
          }

          let actualSelectedSubCommitteeProjects = JSON.parse(respData.subCommitteeProjects);
          if (actualSelectedSubCommitteeProjects && actualSelectedSubCommitteeProjects.length > 0) {
            setTargetKeysRelatesProjects(actualSelectedSubCommitteeProjects.map((a) => a.ProjectID.toLowerCase()));
          }
        } else {
          setLoadingAgenda(false);
        }
      })
      .catch((error) => {
        setLoadingAgenda(false);
      });
  };

  const handleFormErrors = (errors) => {
    if (errors.errorFields && errors.errorFields.length > 0) {
      setFormErrors(errors.errorFields);
    } else {
      setFormErrors([]);
    }
  };

  const saveAgendaData = (sendMail, closeModal) => {
    let selecteAgendaProjects = targetKeysProjects.concat(targetKeysRelatesProjects);
    let values = {};
    let updatedRowOrders = agendaRows.map((item, index) => {
      return { ...item, OrderString: (index + 1).toString() };
    });

    values['scope'] = agendaScope;
    values['duration'] = agendaRows.reduce((total, row) => total + (row.Duration || 0), 0);
    values['frequency'] = agendaFrequency;
    values['agendaItemsType'] = updatedRowOrders;
    values['projects'] = selecteAgendaProjects;

    let editorContentToHtml = BraftEditor.createEditorState(agendaNotes).toHTML();
    values['notes'] = editorContentToHtml;
    if (validateAgendaFields(values) === true) {
      if (!isDefault) {
        let valuesAgendaFields = {};
        valuesAgendaFields.date = data.date.split('T')[0];
        valuesAgendaFields['endTime'] =
          agendaRows.length > 0 ? agendaRows[agendaRows.length - 1].Output : data.startTime.slice(0, 5);
        valuesAgendaFields.startTime = agendaRows.length > 0 ? agendaRows[0].Input : data.startTime.slice(0, 5);
        valuesAgendaFields['duration'] = agendaRows.reduce((total, row) => total + (row.Duration || 0), 0);
        valuesAgendaFields['sessionID'] = agendaSessionId;
        valuesAgendaFields['committeeID'] = committeeID;
        valuesAgendaFields['codice'] = data.codice;
        valuesAgendaFields['state'] = data.stateID;
        handleSession(valuesAgendaFields);
        if (
          agendaRows.length > 0 &&
          agendaRows[agendaRows.length - 1].Output !== '' &&
          agendaRows[agendaRows.length - 1].Output !== null
        )
          getExpiryDate(agendaRows[agendaRows.length - 1]);
        else getExpiryDate(data.startTime.slice(0, 5));
      }
      saveDefaultAgenda(values, closeModal, agendaComitieId, agendaSessionId, defaultAgenda.id, sendMail);
    } else {
      notification.error({
        message: `${t('general.validationError')}`,
        description: `${t('general.requiredField')}`,
      });
    }
  };

  const handleFieldChange = (value, dataIndex, record) => {
    if (value) {
      // Calculate the new total duration
      const newTotalDuration = agendaRows.reduce((total, row) => total + (row.Duration / 60 || 0), 0);
      setTotalDuration(newTotalDuration);

      const updatedRows = agendaRows.map((row) => {
        if (row.ID === record.ID) {
          return { ...row, [dataIndex]: value };
        }
        return row;
      });
      const updatedRow = updatedRows.find((row) => row.ID === record.ID);
      if (updatedRow) {
        updatedRow.Output = updatedRow.Input + updatedRow.Duration;
      }
      setAgendaRows(updatedRows);
      setFieldsChanged(true);
    }
  };

  const handleScopeChange = (e) => {
    setAgendaScope(e.target.value);
    setFieldsChanged(true);
  };

  const handleAgendaDurationChange = (value) => {
    setAgendaDuration(value);
    setFieldsChanged(true);
  };

  const handleAgendaFrequencyhange = (e) => {
    setAgendaFrequency(e.target.value);
    setFieldsChanged(true);
  };

  const validateAgendaFields = (values) => {
    if (
      values.scope === null ||
      values.scope === '' ||
      values.duration === null ||
      values.duration === '' ||
      (!isDefault && (values.frequency === null || values.frequency === ''))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const filterProjectOption = (inputValue, option) =>
    option.projectName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  const handleProjectSelectChange = (newTargetKeys) => {
    setTargetKeysProjects(newTargetKeys);
    setFieldsChanged(true);
  };

  const handleProjectRelatedSelectChange = (newTargetKeys) => {
    setTargetKeysRelatesProjects(newTargetKeys);
    setFieldsChanged(true);
  };

  const filterProjectRelatedOption = (inputValue, option) =>
    option.projectName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  const subComitiesRelatedProjectName = (project) => {
    return (
      <Text>
        <b>{project.committeeName}</b> - ({project.projectCode}) {project.projectName}
      </Text>
    );
  };

  function formatDuration(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60);

    if (hours === 0) {
      return `${minutes} min`;
    } else if (minutes === 0) {
      return `${hours} h`;
    } else {
      return `${hours} h ${minutes} min`;
    }
  }

  const ModalHeader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space direction="horizontal">
          <Title level={4}>{t('comitatiPage.defaultAgendda') + ':' + ` ${commitieData.name}`}</Title>
        </Space>

        <Space direction="horizontal">
          <Button
            loading={loadingSave}
            icon={<SaveOutlined />}
            type="primary"
            key="submit"
            disabled={!fieldsChanged || !formErrors.length < 1}
            onClick={() => saveAgendaData(false, false)}>
            {t('buttons.salva')}
          </Button>

          <Button
            loading={loadingSaveClose}
            type="primary"
            key="submitClose"
            disabled={!fieldsChanged || !formErrors.length < 1}
            onClick={() => saveAgendaData(false, true)}>
            {t('buttons.salvaChiudi')}
          </Button>

          <Button
            onClick={handleModalClose}
            key="close">
            {t('buttons.annulla')}
          </Button>
        </Space>
      </div>
    );
  };

  const handleCommentEditorChange = (editorState) => {
    setAgendaNotes(editorState);
    setFieldsChanged(true);
  };

  return (
    <>
      <div className="edit-commitie-wrapper">
        <Modal
          width={'90%'}
          // title={t('comitatiPage.defaultAgendda') + ':' + ` ${commitieData.name}`}
          title={<ModalHeader />}
          open={showModal}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={handleModalClose}
          closable={false}
          keyboard={false}
          footer={[
            <div
              className={isDefault ? '' : 'spaceBetween'}
              key={'1'}>
              {!isDefault && (
                <Button
                  loading={loadingSendMail}
                  icon={<SaveOutlined />}
                  type="primary"
                  key="submitEmail"
                  disabled={!formErrors.length < 1}
                  onClick={() => saveAgendaData(true, true)}>
                  {t('comitatiPage.saveAndSendEmail')}
                </Button>
              )}

              <Space direction="horizontal">
                <Button
                  loading={loadingSave}
                  icon={<SaveOutlined />}
                  type="primary"
                  key="submit"
                  disabled={!fieldsChanged || !formErrors.length < 1}
                  onClick={() => saveAgendaData(false, false)}>
                  {t('buttons.salva')}
                </Button>

                <Button
                  loading={loadingSaveClose}
                  type="primary"
                  key="submitClose"
                  disabled={!fieldsChanged || !formErrors.length < 1}
                  onClick={() => saveAgendaData(false, true)}>
                  {t('buttons.salvaChiudi')}
                </Button>

                <Button
                  onClick={handleModalClose}
                  key="close">
                  {t('buttons.annulla')}
                </Button>
              </Space>
            </div>,
          ]}>
          <Card>
            {!defaultAgenda && <Loader2 />}

            {defaultAgenda && agendaRows && (
              <>
                <Row
                  gutter={{ lg: 24 }}
                  className="row-margin">
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 18 }}
                    md={{ span: 18 }}
                    lg={{ span: 18 }}>
                    <Card
                      className="agendaCards"
                      title={t('comitatiPage.scopo') + ' *'}>
                      <TextArea
                        rows={4}
                        autoSize={true}
                        value={agendaScope}
                        onChange={(e) => {
                          handleScopeChange(e);
                        }}
                      />
                    </Card>
                  </Col>

                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 6 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}>
                    <Card
                      className="agendaCards"
                      title={t('comitatiPage.tempi') + ' & ' + t('comitatiPage.durataAgenda')}>
                      <Space
                        direction="vertical"
                        style={{ width: '100%' }}>
                        <Input
                          onChange={(e) => {
                            handleAgendaFrequencyhange(e);
                          }}
                          value={agendaFrequency}
                          addonBefore={!isDefault ? t('comitatiPage.tempi') + ' *' : t('comitatiPage.tempi')}
                        />
                        <p>{`${t('comitatiPage.durataAgenda')}: ${formatDuration(totalDuration * 60)}`}</p>
                      </Space>
                    </Card>
                  </Col>

                  <Divider />
                </Row>

                <Row
                  gutter={{ lg: 24 }}
                  className="row-margin">
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Button
                      onClick={handleAdd}
                      type="primary"
                      style={{ marginBottom: 16 }}>
                      {t('comitatiPage.addRow')}
                    </Button>
                  </Col>

                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Table
                      className="agendaTable"
                      components={components}
                      rowClassName={() => 'editable-row'}
                      bordered
                      size="small"
                      dataSource={agendaRows}
                      columns={agendaRowColumns}
                      rowKey={(obj) => obj.ID}
                      loading={loadingAgenda}
                      pagination={false}
                    />
                  </Col>

                  <Divider />
                </Row>

                <Row
                  gutter={{ lg: 24 }}
                  className="activities-filters">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    className="collapse-comitie-activites">
                    <Collapse expandIconPosition={'start'}>
                      <Panel
                        header={t('general.commenti')}
                        key="1"
                        // extra={
                        //     <>
                        //         <Tooltip title={t('buttons.salva')}>
                        //             <Button onClick={(event) => {
                        //                 event.stopPropagation();
                        //                 saveCommitieComment(false);
                        //             }}
                        //                 loading={loadingSaveComment}
                        //                 icon={<SaveOutlined />}
                        //                 style={{ marginRight: 10 }}
                        //                 type='primary'
                        //                 disabled={!comittieCommentChanged}
                        //             >
                        //             </Button>
                        //         </Tooltip>

                        //         {(comentEditiorValue && comentEditiorValue !== "") && (
                        //             <Popconfirm
                        //                 placement="bottomRight"
                        //                 key={2}
                        //                 title={t('comitatiPage.acrhciveComitieComment')}
                        //                 onConfirm={(event) => archiveComment(event)}
                        //                 onCancel={(event) => { event.stopPropagation(); }}
                        //                 okText={t('general.si')}
                        //                 cancelText={t('general.no')}
                        //             >
                        //                 <Tooltip title={t('comitatiPage.archive')}>
                        //                     <Button onClick={(event) => {
                        //                         event.stopPropagation();
                        //                     }}
                        //                         loading={loadingArchive}
                        //                         icon={<InboxOutlined />}
                        //                         type='dashed'
                        //                     >
                        //                     </Button>
                        //                 </Tooltip>
                        //             </Popconfirm>
                        //         )}
                        //     </>
                        // }
                      >
                        <Row gutter={{ lg: 24 }}>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 42 }}>
                            <BraftEditor
                              style={{ height: 350, overflowX: 'auto' }}
                              contentFormat="html"
                              language="en"
                              id="editor-text"
                              controls={richTectEditoControlls}
                              value={BraftEditor.createEditorState(agendaNotes)}
                              onChange={handleCommentEditorChange}
                            />
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>

                <Row
                  gutter={{ lg: 24 }}
                  className="row-margin agendaTransfer">
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Divider plain>{t('comitatiPage.comitieProjects')}</Divider>
                    <Transfer
                      targetKeys={targetKeysProjects}
                      rowKey={(record) => record.projectID}
                      showSelectAll={false}
                      dataSource={comitieRelatedProjects}
                      titles={[`${t('general.progetti')}`, `${t('general.selectedProjects')}`]}
                      showSearch
                      filterOption={filterProjectOption}
                      onChange={handleProjectSelectChange}
                      render={(item) => '(' + item.projectCode + ')' + ' ' + item.projectName}
                      locale={{
                        itemUnit: ' ',
                        notFoundContent: `${t('general.elencoVuoto')}`,
                        searchPlaceholder: `${t('buttons.cerca')}`,
                      }}
                    />
                  </Col>

                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Divider plain>{t('comitatiPage.relatedComitieProjects')}</Divider>
                    <Transfer
                      targetKeys={targetKeysRelatesProjects}
                      rowKey={(record) => record.projectID}
                      showSelectAll={false}
                      dataSource={subComitieRelatedProjects}
                      showSearch
                      titles={[`${t('comitatiPage.relatedComitieProjects')}`, `${t('general.selectedProjects')}`]}
                      filterOption={filterProjectRelatedOption}
                      onChange={handleProjectRelatedSelectChange}
                      render={(item) => subComitiesRelatedProjectName(item)}
                      locale={{
                        itemUnit: ' ',
                        notFoundContent: `${t('general.elencoVuoto')}`,
                        searchPlaceholder: `${t('buttons.cerca')}`,
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Modal>
      </div>
    </>
  );
}

export default AddDefaultAgendaModal;
