import { CalendarOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Avatar, Button, Menu, message, Tooltip, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CommitteeDto, CommitteeMemberDto } from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import { ArchiveStackIcon } from '../../../assets/icons';
import CommitieService from '../../../services/pages/commitieServices';
import { addQueryParam, getQueryParam } from '../../../utils/url-utils';
import ProjectFeed from '../../projects/projectForm/projectFeed/projectFeed';
import Loader from '../../shared/components/loader/loader';
import { checkUserIsLenovysOrCompanyAdmin } from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { feedObjectType, feedRegardingObjectType } from '../../shared/utils/constants';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import ActionPlan from './comitieActivities/activities';
import CalendarIndex from './comitieCalendar/index';
import ComitieProjects from './comitieProjects';
import ProjectRevision from './comitieSintesi/sintesi';
import ComitieSummary from './comitieSummary/comitieSummary';
import RelatedComities from './relatedComities';
import CommitieSessions from './sessions/session';

const ComitieDashboardIndex: FC = () => {
  const currentUser = useAppSelector((state) => state.userData.userData);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const history = useHistory();

  const [committee, setCommittee] = useState<CommitteeDto>();
  const [userHasAccess, setUserHasAccess] = useState(false);
  const [tabActiveKey, setTabActiveKey] = useState('1');

  useEffect(() => {
    setTabActiveKey(getQueryParam('tab') || '1');

    void getCommittee();
  }, [id]);

  const getCommittee = async () => {
    if (!id) {
      return;
    }

    try {
      const { data } = await CommitieService.getById(id);
      setCommittee(data);
      checkIfUserHasAccess(data.members ?? []);
    } catch {
      void message.error(notifyMessages.retrieveFailed);
    }
  };

  const checkIfUserHasAccess = (members: CommitteeMemberDto[]) => {
    const accessByRole = checkUserIsLenovysOrCompanyAdmin(currentUser);
    if (accessByRole) {
      setUserHasAccess(true);

      return;
    }

    setUserHasAccess(!!members.find((m) => m.userId === currentUser?.userId));
  };

  const redirectToProjectDasboard = (tabKey: { key: string }) => {
    if (tabKey.key === '5') {
      setTabActiveKey('1');
      let url = `/progettihoshinkanri/${id}`;
      window.open(url, '_blank');
    } else {
      setTabActiveKey(tabKey.key);
      addQueryParam('tab', tabKey.key);
    }
  };

  const renderComponent = () => {
    if (!committee) {
      return <></>;
    }

    switch (tabActiveKey) {
      case '1':
        return (
          <ComitieSummary
            comitieId={id}
            comitieDetails={committee}
            getCommitieById={getCommittee}
            currentUser={currentUser}
          />
        );
      case '2':
        return (
          <div className="tw-flex tw-flex-col tw-gap-4">
            <ComitieProjects comitieId={id} />
            <RelatedComities
              comitieId={id}
              userHasAccess={userHasAccess}
            />
          </div>
        );
      case '3':
        return (
          <CalendarIndex
            comitieId={id}
            comitieDetails={committee}
          />
        );
      case '4':
        return (
          <ActionPlan
            comitieId={id}
            comitieDetails={committee}
            userHasAccess={userHasAccess}
            reloadCommitie={getCommittee}
          />
        );
      case '5':
        return <></>;
      case '6':
        // verbali
        return (
          <ProjectFeed
            objectId={id}
            userData={currentUser}
            isProject={0}
            isCommitie={1}
            hasUserAccess={false}
            objectType={feedObjectType.commitieFeed}
            feedRegardingObjType={feedRegardingObjectType.commitie}
            showFeed={true}
          />
        );
      case '7':
        return (
          <ProjectRevision
            comitieId={id}
            comitieDetails={committee}
          />
        );
      case '8':
        return (
          <CommitieSessions
            comitieId={id}
            comitieDetails={committee}
            userHasAccess={userHasAccess}
            currentUser={currentUser}
          />
        );
      // case '9':
      //   return (
      //     <CommitieTeam
      //       comitieId={comitieId}
      //       comitieDetails={comitieDetails}
      //     />
      //   );
      default:
        return null;
    }
  };

  return (
    <>
      <Loader />
      {committee && (
        <div className="tw-flex tw-flex-col tw-overflow-hidden tw-absolute tw-inset-0 tw-size-full">
          <div className="tw-bg-white tw-border-2 tw-shrink-0 tw-gap-8 tw-border-b tw-flex tw-items-center tw-justify-between tw-min-h-6 tw-max-h-14 tw-py-2 tw-px-3">
            <div className="tw-flex tw-gap-2 tw-items-center tw-shrink-0">
              <Button
                onClick={() => history.goBack()}
                icon={<LeftOutlined />}></Button>

              <Typography.Text className="tw-text-xl tw-font-semibold"> {committee.name} </Typography.Text>
            </div>

            <div className="tw-flex tw-gap-4">
              {committee.members?.length && (
                <Avatar.Group
                  max={{
                    count: 4,
                  }}>
                  {committee.members.map((p) => (
                    <Tooltip
                      placement="bottom"
                      title={p.userFullName}>
                      <Avatar
                        className="tw-bg-primary"
                        key={p.userId}>
                        {p.userFullName?.at(0)}
                      </Avatar>
                    </Tooltip>
                  ))}
                </Avatar.Group>
              )}
              <div className="tw-flex tw-gap-2 tw-items-center">
                <CalendarOutlined />
                <div>
                  {committee.activitiesDuration}
                  {` `}gg
                </div>
              </div>
              {committee.repositoryLink && committee.repositoryLink !== '' && (
                <Tooltip title={t('comitatiPage.repositoryLink')}>
                  <Button
                    target="_blank"
                    href={committee.repositoryLink}
                    icon={<ArchiveStackIcon />}></Button>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="tw-flex tw-size-full tw-overflow-y-hidden">
            <Menu
              className="tw-min-h-full tw-p-2 tw-shrink-0"
              onClick={redirectToProjectDasboard}
              style={{ width: 250 }}
              defaultSelectedKeys={['1']}
              selectedKeys={[tabActiveKey]}>
              <Menu.Item key="1">{t('comitatiPage.overview')}</Menu.Item>
              {/* <Menu.Item
                key="9"
                className="tw-opacity-30 tw-pointer-events-none">
                {t('comitatiPage.agenda')}
              </Menu.Item> */}
              <Menu.Item key="8">{t('comitatiPage.sessions')}</Menu.Item>
              <Menu.Item key="4">{t('comitatiPage.actionPlan')}</Menu.Item>
              <Menu.Item key="7">{t('comitatiPage.projectSintesi')}</Menu.Item>
              <Menu.Item key="2">{t('general.progetti')}</Menu.Item>
              <Menu.Item key="3">{t('comitatiPage.projectCalendar')}</Menu.Item>
              <Menu.Item key="6">{t('feed.feed')}</Menu.Item>
              <Menu.Item key="5">
                <span className="tw-flex tw-gap-2 tw-items-center">
                  {t('dashboardProgettiPage.dashboardProgetti')}
                  <RightOutlined />
                </span>
              </Menu.Item>
              {/* <Menu.Item key="9">{t('comitatiPage.team')}</Menu.Item> */}
            </Menu>
            <div className="tw-size-full tw-p-8 tw-overflow-auto">{renderComponent()}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(ComitieDashboardIndex);
