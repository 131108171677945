import { message, Table, Tooltip } from 'antd';
import classNames from 'classnames';
import { Component } from 'react';
import ObLongService from '../../../../../services/matrix/obLongService';
import { statusClass } from '../../../../shared/utils/functions';
import { notifyMessages } from '../../../../shared/utils/notifyMessages';
import '../../../matrix.scss';

class LongObjectivesLevel2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      loadingButton: false,
      modalData: {},
    };
  }

  // open add new record modal
  toggleModal = (rowData) => {
    this.setState({ openModal: !this.state.openModal });
    this.setState({ modalData: rowData });
  };

  // handel modal close
  handleModalCancel = () => {
    this.setState({ openModal: false });
    this.setState({ loadingButton: false });
  };

  //handel update
  updateData = (values, id) => {
    this.setState({ loadingButton: true });
    values['longTermGoalID'] = id;
    const payload = values;
    ObLongService.updateObiettivoLungoxMartix(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.handleModalCancel();
          this.props.reloadMatrix();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  onRowSelect = (record, rowIndex) => {
    this.props.selectedRow(record, rowIndex);
  };

  columns = [
    {
      render: (text, record, index) => {
        return {
          children: (
            <div
              className={`tw-flex tw-px-1 matrix-row tw-h-[31px] matrix-row tw-items-center tw-justify-between ${classNames(
                {
                  'tw-bg-primary selected-row':
                    this.props.selection === 'primary' && this.props.selectedIds?.includes(record.longTermGoalID),
                  'tw-bg-primary/30 selected-row':
                    this.props.selection === 'secondary' && this.props.selectedIds?.includes(record.longTermGoalID),
                },
              )}`}>
              <div className="tw-flex tw-items-center tw-truncate">
                <div
                  className={`xMatrix-square-label ${statusClass(record.status)}`}
                  key={index}>
                  {record.orderingValue}
                </div>

                <Tooltip title={record.title}>
                  <p className="tw-text-sm tw-w-full tw-truncate">{record.title}</p>
                </Tooltip>
              </div>
            </div>
          ),
        };
      },
    },
  ];

  render() {
    const { longTermGoals } = this.props;

    return (
      <>
        <Table
          className="[&_table]:tw-h-full"
          dataSource={longTermGoals}
          pagination={false}
          bordered
          size="small"
          showHeader={false}
          columns={this.columns}
          rowKey={(obj) => 'xz' + obj.longTermGoalID}></Table>
      </>
    );
  }
}

export default LongObjectivesLevel2;
