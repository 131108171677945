import { Modal } from 'antd';
import React, { ReactNode, createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ConfirmOptions = {
  message: string;
  title?: string;
  okText?: string;
  cancelText?: string;
};

type ConfirmDialogContextType = {
  confirm: (options: ConfirmOptions) => Promise<boolean>;
};

const ConfirmDialogContext = createContext<ConfirmDialogContextType | null>(null);

export const ConfirmDialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();

  const [dialog, setDialog] = useState<ConfirmOptions | null>(null);
  const [resolveFn, setResolveFn] = useState<(value: boolean) => void>();

  const confirm = (options: ConfirmOptions): Promise<boolean> => {
    return new Promise((resolve) => {
      setDialog(options);
      setResolveFn(() => resolve);
    });
  };

  const handleResolve = (result: boolean) => {
    if (resolveFn) resolveFn(result);
    setDialog(null); // hide the dialog
  };

  return (
    <ConfirmDialogContext.Provider value={{ confirm }}>
      {children}
      {dialog && (
        <Modal
          title={dialog.title || t('general.confirm')}
          open={true}
          closable={false}
          keyboard={false}
          onOk={() => handleResolve(true)}
          onCancel={() => handleResolve(false)}
          okText={dialog.okText || t('buttons.conferma')}
          cancelText={dialog.cancelText || t('buttons.annulla')}>
          <p>{dialog.message}</p>
        </Modal>
      )}
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirm = (): ConfirmDialogContextType => {
  const context = useContext(ConfirmDialogContext);
  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmDialogProvider');
  }
  return context;
};
