import { Form } from 'antd';
import { Component } from 'react';

import {
  Card,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
  Typography,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import CommonService from '../../../../../services/commonService';
import ModalFooterButtons from '../../../../shared/components/modal/modalFooterButtons';
import { objectCodes, projectRoutineProgram } from '../../../../shared/utils/constants';
import { areDatesValid, showErrorNotification } from '../../../../shared/utils/functions';
import { requiredFields } from '../../../../shared/utils/notifyMessages';

const { Option } = Select;
const { Text } = Typography;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const weekDays = [
  { label: 'Monday', value: '1' },
  { label: 'Tuesday', value: '2' },
  { label: 'Wednesday', value: '3' },

  { label: 'Thursday', value: '4' },
  { label: 'Friday', value: '5' },
  { label: 'Saturday', value: '6' },
  { label: 'Sunday', value: '7' },
];

const weekOfDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const monthWeek = [
  { label: 'First', value: '9' },
  { label: 'Second', value: '10' },
  { label: 'Third', value: '11' },
  { label: 'Fourth', value: '12' },
];

class UpdateRoutineModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      users: null,
      loadingButton: false,
      feedTypes: null,
      selectedType: null,
      routineData: props.routineData,
      endDate: props.routineData.endDate,
      endDateProject: props.endDateProject,
      startDateProject: props.startDateProject,
      selectedStartDate: props.routineData.startDate,
      selectedMeetigProgram: props.routineData.schedule,
      dailySelectDays: null, //dayofweek
      frequence: null, //frequence
      // selectedUsers: props.routineData.extraUsers,
      selectedUsers: [],
      isMandatory: false,
      isFrozen: false,
    };
  }

  componentDidMount() {
    const { routineData } = this.props;
    this.retrieveRoutineOrganiser();
    this.retrieveRoutineScheduleType();

    if (routineData.extraUsers) {
      let extraUsersJson = JSON.parse(routineData.extraUsers);
      this.setState({ selectedUsers: extraUsersJson.map((item) => item.UserID) });
    }
  }

  async retrieveRoutineOrganiser() {
    const objectCode = objectCodes.routineOrganiser;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ routineOrganisers: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveRoutineScheduleType() {
    const objectCode = objectCodes.routineSchedule;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ routineScheduleType: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  onDurationChange = (value, startTime) => {
    if (value && startTime) {
      const { routineData } = this.state;
      let endTime = moment(startTime, 'HH:mm').add(value, 'hours').format('HH:mm');
      this.setState({
        routineData: {
          ...routineData,
          endTime: endTime,
          duration: value,
        },
      });
    } else {
      return;
    }
  };

  onStartTimeChange = (value, duration) => {
    if (value && duration) {
      const { routineData } = this.state;
      let endTime = moment(value, 'HH:mm').add(duration, 'hours').format('HH:mm');
      this.setState({
        routineData: {
          ...routineData,
          endTime: endTime,
          startTime: value,
        },
      });
    } else {
      return;
    }
  };

  onStartDateChange = (startDate) => {
    this.setState({ selectedStartDate: startDate });
  };

  onProgramSelect = (program) => {
    this.setState({ selectedMeetigProgram: program });
    const { routineData } = this.state;

    this.setState({
      routineData: {
        ...routineData,
        frequenceType: program,
        schedule: program,
      },
    });
  };

  onEndDateChange = (endDate) => {
    this.setState({ fieldsChanged: true });
    this.setState({ endDate: endDate });
  };

  integrateInTeamsChange(val) {
    this.setState({ integrateInTeams: val });
    var inOutLook = this.state.integrateInOutlook;
    if (val) {
      this.setState({ isMandatory: true });
    } else if (!val && !inOutLook) {
      this.setState({ isMandatory: false });
    }
  }

  integrateInOutLookChange(val) {
    this.setState({ integrateInOutlook: val });
    var inTeams = this.state.integrateInTeams;
    if (val) {
      this.setState({ isMandatory: true });
    } else if (!val && !inTeams) {
      this.setState({ isMandatory: false });
    }
  }

  toggleFreeze(val) {
    if (val === 2) {
      this.setState({ isFrozen: true });
    } else {
      this.setState({ isFrozen: false });
    }
  }

  disableEndDate = (current) => {
    const { selectedMeetigProgram, selectedStartDate } = this.state;
    const { endDateProject, startDateProject } = this.props;

    if (selectedMeetigProgram && selectedStartDate) {
      if (selectedMeetigProgram === projectRoutineProgram.daily) {
        let curr = moment(current).endOf('day');
        let startDate = moment(selectedStartDate).endOf('day');
        let endDate = moment(endDateProject).endOf('day');
        return curr < moment(startDate).add(1, 'days') - 1 || curr > endDate;
      } else if (selectedMeetigProgram === projectRoutineProgram.weekly) {
        let curr = moment(current).endOf('day');
        let startDate = moment(selectedStartDate).endOf('day');
        let endDate = moment(endDateProject).endOf('day');
        return curr < moment(startDate).add(1, 'week') || curr > endDate;
      } else if (selectedMeetigProgram === projectRoutineProgram.monthly) {
        let curr = moment(current).endOf('day');
        let startDate = moment(selectedStartDate).endOf('day');
        let endDate = moment(endDateProject).endOf('day');
        return curr < moment(startDate).add(1, 'month') || curr > endDate;
      } else {
        return;
      }
    }
  };

  disableStartDate = (current) => {
    const { endDateProject, startDateProject } = this.props;

    let curr = moment(current).endOf('day');
    let startDate = moment(startDateProject).endOf('day');
    let endDate = moment(endDateProject).endOf('day');
    return curr < startDate || curr > endDate;
  };

  onWeekOfRecurrenceSelect = (value) => {
    this.setState({ weekOfRecurrence: value });
  };

  onDailySelectDays = (value) => {
    if (value == '8') {
      this.setState({ dailySelectDays: 8 });
      this.setState({ frequence: 0 });
      const { routineData } = this.state;
      this.setState({
        routineData: {
          ...routineData,
          dayOfWeek: 8,
          frequence: 0,
        },
      });
    } else {
      this.setState({ frequence: value });
      this.setState({ dailySelectDays: 0 });
      const { routineData } = this.state;

      this.setState({
        routineData: {
          ...routineData,
          dayOfWeek: 0,
          frequence: value,
        },
      });
    }
  };

  onDayOfWeekRecurrenceSelect = (value) => {
    this.setState({ dailySelectDays: value });
    const { routineData } = this.state;

    this.setState({
      routineData: {
        ...routineData,
        dayOfWeek: value,
      },
    });
  };

  onFrequenceSelect = (value) => {
    this.setState({ frequence: value });
    const { routineData } = this.state;

    this.setState({
      routineData: {
        ...routineData,
        frequence: value,
      },
    });
  };

  checkValidityBeforeUpdate = (values, projectRoutineID, projectID, acivityData) => {
    const { t } = this.props;
    const { selectedUsers, routineData, endDate, selectedMeetigProgram, dailySelectDays, frequence } = this.state;

    values['endTime'] = routineData.endTime;
    values['endDate'] = endDate;
    values['schedule'] = selectedMeetigProgram;
    values['frequenceType'] = selectedMeetigProgram;
    values['frequence'] = frequence;
    values['dayOfWeek'] = dailySelectDays;
    values['outlookEventId'] = routineData.outlookEventId;
    values['extraUsersRoutine'] = selectedUsers;

    if (areDatesValid(values.startDate, values.endDate)) {
      this.props.updateRoutine(values, projectRoutineID, projectID, acivityData);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  onSelectedUser = (user) => {
    this.setState({ selectedUsers: user });
  };

  render() {
    const { users, showEditRoutineModal, handleUpdateRoutineModalClose, loadingButton, t } = this.props;
    const {
      routineOrganisers,
      routineScheduleType,
      endDate,
      routineData,
      selectedMeetigProgram,
      selectedStartDate,
      isMandatory,
    } = this.state;

    let unique = users.filter((a, i) => users.findIndex((s) => a.fullName === s.fullName) === i);
    let uniqueUsr = [];
    unique.map((item) => {
      uniqueUsr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
    });

    const objJson = JSON.parse(routineData.extraUsers);
    const filteredUsers = objJson ? objJson.filter((user) => user.IsDeleted === false) : [];
    var jsonExtra = filteredUsers?.map((el) => {
      return { userID: el.UserID.toLowerCase(), fullName: el.Name + ' ' + el.Surname };
    });

    return (
      <Modal
        className="add-critic-modal"
        width={800}
        title={t('proggetiPage.aggiornaRoutineProggeto')}
        open={showEditRoutineModal}
        destroyOnClose={true}
        onCancel={handleUpdateRoutineModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleUpdateRoutineModalClose}
            formId={'editProjectRoutine'}
          />,
        ]}>
        <Card className="overflow">
          <Form
            id="editProjectRoutine"
            layout="vertical"
            {...formItemLayout}
            // onFinish={this.checkDatesValidity}
            onFinish={(values) =>
              this.checkValidityBeforeUpdate(values, routineData.projectRoutineID, routineData.projectID, routineData)
            }
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  label={t('general.nome')}
                  name="name"
                  rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}
                  initialValue={routineData.name}>
                  <Input />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}>
                <Form.Item
                  label={t('proggetiPage.chi')}
                  name="who"
                  hasFeedback
                  //rules={ this.state.isMandatory? [{ required: true, message: requiredFields.required }] : ""}
                  initialValue={routineData.who}>
                  <Select
                    // disabled={true}
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    onChange={(value) => this.toggleFreeze(value)}>
                    {routineOrganisers &&
                      routineOrganisers.map((item) => (
                        <Option
                          value={item.objectCodeListID}
                          key={item.objectCodeListID}>
                          {item.description}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* users */}
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  name="extraUsers"
                  initialValue={jsonExtra ? jsonExtra.map((el) => el.userID) : []}>
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={t('general.utenti')}
                    onChange={(e) => {
                      this.onSelectedUser(e);
                    }}
                    options={uniqueUsr}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Row>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}>
                    <Form.Item
                      label="Crea riunione su Outlook"
                      name="integrateInOutlook"
                      valuePropName="checked"
                      initialValue={routineData.integrateInOutlook}>
                      <Switch
                        onClick={(val) => this.integrateInOutLookChange(val)}
                        disabled={this.state.isFrozen ? true : false}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}>
                    <Form.Item
                      label="Crea riunione su Teams"
                      name="integrateInTeams"
                      valuePropName="checked"
                      initialValue={routineData.integrateInTeams}>
                      <Switch
                        onClick={(val) => this.integrateInOutLookChange(val)}
                        disabled={this.state.isFrozen ? true : false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              {/* <Col className="gutter-row" xs={{ span: 12 }} sm={{ span: 5 }} md={{ span: 5 }} lg={{ span: 7 }}>
                                <Form.Item
                                    label={t('general.start')}
                                    name="startDate"
                                    rules={[{ required: true, message: requiredFields.required }]}
                                    initialValue={routineData.startDate ? moment(routineData.startDate) : routineData.startDate}
                                >
                                    <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY"  onChange={(value) => this.onStartDateChange(value)} disabledDate={(current) => this.disableStartDate(current)} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 12 }} sm={{ span: 5 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <Form.Item
                                    label={t('proggetiPage.oraInizio')}
                                    name="startTime"
                                    rules={[{ required: true, message: requiredFields.required }]}
                                    initialValue={routineData.startTime ? moment(routineData.startTime, "HH:mm") : routineData.startTime}
                                >

                                    <TimePicker style={{ width: "100%" }} format="HH:mm" onChange={(value) => this.onStartTimeChange(value, routineData.duration)} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 1 }} sm={{ span: 1 }} md={{ span: 1 }} lg={{ span: 1 }}>
                                <Divider type="vertical" style={{ height: "100%", margin: "0 2px" }} />
                            </Col>

                            <Col className="gutter-row" xs={{ span: 12 }} sm={{ span: 5 }} md={{ span: 4 }} lg={{ span: 5 }}>
                                <Form.Item
                                    label={`${t('proggetiPage.durata')} (h)`}
                                    name="duration"
                                    rules={[{ required: true, message: requiredFields.required }]}
                                    initialValue={routineData.duration}
                                >
                                    <InputNumber step=".5" style={{ width: "100%" }} onChange={(value) => this.onDurationChange(value, routineData.startTime)} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 1 }} sm={{ span: 1 }} md={{ span: 1 }} lg={{ span: 1 }}>
                                <Divider type="vertical" style={{ height: "100%", margin: "0 2px" }} />
                            </Col>

                            {/* <Col className="gutter-row" xs={{ span: 11 }} sm={{ span: 5 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <Form.Item
                                    label="Data Fine"
                                    // name="endDate"
                                    rules={[{ required: true, message: requiredFields.required }]}
                                    // initialValue={routineData.endDate ? moment(routineData.endDate) : routineData.endDate}
                                >
                                    <DatePicker value={endDate ? moment(endDate) : endDate} style={{ width: "100%" }} format="DD/MM/YYYY" disabled={true} />
                                </Form.Item>
                            </Col> */}

              {/* <Col className="gutter-row" xs={{ span: 12 }} sm={{ span: 5}} md={{ span: 5 }} lg={{ span: 5 }}>
                                <div className="ant-col ant-col-24 ant-form-item-label">
                                    <label className="ant-form-item" title={t('proggetiPage.oraFine')}>{t('proggetiPage.oraFine')}</label>
                                </div>
                                <TimePicker disabled={true} style={{ width: "100%" }} format="HH:mm" value={routineData.endTime ? moment(routineData.endTime, "HH:mm") : routineData.endTime} />
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <Form.Item
                                    label={t('proggetiPage.programma')}
                                    name="schedule"                                    
                                    hasFeedback
                                    rules={[{ required: true, message: requiredFields.required }]}
                                    initialValue={routineData.schedule}
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder={t('general.seleziona')}
                                        disabled={true}
                                        onSelect={(option)=>this.onProgramSelect(option)}
                                    >
                                        {routineScheduleType && (routineScheduleType.map(item =>
                                            <Option value={item.objectCodeListID} key={item.objectCodeListID}>{item.description}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 12 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <Form.Item
                                    label={t('general.end')}
                                    // name="endDate"
                                    rules={[{ required: true, message: requiredFields.required }]}
                                >
                                    <DatePicker 
                                    value={endDate ? moment(endDate) : endDate} 
                                    disabled={(selectedMeetigProgram && selectedStartDate) ? false : true} style={{ width: "100%" }} format="DD/MM/YYYY" 
                                    disabledDate={(current) => this.disableEndDate(current)}
                                    onChange={(value) => this.onEndDateChange(value)} />
                                </Form.Item>
                            </Col> */}
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <h4>{t('proggetiPage.appontmentTime')}</h4>
                <Row>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Form.Item
                      label={t('proggetiPage.oraInizio')}
                      name="startTime"
                      rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}
                      initialValue={
                        routineData.startTime ? moment(routineData.startTime, 'HH:mm') : routineData.startTime
                      }>
                      <TimePicker
                        style={{ width: '100%' }}
                        format="HH:mm"
                        onChange={(value) => this.onStartTimeChange(value, routineData.duration)}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Form.Item
                      label={t('proggetiPage.durata')}
                      name="duration"
                      rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}
                      initialValue={routineData.duration}>
                      <InputNumber
                        step=".5"
                        style={{ width: '100%' }}
                        onChange={(value) => this.onDurationChange(value, routineData.startTime)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Form.Item
                      label={t('proggetiPage.oraFine')}
                      rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}>
                      <TimePicker
                        disabled={true}
                        style={{ width: '100%' }}
                        format="HH:mm"
                        value={routineData.endTime ? moment(routineData.endTime, 'HH:mm') : routineData.endTime}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <br />
              {/* Recurrence pattern */}
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <h4>{t('proggetiPage.recurencePattern')}</h4>
                <Row>
                  <Col
                    xs={{ span: 6 }}
                    sm={{ span: 6 }}
                    md={{ span: 4 }}
                    lg={{ span: 4 }}>
                    <Form.Item
                      name="schedule"
                      rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}
                      initialValue={selectedMeetigProgram}
                      onChange={(value) => this.onProgramSelect(value.target.value)}>
                      <Radio.Group

                      // onChange={(value) => this.onProgramSelect( value.target.value)}
                      // value = {selectedMeetigProgram == 1 ? 1:selectedMeetigProgram == 2? 2: selectedMeetigProgram ==3?3 : 4}
                      >
                        <Space direction="vertical">
                          {/* <Radio key={1}   value={1}>Daily</Radio>
                            <Radio key= {2}  value={2}>Weekly</Radio>
                            <Radio key ={3}  value={3}>Monthly</Radio>
                            <Radio key={4}   value={4}>Yearly</Radio> */}
                          {routineScheduleType &&
                            routineScheduleType.map((item) => (
                              <Radio
                                key={item.objectCodeListID}
                                value={item.objectCodeListID}>
                                {item.description}
                              </Radio>
                            ))}
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  {/* Weeks */}
                  <Col
                    xs={{ span: 18 }}
                    sm={{ span: 18 }}
                    md={{ span: 20 }}
                    lg={{ span: 20 }}>
                    {this.state.selectedMeetigProgram === 1 ? (
                      <FormItem name="dayOfWeek">
                        <Radio.Group
                          onChange={(value) => this.onDailySelectDays(value.target.value)}
                          defaultValue={routineData.dayOfWeek === 8 ? '8' : '1'}>
                          <Space direction="vertical">
                            <Row
                              xs={{ span: 24 }}
                              md={{ span: 24 }}>
                              <Radio
                                value="1"
                                key={'1'}>
                                Every
                              </Radio>
                              <Form.Item
                                name="frequence"
                                style={{ marginBottom: '0px' }}>
                                <InputNumber
                                  onChange={(value) => this.onDailySelectDays(value)}
                                  defaultValue={routineData.frequence}
                                  min={1}
                                  max={7}
                                />
                                <Text style={{ right: '3px' }}>day(s)</Text>
                              </Form.Item>
                            </Row>
                            <Radio
                              value="8"
                              key={'8'}>
                              Every weekday
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </FormItem>
                    ) : null}

                    {this.state.selectedMeetigProgram === 2 ? (
                      <Form.Item
                        name="frequence"
                        rules={[
                          {
                            required: true,
                            message: requiredFields.required,
                          },
                        ]}>
                        <Row>
                          <Text style={{ marginRight: 5, marginTop: 4 }}>
                            <span style={{ color: 'red' }}>*</span> Recur every
                          </Text>
                          <InputNumber
                            defaultValue={routineData.frequence}
                            onChange={(value) => this.onFrequenceSelect(value)}
                          />
                          <Text style={{ marginLeft: 5, marginTop: 4 }}>weeks(s) on:</Text>
                          <Row>
                            <FormItem name="dayOfWeek">
                              <Radio.Group
                                defaultValue={
                                  routineData.dayOfWeek == 1
                                    ? '1'
                                    : routineData.dayOfWeek == 2
                                      ? '2'
                                      : routineData.dayOfWeek == 3
                                        ? '3'
                                        : routineData.dayOfWeek == 4
                                          ? '4'
                                          : routineData.dayOfWeek == 5
                                            ? '5'
                                            : routineData.dayOfWeek == 6
                                              ? '6'
                                              : '7'
                                }
                                onChange={(value) => this.onDayOfWeekRecurrenceSelect(value?.target.value)}>
                                <Space direction="horizontal">
                                  {weekDays.map((item, i) => (
                                    <Col
                                      xs={{ span: 8 }}
                                      md={{ span: 6 }}
                                      key={i}>
                                      <Radio
                                        key={i}
                                        value={item.value}>
                                        {item.label}
                                      </Radio>
                                    </Col>
                                  ))}
                                </Space>
                              </Radio.Group>
                            </FormItem>
                          </Row>
                        </Row>
                      </Form.Item>
                    ) : null}
                    {this.state.selectedMeetigProgram == 3 ? (
                      <Row>
                        <FormItem name="dayOfWeek">
                          <Radio.Group>
                            <Space direction="vertical">
                              <Radio value="1">
                                <Row
                                  xs={{ span: 24 }}
                                  md={{ span: 24 }}>
                                  Day
                                  <Form.Item
                                    style={{ marginBottom: '0px' }}
                                    name="dayOfWeek">
                                    <InputNumber
                                      onChange={(value) => this.onDayOfWeekRecurrenceSelect(value)}
                                      defaultValue={routineData.dayOfWeek}
                                    />
                                    <Text style={{ marginLeft: '3px' }}>of every</Text>
                                  </Form.Item>
                                  <FormItem name="frequence">
                                    <InputNumber
                                      onChange={(value) => this.onFrequenceSelect(value)}
                                      defaultValue={routineData.frequence}
                                    />
                                    <Text style={{ marginLeft: '3px' }}>month(s)</Text>
                                  </FormItem>
                                </Row>
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </FormItem>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
              </Col>
              <br />
              {/* Range of Recurrence */}
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <h4>{t('proggetiPage.rangeRequrence')}</h4>
                <Row gutter={{ md: 24 }}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Form.Item
                      label={t('general.start')}
                      name="startDate"
                      rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}
                      initialValue={routineData.startDate ? moment(routineData.startDate) : routineData.startDate}>
                      <DatePicker
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                        onChange={(value) => this.onStartDateChange(value)}
                        disabledDate={(current) => this.disableStartDate(current)}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Form.Item
                      label={t('general.end')}
                      // name="endDate"
                      rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}>
                      <DatePicker
                        value={endDate ? moment(endDate) : endDate}
                        disabled={selectedMeetigProgram && selectedStartDate ? false : true}
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                        disabledDate={(current) => this.disableEndDate(current)}
                        onChange={(value) => this.onEndDateChange(value)}
                      />
                    </Form.Item>
                  </Col>
                  {/* </Col> */}
                </Row>
              </Col>
              <br />
              <br />
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default UpdateRoutineModal;
