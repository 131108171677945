import { TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import { countOccurrences } from '../../utils/functions';
import { GetXMatrixPicklistResponseDto } from 'src/connectors/backend';
import { useCallback } from 'react';

export interface TreeXmatrixFilterProps {
  selectedXmatrixIds: string[];
  xmatrixPicklist: GetXMatrixPicklistResponseDto[];
  onTreeXmatrixChange: (value: string[]) => void;
}

interface XmatrixTreeNode {
  title: string;
  value: string;
  key: string;
  children?: XmatrixTreeNode[];
}

const TreeXmatrixFilter: React.FC<TreeXmatrixFilterProps> = (props) => {
  const { t } = useTranslation();

  const buildXmatrixTreeData = useCallback((xmatrixPicklist: GetXMatrixPicklistResponseDto[]) => {
    let result: XmatrixTreeNode[] = [];

    for (const xmatrix of xmatrixPicklist) {
      const xmatrixHkCode = xmatrix.hkCode as string;

      if (countOccurrences(xmatrixHkCode, '-') === 1) {
        result.push({
          value: xmatrix.xMatrixID,
          title: xmatrixHkCode,
          key: xmatrix.xMatrixID,
          children: []
        });
      } else {
        const lastDashIndex = xmatrixHkCode.lastIndexOf('-');
        const first = xmatrixHkCode.replaceAll(' ', '').substring(0, lastDashIndex - 2);

        const whereToInsert = result.find(r => r.title.replaceAll(' ', '') === first);
        whereToInsert?.children?.push({
          value: xmatrix.xMatrixID,
          key: xmatrix.xMatrixID,
          title: xmatrixHkCode
        });
      }
    }

    return result;
  }, []);

  return (
    <TreeSelect
        data-testid='xmatrixTreeFilter'
        value={props.selectedXmatrixIds}
        className={'tw-flex-nowrap tw-min-w-64'}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder={t('general.seleziona')}
        allowClear
        multiple
        treeNodeFilterProp='title'
        treeCheckable={true}
        onChange={props.onTreeXmatrixChange}
        treeData={buildXmatrixTreeData(props.xmatrixPicklist)}
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        maxTagCount={1}
        maxTagPlaceholder={(omittedValues: any[]) => {
          return `+${omittedValues.length}`
        }}
      />
  );
}

export default TreeXmatrixFilter;