import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { getQueryParam, hasQueryParam } from '../../utils/url-utils';
import './baseLayout.scss';
import AdminNavbar from './header/navbar';

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);

  const updateFullScreenMode = useCallback(() => {
    const isPresentationMode = hasQueryParam('presentationMode');
    const isFullScreenMode = getQueryParam('fullScreenMode') === 'true';

    if (isPresentationMode && isFullScreenMode) {
      setFullScreenMode(true);
      enterFullScreen();
    } else {
      setFullScreenMode(false);
      exitFullScreen();
    }
  }, []);

  const enterFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable full-screen mode: ${err.message}`);
      });
    }
  };

  const exitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error(`Error attempting to exit full-screen mode: ${err.message}`);
      });
    }
  };

  const handleQueryParamChange = useCallback(() => {
    updateFullScreenMode();
  }, [updateFullScreenMode]);

  useEffect(() => {
    updateFullScreenMode();

    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    const handleStateChange = () => handleQueryParamChange();

    window.addEventListener('popstate', handleQueryParamChange);

    window.history.pushState = (...args: any[]) => {
      originalPushState.apply(window.history, args as any);
      handleStateChange();
    };

    window.history.replaceState = (...args: any[]) => {
      originalReplaceState.apply(window.history, args as any);
      handleStateChange();
    };

    return () => {
      window.removeEventListener('popstate', handleQueryParamChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [handleQueryParamChange]);

  return (
    <div
      className={classNames('tw-h-screen tw-absolute tw-inset-0 tw-overflow-hidden tw-flex tw-flex-col', {
        '[&_>.ant-layout-header]:tw-hidden': fullScreenMode,
      })}>
      <AdminNavbar />
      <div className="tw-relative tw-overflow-y-auto tw-grow tw-w-full">{children}</div>
    </div>
  );
};

export default AppLayout;
