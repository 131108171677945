import { Form } from 'antd';
import { useEffect, useState } from 'react';

import { Card, Col, Modal, Row, Select } from 'antd';
import CommitieService from '../../../services/pages/commitieServices';
import ModalFooterButtons from '../../shared/components/modal/modalFooterButtons';
import { formItemLayout } from '../../shared/utils/constants';
import { requiredFields } from '../../shared/utils/notifyMessages';
const { Option } = Select;

function AddProjectToComitieModal({
  showModal,
  handleModalClose,
  t,
  addProjectToCommitie,
  loadingSave,
  comitieId,
  selectedXmatrix,
}) {
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [noComitiesProjects, setNoComitiesProjects] = useState(null);

  useEffect(() => {
    getNoComitieProjects();
  }, []);

  const getNoComitieProjects = () => {
    if (!selectedXmatrix) {
      return;
    }

    CommitieService.getNoComititeProjects(selectedXmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setNoComitiesProjects(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const saveData = (values) => {
    addProjectToCommitie(values.projectID, comitieId);
  };

  return (
    <div className="edit-commitie-wrapper">
      <Modal
        width={800}
        title={t('comitatiPage.aggiungiProgetto')}
        open={showModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!fieldsChanged}
            loadingButton={loadingSave}
            modalClose={handleModalClose}
            formId={'newProjectComitie'}
            key="newProjectComitie"
          />,
        ]}>
        <Card>
          <Form
            id="newProjectComitie"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => saveData(values)}
            onValuesChange={() => {
              setFieldsChanged(true);
            }}>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  className="main-container"
                  label={t('general.progetti')}
                  name="projectID"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}>
                  <Select
                    showSearch
                    allowClear
                    style={{ width: '100%' }}
                    loading={!noComitiesProjects}
                    placeholder={t('general.seleziona')}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {noComitiesProjects &&
                      noComitiesProjects.map((item) => (
                        <Option
                          value={item.projectID}
                          key={item.projectID}>
                          {item.projectName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    </div>
  );
}

export default AddProjectToComitieModal;
