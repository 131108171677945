import { ClockCircleOutlined, EyeOutlined, OrderedListOutlined, TableOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { useEffect, useLayoutEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UserDetailsRespose } from 'src/config/connectors';
import UsersService from '../../services/pages/usersService';
import { addQueryParam, getQueryParam } from '../../utils/url-utils.js';
import MyTodo from '../myActivities/myActivities';
import PersonalDashboard from '../personalDashbard/personalDashboard';
import XmatrixAnalysis from '../personalDashbard/xmatrixAnalysis';
import { validateGuid } from '../shared/utils/functions';
import UserTimePlanning from '../userTimePlanning/userTimePlanning';

const DEFAULT_TAB = '1';

const HomeDashboard = (props: { t: (key: string) => string; userData: UserDetailsRespose }) => {
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);
  const t = props.t;
  const { acid, company } = useParams<{
    acid?: string;
    company?: string;
  }>();

  useLayoutEffect(() => {
    setActiveTab(getQueryParam('tab') || DEFAULT_TAB);
  }, []);

  useEffect(() => {
    if (company && validateGuid(company)) {
      updateUserCompany(props.userData, company);
    }
  }, [company, props.userData]);

  const changeActiveTab = (activeKey: string) => {
    setActiveTab(activeKey);
    addQueryParam('tab', activeKey);
  };

  const updateUserCompany = async (user: UserDetailsRespose, companyId: string) => {
    const payload = {
      userID: user.userId,
      companyID: companyId,
    };

    if (user?.hasOwnProperty('userId')) {
      const lastLogInCompany = user.userCompanies?.find((item) => {
        return item.isLastLoggedInCompany === 1;
      });

      if (lastLogInCompany?.lastLoggedInCompany !== companyId.toLowerCase()) {
        try {
          const {
            data: { success },
          } = await UsersService.updateUserLogedInCompany(payload);

          if (success) {
            window.location.replace('/');
          }
        } catch {}
      }
    }
  };

  let tabsItems = [
    {
      label: (
        <div className="tw-flex tw-flex-row tw-gap-2">
          <EyeOutlined />
          {t('homepage.progettiComitati')}
        </div>
      ),
      key: '1',
      children: <PersonalDashboard onActiveTabChange={changeActiveTab} />,
    },
    {
      label: (
        <div className="tw-flex tw-flex-row tw-gap-2">
          <OrderedListOutlined />
          {t('homepage.myTodo')}
        </div>
      ),
      key: '2',
      children: (
        <MyTodo
          t={t}
          acid={acid}
        />
      ),
    },
    // {
    //   label: (
    //     <>
    //       <div className="tw-flex tw-flex-row tw-gap-2">{t('homepage.myProjectTodo')}</div>
    //     </>
    //   ),
    //   key: '3',
    //   children: (
    //     <TeamLeaderActivities
    //       t={t}
    //       acid={acid}
    //     />
    //   ),
    //   className: 'activities obietivo-anno-wrapper',
    // },
    {
      label: (
        <div className="tw-flex tw-flex-row tw-gap-2">
          <TableOutlined />
          {t('homepage.analisiXmatrix')}
        </div>
      ),
      key: '4',
      children: <XmatrixAnalysis />,
    },
    {
      label: (
        <div className="tw-flex tw-flex-row tw-gap-2">
          <ClockCircleOutlined />
          {t('homepage.timeReport')}
        </div>
      ),
      key: '5',
      children: <UserTimePlanning />,
    },
    // {
    //   label: <>{t('homepage.timeReportProgetti')}</>,
    //   key: '6',
    //   children: <ProjectTimePlanning />,
    // },
  ];

  return (
    <Tabs
      className="tw-px-4"
      activeKey={activeTab}
      items={tabsItems}
      destroyInactiveTabPane={true}
      onChange={(key) => {
        addQueryParam('tab', key);
        setActiveTab(key);
      }}></Tabs>
  );
};

const mapStateToProps = (state: { userData: { userData: UserDetailsRespose } }) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(HomeDashboard));
