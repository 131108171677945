import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { perStatusDeliverableChartOptions } from '../chartsConfig';

interface Phase {
  status: number;
}

interface PerStatusPhasesChartProps {
  phases: Phase[];
}

function createSeriesFromPhases(phases: Phase[]) {
  const statusMap: Record<number, string> = {
    1: 'Aperto',
    2: 'Chiuso',
    3: 'Draft',
    4: 'Sospeso',
  };

  const statusCount = phases.reduce(
    (acc, { status }) => {
      if (!acc[status]) {
        acc[status] = { count: 0, description: statusMap[status] };
      }
      acc[status].count += 1;
      return acc;
    },
    {} as Record<number, { count: number; description: string }>,
  );

  return Object.entries(statusCount).map(([status, { count }]) => ({
    name: statusMap[Number(status)],
    y: count,
  }));
}

const PerStatusPhasesChart: React.FC<PerStatusPhasesChartProps> = ({ phases }) => {
  const [perStatusChart, setPerStatusChart] = useState(perStatusDeliverableChartOptions);
  const { t } = useTranslation();

  useEffect(() => {
    setPerStatusChart((prevChart: any) => ({
      ...prevChart,
      series: [
        {
          name: t('general.phases'),
          data: createSeriesFromPhases(phases),
        },
      ],
    }));
  }, [phases, t]);

  return (
    <>
      {phases.length ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={perStatusChart}
        />
      ) : (
        <p>{t('general.noData')}</p>
      )}
    </>
  );
};

export default PerStatusPhasesChart;
