import { FolderOpenOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Form, Popover, Space, Table, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import KpiService from '../../../services/pages/kpiService';
import ProjectChildKpisDetailModal from '../../bowlingChart/projectKpiChildDetailsModal';
import { bowlingChartRowName } from '../../shared/utils/constants';
import {
  capitalizeFirstLetter,
  checkBowlingChartCellColor,
  convertCamelCaseToNormal,
  numberWithCommas,
} from '../../shared/utils/functions';

const { Text } = Typography;

const EditableCell = ({ dataIndex, title, inputType, record, index, children, ...restProps }) => {
  return <td {...restProps}>{children}</td>;
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  // const checkCellColor1 = (record, monthName, monthValue) => {
  //     let data = props.propsData;
  //     if (record.parentType === kpiPuntualeAcumulato.puntuale) {

  //         if (record.name === bowlingChartRowName.actual) {
  //             let targetRow = data.filter(function (item) { return item.name === bowlingChartRowName.target && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID })[0];

  //             if (record.type === kpiCalculationType.valoreMinimo) {
  //                 if (monthValue < targetRow.monthsList[monthName] || monthValue === targetRow.monthsList[monthName]) {
  //                     return greenColor
  //                 }
  //                 else if (monthValue > targetRow.monthsList[monthName]) {
  //                     return redColor
  //                 }
  //                 else {
  //                     return "#FFFFFF"
  //                 }
  //             }

  //             else {
  //                 if (monthValue > targetRow.monthsList[monthName] || monthValue === targetRow.monthsList[monthName]) {
  //                     return greenColor
  //                 }
  //                 else if (monthValue < targetRow.monthsList[monthName]) {
  //                     return redColor
  //                 }
  //                 else {
  //                     return "#FFFFFF"
  //                 }
  //             }

  //         }
  //         else {
  //             return;
  //         }
  //     }

  //     else if (record.parentType === kpiPuntualeAcumulato.acumulato) {

  //         if (record.name === bowlingChartRowName.actualYtd) {
  //             let targetYtdRow = data.filter(function (item) { return item.name === bowlingChartRowName.targetYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID })[0];

  //             if (record.type === kpiCalculationType.valoreMinimo) {

  //                 if (monthValue < targetYtdRow.monthsList[monthName] || monthValue === targetYtdRow.monthsList[monthName]) {
  //                     return greenColor
  //                 }
  //                 else if (monthValue > targetYtdRow.monthsList[monthName]) {
  //                     return redColor
  //                 }
  //                 else {
  //                     return "#FFFFFF"
  //                 }
  //             }
  //             else {
  //                 if (monthValue > targetYtdRow.monthsList[monthName] || monthValue === targetYtdRow.monthsList[monthName]) {
  //                     return greenColor
  //                 }
  //                 else if (monthValue < targetYtdRow.monthsList[monthName]) {
  //                     return redColor
  //                 }
  //                 else {
  //                     return "#FFFFFF"
  //                 }
  //             }
  //         }
  //         else {
  //             return;
  //         }

  //     }
  //     else return "#FFFFFF";
  // }

  const checkCellColor = (record, monthName, monthValue) => {
    let kpiData = props.propsData;
    let companyData = props.companyData;
    let companyKpiTolerance = companyData.kpiTolerance;
    let companyStartFromCurrentMonth = companyData.startFromCurrentMonth;

    let targetRow = kpiData.filter(function (item) {
      return (
        item.name === bowlingChartRowName.target && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID
      );
    })[0];
    let targetYtdRow = kpiData.filter(function (item) {
      return (
        item.name === bowlingChartRowName.targetYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID
      );
    })[0];
    let actualYtdRow = kpiData.filter(function (item) {
      return (
        item.name === bowlingChartRowName.actualYtd && item.kpiProjectRelationshipID === record.kpiProjectRelationshipID
      );
    })[0];

    let cellColor = checkBowlingChartCellColor(
      record,
      monthName,
      monthValue,
      kpiData,
      companyKpiTolerance,
      companyStartFromCurrentMonth,
      targetRow,
      targetYtdRow,
      actualYtdRow,
    );
    return cellColor;
  };

  const renderedColumns = () => {
    let columns = [
      {
        dataIndex: 'kpiDetailName',
        title: `${t('general.nome')} KPI`,
        fixed: 'left',
        width: '200px',
        editable: false,
        ellipsis: {
          showTitle: false,
        },
        render: (text, record, index) => {
          let val = (
            <Space direction="vertical">
              <Tooltip title={text}>{text}</Tooltip>
              <Popover
                content={
                  <Space direction="vertical">
                    <Text style={{ fontSize: 14 }}>
                      <span className="header-text"> {t('kpiPage.cumulatoPuntuale')}: </span>
                      <u>
                        <b>{record.parentTypeDescription}</b>
                      </u>
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      <span className="header-text"> {t('kpiPage.tipoCalcolo')}: </span>
                      <u>
                        <b>{record.typeDescription}</b>
                      </u>
                    </Text>
                  </Space>
                }
                placement="right"
                trigger="hover">
                <InfoCircleOutlined style={{ fontSize: 13 }} />
              </Popover>

              {record.isRollupKPI && (
                <Space direction="horizontal">
                  <Text style={{ color: '#12a52b' }}>{t('kpiPage.gerarchicho')}</Text>
                  <FolderOpenOutlined
                    style={{ color: '#1873a4' }}
                    type="primary"
                    onClick={() => props.handleOpenModal(record)}
                  />
                </Space>
              )}
            </Space>
          );
          const obj = {
            children: val,
            props: {},
          };
          if (index % 4 === 0) {
            obj.props.rowSpan = 4;
          } else {
            obj.props.rowSpan = 0;
          }
          return obj;
        },
      },
      {
        dataIndex: 'name',
        title: '',
        editable: false,
        width: '100px',
        ellipsis: {
          showTitle: false,
        },
        render(text, record) {
          return {
            props: {
              // style: { borderBottom: record.name === bowlingChartColumsValues.targetYTD ? "4px solid #2f4897" : "" }
            },
            children: (
              <Tooltip
                placement="topLeft"
                title={convertCamelCaseToNormal(text)}>
                <Text>{convertCamelCaseToNormal(text)}</Text>
              </Tooltip>
            ),
          };
        },
      },
    ];

    const tabelData = props.propsData;

    if (tabelData && tabelData.length > 0) {
      let firstRow = tabelData.find((element) => Object.keys(element.monthsList).length > 0);

      for (const item in firstRow.monthsList) {
        let currentMonth = moment().format('YYYY-MM');
        let monthName = moment(item).format('YYYY-MM');
        let month = moment(item).format('MMM YYYY');

        columns.push({
          align: 'right',
          dataIndex: monthName,
          title: capitalizeFirstLetter(month),
          editable: true,
          width: '100px',
          render(text, record) {
            return {
              props: {
                style: {
                  backgroundColor:
                    currentMonth > monthName || currentMonth === monthName
                      ? checkCellColor(record, item, record.monthsList[item])?.cellColor
                      : '',
                  color:
                    currentMonth > monthName || currentMonth === monthName
                      ? checkCellColor(record, item, record.monthsList[item])?.textColor
                      : '',
                },
              },
              children: <Text>{numberWithCommas(record.monthsList[item])}</Text>,
            };
          },
        });
      }

      return columns;
    }

    return columns;
  };

  const mergedColumns = renderedColumns().map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  return (
    <Form
      form={form}
      component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        size="small"
        dataSource={props.propsData}
        columns={mergedColumns}
        pagination={false}
        loading={props.loadingData}
        rowKey={(obj) => obj.key}
      />
    </Form>
  );
};

class KpiBowlingChartGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bowlingChartData: null,
      loadingData: false,
      bowlingChartDataParent: [],
      showChildKpiModal: false,
      modalData: null,
    };
  }

  componentDidMount() {
    this.getKPIPlanningDataByID();
  }

  getKPIPlanningDataByID = () => {
    const { kpiDetailId, projectId, annualId, year } = this.props;

    let projectIdValue = projectId && projectId !== '0' ? projectId : '';
    let anualIdValue = annualId && annualId !== '0' ? annualId : '';

    this.setState({ loadingData: true });
    KpiService.getKPIPlanningByID(projectIdValue, anualIdValue, year, kpiDetailId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;

          respData.map((item, index) => {
            item['key'] = index.toString();
          });

          let parentData = respData.filter((item) => {
            return item.parentKPIProjectRelationshipID === null;
          });

          respData.sort((a, b) => a.kpiDetailName.localeCompare(b.kpiDetailName));
          this.setState({ bowlingChartData: respData });

          parentData.sort((a, b) => a.kpiDetailName.localeCompare(b.kpiDetailName));
          this.setState({ bowlingChartDataParent: parentData });

          this.setState({ loadingData: false });
        } else {
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingData: false });
      });
  };

  handleModalClose = () => {
    this.setState({ showChildKpiModal: false });
  };

  togleKpiDetailModal = (kpiData) => {
    this.setState({ showChildKpiModal: true });
    this.setState({ modalData: kpiData });
  };

  render() {
    const { bowlingChartData, loadingData, bowlingChartDataParent, showChildKpiModal, modalData } = this.state;
    const { t, companyData } = this.props;

    return (
      <>
        {showChildKpiModal && (
          <ProjectChildKpisDetailModal
            showModal={showChildKpiModal}
            handleModalClose={this.handleModalClose}
            kpiModalData={modalData}
            bowlingChartData={bowlingChartData.filter((item) => {
              return item.parentKPIProjectRelationshipID === modalData.kpiProjectRelationshipID;
            })}
            loadingData={loadingData}
            t={t}
          />
        )}

        <EditableTable
          t={t}
          companyData={companyData}
          propsData={bowlingChartDataParent}
          loadingData={loadingData}
          handleOpenModal={this.togleKpiDetailModal}
        />
      </>
    );
  }
}

// export default KpiBowlingChartGraph;

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
  companyData: state.companyData.companyData,
});

export default connect(mapStateToProps)(KpiBowlingChartGraph);
