import { Form } from 'antd';
import React from 'react';

import { BookOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Input, InputNumber, Row, Select, Switch, message } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CommonService from '../../../services/commonService';
import DeliverablePageService from '../../../services/pages/deliverableService';
import DeliverableService from '../../../services/pages/projectServices/deliverableServices';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import { deliverableStatus, objectCodes, priority, statusPickListCodes } from '../../shared/utils/constants';
import { areDatesValid, showErrorNotification, updateNotificationBell } from '../../shared/utils/functions';
import { notifyMessages, requiredFields } from '../../shared/utils/notifyMessages';
import EndDateConfirmationModal from './endDateConfirmationModal';

const { TextArea } = Input;
const { Option } = Select;

class AddNewDeliverable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      fieldsChanged: false,
      usersPickList: null,
      projectPickList: null,
      deliverablePickList: null,
      responsiblePickList: null,
      statusList: null,
      priorityPicklist: null,
      hasParent: false,
      parentEndDate: null,
      selectedDeliverableData: null,
      selectedProjectData: null,
      selectedParentDeliverable: null,
      selectedResponsabile: null,
      showGenericResponsabileField: false,
      isGenericResponsabileField: false,
      deliverableDefaultStartDate: moment(),
      endDateConfirmationModal: false,
      formValues: null,
    };
  }

  componentDidMount = async () => {
    const projectId = this.props.match.params.projectId;
    const parentDelId = this.props.match.params.parentId;
    this.retrieveStatus();
    this.retrievePriority();
    this.retrieveProjectPickList();

    if (projectId) {
      this.retrieveDeliverablePickList(projectId);
      this.retrieveResponsibleUsersList(projectId);
      await this.getSelectedProjectData(projectId);
    }

    if (parentDelId) {
      this.setState({ selectedParentDeliverable: parentDelId });
      await this.getSelectedDeliverableData(parentDelId);
    }
  };

  async retrieveStatus() {
    const objectCode = statusPickListCodes.delivarbleStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrievePriority() {
    const objectCode = objectCodes.deliverablePriority;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ priorityPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve list of projects
  async retrieveProjectPickList() {
    await ProgettiService.getProjectPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ projectPickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // async retrieveDeliverablePickList(projectId) {
  //     await DeliverableService.getDeliverableProjectPicklist(projectId)
  //         .then(response => response.data)
  //         .then(resp => {
  //             if (resp.success) {
  //                 this.setState({ deliverablePickList: resp.responseObject.value })
  //             }
  //             else {
  //             }
  //         }).catch(error => {
  //         });
  // }

  async retrieveDeliverablePickList(projectId) {
    let deliverableId = '';
    await DeliverableService.getParentDeliverableProjectPicklist(projectId, deliverableId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ deliverablePickList: resp.responseObject.value });
        } else {
        }
      })
      .catch(() => {});
  }

  async retrieveResponsibleUsersList(projectId) {
    await CommonService.getResponsibleUserList(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          const usr = respData.map((item) => ({
            value: item.userID,
            label: item.fullName,
            key: item.userID,
            disabled: item.isDeleted,
          }));

          this.setState({ responsiblePickList: usr });
          console.log('🚀 ~ AddNewDeliverable ~ .then ~ usr:', usr);
        } else {
        }
      })
      .catch((error) => {});
  }

  handleDateChange(date) {
    if (date) {
      let formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  addData = (values, confirmationResponse) => {
    const parentDelId = this.props.match.params.parentId;
    const { selectedParentDeliverable, selectedResponsabile, isGenericResponsabileField, deliverableDefaultStartDate } =
      this.state;
    values['startDate'] = this.handleDateChange(deliverableDefaultStartDate);
    values['endDate'] = this.handleDateChange(values?.endDate);
    values['parentDeliverableID'] = selectedParentDeliverable;
    values['ownerID'] = selectedResponsabile;
    values['isGenericResponsabile'] = isGenericResponsabileField;
    if (!isGenericResponsabileField) {
      values['genericResponsible'] = '';
    }
    values['updateParentEndDate'] = confirmationResponse;

    this.setState({ loadingButton: true });
    DeliverablePageService.insertDeliverable(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateNotificationBell();
          message.success(notifyMessages.addSuccess);
          this.setState({ loadingButton: false });
          if (parentDelId) {
            this.props.history.push(`/deliverables/id/${parentDelId}`);
          } else {
            this.props.history.push('/deliverables');
          }
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  onProjectSelect = (option) => {
    this.setState({ selectedParentDeliverable: null });
    this.setState({ selectedResponsabile: null });
    this.setState({ responsiblePickList: null });
    this.setState({ showGenericResponsabileField: false });
    this.setState({ isGenericResponsabileField: false });
    this.retrieveDeliverablePickList(option);
    this.retrieveResponsibleUsersList(option);
    this.getSelectedProjectData(option);
    this.setState({ selectedDeliverableData: null });
  };

  onParentDeliverableChange = (option) => {
    if (option) {
      this.setState({ selectedParentDeliverable: option });
      this.getSelectedDeliverableData(option);
    }
  };

  async getSelectedDeliverableData(id) {
    const parentDelId = this.props.match.params.parentId;
    await DeliverablePageService.getDeliverableById(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedDeliverableData: resp.responseObject.value }, () => {
            this.setState({
              deliverableDefaultStartDate: resp.responseObject.value?.startDate
                ? moment(resp.responseObject.value?.startDate)
                : moment(),
            });
            if (parentDelId) {
              this.setState({ deliverableDefaultStartDate: moment(this.state.selectedDeliverableData?.startDate) });
            }
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getSelectedProjectData(id) {
    await ProgettiService.getParentProjectByID(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedProjectData: resp.responseObject.value });
          this.setState({
            selectedResponsabile: resp.responseObject.value.teamLeaderID
              ? resp.responseObject.value.teamLeaderID
              : null,
          });
          this.setState({
            deliverableDefaultStartDate: resp.responseObject.value?.startDate
              ? moment(resp.responseObject.value?.startDate)
              : moment(),
          });
        } else {
        }
      })
      .catch(() => {});
  }

  onReponsabileChange = (option) => {
    if (option) {
      this.setState({ selectedResponsabile: option });
    }
  };

  onDeliverablePadreClear = () => {
    this.setState({ selectedParentDeliverable: null });
    this.setState({ selectedDeliverableData: null });
  };

  onResponsabileClear = () => {
    this.setState({ selectedResponsabile: null });
  };

  disabledDateRange = (current) => {
    const { selectedDeliverableData, selectedProjectData } = this.state;

    // if(parentDelId){
    //     let curr = moment(current).endOf('day');
    //     let startDate = moment(selectedDeliverableData?.startDate).endOf('day');
    //     let endDate = moment(selectedDeliverableData?.endDate).endOf('day');
    //     return ((curr < startDate) || (curr > endDate))
    // }

    if (selectedProjectData && !selectedDeliverableData) {
      let curr = moment(current).endOf('day');
      let startDate = moment(selectedProjectData?.startDate).endOf('day');
      let endDate = moment(selectedProjectData?.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else if (selectedProjectData && selectedDeliverableData) {
      // if (selectedDeliverableData.parentDeliverableID) {
      //     let curr = moment(current).endOf('day');
      //     let startDate = moment(selectedDeliverableData.parentDeliverableStartDate).endOf('day');
      //     let endDate = moment(selectedDeliverableData.parentDeliverableEndDate).endOf('day');
      //     return ((curr < startDate) || (curr > endDate))
      // }
      // else {
      //     let curr = moment(current).endOf('day');
      //     let startDate = moment(selectedProjectData?.startDate).endOf('day');
      //     let endDate = moment(selectedProjectData?.endDate).endOf('day');
      //     return ((curr < startDate) || (curr > endDate))
      // }

      let curr = moment(current).endOf('day');
      let startDate = moment(selectedProjectData?.startDate).endOf('day');
      let endDate = moment(selectedProjectData?.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else {
      return;
    }
  };

  disableStartDateRange = (current) => {
    const parentDelId = this.props.match.params.parentId;
    const { selectedDeliverableData, selectedProjectData } = this.state;

    if (parentDelId) {
      let curr = moment(current).endOf('day');
      let startDate = moment(selectedDeliverableData?.startDate).endOf('day');
      let endDate = moment(selectedDeliverableData?.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    }

    if (selectedProjectData && !selectedDeliverableData) {
      let curr = moment(current).endOf('day');
      let startDate = moment(selectedProjectData?.startDate).endOf('day');
      let endDate = moment(selectedProjectData?.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else if (selectedProjectData && selectedDeliverableData) {
      if (selectedDeliverableData.parentDeliverableID) {
        let curr = moment(current).endOf('day');
        let startDate = moment(selectedDeliverableData.parentDeliverableStartDate).endOf('day');
        let endDate = moment(selectedDeliverableData.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else if (selectedDeliverableData) {
        let curr = moment(current).endOf('day');
        let startDate = moment(selectedDeliverableData?.startDate).endOf('day');
        let endDate = moment(selectedDeliverableData?.endDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        let curr = moment(current).endOf('day');
        let startDate = moment(selectedProjectData?.startDate).endOf('day');
        let endDate = moment(selectedProjectData?.endDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    } else {
      return;
    }
  };

  handleModalEndDateClsose = () => {
    this.setState({ endDateConfirmationModal: false });
  };

  toggleEndDateModal = (values) => {
    this.setState({ formValues: values }, () => {
      this.setState({ endDateConfirmationModal: true });
    });
  };

  checkDatesValidity = (values) => {
    let t = this.props.t;
    const { selectedResponsabile, selectedDeliverableData } = this.state;
    if (areDatesValid(values?.startDate, values?.endDate)) {
      if (selectedResponsabile) {
        if (!this.checkDeliverableEndDateValidity(selectedDeliverableData, values)) {
          this.toggleEndDateModal(values);
        } else {
          this.addData(values, false);
        }
      } else {
        showErrorNotification(`${t('notifications.generalError')}`, requiredFields.responsabileRequired);
      }
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  checkDeliverableEndDateValidity = (selectedDeliverableData, values) => {
    let selectedDate = values?.endDate;

    if (selectedDeliverableData) {
      // if (selectedDate > moment(selectedDeliverableData?.endDate)) {
      //     return false;
      // }
      // else { return true; }
      if (moment(selectedDeliverableData?.endDate) >= selectedDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  onGenericCheck = (checked) => {
    const { selectedProjectData, isGenericResponsabileField } = this.state;
    this.setState({ isGenericResponsabileField: !isGenericResponsabileField });
    if (checked) {
      this.setState({ showGenericResponsabileField: true });
      this.setState({ selectedResponsabile: selectedProjectData.teamLeaderID });
    } else {
      this.setState({ showGenericResponsabileField: false });
      this.setState({ selectedResponsabile: null });
    }
  };

  onStartDateChange = (date) => {
    this.setState({ deliverableDefaultStartDate: date });
  };

  handleConfirmResponse = (values, response) => {
    this.handleModalEndDateClsose();
    this.addData(values, response);
  };

  handleDeclineResponse = (values, response) => {
    const { selectedDeliverableData } = this.state;
    if (selectedDeliverableData) {
      values.endDate = this.handleDateChange(selectedDeliverableData?.endDate);
      this.handleModalEndDateClsose();
      this.addData(values, response);
    }
  };

  render() {
    const parentDelId = this.props.match.params.parentId;
    const projectId = this.props.match.params.projectId;

    const {
      loadingButton,
      responsiblePickList,
      statusList,
      priorityPicklist,
      projectPickList,
      deliverablePickList,
      selectedParentDeliverable,
      selectedResponsabile,
      showGenericResponsabileField,
      selectedProjectData,
      isGenericResponsabileField,
      deliverableDefaultStartDate,
      formValues,
    } = this.state;

    let t = this.props.t;

    return (
      <div className="edit-obietivo-anno-wrapper">
        {this.state?.endDateConfirmationModal && (
          <EndDateConfirmationModal
            showModal={this.state?.endDateConfirmationModal}
            handleModalClose={this.handleModalEndDateClsose}
            handleConfirmResponse={this.handleConfirmResponse}
            handleDeclineResponse={this.handleDeclineResponse}
            t={t}
            deliverableFormValues={formValues}
          />
        )}
        <div className="site-card-wrapper">
          {parentDelId && <span> {t('deliverablePage.aggiungiDeliverableChild')}</span>}
          {!parentDelId && <span> {t('general.addNew')}</span>}
          <Row className="site-card-links">
            <Col>
              {parentDelId && (
                <Link to={{ pathname: `/deliverables/id/${parentDelId}` }}>
                  <BookOutlined /> {t('deliverablePage.macroAttivita')}
                </Link>
              )}
            </Col>
          </Row>
        </div>
        <Card>
          <Form
            name="add_deliverable"
            layout="vertical"
            onFinish={this.checkDatesValidity}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}>
                <div className="button-wrapper">
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Button
                      loading={loadingButton}
                      type="primary"
                      htmlType="submit"
                      className="tw-float-end"
                      disabled={!this.state.fieldsChanged}>
                      <SaveOutlined />
                      {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row style={{ display: 'flex', justifyContent: 'center' }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 14 }}>
                <Card title={t('general.informazioniGenerali')}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Form.Item
                        label={t('general.nome')}
                        name="name"
                        rules={[{ required: true, message: requiredFields.required }]}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            label={t('general.progetto')}
                            name="projectID"
                            rules={[{ required: true, message: requiredFields.required }]}
                            hasFeedback
                            initialValue={projectId ? projectId : null}>
                            <Select
                              disabled={projectId ? true : false}
                              style={{ width: '100%' }}
                              placeholder={t('general.seleziona')}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onSelect={(option) => this.onProjectSelect(option)}>
                              {projectPickList &&
                                projectPickList.map((item) => (
                                  <Option
                                    value={item.projectID}
                                    key={item.projectID}>
                                    {item.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            label={t('deliverablePage.macroAttivita')}
                            hasFeedback>
                            <Select
                              disabled={parentDelId ? true : false}
                              value={parentDelId ? parentDelId : selectedParentDeliverable}
                              allowClear
                              style={{ width: '100%' }}
                              placeholder={t('general.seleziona')}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(option) => this.onParentDeliverableChange(option)}
                              onClear={() => this.onDeliverablePadreClear()}>
                              {deliverablePickList &&
                                deliverablePickList.map((item) => (
                                  <Option
                                    value={item.deliverableID}
                                    key={item.deliverableID}>
                                    {item.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}>
                          {selectedResponsabile && (
                            <Form.Item
                              name="owner"
                              label={t('general.responsabile')}
                              initialValue={selectedResponsabile}
                              required={true}>
                              <Select
                                value={selectedResponsabile}
                                placeholder={t('general.seleziona')}
                                style={{ width: '100%' }}
                                onChange={(option) => this.onReponsabileChange(option)}
                                onClear={() => this.onResponsabileClear()}
                                options={responsiblePickList}
                                showSearch
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          )}

                          {!selectedResponsabile && (
                            <Form.Item
                              name="owner"
                              label={t('general.responsabile')}
                              required={true}>
                              <Select
                                value={selectedResponsabile}
                                placeholder={t('general.seleziona')}
                                style={{ width: '100%' }}
                                onChange={(option) => this.onReponsabileChange(option)}
                                onClear={() => this.onResponsabileClear()}
                                options={responsiblePickList}
                                showSearch
                                filterOption={(input, option) =>
                                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          )}
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}>
                          {selectedProjectData && selectedProjectData.teamLeaderID && (
                            <Form.Item label={t('deliverablePage.isGenericResponsible')}>
                              <Switch
                                checked={isGenericResponsabileField}
                                value={isGenericResponsabileField}
                                onChange={(checked) => this.onGenericCheck(checked)}
                              />
                            </Form.Item>
                          )}
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}>
                          {showGenericResponsabileField && (
                            <Form.Item
                              label={t('deliverablePage.genericResponsabile')}
                              name="genericResponsible"
                              rules={[{ required: true, message: requiredFields.required }]}>
                              <Input />
                            </Form.Item>
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            label={t('general.stato')}
                            name="status"
                            hasFeedback
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={deliverableStatus.aperto}>
                            <Select
                              style={{ width: '100%' }}
                              placeholder={t('general.seleziona')}>
                              {statusList &&
                                statusList.map((item) => (
                                  <Option
                                    value={item.statusID}
                                    key={item.statusID}>
                                    {item.statusDescription}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            label={t('general.priorita')}
                            name="priority"
                            hasFeedback
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={priority.medium}>
                            <Select
                              style={{ width: '100%' }}
                              placeholder={t('general.seleziona')}>
                              {priorityPicklist &&
                                priorityPicklist.map((item) => (
                                  <Option
                                    value={item.objectCodeListID}
                                    key={item.objectCodeListID}>
                                    {item.description}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            label={t('general.start')}
                            rules={[{ required: true, message: requiredFields.required }]}
                            required={true}>
                            <DatePicker
                              value={deliverableDefaultStartDate}
                              style={{ width: '100%' }}
                              format="DD/MM/YYYY"
                              disabledDate={(current) => this.disableStartDateRange(current)}
                              onChange={(date) => this.onStartDateChange(date)}
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            label={t('general.end')}
                            name="endDate"
                            rules={[{ required: true, message: requiredFields.required }]}>
                            <DatePicker
                              style={{ width: '100%' }}
                              format="DD/MM/YYYY"
                              disabledDate={(current) => this.disabledDateRange(current)}
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            label={t('general.budget')}
                            name="budget"
                            initialValue={0}>
                            <InputNumber min={0} />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            label={t('deliverablePage.tag')}
                            name="tag"
                            initialValue={''}>
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            label={t('general.commenti')}
                            name="comment"
                            initialValue={''}>
                            <TextArea
                              rows={5}
                              allowClear
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            label={t('general.nota')}
                            name="notes"
                            initialValue={''}>
                            <TextArea
                              rows={5}
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

export default withTranslation()(AddNewDeliverable);
