import {
  BookOutlined,
  BorderOuterOutlined,
  CheckOutlined,
  CommentOutlined,
  DesktopOutlined,
  EuroOutlined,
  ExclamationOutlined,
  FileOutlined,
  FullscreenExitOutlined,
  NotificationOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import { Button, Menu, Tooltip } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import { useTranslation } from 'react-i18next';
import { history } from '../../../App';
import {
  AlarmIcon,
  CheckboxMultipleIcon,
  FileTextIcon,
  FocusLineIcon,
  LinksIcon,
  QuestionLineIcon,
  ScalesIcon,
  SettingsIcon,
  SpeedometerIcon,
  SpeedometerLineIcon,
  StackShareIcon,
  TodoIcon,
} from '../../../assets/icons';
import CommonService from '../../../services/commonService';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import UsersService from '../../../services/pages/usersService';
import { addFragment, addQueryParam, getQueryParam, hasQueryParam, removeFragment } from '../../../utils/url-utils';
import A3Index from '../../a3/a3';
import Section2 from '../../a3/section2/section2';
import { hasUserProjectCostAccess } from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { feedObjectType, feedRegardingObjectType, objectTypeUserSecurity } from '../../shared/utils/constants';
import { NoAccessMesssage } from '../../shared/utils/functions';
import '../project.scss';
import ProjectDataHeader from '../projectDataHeader';
import ProjectGoalEditor from '../projectGoalEditor/goalEditor';
import ReasonToAct from '../projectReasonToAct/reasonToAct';
import ChildProjects from './childProjects';
import ProjectKpi from './deliverablesAndKpi/projectKpi';
import ProjectDeliverable from './deliverablesAndKpi/projectReviewSystem';
import SettingsAndTeam from './mainInfo/settingsAndTeam';
import ProjectActivities from './projectActivities/projectActivities';
import ProjectCosts from './projectCosts/projectCost';
import ProjectFeed from './projectFeed/projectFeed';
import ProjectFiles from './projectFiles/projectFiles';
import ProjectObjectivesIndex from './projectObjectives/index';
import ProjectResourceWorkload from './projectResourceWorload/projectResourceWorkload';
import RiskAndPrecautions from './riskAndPrecaution/riskAndPrecaution';

import AttivitaSvolte from '../../a3/section3/attivitaSvolte';

import PresentationBase from '../../a3/presentationMode/presentationBase';

import KpiDashboard from '../../kpiDashboard/kpiDashboard';
import ProjectMeetings from './projectMeetings/projectMeetings';

import { useEffect, useState } from 'react';
import FontSizeChanger from 'react-font-size-changer';
import { useParams } from 'react-router-dom';
import { getProjectById } from 'src/redux/slices/projectSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';

const ProjectIndex = (props) => {
  const userData = useAppSelector((state) => state.userData.userData);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentProject = useAppSelector((state) => state.project.currentProject);
  const companyData = useAppSelector((state) => state.companyData.companyData);

  const { id, cmpid } = useParams();

  const [activeTab, setActiveTab] = useState(10.1);
  const [checkUserAccessOnObject, setCheckUserAccessOnObject] = useState(null);
  const [projectCostUserAccessSP, setProjectCostUserAccessSP] = useState(null);
  const [projectCostUserAccess, setProjectCostUserAccess] = useState(null);
  const [projectTeamMembers, setProjectTeamMembers] = useState([]);
  const [projectTeamMembersList, setProjectTeamMembersList] = useState([]);
  const [isPresentationMode, setIsPresentationMode] = useState(false);
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [dynamicFontClass, setDynamicFontClass] = useState('dynaFontSize20');
  const [dynamicFontSize, setDynamicFontSize] = useState(20);
  const [hasUserAccess, setHasUserAccess] = useState(null);

  useEffect(() => {
    if (!id) {
      history.push('/progetti');
    }

    getUserProjectCostAccess(id);
    getProjectData(id);
    getTeamMembers(id);
    checkIfUserHasAccess(id);

    if (hasQueryParam('presentationMode') && getQueryParam('presentationMode') === 'true') {
      setIsPresentationMode(true);
      setActiveTab('15');
      addQueryParam({ activeTab: '15' });
    }

    if (hasQueryParam('tab')) {
      setActiveTab(getQueryParam('tab') || '10.1');
    } else {
      // addQueryParam('tab', 10.1);
      setActiveTab('10.1');
    }

    const sectionFragment = props.location.hash.slice(1);
    if (sectionFragment) {
      setTimeout(() => {
        const section = document.getElementById(sectionFragment);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
        }
      }, 1000);
    }

    if (cmpid && cmpid.length > 2) {
      updateUserCompnay(id, cmpid);
    }
    if (cmpid && cmpid.length <= 2) {
      setActiveTab(cmpid);
    }
  }, [id, cmpid]);

  useEffect(() => {
    if (currentProject && userData) {
      const access = hasUserProjectCostAccess(
        userData,
        currentProject.teamLeaderID,
        currentProject.sponsorID,
        projectCostUserAccessSP,
        projectTeamMembers,
      );

      setProjectCostUserAccess(access);
    }
  }, [currentProject, userData]);

  const getTeamMembers = (id) => {
    if (projectTeamMembersList.length > 0) {
      return;
    }

    ProgettiService.getProjectTeamMembers(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          let usr = [];
          setProjectTeamMembers(respData.map((a) => a.userID));

          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });
          setProjectTeamMembersList(usr);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getUserProjectCostAccess = async (projectId) => {
    try {
      const response = await ProgettiService.getProjectCostUserAccess(projectId);
      const resp = response.data;
      if (resp.success) {
        setProjectCostUserAccessSP(resp.responseObject.value.hasAccess === 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectData = async (id) => {
    dispatch(getProjectById({ projectId: id }));
  };

  const updateUserCompnay = (id, companyId) => {
    let payload = {};
    payload['userID'] = userData.userId;
    payload['companyID'] = companyId;

    if (userData !== undefined && userData.hasOwnProperty('userId')) {
      let lastLogInCompany = userData.userCompanies.filter((item) => {
        return item.isLastLoggedInCompany === 1;
      });

      if (lastLogInCompany[0].lastLoggedInCompany !== companyId.toLowerCase()) {
        UsersService.updateUserLogedInCompany(payload)
          .then((response) => response.data)
          .then((resp) => {
            if (resp.success) {
              window.location.replace(`/progetti/id/${id}/${companyId}`);
              dispatch(getProjectById({ projectId: id }));
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  };

  const checkIfUserHasAccess = async (objectId) => {
    const obj = {
      type: objectTypeUserSecurity.project,
      objectID: objectId,
    };

    try {
      const response = await CommonService.chaeckUserAccessOnObject(obj);
      const { success, responseObject } = response.data;

      if (success) {
        const { value } = responseObject;
        setCheckUserAccessOnObject(value.hasPrivilege === 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuClick = (e) => {
    const { key } = e;

    addQueryParam('tab', key);
    setActiveTab(key);
    if (key.includes('.')) {
      addFragment(key);

      setTimeout(() => {
        const section = document.getElementById(key);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }, 500);
    } else {
      removeFragment();
    }
  };

  const handlePresentationMode = () => {
    addQueryParam('presentationMode', !isPresentationMode);
    setIsPresentationMode(!isPresentationMode);
    setActiveTab('15');
  };

  const handleFullScreenMode = () => {
    addQueryParam('fullScreenMode', !fullScreenMode);
    setFullScreenMode(!fullScreenMode);
  };

  const renderComponent = () => {
    switch (activeTab) {
      case '2':
        return (
          <ProjectDeliverable
            hasUserAccess={hasUserAccess}
            currentProject={currentProject}
            isPresentationMode={isPresentationMode}
          />
        );

      case '3':
        return (
          <RiskAndPrecautions
            projectId={currentProject.projectID}
            hasUserAccess={hasUserAccess}
          />
        );

      case '4':
        return (
          <ProjectActivities
            userData={userData}
            t={t}
            projectId={currentProject.projectID}
            projectName={currentProject.name}
            currentProject={currentProject}
            hasUserAccess={hasUserAccess}
          />
        );

      case '5':
        return (
          <ProjectResourceWorkload
            userData={userData}
            t={t}
            hasUserAccess={hasUserAccess}
            projectId={currentProject.projectID}
            projectName={currentProject.name}
            reloadProject={() => dispatch(getProjectById({ projectId: currentProject.projectID }))}
          />
        );

      case '6':
        return (
          <ChildProjects
            t={t}
            projectId={currentProject.projectID}
            projectName={currentProject.name}
          />
        );

      case '7':
        return (
          <ProjectCosts
            t={t}
            projectCostUserAccessProp={projectCostUserAccess}
            hasUserAccess={hasUserAccess}
            projectId={currentProject.projectID}
            projectName={currentProject.name}
            currentProject={currentProject}
            reloadProject={() => dispatch(getProjectById({ projectId: currentProject.projectID }))}
          />
        );

      case '8':
        return (
          <ProjectKpi
            isPresentationMode={isPresentationMode}
            projectTeamMembers={projectTeamMembersList}
            t={t}
            hasUserAccess={hasUserAccess}
            currentProject={currentProject}
            projectId={currentProject.projectID}
            projectName={currentProject.name}
            history={history}
          />
        );

      case '10':
      case '10.1':
      case '10.2':
      case '10.3':
      case '10.4':
        return <A3Index id={currentProject.projectID} />;

      case '11':
        return (
          <ProjectFeed
            objectId={currentProject.projectID}
            userData={userData}
            isProject={1}
            hasUserAccess={hasUserAccess}
            objectType={feedObjectType.projectFeed}
            feedRegardingObjType={feedRegardingObjectType.project}
            showFeed={true}
          />
        );

      case '12':
        return (
          <ProjectFiles
            userData={userData}
            defaultCheckedFeedType={3}
            feedRegardingObjType={feedRegardingObjectType.project}
            objectId={currentProject.projectID}
            hasUserAccess={hasUserAccess}
            t={t}
            projectId={currentProject.projectID}
            currentProject={currentProject}
          />
        );

      case '13':
        return (
          <SettingsAndTeam
            t={t}
            projectId={currentProject.projectID}
            currentProject={currentProject}
            reloadProject={() => dispatch(getProjectById({ projectId: currentProject.projectID }))}
            hasUserAccess={hasUserAccess}
          />
        );

      case '14':
        return (
          <ProjectObjectivesIndex
            t={t}
            projectId={currentProject.projectID}
            currentProject={currentProject}
            hasUserAccess={hasUserAccess}
          />
        );

      case '15':
        return (
          <ReasonToAct
            key={'groundToAct'}
            currentProject={currentProject}
            isPresentationMode={true}
          />
        );

      case '16':
        return (
          <ProjectGoalEditor
            isEditable={!hasUserAccess}
            isPresentationMode={true}
            key={'projectGoal'}
            currentProject={currentProject}
          />
        );

      case '18':
        return (
          <Section2
            className="presTable"
            currentProject={currentProject}
            t={t}
            id={currentProject.projectID}
            hasUserAccess={hasUserAccess}
            isPresentationMode={true}
            dynaFontClass={dynamicFontClass}
            userData={userData}
            showGantt={true}
            showPrs={false}
          />
        );

      case '19':
        return (
          <ProjectDeliverable
            isPresentationMode={true}
            userData={userData}
            t={t}
            hasUserAccess={hasUserAccess}
            currentProject={currentProject}
            projectId={currentProject.projectID}
            projectName={currentProject.name}
            history={history}
          />
        );

      case '20':
        return (
          <AttivitaSvolte
            t={t}
            projectId={currentProject.projectID}
            isPresentationMode={true}
            companyData={companyData}
            dynaFontClass={dynamicFontClass}
          />
        );

      case '21':
        return (
          <KpiDashboard
            isPresentationMode={true}
            presentationProjectId={currentProject.projectID}
          />
        );

      case '22':
        return <PresentationBase takeAsBlackBox="tab8" />;

      case '23':
        return (
          <RiskAndPrecautions
            className="sub700-pres-card-body"
            t={t}
            projectId={currentProject.projectID}
            isPresentationMode={true}
            dynaFontClass={dynamicFontClass}
          />
        );

      case '24':
        return <PresentationBase takeAsBlackBox="tab10" />;

      case '25':
        return (
          <ProjectFiles
            isPresentationMode={true}
            t={t}
            feedRegardingObjType={feedRegardingObjectType.file}
            projectId={currentProject.projectID}
            currentProject={currentProject}
          />
        );

      case '26':
        return (
          <ProjectFeed
            isPresentationMode={true}
            dynaFontClass={dynamicFontClass}
            objectId={currentProject.projectID}
            userData={userData}
            isProject={1}
            hasUserAccess={hasUserAccess}
            objectType={feedObjectType.projectFeed}
            feedRegardingObjType={feedRegardingObjectType.feed}
            showFeed={false}
          />
        );

      case '27':
        return (
          <ProjectMeetings
            objectId={currentProject.projectID}
            userData={userData}
            isProject={1}
            hasUserAccess={hasUserAccess}
            objectType={feedObjectType.projectFeed}
            feedRegardingObjType={feedRegardingObjectType.meeting}
            showFeed={true}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {currentProject && (
        <>
          {!checkUserAccessOnObject && <NoAccessMesssage />}

          {checkUserAccessOnObject && (
            <div className="tw-flex tw-flex-col tw-overflow-hidden tw-absolute tw-inset-0 tw-size-full">
              <ProjectDataHeader
                t={t}
                id={currentProject.projectID}
                presentationModeTemplate={
                  <>
                    <div>
                      {!isPresentationMode && (
                        <Button
                          onClick={handlePresentationMode}
                          icon={<DesktopOutlined className="editIcon printIcon" />}
                          type="primary">
                          {t('a3Page.presenta')}
                        </Button>
                      )}

                      {isPresentationMode && (
                        <div className="tw-flex tw-items-center tw-gap-2">
                          <Tooltip
                            placement="bottom"
                            title={t('a3Page.fullscreenPage')}>
                            <Button
                              type="dashed"
                              onClick={handleFullScreenMode}
                              icon={<BorderOuterOutlined />}></Button>
                          </Tooltip>

                          <FontSizeChanger
                            targets={['.presContent']}
                            onChange={(element, newValue, oldValue) => {
                              setDynamicFontClass('dynaFontSize' + newValue.toString());
                              setDynamicFontSize(newValue);
                            }}
                            options={{
                              stepSize: 2,
                              range: 8,
                            }}
                            customButtons={{
                              up: (
                                <Button
                                  disabled={dynamicFontSize < 36 ? false : true}
                                  shape="circle">
                                  A+
                                </Button>
                              ),
                              down: (
                                <Button
                                  disabled={dynamicFontSize > 20 ? false : true}
                                  shape="circle">
                                  A-
                                </Button>
                              ),
                              style: {
                                border: 'none',
                                WebkitBoxSizing: 'none',
                              },
                            }}
                          />

                          <Tooltip
                            placement="bottom"
                            title={t('a3Page.chiuderePresentazioneA3')}>
                            <Button
                              type="primary"
                              icon={<FullscreenExitOutlined />}
                              onClick={() => {
                                addQueryParam('presentationMode', false);

                                setIsPresentationMode(false);
                                setActiveTab('10.1');
                              }}>
                              Esci
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </>
                }
                isPresentationMode={isPresentationMode}
                currentProjectData={currentProject}
                location={'project'}
              />

              <div className="tw-flex tw-size-full tw-overflow-y-hidden">
                <Menu
                  mode="inline"
                  className="tw-min-h-full tw-overflow-y-auto tw-p-2 tw-shrink-0"
                  onClick={handleMenuClick}
                  defaultOpenKeys={['10']}
                  defaultActiveFirst
                  style={{ width: 250 }}
                  defaultSelectedKeys={[activeTab]}
                  selectedKeys={[activeTab]}>
                  {!isPresentationMode && (
                    <SubMenu
                      key="10"
                      title={
                        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                          <SpeedometerIcon />
                          {t('proggetiPage.a3')}
                        </div>
                      }>
                      <Menu.Item key="10.1">{t('proggetiPage.obiettivo')}</Menu.Item>
                      <Menu.Item key="10.2">{t('proggetiPage.masterplan')}</Menu.Item>
                      <Menu.Item key="10.3">{t('proggetiPage.implementazione')}</Menu.Item>
                      <Menu.Item key="10.4">{t('proggetiPage.review')}</Menu.Item>
                    </SubMenu>
                  )}
                  {isPresentationMode && (
                    <>
                      <Menu.Item
                        key="15"
                        className="with-icon-title">
                        <QuestionLineIcon />
                        {t('proggetiPage.motivoAgire')}
                      </Menu.Item>

                      <Menu.Item
                        key="16"
                        className="with-icon-title">
                        <FocusLineIcon />
                        {t('proggetiPage.expectedResult')}
                      </Menu.Item>

                      {/* <Menu.Item
                          key="17"
                          className="with-icon-title">
                          <TeamIcon />
                          {t('proggetiPage.team')}
                        </Menu.Item> */}

                      <Menu.Item
                        key="8"
                        className="with-icon-title">
                        <SpeedometerLineIcon /> {t('general.kpi')}
                      </Menu.Item>

                      <Menu.Item
                        key="18"
                        className="with-icon-title">
                        <TodoIcon />
                        {t('proggetiPage.masterplan')}
                      </Menu.Item>

                      <Menu.Item
                        key="19"
                        className="with-icon-title">
                        <CheckboxMultipleIcon /> {t('proggetiPage.projectReviewSystem')}
                      </Menu.Item>

                      <Menu.Item
                        key="20"
                        className="with-icon-title">
                        <CheckOutlined /> {t('proggetiPage.attivitaSvolte')}
                      </Menu.Item>

                      <Menu.Item
                        key="21"
                        className="with-icon-title">
                        <SpeedometerIcon /> {t('proggetiPage.kpiStatus')}
                      </Menu.Item>

                      <Menu.Item
                        key="22"
                        className="with-icon-title">
                        <OrderedListOutlined /> {t('proggetiPage.prossimiPassi')}
                      </Menu.Item>

                      <Menu.Item
                        key="23"
                        className="with-icon-title">
                        <ExclamationOutlined /> {t('proggetiPage.criticitaRischi')}
                      </Menu.Item>

                      <Menu.Item
                        key="24"
                        className="with-icon-title">
                        <BookOutlined /> {t('proggetiPage.hensei')}
                      </Menu.Item>

                      <Menu.Item
                        key="25"
                        className="with-icon-title">
                        <FileOutlined /> {t('proggetiPage.file')}
                      </Menu.Item>

                      <Menu.Item
                        key="26"
                        className="with-icon-title">
                        <CommentOutlined /> {t('general.commenti')}
                      </Menu.Item>
                    </>
                  )}

                  {!isPresentationMode && (
                    <>
                      <Menu.Item
                        key="14"
                        className="with-icon-title">
                        <LinksIcon />
                        {t('proggetiPage.obiettiviCollegati')}
                      </Menu.Item>

                      <Menu.Item
                        key="2"
                        className="with-icon-title">
                        <CheckboxMultipleIcon /> {t('proggetiPage.projectReviewSystem')}
                      </Menu.Item>

                      <Menu.Item
                        key="8"
                        className="with-icon-title">
                        <SpeedometerLineIcon /> {t('general.kpi')}
                      </Menu.Item>

                      <Menu.Item
                        key="3"
                        className="with-icon-title">
                        <AlarmIcon />
                        {t('proggetiPage.criticitaRischi')}
                      </Menu.Item>
                      <Menu.Item
                        key="4"
                        className="with-icon-title">
                        <TodoIcon /> {t('proggetiPage.toDoProject')}
                      </Menu.Item>
                      <Menu.Item
                        key="5"
                        className="with-icon-title">
                        <ScalesIcon /> {t('proggetiPage.caricoRisorse')}
                      </Menu.Item>
                      <Menu.Item
                        key="7"
                        className="with-icon-title">
                        <EuroOutlined />
                        {t('proggetiPage.costiProgetto')}
                      </Menu.Item>
                      <Menu.Item
                        key="6"
                        className="with-icon-title">
                        <StackShareIcon /> {t('proggetiPage.progetttoFigli')}
                      </Menu.Item>
                      <Menu.Item
                        key="11"
                        className="with-icon-title">
                        <FileTextIcon /> {t('feed.feed')}
                      </Menu.Item>
                      <Menu.Item
                        key="27"
                        className="with-icon-title">
                        <NotificationOutlined /> {t('proggetiPage.notificeRiunioni')}
                      </Menu.Item>
                      <Menu.Item
                        key="12"
                        className="with-icon-title">
                        <FileOutlined />
                        {t('proggetiPage.file')}
                      </Menu.Item>
                      <Menu.Item
                        key="13"
                        className="with-icon-title">
                        <SettingsIcon className="tw-fill-black tw-shrink-0" /> {t('general.settings')}
                      </Menu.Item>
                    </>
                  )}
                </Menu>
                <div
                  className={
                    'tw-size-full tw-p-8 tw-overflow-auto tw-h-full ' +
                    (isPresentationMode && !!dynamicFontClass && 'presContent ' + dynamicFontClass)
                  }>
                  {renderComponent()}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectIndex;
