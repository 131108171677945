import { Spin } from 'antd';
import React from 'react';
import './loader2.scss';

const Loader2: React.FC = () => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center">
      <Spin
        size="large"
        tip="Loading..."
        style={{ color: '#a1aeae' }}
      />
    </div>
  );
};

export default Loader2;
