import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import activeXMatrixSlice from './slices/activeXMatrixSlice';
import committesSlice from './slices/committeesSlice';
import companySlice from './slices/companySlice';
import feedNotificationsSlice from './slices/feedNotificationsSlice';
import notificationsSlice from './slices/notificationsSlice';
import projectSlice from './slices/projectSlice';
import userSlice from './slices/userSlice';
import xMatrixSlice from './slices/xMatrixSlice';

export const store = configureStore({
  reducer: {
    commities: committesSlice,
    companyData: companySlice,
    feedNotificationsList: feedNotificationsSlice,
    notificationsList: notificationsSlice,
    selectedXmatrix: xMatrixSlice,
    userData: userSlice,
    activeXMatrix: activeXMatrixSlice,
    project: projectSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: import('react-redux').TypedUseSelectorHook<RootState> = useSelector;
