import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  DeliverableApi,
  FastCloseDeliverableRequestDto,
  InsertDeliverableRequestDto,
  UpdateDeliverableRequestDto,
} from 'src/connectors/backend';
import i18n from '../../i18n';

const DeliverablePageService = {
  getDeliverableList: async function () {
    const currentLng = i18n.language;
    const api = await authenticatedApi(DeliverableApi);

    return trackPromise(api.deliverableGetDeliverableListPage(currentLng));
  },

  getDeliverableById: async function (deliverableId: string) {
    const currentLng = i18n.language;
    const api = await authenticatedApi(DeliverableApi);

    return trackPromise(api.deliverableGetDeliverableByID(deliverableId, currentLng));
  },

  updateDeliverable: async function (payload: UpdateDeliverableRequestDto) {
    const api = await authenticatedApi(DeliverableApi);

    return trackPromise(api.deliverableUpdateDeliverable(payload));
  },

  insertDeliverable: async function (payload: InsertDeliverableRequestDto) {
    const api = await authenticatedApi(DeliverableApi);

    return trackPromise(api.deliverableInsertDeliverable(payload));
  },

  getDeliverablePicklist: async function () {
    const api = await authenticatedApi(DeliverableApi);

    return trackPromise(api.deliverableGetDeliverablePicklist());
  },

  fastCloseDeliverable: async function (payload: FastCloseDeliverableRequestDto) {
    const api = await authenticatedApi(DeliverableApi);

    return trackPromise(api.deliverableFastCloseDeliverable(payload));
  },
};

export default DeliverablePageService;
