import { PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Card, Col, message, Row, Space, Tooltip } from 'antd';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ProgettiService from '../../../../../services/pages/projectServices/progettiService';
import Loader2 from '../../../../shared/components/loader2/loader2';
import UserBadge from '../../../../shared/userBadge/userBadge';
import { userRolesCodes } from '../../../../shared/utils/constants';
import { updateNotificationBell } from '../../../../shared/utils/functions';
import { notifyMessages } from '../../../../shared/utils/notifyMessages';
import AddMemberModal from './addMemberModal';
import ManageTeamMembers from './manageTeamMembersModal';

class ProjectTeamMembers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      showNewFeedModal: false,
      showTeamMemberModal: false,
      showSwapTeamMemberModal: false,
    };
  }

  toggleTeamMemberModal = () => {
    this.setState({ showTeamMemberModal: true });
  };

  toggleManageTeamMemberModal = () => {
    this.setState({ showSwapTeamMemberModal: true });
  };

  handleTeamMemberModalClose = () => {
    this.setState({ showTeamMemberModal: false });
    this.setState({ showSwapTeamMemberModal: false });
  };

  addTeamMembers = (values) => {
    const { projectId } = this.props;
    let payload = {};
    payload['userIds'] = values;
    payload['projectID'] = projectId;

    this.setState({ loadingButton: true });
    ProgettiService.updateTeamMembers(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          updateNotificationBell();
          this.setState({ loadingButton: false });
          this.handleTeamMemberModalClose();
          this.props.reloadUsers();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  manageSwapTeamMembers = (values) => {
    const { projectId } = this.props;
    values['ProjectID'] = projectId;

    this.setState({ loadingButton: true });
    ProgettiService.swapProjectTeamMembers(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);

          this.setState({ loadingButton: false });
          this.handleTeamMemberModalClose();
          this.props.reloadUsers();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  render() {
    const { showTeamMemberModal, loadingButton, showSwapTeamMemberModal } = this.state;
    const {
      teamMembers,
      projectId,
      isDataLoading,
      hasUserAccess,
      isAdminOrProjectTeam,
      checkProjectEndDateValidity,
      t,
      isPresentationMode,
    } = this.props;

    let leaderMembers = teamMembers
      ? teamMembers.filter(
          (obj) =>
            (obj.roleCode === userRolesCodes.sponsor || obj.roleCode === userRolesCodes.teamLeader) &&
            obj.isDeleted === false,
        )
      : [];
    let otherTeamMenbers = teamMembers
      ? teamMembers.filter(
          (obj) =>
            obj.roleCode !== userRolesCodes.sponsor &&
            obj.roleCode !== userRolesCodes.teamLeader &&
            obj.isDeleted === false,
        )
      : [];

    return (
      <>
        {!isPresentationMode && showTeamMemberModal && (
          <AddMemberModal
            showTeamMemberModal={showTeamMemberModal}
            handleTeamMemberModalClose={this.handleTeamMemberModalClose}
            handleTeamMemberModalSave={this.addTeamMembers}
            projectId={projectId}
            teamMembers={teamMembers ? teamMembers.filter((x) => x.isDeleted === false) : []}
            loadingButton={loadingButton}
            t={t}
          />
        )}

        {!isPresentationMode && showSwapTeamMemberModal && (
          <ManageTeamMembers
            showModal={showSwapTeamMemberModal}
            handleModalClose={this.handleTeamMemberModalClose}
            handleModalSave={this.manageSwapTeamMembers}
            projectId={projectId}
            projectTeam={teamMembers}
            loadingSave={loadingButton}
            t={t}
          />
        )}

        <Card
          className="!tw-shadow-none !tw-mb-4"
          styles={{ header: { padding: 0 }, body: { padding: 0 } }}
          bordered={false}
          extra={
            !hasUserAccess && !isAdminOrProjectTeam && !checkProjectEndDateValidity ? (
              <Space direction="horizontal">
                <Tooltip title={t('proggetiPage.changeTeamMembers')}>
                  <Button
                    onClick={this.toggleManageTeamMemberModal}
                    type="dashed"
                    icon={<SwapOutlined />}
                  />
                </Tooltip>

                <Tooltip
                  placement="topRight"
                  title={t('proggetiPage.gestireMembriProgetto')}>
                  <Button
                    onClick={this.toggleTeamMemberModal}
                    type="primary"
                    data-testid="add_member_btn"
                    icon={<PlusOutlined />}>
                    {t('buttons.aggiungiNuovo')}
                  </Button>
                </Tooltip>
              </Space>
            ) : null
          }>
          {isDataLoading && <Loader2 />}
          <Row
            gutter={{ lg: 24 }}
            className="row-margin tw-mt-4">
            {leaderMembers &&
              leaderMembers.map((user, index) => (
                <Col
                  key={index}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 8 }}>
                  <UserBadge
                    user={user}
                    key={index}
                    projectId={projectId}
                  />
                </Col>
              ))}
          </Row>

          <Row gutter={{ lg: 24 }}>
            {otherTeamMenbers &&
              otherTeamMenbers.map((user, index) => (
                <Col
                  key={index}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}>
                  <UserBadge
                    user={user}
                    key={index}
                    projectId={projectId}
                  />
                </Col>
              ))}
          </Row>
        </Card>
      </>
    );
  }
}

export default withTranslation()(ProjectTeamMembers);
