import { Form } from 'antd';
import React from 'react';
import '../projects/projectForm/projectActivities/activities.scss';

import { Card, Col, DatePicker, Input, Modal, Row, Select } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CommonService from '../../services/commonService';
import MatrixService from '../../services/matrix/matrixService';
import ProgettiService from '../../services/pages/projectServices/progettiService';
import ModalFooterButtons from '../shared/components/modal/modalFooterButtons';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import { activityStatus, statusPickListCodes } from '../shared/utils/constants';
import { areDatesValid, showErrorNotification } from '../shared/utils/functions';
import { requiredFields } from '../shared/utils/notifyMessages';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class NewActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      projectPicklist: null,
      selectedProject: null,
      xmatrixPicklist: null,
      selectedXmatrixValue: null,
    };
  }

  componentDidMount() {
    this.retrieveStatus();
    this.retrieveComponentData();
  }

  retrieveComponentData = async () => {
    await this.xmatrixPicklist();
    await this.retrieveUserProjects();
  };

  getDefaultXmatrix = (xmatrixPicklist) => {
    let defaultXmatrix = xmatrixPicklist
      ? xmatrixPicklist.find((x) => x.status === 1 && x.parentXMatrixID === null).xMatrixID
      : null;
    this.setState({ selectedXmatrixValue: defaultXmatrix });
  };

  async xmatrixPicklist() {
    await MatrixService.getXmatrixPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ xmatrixPicklist: resp.responseObject.value }, () => {
            this.getDefaultXmatrix(resp.responseObject.value);
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveStatus() {
    const objectCode = statusPickListCodes.activityStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveUserProjects() {
    const { selectedXmatrixValue } = this.state;
    const { userData } = this.props;

    if (userData && Object.keys(userData).length > 0) {
      if (selectedXmatrixValue) {
        await CommonService.getUserProjects(selectedXmatrixValue, userData.userId)
          .then((response) => response.data)
          .then((resp) => {
            if (resp.success) {
              this.setState({ projectPicklist: resp.responseObject.value });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  }

  addData = (values) => {
    this.props.handleSaveActivity(values);
  };

  limitDateSelection(current) {
    const { selectedProject } = this.state;

    if (selectedProject) {
      let curr = moment(current).endOf('day');
      let startDate = moment(selectedProject.startDate).endOf('day');
      let endDate = moment(selectedProject.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    }
  }

  onProjectChange = (option) => {
    ProgettiService.getProgettiByiD(option)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedProject: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  checkValidityBeforeSave = (values) => {
    const { t } = this.props;
    if (areDatesValid(values.startDate, values.endDate)) {
      this.addData(values);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  render() {
    const { showCreateModal, handleCreateModalClose, loadingButton, t, userData } = this.props;
    const { statusList, projectPicklist, selectedProject } = this.state;
    let currentDate = moment();

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('leMieAttivitaPage.aggiungiNuovaAttivita')}
        open={showCreateModal}
        destroyOnClose={true}
        onCancel={handleCreateModalClose}
        footer={[
          // <div key="close_buttons" >
          //     <Button onClick={handleCreateModalClose}>
          //         Chiudi
          //         </Button>

          // </div>
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleCreateModalClose}
            formId={'newMyActivityForm'}
          />,
        ]}>
        <Card>
          <Form
            id="newMyActivityForm"
            layout="vertical"
            {...formItemLayout}
            // onFinish={this.checkDatesValidity}
            onFinish={(values) => this.checkValidityBeforeSave(values)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  className="main-container"
                  label={t('leMieAttivitaPage.soggetto')}
                  name="subject"
                  rules={[{ required: true, message: requiredFields.required }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}>
                <UserSelectDropDown
                  disabled={false}
                  allowClear={false}
                  fieldLabel={t('general.responsabile')}
                  t={t}
                  formName={'ownerID'}
                  required={true}
                  initValue={userData.userId}
                />

                {/* <Form.Item className='main-container'
                                    labelAlign="left"
                                    label={t('general.responsabile')}
                                    name="ownerID"
                                    rules={[{ required: true, message: requiredFields.required }]}   
                                    initialValue={userData.userId}                                 
                                >
                                    <Select
                                        className='main-container'
                                        showSearch
                                        placeholder={t('general.seleziona')}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        style={{ width: "100%" }}
                                    >
                                        {usersPickList && usersPickList.map(item =>
                                            <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                        )}
                                    </Select>
                                </Form.Item> */}
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}>
                <Form.Item
                  className="main-container"
                  label={t('general.stato')}
                  name="status"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={activityStatus.active}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}>
                    {statusList &&
                      statusList.map((item) => (
                        <Option
                          value={item.statusID}
                          key={item.statusID}>
                          {item.statusDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}>
                <Form.Item
                  className="main-container"
                  label={t('general.progetto')}
                  name="projectID"
                  hasFeedback>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    onChange={(option) => this.onProjectChange(option)}>
                    {projectPicklist &&
                      projectPicklist.map((item) => (
                        <Option
                          value={item.projectID}
                          key={item.projectID}>
                          {item.projectName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  className="main-container"
                  label={t('general.start')}
                  name="startDate"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={currentDate}>
                  {/* <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" 
                                        disabledDate={selectedProject ? (current) => this.limitDateSelection(current, selectedProject) : selectedProject} 
                                    /> */}
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  className="main-container"
                  label={t('general.end')}
                  name="endDate"
                  rules={[{ required: true, message: requiredFields.required }]}>
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    disabledDate={
                      selectedProject ? (current) => this.limitDateSelection(current, selectedProject) : selectedProject
                    }
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  className="main-container"
                  label={t('general.descrizione')}
                  name="description">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(NewActivity));
