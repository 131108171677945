import { DeleteOutlined, EditOutlined, PlusOutlined, UnorderedListOutlined, WarningOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  message,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ProgettiService from '../../../../../services/pages/projectServices/progettiService';
import RoutineService from '../../../../../services/pages/projectServices/routineServices';
import Loader2 from '../../../../shared/components/loader2/loader2';
import { formatOfDate } from '../../../../shared/utils/constants';
import { updateNotificationBell } from '../../../../shared/utils/functions';
import { notifyMessages } from '../../../../shared/utils/notifyMessages';
import NewRoutineModal from './newRoutineModal';
import UpdateRoutineModal from './updateRoutineModal';

class ProjectRoutine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      isDataLoading: false,
      routineList: null,
      showNewRoutineModal: false,
      showUserModal: true,
      showEditRoutineModal: false,
      selectedRoutineData: null,
      endDateProject: null,
      startDateProject: null,
      schedule: null,
      dailySelectDays: null, //dayofweek
      frequence: null, //frequence
      extraUsers: [],
    };
  }

  componentWillMount() {
    this.retrieveProjectRoutine();
    this.retrieveProjectUsers();
    // this.loadExtraUsers()
  }

  attemptShowUserModal() {
    this.showUserModal = true;
  }

  componentWillUnmount() {
    this.setState({ isDataLoading: false });
  }

  handleDateChange(date) {
    if (date) {
      let formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  handleTimeChange(time) {
    if (time) {
      let formatedTime = moment(time).format('HH:mm');
      return formatedTime;
    }
    return null;
  }

  async retrieveProjectRoutine() {
    this.setState({ isDataLoading: true });
    const { projectId } = this.props;
    await RoutineService.getProjectRoutine(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ routineList: resp.responseObject.value });
          this.setState({ isDataLoading: false });
        } else {
          this.setState({ isDataLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isDataLoading: false });
      });
  }

  async retrieveProjectUsers() {
    this.setState({ isDataLoading: true });
    const { projectId } = this.props;
    await ProgettiService.getProjectTeamMembers(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ usersList: resp.responseObject.value });
          this.setState({ isDataLoading: false });
        }
      })
      .catch((error) => {});
  }

  handleNewRoutineModalClose = () => {
    this.setState({ showNewRoutineModal: false });
    this.setState({ loadingButton: false });
  };

  toggleNewRoutineModal = () => {
    this.setState({ showNewRoutineModal: true });
  };

  addNewRoutine = (values, projectId) => {
    var MyTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let currentUserTime = moment().format('YYYY-MM-DD HH:mm');
    values['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    values['projectID'] = projectId;
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);

    values.startTime = this.handleTimeChange(values.startTime);
    values.endTime = this.handleTimeChange(values.endTime);
    values['extraUsersRoutine'] = values['extraUsersRoutine'];

    this.setState({ loadingButton: true });

    RoutineService.addProjectRoutine(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleNewRoutineModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveProjectRoutine();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  handleUpdateRoutineModalClose = () => {
    this.setState({ showEditRoutineModal: false });
    this.setState({ loadingButton: false });
  };

  toggleUpdateRoutineModal = (recordData) => {
    this.setState({ showEditRoutineModal: true });
    this.setState({ selectedRoutineData: recordData });
  };

  userAvatarImg = (member) => {
    var a = member.Name[0];
    var b = member.Surname[0];
    return <Avatar>{a + b}</Avatar>;
  };

  popOverContent = (member) => {
    const { emailAddress } = this.props;
    return (
      <div>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 4 }}>
          {this.userAvatarImg(member)}
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 20 }}>
          <Space
            direction="vertical"
            style={{ lineHeight: '1' }}>
            <Text strong>{member.Name !== null ? member.Name : ''}</Text>
            <Text>{member.Surname !== null ? member.Surname : ''}</Text>
            <Text>{emailAddress}</Text>
            <Divider style={{ margin: '5px 0' }} />
          </Space>
        </Col>
      </div>
    );
  };

  updateRoutine = (values, projectRoutineID, projectID, activityData) => {
    values['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    values['projectRoutineID'] = projectRoutineID;
    values['projectID'] = projectID;
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);
    values.startTime = this.handleTimeChange(values.startTime);
    values['outlookEventId'] = activityData.outlookEventId; //"AAMkAGQyOTg5NmRlLTc4ZTAtNDU0Ni1hNDZhLWJhODYxNTM4YjExZgBGAAAAAACm-F-liHhxT5DDWLNwtZ9rBwCg3ewhK5MHTpKFoGuR5xm6AAAAAAENAACg3ewhK5MHTpKFoGuR5xm6AAGmNJkEAAA="
    values['schedule'] = activityData.schedule;
    values['dayOfWeek'] = activityData.dayOfWeek;
    values['frequence'] = activityData.frequence;
    values['frequenceType'] = activityData.frequenceType;
    values['extraUsers'] = values['extraUsersRoutine'];

    this.setState({ loadingButton: true });
    RoutineService.updateProjectRoutine(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleUpdateRoutineModalClose();
          updateNotificationBell();
          message.success(notifyMessages.updateSuccess);
          this.retrieveProjectRoutine();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  onRemoveRoutine = (id, outlookEventId) => {
    RoutineService.removeRoutine(id, outlookEventId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.retrieveProjectRoutine();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  render() {
    const {
      usersList,
      routineList,
      isDataLoading,
      showNewRoutineModal,
      showEditRoutineModal,
      loadingButton,
      selectedRoutineData,
    } = this.state;
    const { projectId, hasUserAccess, isAdminOrProjectTeam, t, endDateProject, startDateProject } = this.props;

    const columns = [
      {
        title: `${t('bachechaPersonalePage.attivita')}`,
        dataIndex: 'name',
        ellipsis: {
          showTitle: false,
        },
        render: (title) => <Tooltip title={title}>{title !== null ? title : ''}</Tooltip>,
      },
      {
        title: `${t('proggetiPage.pianificazione')}`,
        dataIndex: 'scheduleDescription',
        ellipsis: {
          showTitle: false,
        },
        render: (title) => <Tooltip title={title}>{title !== null ? title : ''}</Tooltip>,
      },
      {
        title: `${t('general.start')}`,
        dataIndex: 'startDate',
        render: (text, record) => (text !== null ? moment(text).format(formatOfDate) : ''),
      },
      {
        title: `${t('general.end')}`,
        dataIndex: 'endDate',
        render: (text, record) => (text !== null ? moment(text).format(formatOfDate) : ''),
      },

      {
        // className: "center-text",
        title: `${t('proggetiPage.chi')}`,
        width: '100px',
        dataIndex: 'extraUsers',
        render: (title, record) => {
          const objJson = JSON.parse(record.extraUsers);

          let items = [];

          if (objJson) {
            objJson.map((item, index) => {
              items.push({
                label: (
                  <Popover
                    trigger="hover"
                    placement="left"
                    content={() => this.popOverContent(item)}>
                    <span> {item.Name + ' ' + item.Surname}</span>
                  </Popover>
                ),
                key: index,
              });
            });
          }

          if (objJson) {
            return (
              <Dropdown
                menu={{ items }}
                placement="bottomLeft"
                arrow
                overlayStyle={{ zIndex: '500px' }}>
                <Text
                  onClick={(e) => {
                    e.preventDefault();
                  }}>
                  {' '}
                  <UnorderedListOutlined />
                </Text>
              </Dropdown>
            );
          } else {
            return;
          }
        },
      },

      {
        title: `${t('proggetiPage.durata')} (h)`,
        dataIndex: 'duration',
      },
      {
        dataIndex: 'actions',
        width: '100px',
        fixed: 'right',

        render: (_, record) => (
          <div className="tw-flex tw-justify-end tw-gap-2 tw-items-center">
            <Button
              icon={<EditOutlined />}
              onClick={() => this.toggleUpdateRoutineModal(record)}
            />
            <Popconfirm
              title={t('proggetiPage.rimuoviRoutine')}
              icon={<WarningOutlined />}
              okText={t('general.si')}
              cancelText={t('general.no')}
              onConfirm={() => this.onRemoveRoutine(record.projectRoutineID, record.outlookEventId)}>
              <Button
                icon={<DeleteOutlined />}
                danger></Button>
            </Popconfirm>
          </div>
        ),
      },
    ];

    return (
      <>
        {showNewRoutineModal && (
          <NewRoutineModal
            showNewRoutineModal={showNewRoutineModal}
            handleNewRoutineModalClose={this.handleNewRoutineModalClose}
            loadingButton={loadingButton}
            addNewRoutine={this.addNewRoutine}
            projectId={projectId}
            t={t}
            endDateProject={endDateProject}
            startDateProject={startDateProject}
            users={usersList}
          />
        )}

        {showEditRoutineModal && (
          <UpdateRoutineModal
            showEditRoutineModal={showEditRoutineModal}
            handleUpdateRoutineModalClose={this.handleUpdateRoutineModalClose}
            loadingButton={loadingButton}
            updateRoutine={this.updateRoutine}
            projectId={projectId}
            routineData={selectedRoutineData}
            t={t}
            endDateProject={endDateProject}
            startDateProject={startDateProject}
            users={usersList}
          />
        )}

        <Card
          className="!tw-shadow-none"
          styles={{ header: { padding: 0 }, body: { padding: 0 } }}
          bordered={false}
          extra={
            !hasUserAccess && !isAdminOrProjectTeam ? (
              <Button
                data-testid="openAddRoutineModal"
                onClick={this.toggleNewRoutineModal}
                type="primary"
                icon={<PlusOutlined />}>
                {t('buttons.aggiungiNuovo')}
              </Button>
            ) : null
          }>
          {isDataLoading && <Loader2 />}
          {routineList && (
            <Row>
              <Col xs={{ span: 24 }}>
                <Table
                  size="small"
                  className="small-table-height"
                  columns={
                    hasUserAccess || isAdminOrProjectTeam
                      ? columns.filter((el) => {
                          return el.dataIndex !== 'action2' && el.dataIndex !== 'action';
                        })
                      : columns
                  }
                  dataSource={routineList}
                  pagination={false}
                  rowKey={(obj) => obj.projectRoutineID}
                />
              </Col>
            </Row>
          )}
        </Card>
      </>
    );
  }
}

export default withTranslation()(ProjectRoutine);
