import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonalDashboardService from '../../../services/pages/personalDashboardService';
import Loader2 from '../../shared/components/loader2/loader2';
import { perStatusDeliverableChartOptions } from '../chartsConfig';

const PerStatusDeliverableChart: React.FC = () => {
  const { t } = useTranslation();

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [perStatusChart, setPerStatusChart] = useState<any>(perStatusDeliverableChartOptions);

  useEffect(() => {
    getPerStatusChartData();
  }, []);

  const getPerStatusChartData = async () => {
    setLoadingData(true);
    try {
      const response = await PersonalDashboardService.getDeliverablePerStatusChartData();
      const resp = response.data;
      if (resp.success) {
        const seriesObj = {
          name: t('bachechaPersonalePage.deliverable'),
          data:
            resp.responseObject?.value?.map((item: any) => ({
              name: item.statusDescription,
              y: item.deliverableNumber,
            })) || [],
        };
        setPerStatusChart((prevChart: any) => ({
          ...prevChart,
          series: [seriesObj],
        }));
      }
    } catch (error) {
      console.error('Error fetching deliverable per status chart data:', error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <>
      {loadingData && <Loader2 />}
      {perStatusChart ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={perStatusChart}
        />
      ) : (
        <p>{t('general.noData')}</p>
      )}
    </>
  );
};

export default PerStatusDeliverableChart;
