import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { perStatusDeliverableChartOptions } from '../chartsConfig';

interface Phase {
  onTime: boolean;
  late: boolean;
}

interface PerTimePhasesChartProps {
  phases: Phase[];
}

function createSeriesFromPhases(phases: Phase[]) {
  const { onTime, late } = phases.reduce(
    (acc, phase) => {
      if (phase.onTime) acc.onTime++;
      if (phase.late) acc.late++;
      return acc;
    },
    { onTime: 0, late: 0 },
  );

  return [
    {
      name: 'In tempo',
      y: onTime,
    },
    {
      name: 'In ritardo',
      y: late,
    },
  ];
}

const PerTimePhasesChart: React.FC<PerTimePhasesChartProps> = ({ phases }) => {
  const [perStatusChart, setPerStatusChart] = useState<{
    chart: any;
    series?: any;
  }>(perStatusDeliverableChartOptions);
  const { t } = useTranslation();

  useEffect(() => {
    setPerStatusChart((prevChart) => ({
      ...prevChart,
      series: [
        {
          name: `${t('general.phases')}`,
          data: createSeriesFromPhases(phases),
        },
      ],
    }));
  }, [t, phases]);

  return (
    <>
      {phases.length > 0 ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={perStatusChart}
        />
      ) : (
        <p>{t('general.noData')}</p>
      )}
    </>
  );
};

export default PerTimePhasesChart;
