import { Form } from 'antd';
import { Component, useState } from 'react';
import '../projects/project.scss';
import './userProfile.scss';

import { EditOutlined, FilterOutlined, MinusSquareOutlined, SaveOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, InputNumber, message, Table, Tooltip, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import ResourceWorkloadService from '../../services/pages/resourceWorkloadServices';
import { projectWorkloadColumNames, workloadColumnNamesValues } from '../shared/utils/constants';
import {
  capitalizeFirstLetter,
  convertCamelCaseToNormal,
  numberWithCommas,
  orderUserWorkloadData,
} from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';

import moment from 'moment';
import TableLayout from '../shared/tableLayout';

const { Text } = Typography;

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = (
    <InputNumber
      min={0}
      decimalSeparator=","
      precision="2"
    />
  );
  return (
    <td {...restProps}>
      {editing ? (
        <>
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              () => ({
                validator(_, value) {
                  if (isNaN(value) && value < 0) {
                    return Promise.reject('No negative numbers');
                  }
                  return Promise.resolve();
                },
              }),
            ]}>
            {inputNode}
          </Form.Item>
        </>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditableRow = (record) => (record.Name === workloadColumnNamesValues.budget ? true : false);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key, record) => {
    try {
      const values = await form.getFieldsValue(null, (meta) => meta.touched);

      props.updateRow(values, record);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const renderedColumns = () => {
    let columns = [];

    const tabelData = props.propsData;

    if (tabelData && tabelData.length > 0) {
      let firstRow = tabelData[0];

      for (const col in firstRow) {
        if (col === projectWorkloadColumNames.name) {
          columns.unshift({
            dataIndex: col,
            width: '40px',
            fixed: 'left',
            title: '',
            editable: false,
            ellipsis: {
              showTitle: false,
            },

            render(text, record) {
              return {
                props: {
                  style: {
                    borderBottom: record.Name === workloadColumnNamesValues.consuntivo ? '2px solid #6f6f6f' : '',
                  },
                },

                children:
                  record.Type === 'user' ? (
                    <Tooltip title={props.t(`budgetTypes.${text}`)}>
                      {isEditableRow(record) ? (
                        <Text strong>{props.t(`budgetTypes.${text}`)}</Text>
                      ) : (
                        <Text>{props.t(`budgetTypes.${text}`)}</Text>
                      )}
                    </Tooltip>
                  ) : (
                    <Tooltip title={convertCamelCaseToNormal(text)}>
                      {isEditableRow(record) ? (
                        <Text strong>{convertCamelCaseToNormal(text)}</Text>
                      ) : (
                        <Text>{convertCamelCaseToNormal(text)}</Text>
                      )}
                    </Tooltip>
                  ),
              };
            },
          });
        } else if (
          col === projectWorkloadColumNames.userID ||
          col === projectWorkloadColumNames.key ||
          col === projectWorkloadColumNames.type ||
          col === projectWorkloadColumNames.projectName
        ) {
          continue;
        } else if (col === projectWorkloadColumNames.total) {
          columns.push({
            width: '25px',
            align: 'right',
            dataIndex: col,
            title: col,
            editable: false,

            render(text, record) {
              return {
                props: {
                  style: {
                    borderBottom: record.Name === workloadColumnNamesValues.consuntivo ? '2px solid #6f6f6f' : '',
                  },
                },
                children: isEditableRow(record) ? (
                  <Text
                    strong
                    style={{ color: parseInt(text) < 0 ? '#f92f2f' : '' }}>
                    {numberWithCommas(text)}
                  </Text>
                ) : (
                  <Text style={{ color: parseInt(text) < 0 ? '#f92f2f' : '' }}>{numberWithCommas(text)}</Text>
                ),
              };
            },
          });
        } else {
          let month = moment(col).format('MMM YYYY');
          columns.push({
            dataIndex: col,
            title: capitalizeFirstLetter(month),
            editable: true,
            width: '20px',
            align: 'right',

            render(text, record) {
              return {
                props: {
                  style: {
                    borderBottom: record.Name === workloadColumnNamesValues.consuntivo ? '2px solid #6f6f6f' : '',
                  },
                },
                children: isEditableRow(record) ? (
                  <Text
                    strong
                    style={{ color: parseInt(text) < 0 ? '#f92f2f' : '' }}>
                    {numberWithCommas(text)}
                  </Text>
                ) : (
                  <Text style={{ color: parseInt(text) < 0 ? '#f92f2f' : '' }}>{numberWithCommas(text)}</Text>
                ),
              };
            },
          });
        }
      }
      columns.push({
        title: '',
        dataIndex: 'operation',
        width: '30px',
        render: (_, record) => {
          if (isEditableRow(record)) {
            const editable = isEditing(record);
            return editable ? (
              <span>
                {props.loadingIcon && (
                  <SyncOutlined
                    style={{ color: '#0f87dc', marginRight: 5 }}
                    spin
                  />
                )}
                <Typography.Link onClick={() => save(record.key, record)}>
                  <SaveOutlined style={{ marginRight: 5 }} />
                  Salva
                </Typography.Link>
                <Divider
                  type="vertical"
                  style={{ borderLeft: '1px solid rgb(183 166 166)' }}
                />
                <Typography.Link onClick={() => cancel()}>Chiudi</Typography.Link>
              </span>
            ) : (
              <Typography.Link
                disabled={editingKey !== ''}
                onClick={() => edit(record)}>
                <EditOutlined /> Modifica
              </Typography.Link>
            );
          } else {
            return;
          }
        },
      });
      return columns;
    }
    return columns;
  };

  const mergedColumns = renderedColumns().map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form
      form={form}
      component={false}>
      <Table
        className="resource"
        tableLayout="fixed"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        size="small"
        dataSource={props.propsData}
        columns={mergedColumns}
        pagination={false}
        scroll={{ x: 'calc(700px + 50%)' }}
        loading={props.loadingData}
      />
    </Form>
  );
};

class UserResourceWorkload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingData: false,
      userWorkloadData: null,
      editingKey: '',
      selectedYear: moment(),
      clearFilters: false,
    };
  }

  componentWillMount() {
    this.getUserWorkload();
  }

  async getUserWorkload() {
    const { selectedYear } = this.state;
    let currentYear = moment().format('YYYY');
    let fromatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;
    const { userId } = this.props;
    this.setState({ loadingData: true });
    await ResourceWorkloadService.getUserWorkloadData(userId, fromatedYear)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          resp.responseObject.value.forEach((item, index) => {
            let total = 0;
            for (const col in item) {
              if (
                col !== projectWorkloadColumNames.userID &&
                col !== projectWorkloadColumNames.name &&
                col !== projectWorkloadColumNames.type &&
                col !== projectWorkloadColumNames.projectName
              ) {
                total += item[col];
              }
            }
            item['Totale'] = total;
            item['key'] = index.toString();
          });
          this.setState({ userWorkloadData: resp.responseObject.value });
          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  updateUserWorkload = (values, record) => {
    let payload = {};
    let monthsArray = [];

    payload['userID'] = record.UserID;

    if (values) {
      for (const item in values) {
        monthsArray.push({ referencePeriod: item, value: values[item] });
      }
    } else {
      monthsArray = [];
    }

    payload['months'] = monthsArray;

    this.setState({ loadingButton: true });
    ResourceWorkloadService.updateUserPlanning(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingButton: false });
          this.setState({ cancelRow: true });
          this.getUserWorkload();
          this.props.reloadUserData();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  onYearChange = (year) => {
    this.setState({ selectedYear: year });
  };

  onFilter = () => {
    this.setState({ clearFilters: false }, () => {
      this.getUserWorkload();
      // this.props.onFilterUserWorkload(selectedYear);
    });
  };

  onClearFilter = () => {
    this.setState({ selectedYear: moment(), clearFilters: true }, () => {
      this.getUserWorkload();
      // this.props.onFilterUserWorkload(moment());
    });
  };

  render() {
    const { loadingData, loadingButton, userWorkloadData, selectedYear } = this.state;
    let orderedList = [];
    let t = this.props.t;
    orderedList = orderUserWorkloadData(userWorkloadData);

    return (
      <TableLayout>
        <TableLayout.Actions>
          <DatePicker
            placeholder="Anno"
            picker="year"
            value={selectedYear}
            format="YYYY"
            onChange={(date) => this.onYearChange(date)}
          />
          <Tooltip title="Filter">
            <Button
              type="dashed"
              icon={<FilterOutlined />}
              onClick={() => this.onFilter()}
              disabled={!selectedYear}
            />
          </Tooltip>
          <Tooltip title="Clear Filters">
            <Button
              type="dashed"
              icon={<MinusSquareOutlined />}
              onClick={() => this.onClearFilter()}
              disabled={selectedYear ? false : true}
            />
          </Tooltip>
        </TableLayout.Actions>

        <TableLayout.Content>
          <EditableTable
            t={t}
            propsData={orderedList}
            updateRow={this.updateUserWorkload}
            loadingIcon={loadingButton}
            loadingData={loadingData}
          />
        </TableLayout.Content>
      </TableLayout>
    );
  }
}

export default withTranslation()(UserResourceWorkload);
