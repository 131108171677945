import { Form } from 'antd';
import React from 'react';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Input, InputNumber, message, Row, Select, Switch } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import CommonService from '../../services/commonService';
import MatrixService from '../../services/matrix/matrixService';
import DashboardService from '../../services/pages/dashboardService';
import ProgettiService from '../../services/pages/projectServices/progettiService';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import { projectStatusAsNumber, statusPickListCodes } from '../shared/utils/constants';
import { areDatesValid, showErrorNotification, updateNotificationBell } from '../shared/utils/functions';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class AddNewProjectPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      commitiePickList: null,
      parentProjectList: null,
      divisionsList: null,
      loadingButton: false,
      isMandatory: false,
      validateProjectCode: false,
      preDefinedProjectCode: null,
      projectCodeValue: null,
      selectedProjectData: null,
      activeXmatrixInfo: null,
      selectedXmatrixInfo: null,
    };
  }

  handleDateChange(date) {
    if (date) {
      let formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  componentWillMount() {
    const { xmid } = this.props.match.params;
    // this.retrieveComponentData(xmid)
    this.retrieveStatus();
    this.retrieveCommities();
    this.getParentProjects();
    this.retrieveDivisions();
  }

  retrieveComponentData = async (xmid) => {
    await this.getActiveXmatrix();
    this.getSelectedXmatrixInfo(xmid);
  };

  getProjectData = (id) => {
    ProgettiService.getProgettiByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedProjectData: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  disabledStartDate(current) {
    const { selectedProjectData } = this.state;

    if (selectedProjectData) {
      let curr = moment(current).endOf('day');
      let startDate = moment(selectedProjectData.startDate).endOf('day');
      let endDate = moment(selectedProjectData.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    }
  }

  async retrieveDivisions() {
    await DashboardService.getDivisionFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ divisionsList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveStatus() {
    const objectCode = statusPickListCodes.statusProjects;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveCommities() {
    await CommonService.getCommitiePicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ commitiePickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getParentProjects() {
    await ProgettiService.getParentProjects()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ parentProjectList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  onStatusChange = (value, option) => {
    if (option.children === 'Attivo') {
      this.setState({ isMandatory: true });
    } else {
      this.setState({ isMandatory: false });
    }
  };

  _validateProjectCode = (_rule, value, callback) => {
    ProgettiService.checkIfProjectCodeExist(value)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (resp.responseObject.value) {
            callback(requiredFields.validateProjectCode);
          }
          callback();
        }
        callback();
      })
      .catch((error) => {});
  };

  addData = (values) => {
    const { preDefinedProjectCode, projectCodeValue } = this.state;

    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);
    values['fastCreation'] = false;
    values['projectCode'] = preDefinedProjectCode ? preDefinedProjectCode + '-' + projectCodeValue : projectCodeValue;
    const payload = values;

    this.setState({ loadingButton: true });
    ProgettiService.addNewProject(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateNotificationBell();
          message.success(notifyMessages.addSuccess);
          this.setState({ loadingButton: false });
          this.props.history.push('/progetti');
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  onProjectClear = () => {
    this.setState({ preDefinedProjectCode: null });
    this.setState({ selectedProjectData: null });
  };

  onProjectSelect = (option) => {
    const { parentProjectList } = this.state;
    var selectedProject = parentProjectList.filter((obj) => {
      return obj.projectID === option;
    })[0];

    this.getProjectData(option);
    this.setState({ preDefinedProjectCode: selectedProject.projectCode });
  };

  onProjectCodeChange = (e) => {
    this.setState({ projectCodeValue: e.target.value });
  };

  checkValidityBeforeSave = (values) => {
    let t = this.props.t;
    if (areDatesValid(values.startDate, values.endDate)) {
      this.addData(values);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  async getSelectedXmatrixInfo(xMatrixId) {
    await MatrixService.getSelectedXmatrixInfo(xMatrixId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedXmatrixInfo: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getActiveXmatrix() {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          this.setState({ activeXmatrixInfo: respData }, () => {});
        } else {
        }
      })
      .catch((error) => {});
  }

  render() {
    const {
      statusList,
      commitiePickList,
      parentProjectList,
      divisionsList,
      loadingButton,
      isMandatory,
      preDefinedProjectCode,
      projectCodeValue,
      selectedXmatrixInfo,
      activeXmatrixInfo,
    } = this.state;
    let t = this.props.t;

    return (
      <div className="edit-obietivo-anno-wrapper">
        <>
          <div className="site-card-wrapper">{t('proggetiPage.aggiungiNuovoProgetto')}</div>

          <Card>
            <Form
              name="add_ob_project"
              layout="vertical"
              {...formItemLayout}
              onFinish={this.checkValidityBeforeSave}
              onValuesChange={() => {
                this.setState({ fieldsChanged: true });
              }}>
              <Row gutter={{ lg: 24 }}>
                {/* <Col className="gutter-row" xs={{ span: 3 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Text strong style={{ marginLeft: 10 }}>{t('general.xmatrix')} {t('general.selezionato')}: </Text>
                                    <Text>{selectedXmatrixInfo ? selectedXmatrixInfo.hkCode : (activeXmatrixInfo ? activeXmatrixInfo.hkCode : "-")}</Text>
                                </Col> */}
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <div className="button-wrapper">
                    <Form.Item wrapperCol={{ span: 24 }}>
                      <Button
                        loading={loadingButton}
                        type="primary"
                        htmlType="submit"
                        className="tw-float-end"
                        disabled={!this.state.fieldsChanged}>
                        <SaveOutlined /> {t('buttons.salva')}
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 18 }}>
                  <Card title={t('general.informazioniGenerali')}>
                    <Row gutter={{ lg: 24 }}>
                      {/* <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                <Form.Item
                                                    label="È progetto secondario?"
                                                >
                                                    <Switch defaultChecked={isSecondaryProject} onChange={(value) => this.handleSecondaryProjectChange(value)} />
                                                </Form.Item>
                                            </Col> */}

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Form.Item
                          label={t('proggetiPage.nomeProgetto')}
                          name="name"
                          rules={[{ required: true, message: requiredFields.required }]}>
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          extra={t('proggetiPage.codiceLungeza')}
                          label={t('proggetiPage.codiceProgetto')}
                          name="projectCode"
                          rules={[
                            { required: true, message: requiredFields.required },
                            { max: 10, message: `${t('proggetiPage.codiceLungeza')}` },
                            {
                              validator: this._validateProjectCode,
                            },
                          ]}
                          //initialValue={preDefinedProjectCode}
                        >
                          <Input
                            // value={preDefinedProjectCode ? `${preDefinedProjectCode} - ` : ""}
                            addonBefore={preDefinedProjectCode ? `${preDefinedProjectCode}-` : null}
                            value={projectCodeValue}
                            onChange={(e) => this.onProjectCodeChange(e)}
                            maxLength={10}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Form.Item
                          label={t('proggetiPage.progettoPrincipale')}
                          name="parentProjectID"
                          hasFeedback>
                          <Select
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onSelect={(option) => this.onProjectSelect(option)}
                            onClear={() => this.onProjectClear()}>
                            {parentProjectList &&
                              parentProjectList.map((item) => (
                                <Option
                                  value={item.projectID}
                                  key={item.projectID}>
                                  {item.name} ({item.projectCode})
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={t('general.stato')}
                          name="status"
                          hasFeedback
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={projectStatusAsNumber.draft}>
                          <Select
                            allowClear
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onChange={(value, option) => this.onStatusChange(value, option)}>
                            {statusList &&
                              statusList.map((item) => (
                                <Option
                                  value={item.statusID}
                                  key={item.statusID}>
                                  {item.statusDescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 4 }}
                        lg={{ span: 4 }}>
                        <Form.Item
                          labelAlign="left"
                          label={t('general.budget')}
                          name="budget">
                          <InputNumber min={0} />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 6 }}>
                        <UserSelectDropDown
                          disabled={false}
                          allowClear={true}
                          fieldLabel={t('general.teamLeader')}
                          t={t}
                          formName={'teamLeaderID'}
                          required={isMandatory}
                          initValue={null}
                        />
                        {/* <Form.Item
                                                    label={t('general.teamLeader')}
                                                    name="teamLeaderID"
                                                    hasFeedback
                                                    rules={isMandatory ? [{ required: true, message: requiredFields.required }] : ""}
                                                >
                                                    <Select
                                                        allowClear
                                                        showSearch
                                                        placeholder={t('general.seleziona')}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{ width: "100%" }}
                                                    >
                                                        {usersPickList && usersPickList.map(item =>
                                                            <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                                        )}
                                                    </Select>
                                                </Form.Item> */}
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 6 }}>
                        <UserSelectDropDown
                          disabled={false}
                          allowClear={true}
                          fieldLabel={t('general.sponsor')}
                          t={t}
                          formName={'sponsorID'}
                          required={isMandatory}
                          initValue={null}
                        />

                        {/* <Form.Item
                                                    label={t('general.sponsor')}
                                                    name="sponsorID"
                                                    hasFeedback
                                                    rules={isMandatory ? [{ required: true, message: requiredFields.required }] : ""}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{ width: "100%" }}
                                                        placeholder={t('general.seleziona')}
                                                    >
                                                        {usersPickList && usersPickList.map(item =>
                                                            <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                                        )}
                                                    </Select>
                                                </Form.Item> */}
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 6 }}>
                        <Form.Item
                          label={t('general.comitato')}
                          name="committeeID"
                          hasFeedback
                          // rules={isMandatory ? [{ required: true, message: requiredFields.required }] : ""}
                        >
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}>
                            {commitiePickList &&
                              commitiePickList.map((item) => (
                                <Option
                                  disabled={item.hasAccess === 1 ? false : true}
                                  value={item.committeeID}
                                  key={item.committeeID}>
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 6 }}>
                        <Form.Item
                          labelAlign="left"
                          label={t('general.divisione')}
                          name="divisionID"
                          hasFeedback>
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}>
                            {divisionsList &&
                              divisionsList.map((item) => (
                                <Option
                                  disabled={item.hasAccess === 1 ? false : true}
                                  value={item.divisionID}
                                  key={item.divisionID}>
                                  {item.divisionName}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 12 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          label={t('general.start')}
                          name="startDate"
                          rules={[{ required: true, message: requiredFields.required }]}
                          // initialValue = {currentDate}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            disabledDate={(current) => this.disabledStartDate(current)}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 12 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          label={t('general.end')}
                          name="endDate"
                          rules={[{ required: true, message: requiredFields.required }]}
                          // initialValue = {lastDayOfYear}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            disabledDate={(current) => this.disabledStartDate(current)}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 6 }}>
                        <Form.Item
                          label={t('proggetiPage.revisioneEsterna')}
                          name="hasExternalReview"
                          valuePropName="checked"
                          initialValue={false}>
                          <Switch />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Form.Item
                          label={t('proggetiPage.obiettivoProgetto')}
                          name="projectGoal"
                          initialValue="">
                          <TextArea rows={4} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      </div>
    );
  }
}

export default withTranslation()(AddNewProjectPage);
