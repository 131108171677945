import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import {
  FeedApi,
  InsertFeedActivityLikeRequestDto,
  InsertFeedCommentRequestDto,
  InsertFeedFileRequestDto,
  InsertFeedMeetingRequestDto,
  UpdateFeedActivityRequestDto,
} from 'src/connectors/backend';

const FeedService = {
  // get feed list
  getFeedData: async function (regardingObjectId: string, userId: string, isProject: string) {
    const api = await authenticatedApi(FeedApi);

    return api.feedGetFeedActivityList(regardingObjectId, userId, isProject);
  },

  // get feed activity list
  getFeedActivity: async function (activityId: string) {
    const api = await authenticatedApi(FeedApi);

    return api.feedGetFeedActivity(activityId);
  },

  // feed activity like button
  addLike: async function (payload: InsertFeedActivityLikeRequestDto) {
    const api = await authenticatedApi(FeedApi);

    return api.feedInsertFeedActivityLike(payload);
  },

  // add comment
  addFeedComment: async function (payload: InsertFeedCommentRequestDto) {
    const api = await authenticatedApi(FeedApi);

    return api.feedInsertFeedComment(payload);
  },

  // add comment
  addFeedFile: async function (payload: InsertFeedFileRequestDto) {
    const api = await authenticatedApi(FeedApi);

    return api.feedInsertFeedFile(payload);
  },

  // add feed meeting
  addFeedMeeting: async function (payload: InsertFeedMeetingRequestDto) {
    const api = await authenticatedApi(FeedApi);

    return api.feedInsertFeedMeeting(payload);
  },

  // get project feed list
  getProjectFeedData: async function (regardingObjectId: string, userId: string) {
    const api = await authenticatedApi(FeedApi);

    return api.feedGetProjectFeedActivityList(regardingObjectId, userId);
  },

  // update feed item
  updateFeedItem: async function (payload: UpdateFeedActivityRequestDto) {
    const api = await authenticatedApi(FeedApi);

    return api.feedUpdateFeedActivity(payload);
  },

  // delete feed item
  removeFeedItem: async function (activityId: string, type: number, outlookEventId: string) {
    const api = await authenticatedApi(FeedApi);

    return api.feedDeleteActivityFeed(activityId, type, outlookEventId);
  },

  getFeedNotificationBellItems: async function () {
    const api = await authenticatedApi(FeedApi);

    return trackPromise(api.feedGetFeedMonthlyAlertByUser());
  },

  updateFeedNotificationBell: async function () {
    const api = await authenticatedApi(FeedApi);

    return api.feedUpdateMonthlyAlertFeed();
  },
};

export default FeedService;
