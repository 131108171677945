import { InsertProjectRoutineRequestDto, ProjectApi, UpdateProjectRoutineRequestDto } from 'src/connectors/backend';
import { authenticatedApi } from 'src/config/connectors';
import { trackPromise } from 'react-promise-tracker';

const RoutineService = {
  getProjectRoutine: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectRoutine(projectId));
  },

  // add new project routine
  addProjectRoutine: async function (payload: InsertProjectRoutineRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectInsertProjectRoutine(payload));
  },

  updateProjectRoutine: async function (payload: UpdateProjectRoutineRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateProjectRoutine(payload));
  },

  removeRoutine: async function (projectRoutineId: string, outlookEventId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectDeleteProjectRoutine());
  },
};

export default RoutineService;
