import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import MatrixService from '../../services/matrix/matrixService';
import Loader from '../shared/components/loader/loader';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

const ViewEditXmatrixCycle = () => {
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [cycleData, setCycleData] = useState<any>(null);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      getXamtrixCycleDataById(id);
    }
  }, [id]);

  const getXamtrixCycleDataById = (id: string) => {
    MatrixService.getXmatrixCycleById(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setCycleData(resp.responseObject?.value || null);
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch(() => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  const editCycle = (values: any, xMatrixCycleID: string) => {
    values['xMatrixCycleID'] = xMatrixCycleID;
    setLoadingButton(true);
    MatrixService.updateXmatrixCycle(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setLoadingButton(false);
          history.push('/strategicCycle');
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingButton(false);
        }
      })
      .catch(() => {
        message.error(notifyMessages.updateFailed);
        setLoadingButton(false);
      });
  };

  return (
    <div className="edit-obietivo-anno-wrapper">
      <Loader />
      {cycleData && (
        <>
          <div className="site-card-wrapper">{t('xmatrixCyclePage.aggiungiCycle')}</div>
          <Card>
            <Form
              name="add_matrix_cycle"
              layout="vertical"
              onFinish={(values) => editCycle(values, cycleData.xMatrixCycleID)}
              onValuesChange={() => setFieldsChanged(true)}>
              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 4 }}
                  sm={{ span: 4 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                />
                <Col
                  className="gutter-row"
                  xs={{ span: 20 }}
                  sm={{ span: 20 }}
                  md={{ span: 20 }}
                  lg={{ span: 20 }}>
                  <div className="button-wrapper">
                    <Form.Item wrapperCol={{ span: 24 }}>
                      <Button
                        loading={loadingButton}
                        type="primary"
                        htmlType="submit"
                        disabled={!fieldsChanged}
                        className="tw-float-end">
                        <SaveOutlined /> {t('buttons.salva')}
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={{ lg: 24 }}>
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  name="companyID"
                  initialValue={cycleData.companyID}>
                  <Input type="hidden" />
                </Form.Item>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 12 }}>
                  <Card title={t('general.informazioniGenerali')}>
                    <Row gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Form.Item
                          label={t('xmatrixCyclePage.nomeCycle')}
                          name="cycleName"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={cycleData.cycleName}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      )}
    </div>
  );
};

export default ViewEditXmatrixCycle;
