import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi, DivisionApi } from 'src/config/connectors';

const DivisionService = {
  list: async function () {
    const api = await authenticatedApi(DivisionApi);

    return trackPromise(api.divisionList());
  },

  get: async function (id: string) {
    const api = await authenticatedApi(DivisionApi);

    return trackPromise(api.divisionGet(id));
  },

  update: async function (id: string, name: string, ownerId?: string, logo?: File) {
    const api = await authenticatedApi(DivisionApi);

    return trackPromise(api.divisionUpdate(id, name, ownerId ?? undefined, logo ?? undefined));
  },

  create: async function (divisionName: string, ownerId?: string, logo?: File) {
    const api = await authenticatedApi(DivisionApi);

    return trackPromise(api.divisionCreate(divisionName, ownerId ?? undefined, logo ?? undefined));
  },
};

export default DivisionService;
