import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Modal, Space, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bowlingChartRowName, kpiCalculationType } from '../../../shared/utils/constants';
import './deliverable.scss';
import KpiValoreFattoNonFatto from './kpiValoreFattoNonFatto';
import KpiValoreMassimo from './kpiValoreMassimo';
import KpiValoreMinimo from './kpiValoreMinimo';
import KpiValorePercentuale from './kpiValorePercentuale';

const { Text, Title } = Typography;

const ModalHeader = (props) => {
  return (
    <Space direction="vertical">
      <Title
        style={{ marginBottom: 1 }}
        level={5}>
        {props.t('general.kpi')}: <span style={{ color: '#1463b5' }}>{props.data.name}</span>
      </Title>
      <span>
        <Text style={{ fontSize: 14 }}>
          {' '}
          <span className="header-text"> {props.t('kpiPage.cumulatoPuntuale')}: </span>{' '}
          <u>{props.data.parentTypeDescription}</u>
        </Text>
        <Divider
          type="vertical"
          style={{ borderColor: '#067af3' }}
        />
        <Text style={{ fontSize: 14 }}>
          {' '}
          <span className="header-text"> {props.t('kpiPage.tipoCalcolo')}: </span>
          <u>{props.data.calculationTypeDescription}</u>
        </Text>
        <Divider
          type="vertical"
          style={{ borderColor: '#067af3' }}
        />
        <Text style={{ fontSize: 14 }}>
          {' '}
          <span className="header-text"> {props.t('kpiPage.gerarchicho')}: </span>
          {props.data.isRollupKPI ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
        </Text>
      </span>
    </Space>
  );
};

class EditProjectKpi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
    };
  }

  onDataSave = (
    bowlingChartData,
    jumpOffPointValue,
    kpiProjectRelationShipID,
    calculationType,
    finalTarget,
    showInA3,
    definedTarget,
    responsibleID,
  ) => {
    let payload = {};
    let tableData = [];

    let targetRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.target;
    })[0];
    let actualRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.actual;
    })[0];
    let actualYtdRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.actualYtd;
    })[0];
    let targetYtdRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.targetYtd;
    })[0];

    let isTargetInvalid = false;
    if (calculationType === kpiCalculationType.valorePercentuale) {
      for (var el in targetRow) {
        if (targetRow.hasOwnProperty(el) && (targetRow[el] > 100 || targetRow[el] < 0)) {
          isTargetInvalid = true;
        }
      }
    }

    let isActualInvalid = false;
    if (calculationType === kpiCalculationType.valorePercentuale) {
      for (var act in actualRow) {
        if (actualRow.hasOwnProperty(act) && (actualRow[act] > 100 || actualRow[act] < 0)) {
          isActualInvalid = true;
        }
      }
    }

    let targetYtdInvalid = false;
    if (calculationType === kpiCalculationType.valorePercentuale) {
      for (var targetYtd in targetYtdRow) {
        if (targetYtdRow.hasOwnProperty(targetYtd) && (targetYtdRow[targetYtd] > 100 || targetYtdRow[targetYtd] < 0)) {
          targetYtdInvalid = true;
        }
      }
    }

    let actualYtdInvalid = false;
    if (calculationType === kpiCalculationType.valorePercentuale) {
      for (var actualYtd in actualYtdRow) {
        if (actualYtdRow.hasOwnProperty(actualYtd) && (actualYtdRow[actualYtd] > 100 || actualYtdRow[actualYtd] < 0)) {
          actualYtdInvalid = true;
        }
      }
    }
    if (targetRow || actualRow) {
      if (!isActualInvalid && !isTargetInvalid && !targetYtdInvalid && !actualYtdInvalid) {
        for (let key in targetRow) {
          if (key !== 'Name') {
            let formatedDate = moment(key).format('YYYY-MM-DD');
            let item = `${formatedDate},${actualRow[key]},${targetRow[key]}`;
            tableData.push(item);
          }
        }
        payload['jumpOffPoint'] = jumpOffPointValue;
        payload['showInA3'] = showInA3;
        payload['finalTarget'] = finalTarget;
        payload['kpiProjectRelationShipID'] = kpiProjectRelationShipID;
        payload['tableData'] = tableData;
        payload['freezeTargetValues'] = definedTarget;
        payload['responsibleID'] = responsibleID;

        this.props.handleUpdateBowlingChart(payload);
      }
    }
  };

  render() {
    const {
      showEditKpiModal,
      handleEditKpiModalClose,
      projectId,
      kpiRowData,
      loadingButton,
      companyData,
      projectTeamMembers,
      userData,
      currentProject,
    } = this.props;
    let t = this.props.t;

    return (
      <Modal
        className="edit-kpi-modal"
        width={'92%'}
        title={
          <ModalHeader
            data={kpiRowData}
            t={t}
          />
        }
        open={showEditKpiModal}
        destroyOnClose={true}
        onCancel={handleEditKpiModalClose}
        footer={[
          <div key="close_buttons">
            <Button onClick={handleEditKpiModalClose}>{t('buttons.chiudi')}</Button>
          </div>,
        ]}>
        <Card className="overflow">
          {kpiRowData && kpiRowData.calculationType === kpiCalculationType.valoreMassimo && (
            <KpiValoreMassimo
              currentProject={currentProject}
              projectTeamMembers={projectTeamMembers}
              userData={userData}
              companyData={companyData}
              t={t}
              kpiRowData={kpiRowData}
              projectId={projectId}
              onSave={this.onDataSave}
              loadingButton={loadingButton}
              calculationType={kpiRowData.calculationType}
              isGlobalKpiBowlingChart={false}
            />
          )}

          {kpiRowData && kpiRowData.calculationType === kpiCalculationType.valoreMinimo && (
            <KpiValoreMinimo
              currentProject={currentProject}
              projectTeamMembers={projectTeamMembers}
              userData={userData}
              companyData={companyData}
              t={t}
              kpiRowData={kpiRowData}
              projectId={projectId}
              onSave={this.onDataSave}
              loadingButton={loadingButton}
              calculationType={kpiRowData.calculationType}
            />
          )}

          {kpiRowData && kpiRowData.calculationType === kpiCalculationType.valorePercentuale && (
            <KpiValorePercentuale
              currentProject={currentProject}
              projectTeamMembers={projectTeamMembers}
              userData={userData}
              companyData={companyData}
              t={t}
              kpiRowData={kpiRowData}
              projectId={projectId}
              onSave={this.onDataSave}
              loadingButton={loadingButton}
              calculationType={kpiRowData.calculationType}
            />
          )}

          {kpiRowData && kpiRowData.calculationType === kpiCalculationType.valoreFatoNonFato && (
            <KpiValoreFattoNonFatto
              currentProject={currentProject}
              projectTeamMembers={projectTeamMembers}
              userData={userData}
              companyData={companyData}
              t={t}
              kpiRowData={kpiRowData}
              projectId={projectId}
              onSave={this.onDataSave}
              loadingButton={loadingButton}
              calculationType={kpiRowData.calculationType}
            />
          )}
        </Card>
      </Modal>
    );
  }
}

// export default (withTranslation()(EditProjectKpi));

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
  companyData: state.companyData.companyData,
});

export default withTranslation()(connect(mapStateToProps)(EditProjectKpi));
