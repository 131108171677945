import { Form } from 'antd';
import React from 'react';
import '../../deliverables/deliverable.scss';

import { FilterOutlined, MinusSquareOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, InputNumber, Row, Select, Statistic, Switch, Table, Tooltip, message } from 'antd';
import moment from 'moment';
import ObiettiviAnnoService from '../../../services/pages/obiettiviAnnoService';
import {
  actualYtdRow,
  currentMonthOfActualYtd,
  currentMonthOfTargetYtd,
  firstMonthOfActual,
  firstMonthOfActualYtd,
  firstMonthOfTarget,
  firstMonthOfTargetYtd,
  lastMonthOfTargetYtd,
  previousMonthOfActualYtd,
  previousMonthOfTargetYtd,
  targetYtdRow,
} from '../../projects/projectForm/deliverablesAndKpi/calculations';
import Loader2 from '../../shared/components/loader2/loader2';
import { responsabileAnnualGoalOrAdmin } from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { bowlingChartRowName, kpiPuntualeAcumulato, kpiType } from '../../shared/utils/constants';
import {
  AddSingelSpace,
  alignCurrentMonthCellOnKpiValuesGrid,
  capitalizeFirstLetter,
  checkUserIsKpiResponsabile,
  inputFormatter,
  inputParser,
  italianNumberFormat,
} from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';

const { Option } = Select;

class AnualKpiValoreMinimo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parentType: props.kpiRowData.parentType,
      jumpOffPointValue: props.kpiRowData.jumpOffPoint,
      annualGoalKPIRelationShipID: props.kpiRowData.annualGoalKPIRelationShipID,
      finalTargetValue: '',
      targetYtdValue: '',
      actualYtdValue: '',
      showInA3: props.kpiRowData.showInA3,
      fieldsChanged: false,
      loadingButton: false,
      kpiList: null,
      definedTarget: props.kpiRowData.freezeTargetValues,
      kpiData: null,
      bowlingChartData: null,
      loadingData: false,

      clearFilters: false,
      // selectedYear: moment().format('YYYY'),
      selectedYear: props.kpiYear,

      kpiFieldsChanged: false,
      selectedResponsabile: props.kpiRowData.responsibleID,
    };
  }

  componentDidMount() {
    this.getKpiBowlingChart();
  }

  calculateKpiTargetsAndActuals = (bowlingChartData) => {
    if (bowlingChartData) {
      this.calculateFinalTarget(this.state.jumpOffPointValue);
      //this.calculateTargetYtdRow();
    }
  };

  async getKpiBowlingChart() {
    const { projectId, kpiRowData } = this.props;
    const { selectedYear } = this.state;

    this.setState({ loadingData: true });
    await ObiettiviAnnoService.getAnnualKpiBowlingChart(kpiRowData.annualGoalKPIRelationShipID, selectedYear)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ bowlingChartData: resp.responseObject.value }, () => {
            this.calculateKpiTargetsAndActuals(this.state.bowlingChartData);
            this.calculateTargetYTD(this.state.bowlingChartData);
            this.calculateActualYTD(this.state.bowlingChartData);
          });
          this.setState({ loadingData: false });
          alignCurrentMonthCellOnKpiValuesGrid();
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  onConfirm = () => {
    const {
      bowlingChartData,
      jumpOffPointValue,
      annualGoalKPIRelationShipID,
      finalTargetValue,
      showInA3,
      definedTarget,
      selectedResponsabile,
    } = this.state;
    const { calculationType } = this.props;
    this.props.onSave(
      bowlingChartData,
      jumpOffPointValue,
      annualGoalKPIRelationShipID,
      calculationType,
      finalTargetValue,
      showInA3,
      definedTarget,
      selectedResponsabile,
    );
  };

  renderTargetColumns = (tableData) => {
    const { kpiRowData } = this.props;
    const { definedTarget } = this.state;

    if (tableData && tableData.length > 0) {
      let columns = [];

      let firstRow = tableData[0];

      for (const col in firstRow) {
        if (col === 'Name') {
          columns.push({
            dataIndex: 'Name',
            fixed: 'left',
            title: '',
            width: '10%',
            render: (text) => AddSingelSpace(text, 6).join(' '),
          });
        } else {
          let currentMonth = moment().format('YYYY-MM');
          let month = moment(col).format('MMM YYYY');
          columns.push({
            dataIndex: col,
            className: col === currentMonth ? 'currentMonthBorderLineIndicatorTarget' : '',
            // title: moment(col).format("MMM YYYY"),
            title: capitalizeFirstLetter(month),
            render: (text, record, index) => {
              if (kpiRowData.parentType === kpiPuntualeAcumulato.puntuale) {
                return record.Name === bowlingChartRowName.target ? (
                  <InputNumber
                    precision={2}
                    formatter={(value) => inputFormatter(value)}
                    parser={(x) => inputParser(x)}
                    disabled={kpiRowData.isRollupKPI || definedTarget}
                    size="small"
                    style={{ width: '100%' }}
                    value={text}
                    defaultValue={text}
                    onChange={(value) => this.onTargetChangePuntuale(col, text, record, value)}
                  />
                ) : (
                  <InputNumber
                    precision={2}
                    formatter={(value) => inputFormatter(value)}
                    parser={(x) => inputParser(x)}
                    disabled={true}
                    size="small"
                    style={{ width: '100%' }}
                    value={text}
                    defaultValue={text}
                    onChange={(value) => this.onTargetYtdChangePuntuale(col, text, record, value)}
                  />
                );
              } else {
                return record.Name === bowlingChartRowName.target ? (
                  <InputNumber
                    precision={2}
                    formatter={(value) => inputFormatter(value)}
                    parser={(x) => inputParser(x)}
                    disabled={kpiRowData.isRollupKPI || definedTarget}
                    size="small"
                    style={{ width: '100%' }}
                    value={text}
                    defaultValue={text}
                    onChange={(value) => this.onTargetChange(col, text, record, value)}
                  />
                ) : (
                  <InputNumber
                    precision={2}
                    formatter={(value) => inputFormatter(value)}
                    parser={(x) => inputParser(x)}
                    disabled={kpiRowData.isRollupKPI || definedTarget}
                    size="small"
                    style={{ width: '100%' }}
                    value={text}
                    defaultValue={text}
                    onChange={(value) => this.onTargetYtdChange(col, text, record, value)}
                  />
                );
              }
            },
          });
        }
      }

      return columns;
    }
  };

  renderActualColumns = (tableData) => {
    const { kpiRowData } = this.props;
    if (tableData) {
      let columns = [];

      if (tableData.length > 0) {
        let firstRow = tableData[0];

        for (const col in firstRow) {
          if (col === 'Name') {
            columns.push({
              dataIndex: 'Name',
              fixed: 'left',
              title: '',
              width: '10%',
              render: (text) => AddSingelSpace(text, 6).join(' '),
            });
          } else {
            let currentMonth = moment().format('YYYY-MM');
            let month = moment(col).format('MMM YYYY');
            columns.push({
              className: col === currentMonth ? 'currentMonthBorderLineIndicatorActual' : '',
              dataIndex: col,
              // title: moment(col).format("MMM YYYY"),
              title: capitalizeFirstLetter(month),

              render: (text, record, index) => {
                if (kpiRowData.parentType === kpiPuntualeAcumulato.puntuale) {
                  return record.Name === bowlingChartRowName.actual ? (
                    <InputNumber
                      precision={2}
                      formatter={(value) => inputFormatter(value)}
                      parser={(x) => inputParser(x)}
                      disabled={kpiRowData.isRollupKPI}
                      size="small"
                      style={{ width: '100%' }}
                      value={text}
                      defaultValue={text}
                      onChange={(value) => this.onActualChangePuntuale(col, text, record, value)}
                    />
                  ) : (
                    <InputNumber
                      precision={2}
                      formatter={(value) => inputFormatter(value)}
                      parser={(x) => inputParser(x)}
                      disabled={true}
                      size="small"
                      style={{ width: '100%' }}
                      value={text}
                      defaultValue={text}
                      onChange={(value) => this.onActualYtdChangePuntuale(col, text, record, value)}
                    />
                  );
                } else {
                  return record.Name === bowlingChartRowName.actual ? (
                    <InputNumber
                      precision={2}
                      formatter={(value) => inputFormatter(value)}
                      parser={(x) => inputParser(x)}
                      disabled={kpiRowData.isRollupKPI}
                      size="small"
                      style={{ width: '100%' }}
                      value={text}
                      defaultValue={text}
                      onChange={(value) => this.onActualChange(col, text, record, value)}
                    />
                  ) : (
                    <InputNumber
                      precision={2}
                      formatter={(value) => inputFormatter(value)}
                      parser={(x) => inputParser(x)}
                      disabled={kpiRowData.isRollupKPI}
                      size="small"
                      style={{ width: '100%' }}
                      value={text}
                      defaultValue={text}
                      onChange={(value) => this.onActualYtdChange(col, text, record, value)}
                    />
                  );
                }
              },
            });
          }
        }
      }

      return columns;
    }
  };

  getMonthByDate = (date) => {
    let dateToFormat = moment(date, 'DD-MM-YYYY');
    let monthName = dateToFormat.format('MMMM');
    return monthName;
  };

  onTargetChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData, jumpOffPointValue } = this.state;
    const { kpiRowData } = this.props;
    this.setState({ kpiFieldsChanged: true });

    let targetRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.target;
    })[0];
    let targetFirstMonth = firstMonthOfTarget(bowlingChartData);
    targetRow[selectedMonth] = newValue;

    let firstYearWithValues = kpiRowData.firstYearOfPlanning ? kpiRowData.firstYearOfPlanning.toString() : null;

    for (let key in targetRow) {
      if (key !== 'Name') {
        if (key === selectedMonth) {
          if (key === targetFirstMonth.monthName && moment(key).year().toString() === firstYearWithValues) {
            let targetYtdRow = bowlingChartData.filter(function (item) {
              return item.Name === bowlingChartRowName.targetYtd;
            })[0];
            targetYtdRow[key] = newValue + jumpOffPointValue;
          } else if (key === targetFirstMonth.monthName && moment(key).year().toString() !== firstYearWithValues) {
            let targetYtdRow = bowlingChartData.filter(function (item) {
              return item.Name === bowlingChartRowName.targetYtd;
            })[0];
            let previousTargetYtdMonth = previousMonthOfTargetYtd(bowlingChartData, key);
            let previousTargetYtdMonthValue = targetYtdRow[previousTargetYtdMonth.monthName];

            if (previousTargetYtdMonth.monthName === targetFirstMonth.monthName) {
              targetYtdRow[key] = previousTargetYtdMonthValue + (newValue - oldValue);
            } else {
              targetYtdRow[key] = previousTargetYtdMonthValue + newValue;
            }
          } else {
            let targetYtdRow = bowlingChartData.filter(function (item) {
              return item.Name === bowlingChartRowName.targetYtd;
            })[0];
            let previousTargetYtdMonth = previousMonthOfTargetYtd(bowlingChartData, key);
            let previousTargetYtdMonthValue = targetYtdRow[previousTargetYtdMonth.monthName];

            targetYtdRow[key] = previousTargetYtdMonthValue + newValue;
            // bowlingChartData.find(a => a.Name == bowlingChartRowName.targetYtd)[key] = previousTargetYtdMonthValue + newValue;
          }
        }

        if (moment(key, 'YYYY-MM') > moment(selectedMonth, 'YYYY-MM')) {
          let targetYtdRow = bowlingChartData.filter(function (item) {
            return item.Name === bowlingChartRowName.targetYtd;
          })[0];

          let previousTargetYtdMonth = previousMonthOfTargetYtd(bowlingChartData, key);
          let previousTargetYtdMonthValue = targetYtdRow[previousTargetYtdMonth.monthName];

          let targetValue = targetRow[key];

          targetYtdRow[key] = previousTargetYtdMonthValue + targetValue;
          //bowlingChartData.find(a => a.Name == bowlingChartRowName.targetYtd)[key] = previousTargetYtdMonthValue + targetValue;
        }
      }
    }
    // this.setState(bowlingChartData)
    this.setState({ bowlingChartData }, () => {
      this.calculateFinalTarget();
      this.calculateTargetYTD();
    });
  };

  onTargetChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    let targetRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.target;
    })[0];

    targetRow[selectedMonth] = newValue;

    this.setState({ bowlingChartData }, () => {
      this.calculateFinalTarget();
      this.calculateTargetYTD();
    });
  };

  onTargetYtdChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    let targetYtdRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.targetYtd;
    })[0];
    let targetRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.target;
    })[0];

    for (let key in targetYtdRow) {
      if (key !== 'Name') {
        let formatKey = moment(key, 'YYYY-MM');

        if (key === selectedMonth) {
          targetYtdRow[selectedMonth] = newValue;
          targetRow[key] = targetRow[key] + (newValue - oldValue);
        } else if (formatKey > moment(selectedMonth, 'YYYY-MM')) {
          let preivoustTargetYtdMonthValue = previousMonthOfTargetYtd(bowlingChartData, key);
          targetYtdRow[key] = preivoustTargetYtdMonthValue.monthValue + targetRow[key];
        }
      }
    }
    // this.setState(bowlingChartData)
    this.setState({ bowlingChartData }, () => {
      this.calculateFinalTarget();
      this.calculateTargetYTD();
    });
  };

  onTargetYtdChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    let targetYtdRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.targetYtd;
    })[0];
    targetYtdRow[selectedMonth] = newValue;

    this.setState({ bowlingChartData }, () => {
      this.calculateFinalTarget();
      this.calculateTargetYTD();
    });
  };

  onActualChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData, jumpOffPointValue } = this.state;
    const { kpiRowData } = this.props;
    this.setState({ kpiFieldsChanged: true });

    let actualFirstMonth = firstMonthOfActual(bowlingChartData);
    let actualRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.actual;
    })[0];
    actualRow[selectedMonth] = newValue;

    let firstYearWithValues = kpiRowData.firstYearOfPlanning ? kpiRowData.firstYearOfPlanning.toString() : null;

    for (let key in actualRow) {
      if (key !== 'Name') {
        if (key === selectedMonth) {
          if (key === actualFirstMonth.monthName && moment(key).year().toString() === firstYearWithValues) {
            let actualYtdRow = bowlingChartData.filter(function (item) {
              return item.Name === bowlingChartRowName.actualYtd;
            })[0];
            actualYtdRow[key] = newValue + jumpOffPointValue;
          } else if (key === actualFirstMonth.monthName && moment(key).year().toString() !== firstYearWithValues) {
            let actualYtdRow = bowlingChartData.filter(function (item) {
              return item.Name === bowlingChartRowName.actualYtd;
            })[0];
            let previousActualYtdMonth = previousMonthOfActualYtd(bowlingChartData, key);
            let previousActualYtdMonthValue = actualYtdRow[previousActualYtdMonth.monthName];

            if (previousActualYtdMonth.monthName === actualFirstMonth.monthName) {
              actualYtdRow[key] = previousActualYtdMonthValue + (newValue - oldValue);
            } else {
              actualYtdRow[key] = previousActualYtdMonthValue + newValue;
            }
          } else {
            let actualYtdRow = bowlingChartData.filter(function (item) {
              return item.Name === bowlingChartRowName.actualYtd;
            })[0];
            let previousActualYtdMonth = previousMonthOfActualYtd(bowlingChartData, key);
            let previousActualYtdMonthValue = actualYtdRow[previousActualYtdMonth.monthName];

            actualYtdRow[key] = previousActualYtdMonthValue + newValue;
            // bowlingChartData.find(a => a.Name == bowlingChartRowName.targetYtd)[key] = previousTargetYtdMonthValue + newValue;
          }
        }

        if (moment(key, 'YYYY-MM') > moment(selectedMonth, 'YYYY-MM')) {
          let actualYtdRow = bowlingChartData.filter(function (item) {
            return item.Name === bowlingChartRowName.actualYtd;
          })[0];

          let previousActualYtdMonth = previousMonthOfTargetYtd(bowlingChartData, key);
          let previousActualYtdMonthValue = actualYtdRow[previousActualYtdMonth.monthName];

          let actualValue = actualRow[key];

          actualYtdRow[key] = previousActualYtdMonthValue + actualValue;
          //bowlingChartData.find(a => a.Name == bowlingChartRowName.targetYtd)[key] = previousTargetYtdMonthValue + targetValue;
        }
      }
    }
    this.setState({ bowlingChartData }, () => {
      this.calculateActualYTD();
    });
  };

  onActualChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    let actualRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.actual;
    })[0];
    actualRow[selectedMonth] = newValue;

    this.setState({ bowlingChartData }, () => {
      this.calculateActualYTD();
    });
  };

  onActualYtdChange = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    let actualYtdRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.actualYtd;
    })[0];
    let actualRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.actual;
    })[0];

    for (let key in actualYtdRow) {
      if (key !== 'Name') {
        let formatKey = moment(key, 'YYYY-MM');

        if (key === selectedMonth) {
          actualYtdRow[selectedMonth] = newValue;
          actualRow[key] = actualRow[key] + (newValue - oldValue);
        } else if (formatKey > moment(selectedMonth, 'YYYY-MM')) {
          let preivousActualYtdMonthValue = previousMonthOfActualYtd(bowlingChartData, key);
          actualYtdRow[key] = preivousActualYtdMonthValue.monthValue + actualRow[key];
        }
      }
    }
    this.setState({ bowlingChartData }, () => {
      this.calculateActualYTD();
    });
  };

  onActualYtdChangePuntuale = (selectedMonth, oldValue, row, newValue) => {
    const { bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    let actualYtdRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.actualYtd;
    })[0];
    actualYtdRow[selectedMonth] = newValue;

    this.setState({ bowlingChartData }, () => {
      this.calculateActualYTD();
    });
  };

  handleJumpOffPointChange = (value) => {
    const { parentType, bowlingChartData } = this.state;
    this.setState({ kpiFieldsChanged: true });

    if (parentType && parentType === kpiPuntualeAcumulato.puntuale) {
      this.setState({ jumpOffPointValue: value });
    }

    if (parentType && parentType === kpiPuntualeAcumulato.acumulato) {
      this.setState({ jumpOffPointValue: value }, () => {
        this.calculateFinalTarget();
        this.calculateTargetYTD();
        this.calculateActualYTD();
        this.calculateTargetYtdRow();
        this.calculateActualYtdRow();
      });
    }
    this.setState(bowlingChartData);
  };

  handleShowInA3Change = (value) => {
    this.setState({ showInA3: value });
  };

  calculateFinalTarget = () => {
    const { parentType, bowlingChartData, jumpOffPointValue } = this.state;

    if (parentType === kpiPuntualeAcumulato.puntuale) {
      // let lastMonth = lastMonthOfTarget(bowlingChartData);
      // this.setState({ finalTargetValue: (lastMonth.monthValue) });

      let lastMonth = lastMonthOfTargetYtd(bowlingChartData);
      this.setState({ finalTargetValue: lastMonth.monthValue });
    } else if (parentType === kpiPuntualeAcumulato.acumulato) {
      // let targetTotalMonths = getTotalTargetMonths(bowlingChartData)
      // this.setState({ finalTargetValue: (jumpOffPointValue + targetTotalMonths) });

      let lastMonth = lastMonthOfTargetYtd(bowlingChartData);
      this.setState({ finalTargetValue: lastMonth.monthValue });
    } else {
      return;
    }
  };

  calculateTargetYTD = () => {
    const { parentType, bowlingChartData, jumpOffPointValue } = this.state;

    if (parentType === kpiPuntualeAcumulato.puntuale) {
      // let previousMonth = previousMonthOfTarget(bowlingChartData);
      // this.setState({ targetYtdValue: (previousMonth.monthValue) });

      let currentMonth = moment().format('YYYY-MM');
      let previousMonth = currentMonthOfTargetYtd(bowlingChartData, currentMonth);
      this.setState({ targetYtdValue: previousMonth.monthValue });
    } else if (parentType === kpiPuntualeAcumulato.acumulato) {
      // let targetTotalMonthsYTD = getTotalTargetMonthsYTD(bowlingChartData)
      // this.setState({ targetYtdValue: (jumpOffPointValue + targetTotalMonthsYTD) });

      let currentMonth = moment().format('YYYY-MM');
      let previousMonth = currentMonthOfTargetYtd(bowlingChartData, currentMonth);
      this.setState({ targetYtdValue: previousMonth.monthValue });
    } else {
      return;
    }
  };

  calculateActualYTD = () => {
    const { parentType, bowlingChartData, jumpOffPointValue } = this.state;

    if (parentType === kpiPuntualeAcumulato.puntuale) {
      // let previousMonth = previousMonthOfActual(bowlingChartData);
      // this.setState({ actualYtdValue: (previousMonth.monthValue) });

      let lastMonthActualYtd = currentMonthOfActualYtd(bowlingChartData);
      this.setState({ actualYtdValue: lastMonthActualYtd.monthValue });
    } else if (parentType === kpiPuntualeAcumulato.acumulato) {
      // let actualTotalMonthsYTD = getTotalActualMonthsYTD(bowlingChartData)
      // this.setState({ actualYtdValue: (jumpOffPointValue + actualTotalMonthsYTD) });

      let lastMonthActualYtd = currentMonthOfActualYtd(bowlingChartData);
      this.setState({ actualYtdValue: lastMonthActualYtd.monthValue });
    } else {
      return;
    }
  };

  calculateTargetYtdRow = () => {
    const { bowlingChartData, jumpOffPointValue } = this.state;

    let targetYtdFirstMonth = firstMonthOfTargetYtd(bowlingChartData);

    let targetYtdRowData = targetYtdRow(bowlingChartData);

    for (let key in targetYtdRowData) {
      if (key !== 'Name') {
        if (key === targetYtdFirstMonth.monthName) {
          let targetRow = bowlingChartData.filter(function (item) {
            return item.Name === bowlingChartRowName.target;
          })[0];
          let targetValue = targetRow[key];

          let targetYtdRow = bowlingChartData.filter(function (item) {
            return item.Name === bowlingChartRowName.targetYtd;
          })[0];
          targetYtdRow[key] = jumpOffPointValue + targetValue;
        } else {
          let targetYtdRow = bowlingChartData.filter(function (item) {
            return item.Name === bowlingChartRowName.targetYtd;
          })[0];
          let targetRow = bowlingChartData.filter(function (item) {
            return item.Name === bowlingChartRowName.target;
          })[0];

          let previousTargetYtdMonth = previousMonthOfTargetYtd(bowlingChartData, key);
          let previousTargetYtdMonthValue = targetYtdRow[previousTargetYtdMonth.monthName];

          let targetValue = targetRow[key];

          targetYtdRow[key] = previousTargetYtdMonthValue + targetValue;
        }
      }
    }
  };

  calculateActualYtdRow = () => {
    const { bowlingChartData, jumpOffPointValue } = this.state;

    let actualYtdFirstMonth = firstMonthOfActualYtd(bowlingChartData);

    let actualYtdRowData = actualYtdRow(bowlingChartData);

    for (let key in actualYtdRowData) {
      if (key !== 'Name') {
        if (key === actualYtdFirstMonth.monthName) {
          let actualRow = bowlingChartData.filter(function (item) {
            return item.Name === bowlingChartRowName.actual;
          })[0];
          let actualValue = actualRow[key];

          let actualYtdRow = bowlingChartData.filter(function (item) {
            return item.Name === bowlingChartRowName.actualYtd;
          })[0];
          actualYtdRow[key] = jumpOffPointValue + actualValue;
        } else {
          let actualYtdRow = bowlingChartData.filter(function (item) {
            return item.Name === bowlingChartRowName.actualYtd;
          })[0];
          let actualRow = bowlingChartData.filter(function (item) {
            return item.Name === bowlingChartRowName.actual;
          })[0];

          let previousActualYtdMonth = previousMonthOfActualYtd(bowlingChartData, key);
          let previousActualYtdMonthValue = actualYtdRow[previousActualYtdMonth.monthName];

          let actualValue = actualRow[key];

          actualYtdRow[key] = previousActualYtdMonthValue + actualValue;
        }
      }
    }
  };

  onFilter = () => {
    this.setState({ clearFilters: false }, () => {
      this.getKpiBowlingChart();
    });
  };

  onClearFilter = () => {
    // let currentYear = moment().format('YYYY');
    const { kpiYear } = this.props;
    this.setState({ selectedYear: kpiYear, clearFilters: true }, () => {
      this.getKpiBowlingChart();
    });
  };

  onYearChange = (year) => {
    let formatedYear = moment(year).format('YYYY');
    this.setState({ selectedYear: formatedYear });
  };

  disablePreviousYears = (current, selectedYear) => {
    // return current && (current > moment().add(10, "year") || current < moment())
    const { kpiYear } = this.props;
    return current < moment(kpiYear) || moment(kpiYear).add(10, 'year') < current;
  };

  handleDefinedTargetChange = (value) => {
    this.setState({ kpiFieldsChanged: true });
    this.setState({ definedTarget: value });
  };

  handleResponsabileSelect = (option) => {
    this.setState({ kpiFieldsChanged: true });
    if (option) {
      this.setState({ selectedResponsabile: option });
    } else {
      this.setState({ selectedResponsabile: null });
    }
  };

  render() {
    const { loadingButton, kpiRowData, t, companyData, responsabileUsersList, userData, annualGoalData } = this.props;
    const { bowlingChartData, loadingData, selectedYear, kpiFieldsChanged, definedTarget, selectedResponsabile } =
      this.state;

    let disableJumpOfPointResetGlobalKpi =
      companyData.resetGlobalKPI &&
      kpiRowData.parentType === kpiPuntualeAcumulato.acumulato &&
      kpiRowData.kpiType === kpiType.globale
        ? true
        : false;

    let targetRow = [];
    let actualRow = [];

    if (bowlingChartData) {
      targetRow = bowlingChartData.filter(function (item) {
        return item.Name === bowlingChartRowName.target;
      })[0];
      actualRow = bowlingChartData.filter(function (item) {
        return item.Name === bowlingChartRowName.actual;
      })[0];
    }

    return (
      <>
        <Row
          gutter={{ lg: 24 }}
          className="mb10"
          style={{ marginBottom: 10 }}>
          <Col
            className="gutter-row"
            xs={{ span: 8 }}
            sm={{ span: 6 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}>
            {/* <DatePicker allowClear={false} placeholder={t('general.anno')} picker="year" value={moment(selectedYear)} style={{ width: "100%" }} format="YYYY" onChange={(date) => this.onYearChange(date)} disabledDate={(current) => this.disablePreviousYears(current, moment(selectedYear))} /> */}
            <DatePicker
              allowClear={false}
              placeholder={t('general.anno')}
              picker="year"
              value={moment(selectedYear)}
              style={{ width: '100%' }}
              format="YYYY"
              onChange={(date) => this.onYearChange(date)}
            />
          </Col>
          <Col
            xs={{ span: 4 }}
            sm={{ span: 4 }}
            md={{ span: 4 }}
            lg={{ span: 4 }}>
            <Tooltip title={t('general.filter')}>
              <Button
                type="dashed"
                icon={<FilterOutlined />}
                onClick={() => this.onFilter()}
                disabled={!selectedYear}
              />
            </Tooltip>
            <Tooltip title={t('general.clearFilters')}>
              <Button
                type="dashed"
                icon={<MinusSquareOutlined />}
                onClick={() => this.onClearFilter()}
                disabled={selectedYear ? false : true}
              />
            </Tooltip>
          </Col>
        </Row>

        {bowlingChartData && (
          <Row
            gutter={{ lg: 24 }}
            className="row-margin">
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              {!kpiRowData.isRollupKPI && (
                <>
                  {checkUserIsKpiResponsabile(kpiRowData.responsibleID, userData) && (
                    <div className="button-wrapper">
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button
                          loading={loadingButton}
                          type="primary"
                          onClick={() => this.onConfirm()}
                          icon={<SaveOutlined />}
                          disabled={!targetRow || !actualRow || !kpiFieldsChanged}>
                          {t('buttons.salva')}
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        )}

        {
          <Row
            gutter={{ lg: 24 }}
            className="row-margin">
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 3 }}>
              <Form.Item label={t('general.jumpOffPoint')}>
                <InputNumber
                  min="0"
                  style={{ width: '80%' }}
                  required="required"
                  value={this.state.jumpOffPointValue}
                  onChange={(value) => this.handleJumpOffPointChange(value)}
                  disabled={kpiRowData.isRollupKPI || disableJumpOfPointResetGlobalKpi}
                  precision={2}
                  formatter={(value) => inputFormatter(value)}
                  parser={(x) => inputParser(x)}
                />
              </Form.Item>
            </Col>

            {kpiRowData.parentType !== kpiPuntualeAcumulato.puntuale && (
              <>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 3 }}>
                  <Statistic
                    title={t('kpiPage.finalTarget')}
                    value={this.state.finalTargetValue ? italianNumberFormat(this.state.finalTargetValue) : '0'}
                  />
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 3 }}>
                  <Statistic
                    title={t('kpiPage.targetYearDate')}
                    value={this.state.targetYtdValue ? italianNumberFormat(this.state.targetYtdValue) : '0'}
                  />
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 3 }}>
                  <Statistic
                    title={t('kpiPage.actualYearDate')}
                    value={this.state.actualYtdValue ? italianNumberFormat(this.state.actualYtdValue) : '0'}
                  />
                </Col>

                {/* <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3 }}>
                                    <Form.Item
                                        label="Show in A3"
                                    >
                                        <Switch defaultChecked={this.state.showInA3} onChange={(value) => this.handleShowInA3Change(value)} />
                                    </Form.Item>
                                </Col> */}
              </>
            )}

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 3 }}>
              <Form.Item label={t('kpiPage.definedTarget')}>
                <Switch
                  defaultChecked={definedTarget}
                  onChange={(value) => this.handleDefinedTargetChange(value)}
                />
              </Form.Item>
            </Col>

            {kpiRowData.kpiType === kpiType.specifico && (
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 5 }}>
                <Form.Item label={t('general.responsabile')}>
                  <Select
                    value={selectedResponsabile}
                    placeholder={t('general.seleziona')}
                    allowClear
                    onChange={(value) => this.handleResponsabileSelect(value)}
                    style={{ width: '100%' }}
                    loading={!responsabileUsersList}
                    options={responsabileUsersList}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    disabled={!responsabileAnnualGoalOrAdmin(userData, annualGoalData.owner)}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        }
        {loadingData && <Loader2 />}
        {bowlingChartData && (
          <div>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <div style={{ margin: '15px 0px' }}>
                  <Table
                    rowKey={(obj) => obj.Name}
                    columns={this.renderTargetColumns(
                      bowlingChartData.filter((obj) => {
                        return obj.Name === 'Target' || obj.Name === 'TargetYTD';
                      }),
                    )}
                    dataSource={bowlingChartData.filter((obj) => {
                      return obj.Name === 'Target' || obj.Name === 'TargetYTD';
                    })}
                    pagination={false}
                    bordered={true}
                    size="small"
                    scroll={{ x: 'calc(950px + 50%)' }}
                  />
                </div>
              </Col>
            </Row>

            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <div style={{ margin: '15px 0px' }}>
                  <Table
                    rowKey={(obj) => obj.Name}
                    columns={this.renderActualColumns(
                      bowlingChartData.filter((obj) => {
                        return obj.Name === 'Actual' || obj.Name === 'ActualYTD';
                      }),
                    )}
                    dataSource={bowlingChartData.filter((obj) => {
                      return obj.Name === 'Actual' || obj.Name === 'ActualYTD';
                    })}
                    pagination={false}
                    bordered={true}
                    size="small"
                    scroll={{ x: 'calc(950px + 50%)' }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        )}

        {bowlingChartData && (
          <Row
            gutter={{ lg: 24 }}
            className="row-margin">
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              {!kpiRowData.isRollupKPI && (
                <>
                  {checkUserIsKpiResponsabile(kpiRowData.responsibleID, userData) && (
                    <div className="button-wrapper">
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button
                          loading={loadingButton}
                          type="primary"
                          onClick={() => this.onConfirm()}
                          icon={<SaveOutlined />}
                          disabled={!targetRow || !actualRow || !kpiFieldsChanged}>
                          {t('buttons.salva')}
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default AnualKpiValoreMinimo;
