import { Select, message } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'src/redux/store';
import { EyeLineIcon, FlagLineIcon, HeartLineIcon } from '../../../../assets/icons';
import UsersService from '../../../../services/pages/usersService';
import { userRolesCodes } from '../../../shared/utils/constants';
import { notifyMessages } from '../../../shared/utils/notifyMessages';

const UserNameCompany: React.FC = () => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);
  const companyData = useAppSelector((state) => state.companyData.companyData);
  const history = useHistory();

  const [companyList, setCompanyList] = useState<{ label: string | null; value: string | null }[]>([]);
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [companyPanelHidden, setCompanyPanelHidden] = useState(true);

  let hoverTimeout: NodeJS.Timeout;

  useEffect(() => {
    generateCompanyList();
  }, [userData]);

  const checkIfUserHasRoles = (user: any): boolean => {
    const roles = [
      userRolesCodes.techAdmin,
      userRolesCodes.lenovysAdmin,
      userRolesCodes.externalReviewer,
      userRolesCodes.teamMember,
      userRolesCodes.teamLeader,
      userRolesCodes.steering,
      userRolesCodes.companyAdmin,
    ];
    return user ? roles.includes(user.roleCode) : false;
  };

  const onCompanySelect = (companyId: string): void => {
    const payload = {
      userID: userData?.userId,
      companyID: companyId,
    };

    setLoadingIcon(true);
    UsersService.updateUserLogedInCompany(payload)
      .then((response) => response?.data)
      .then((resp) => {
        if (resp?.success) {
          setLoadingIcon(false);
          history.replace('/');
        } else {
          message.error(notifyMessages.changeCompanyFailed);
          setLoadingIcon(false);
        }
      })
      .catch(() => {
        message.error(notifyMessages.changeCompanyFailed);
        setLoadingIcon(false);
      });
  };

  const generateCompanyList = (): void => {
    const comList =
      userData?.userCompanies?.map((item) => ({
        label: item.companyName || null,
        value: item.companyID || null,
      })) || [];
    setCompanyList(comList);
  };

  const labelRender = ({ label, value }: { label: string; value: string }) => {
    if (label) {
      return (
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <div className="company-info tw-size-6 tw-flex tw-items-center tw-justify-center tw-shrink-0 tw-rounded tw-border tw-border-blue-900">
            <img
              className="tw-box-border tw-bg-white tw-size-full tw-object-contain tw-bg-no-repeat"
              alt="company logo"
              src={companyData?.logo ?? 'not-found'}
              onError={(e) => {
                (e.target as HTMLImageElement).classList.add('!tw-hidden');
              }}
            />
          </div>
          <span className="tw-truncate !tw-text-white">{label}</span>
        </div>
      );
    } else {
      return null;
    }
  };

  const hasRole = checkIfUserHasRoles(userData);

  return (
    <>
      {hasRole && (
        <Select
          onMouseEnter={() => {
            hoverTimeout = setTimeout(() => {
              setCompanyPanelHidden(false);
            }, 1500);
          }}
          onMouseLeave={() => {
            clearTimeout(hoverTimeout);
            setCompanyPanelHidden(true);
          }}
          onClick={() => {
            clearTimeout(hoverTimeout);
            setCompanyPanelHidden(true);
          }}
          className="tw-w-44 company-select"
          showSearch
          listHeight={700}
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          defaultValue={userData?.lastLoggedInCompanyID}
          options={companyList}
          onChange={(value) => onCompanySelect(value as string)}
          labelRender={(props) => labelRender(props as { label: string; value: string })}
        />
      )}
      {!hasRole && userData?.companyName}

      <div
        className={classNames('company-blurred-panel tw-fixed tw-z-[999] tw-top-16 tw-transition-all', {
          'tw-hidden': companyPanelHidden,
        })}>
        <div className="tw-flex tw-flex-col tw-divide-y tw-pb-3">
          {companyData?.companyName && (
            <div className="tw-px-4 [&_.company-info>img]:!tw-bg-none [&_.company-info]:!tw-border-zinc-400 [&_.company-info]:!tw-size-8">
              {labelRender({ label: companyData?.companyName, value: companyData?.companyName })}
            </div>
          )}
          <div className="tw-flex tw-flex-col tw-gap-6 tw-px-4 tw-py-3">
            <div className="tw-flex tw-flex-col tw-gap-2">
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-leading-6 tw-text-lg tw-font-semibold">
                <EyeLineIcon />
                {t('general.vision')}
              </div>
              <div className="tw-leading-5 tw-ml-7 tw-line-clamp-4 tw-text-sm">{companyData?.vision ?? '-'}</div>
            </div>

            <div className="tw-flex tw-flex-col tw-gap-2">
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-leading-6 tw-text-lg tw-font-semibold">
                <FlagLineIcon />
                {t('general.mission')}
              </div>
              <div className="tw-leading-5 tw-ml-7 tw-line-clamp-4 tw-text-sm">{companyData?.mission ?? '-'}</div>
            </div>

            <div className="tw-flex tw-flex-col tw-gap-2">
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-leading-6 tw-text-lg tw-font-semibold">
                <HeartLineIcon />
                {t('general.values')}
              </div>
              <div className="tw-leading-5 tw-ml-7 tw-line-clamp-4 tw-text-sm">{companyData?.values ?? '-'}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserNameCompany;
