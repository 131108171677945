import { Col, Form, Row, Select } from 'antd';
import { differenceInMinutes, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { formatMinutesAsHoursAndMinutes, handleTimeConvert } from 'src/components/shared/utils/functions';
import { requiredFields } from 'src/components/shared/utils/notifyMessages';

const QUARTERS_IN_A_DAY = [
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
];

const calcEndTimeOptions = (startTime: Date) => {
  if (startTime === null) {
    return [];
  }

  const currentHour = startTime.getHours().toString().padStart(2, '0');
  const currentMinute = startTime.getMinutes().toString().padStart(2, '0');
  const currentTime = `${currentHour}:${currentMinute}`;

  const startIndex = QUARTERS_IN_A_DAY.findIndex((time) => time > currentTime);

  const availableTimes = startIndex === -1 ? [] : QUARTERS_IN_A_DAY.slice(startIndex);

  return availableTimes.map((time) => {
    const duration = differenceInMinutes(parse(time, 'HH:mm', new Date()), startTime);
    const formatted = formatMinutesAsHoursAndMinutes(duration);

    return { value: time, label: `${time} (${formatted})` };
  });
};

interface TimeRangePickerProps {
  startTime: Date;
  endTime: Date;
  onStartTimeChange: (value: string) => void;
  onEndTimeChange: (value: string) => void;
  isRequired?: boolean;
}

const TimeRangePicker: React.FC<TimeRangePickerProps> = ({
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange,
  isRequired = true,
}) => {
  const { t } = useTranslation();

  return (
    <Row
      gutter={{ lg: 24 }}
      className="row-margin">
      <Col
        className="gutter-row"
        xs={{ span: 12 }}>
        <Form.Item
          label={t('proggetiPage.oraInizio')}
          name="startTime"
          rules={isRequired ? [{ required: true, message: (requiredFields as any).required }] : []}>
          <Select
            style={{ width: '100%' }}
            placeholder={t('proggetiPage.oraInizio')}
            onChange={(value) => onStartTimeChange(value)}
            options={QUARTERS_IN_A_DAY.map((v) => ({ value: v, label: v }))}
            showSearch
          />
        </Form.Item>
      </Col>

      <Col
        className="gutter-row"
        xs={{ span: 12 }}>
        <Form.Item
          label={t('proggetiPage.oraFine')}
          name="endTime">
          <Select
            style={{ width: '100%' }}
            placeholder={t('proggetiPage.oraFine')}
            value={handleTimeConvert(endTime)}
            onChange={(value) => onEndTimeChange(value)}
            options={calcEndTimeOptions(startTime)}
            showSearch
          />
          <span className="tw-hidden">{handleTimeConvert(endTime)}</span> {/* necessary to update */}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default TimeRangePicker;
