import { Card, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Row, Select, Switch } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonService from '../../../../services/commonService';
import DeliverablePageService from '../../../../services/pages/deliverableService';
import DeliverableService from '../../../../services/pages/projectServices/deliverableServices';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { deliverableStatus, objectCodes, priority, statusPickListCodes } from '../../../shared/utils/constants';
import { areDatesValid, showErrorNotification } from '../../../shared/utils/functions';
import { requiredFields } from '../../../shared/utils/notifyMessages';
import './deliverable.scss';

const { Option } = Select;
const { TextArea } = Input;

const NewProjectDeliverable = ({
  showNewDeliverableModal,
  handleNewDeliverableModalClose,
  currentProject,
  addProjectDeliverable,
}) => {
  const { t } = useTranslation();

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [ownerId, setOwnerId] = useState(currentProject?.teamLeaderID || null);
  const [deliverableDefaultStartDate, setDeliverableDefaultStartDate] = useState();
  const [responsiblePickList, setResponsiblePickList] = useState([]);
  const [deliverablePickList, setDeliverablePickList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [priorityPicklist, setPriorityPicklist] = useState([]);
  const [selectedDeliverableData, setSelectedDeliverableData] = useState(null);
  const [showGenericResponsabileField, setShowGenericResponsabileField] = useState(false);

  useEffect(() => {
    retrieveStatus();
    retrievePriority();
    retrieveDeliverablePickList();
    retrieveResponsibleUsersList();
  }, []);

  useEffect(() => {
    if (currentProject) {
      setDeliverableDefaultStartDate(currentProject ? moment(currentProject.startDate) : moment());
    }
  }, [currentProject]);

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.delivarbleStatus;
    await CommonService.getStatusData(objectCode).then((response) => {
      const resp = response.data;
      if (resp.success) {
        setStatusList(resp.responseObject.value);
      }
    });
  };

  const retrievePriority = async () => {
    const objectCode = objectCodes.deliverablePriority;
    await CommonService.getPicklist(objectCode).then((response) => {
      const resp = response.data;
      if (resp.success) {
        setPriorityPicklist(resp.responseObject.value);
      }
    });
  };

  const retrieveResponsibleUsersList = async () => {
    const response = await CommonService.getResponsibleUserList(currentProject.projectID);
    const resp = response.data;
    if (resp.success) {
      const usr = resp.responseObject.value.map((item) => ({
        value: item.userID,
        label: item.fullName,
        key: item.userID,
        disabled: item.isDeleted,
      }));
      setResponsiblePickList(usr);
    }
  };

  const retrieveDeliverablePickList = async () => {
    const deliverableId = '';
    await DeliverableService.getParentDeliverableProjectPicklist(currentProject.projectID, deliverableId).then(
      (response) => {
        const resp = response.data;
        if (resp.success) {
          setDeliverablePickList(resp.responseObject.value);
        }
      },
    );
  };

  const getDeliverableData = (id) => {
    DeliverablePageService.getDeliverableById(id).then((response) => {
      const resp = response.data;
      if (resp.success) {
        setSelectedDeliverableData(resp.responseObject.value);
        setDeliverableDefaultStartDate(
          resp.responseObject.value.startDate ? moment(resp.responseObject.value.startDate) : currentProject.startDate,
        );
      }
    });
  };

  const disableDateRange = (current, projectStartDate, projectEndDate) => {
    const curr = moment(current).endOf('day');
    if (selectedDeliverableData) {
      if (selectedDeliverableData.parentDeliverableID) {
        let startDate = moment(selectedDeliverableData.parentDeliverableStartDate).endOf('day');
        let endDate = moment(selectedDeliverableData.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        let startDate = moment(selectedDeliverableData.startDate).endOf('day');
        let endDate = moment(selectedDeliverableData.endDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    } else {
      let startDate = moment(projectStartDate).endOf('day');
      let endDate = moment(projectEndDate).endOf('day');
      return curr < startDate || curr > endDate;
    }
  };

  const disableStartDateRange = (current, projectStartDate, projectEndDate) => {
    const curr = moment(current).endOf('day');
    if (selectedDeliverableData) {
      if (selectedDeliverableData.parentDeliverableID) {
        let startDate = moment(selectedDeliverableData.parentDeliverableStartDate).endOf('day');
        let endDate = moment(selectedDeliverableData.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        let startDate = moment(selectedDeliverableData.startDate).endOf('day');
        let endDate = moment(selectedDeliverableData.endDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    } else {
      let startDate = moment(projectStartDate).endOf('day');
      let endDate = moment(projectEndDate).endOf('day');
      return curr < startDate || curr > endDate;
    }
  };

  const onParentDeliverableChange = (id) => {
    getDeliverableData(id);
  };

  const checkValidityBeforeSave = (values, projectId) => {
    if (areDatesValid(values.startDate, values.endDate)) {
      if (ownerId) {
        saveDeliverable(values, projectId);
      } else {
        showErrorNotification(`${t('notifications.generalError')}`, requiredFields.responsabileRequired);
      }
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  const saveDeliverable = (values, projectId) => {
    values['ownerID'] = ownerId;
    addProjectDeliverable(values, projectId, deliverableDefaultStartDate);
  };

  return (
    <Modal
      className="add-kpi-modal"
      width={1100}
      title={t('general.addNew')}
      open={showNewDeliverableModal}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={handleNewDeliverableModalClose}
      footer={[
        <ModalFooterButtons
          disabled={!fieldsChanged}
          modalClose={handleNewDeliverableModalClose}
          formId={'newProjectDeliverable'}
        />,
      ]}>
      <Card>
        <Form
          id="newProjectDeliverable"
          layout="vertical"
          onFinish={(values) => checkValidityBeforeSave(values, currentProject.projectID)}
          onValuesChange={() => setFieldsChanged(true)}>
          <Row gutter={{ lg: 24 }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <Row gutter={{ lg: 24 }}>
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    label={t('general.nome')}
                    name="name"
                    rules={[{ required: true, message: requiredFields.required }]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }}>
                  <Row gutter={{ lg: 24 }}>
                    {/* <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <Form.Item
                        label={t('general.progetto')}
                        name="projectID"
                        initialValue={currentProject.name}>
                        <Input disabled={true} />
                      </Form.Item>
                    </Col> */}
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Form.Item
                        label={t('deliverablePage.macroAttivita')}
                        name="parentDeliverableID"
                        hasFeedback>
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(option) => onParentDeliverableChange(option)}>
                          {deliverablePickList &&
                            deliverablePickList.map((item) => (
                              <Option
                                value={item.deliverableID}
                                key={item.deliverableID}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col xs={{ span: 24 }}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      <Form.Item
                        label={t('general.responsabile')}
                        name="owner"
                        initialValue={ownerId}
                        required={true}>
                        <Select
                          value={ownerId}
                          placeholder={t('general.seleziona')}
                          style={{ width: '100%' }}
                          onChange={(option) => setOwnerId(option)}
                          options={responsiblePickList}
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          loading={!responsiblePickList}
                        />
                      </Form.Item>
                    </Col>

                    {currentProject.teamLeaderID && (
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={t('deliverablePage.isGenericResponsible')}
                          name="isGenericResponsabile"
                          valuePropName="checked"
                          initialValue={false}>
                          <Switch onChange={(checked) => setShowGenericResponsabileField(checked)} />
                        </Form.Item>
                      </Col>
                    )}

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      {showGenericResponsabileField && (
                        <Form.Item
                          label={t('deliverablePage.genericResponsabile')}
                          name="genericResponsible"
                          rules={[{ required: true, message: requiredFields.required }]}>
                          <Input />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col xs={{ span: 24 }}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      <Form.Item
                        label={t('general.stato')}
                        name="status"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}
                        initialValue={deliverableStatus.aperto}>
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}>
                          {statusList &&
                            statusList.map((item) => (
                              <Option
                                value={item.statusID}
                                key={item.statusID}>
                                {item.statusDescription}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      <Form.Item
                        label={t('general.priorita')}
                        name="priority"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}
                        initialValue={priority.medium}>
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}>
                          {priorityPicklist &&
                            priorityPicklist.map((item) => (
                              <Option
                                value={item.objectCodeListID}
                                key={item.objectCodeListID}>
                                {item.description}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      <Form.Item
                        label={t('general.budget')}
                        name="budget"
                        initialValue={0}>
                        <InputNumber
                          min={0}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col xs={{ span: 24 }}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <Form.Item
                        label={t('general.start')}
                        rules={[{ required: true, message: requiredFields.required }]}
                        required={true}>
                        <DatePicker
                          value={deliverableDefaultStartDate}
                          style={{ width: '100%' }}
                          format="DD/MM/YYYY"
                          disabledDate={(current) =>
                            disableStartDateRange(current, currentProject.startDate, currentProject.endDate)
                          }
                          onChange={(date) => setDeliverableDefaultStartDate(date)}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <Form.Item
                        label={t('general.end')}
                        name="endDate"
                        rules={[{ required: true, message: requiredFields.required }]}>
                        <DatePicker
                          style={{ width: '100%' }}
                          format="DD/MM/YYYY"
                          disabledDate={(current) =>
                            disableDateRange(current, currentProject.startDate, currentProject.endDate)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col xs={{ span: 24 }}>
                  <Divider style={{ margin: '12px 0' }} />
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      <Form.Item
                        label={t('deliverablePage.tag')}
                        name="tag"
                        initialValue={''}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      <Form.Item
                        label={t('general.commenti')}
                        name="comment"
                        initialValue={''}>
                        <TextArea
                          rows={5}
                          allowClear
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      <Form.Item
                        label={t('general.nota')}
                        name="notes"
                        initialValue={''}>
                        <TextArea
                          rows={5}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default NewProjectDeliverable;
