import { Form } from 'antd';
import { FC, useEffect, useState } from 'react';
import './divisions.scss';

import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, message, Row, Space, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DivisionDto } from 'src/connectors/backend';
import DivisionService from '../../services/pages/divisionServices';
import Loader from '../shared/components/loader/loader';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const EditDivision: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{
    id: string;
  }>();

  const [fieldsChanged, setFieldsChanged] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [currentData, setCurrentData] = useState<DivisionDto>();

  useEffect(() => {
    id && getDivisionDataById(id);
  }, [id]);

  const getDivisionDataById = async (id: string) => {
    try {
      const { data } = await DivisionService.get(id);
      debugger;
      setCurrentData(data);
    } catch {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const removeUploadedFile = () => {
    setUploadedFile(undefined);
  };

  const updateData = async (
    id: string,
    values: {
      name: string;
      ownerId?: string;
    },
  ) => {
    setLoadingButton(true);

    try {
      const { data } = await DivisionService.update(id, values.name, values.ownerId, uploadedFile);
      message.success(notifyMessages.updateSuccess);

      setCurrentData(data);
    } catch {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <div className="edit-division-wrapper">
      <Loader />

      {currentData && (
        <>
          <div className="site-card-wrapper">
            <h2>&nbsp;</h2>
            <p> {t('divisioniPage.divisioni')} </p>
          </div>

          <Card>
            <Form
              name="company_Details"
              layout="vertical"
              {...formItemLayout}
              onFinish={(values) => updateData(currentData.id as string, values)}
              onValuesChange={() => {
                setFieldsChanged(true);
              }}>
              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 4 }}
                  sm={{ span: 4 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}>
                  {/* <Button
                      onClick={this.backFunction}
                      className="backButton">
                      <RollbackOutlined />
                      {t('buttons.indietro')}
                    </Button> */}
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 20 }}
                  sm={{ span: 20 }}
                  md={{ span: 20 }}
                  lg={{ span: 20 }}>
                  <div className="button-wrapper">
                    <Form.Item wrapperCol={{ span: 24 }}>
                      <Button
                        loading={loadingButton}
                        type="primary"
                        htmlType="submit"
                        className="tw-float-end"
                        disabled={!fieldsChanged}>
                        <SaveOutlined /> {t('buttons.salva')}
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <Card title={t('general.informazioniGenerali')}>
                    <Row
                      gutter={{ lg: 24 }}
                      className="mt10">
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          label={t('general.nome')}
                          name="name"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={currentData.name as string}>
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <UserSelectDropDown
                          disabled={false}
                          allowClear={true}
                          fieldLabel={t('general.responsabile')}
                          t={t}
                          formName={'ownerId'}
                          required={false}
                          selectSize={null}
                          initValue={currentData.owner?.id}
                        />
                        {/* <Form.Item
                                                    style={{ marginBottom: 5 }}
                                                    labelAlign="left"
                                                    label={t('general.responsabile')}
                                                    name="ownerID"
                                                   
                                                    initialValue={currentData.ownerID}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        placeholder={t('general.seleziona')}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{ width: "100%" }}
                                                    >
                                                        {responsabilePickList && responsabilePickList.map(item =>
                                                            <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                                        )}
                                                    </Select>
                                                </Form.Item> */}
                      </Col>

                      <Col>
                        <Form.Item
                          name="image"
                          extra={t('general.image')}>
                          <Space
                            direction="vertical"
                            style={{ width: '100%' }}>
                            <div className="ant-col ant-col-24 ant-form-item-label">
                              <label className="ant-form-item">{t('divisioniPage.immagineDivisione')}</label>
                            </div>

                            {currentData.imageUri && (
                              <img
                                src={currentData.imageUri}
                                width="140"
                                alt="Logo"
                              />
                            )}

                            <Upload
                              accept="image/*"
                              maxCount={1}
                              listType="picture"
                              onRemove={removeUploadedFile}
                              beforeUpload={(file) => {
                                setUploadedFile(file);
                                return false;
                              }}>
                              <Button
                                icon={<UploadOutlined />}
                                size="small">
                                {t('general.upload')}
                              </Button>
                            </Upload>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      )}
    </div>
  );
  // }
};
export default EditDivision;
