import { Form, Typography } from 'antd';
import { useEffect, useState } from 'react';

import {
  EditFilled,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  StarFilled,
  StarOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Col, message, Popover, Radio, Row, Space, Table, Tabs, Tooltip } from 'antd';
import BraftEditor from 'braft-editor';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CommonService from '../../../services/commonService';
import MatrixService from '../../../services/matrix/matrixService';
import ObAnnoService from '../../../services/matrix/obAnnoService';
import ObLongService from '../../../services/matrix/obLongService';
import RelationsService from '../../../services/matrix/relationsService';
import ObiettiviLongService from '../../../services/pages/obiettiviLongService';
import XmatrixDropDownFilter from '../../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import TableLayout from '../../shared/tableLayout';
import {
  formItemLayout,
  longOrAnnualGoalFieldUpdate,
  richTectEditoControlls,
  statusPickListCodes,
} from '../../shared/utils/constants';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import AddObietivoAnnoModal from '../../xMatrix/data_tables/center/addObAnnoModal';
import AddObietivoLungoModal from '../../xMatrix/data_tables/center/addObLungoModal';

const Workshop = ({
  longTermData,
  longTermId,
  isRoleTeamMemberOrTeamLeader,
  loadingSaveField = false,
  updateFields,
  updateLongTermData,
  routeSelectedXmatrix,
  isPresentationMode = false,
}) => {
  const [comentEditiorValue, setComentEditiorValue] = useState(longTermData.notes ? longTermData.notes : '');
  const { t } = useTranslation();

  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);

  const [longTermList, setLongTermList] = useState(null);
  const [loadingLongTerm, setLoadingLongTerm] = useState(false);
  const [loadingUpdateRelation, setLoadingUpdateRelation] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [statusList, setStatusList] = useState(null);

  const [newLongTermModal, setNewLongTermModal] = useState(false);
  const [newAnnualGoalModal, setNewAnnualGoalModal] = useState(false);

  const [selectedObietivoData, setSelectedObietivoData] = useState(null);
  const [selectedObietivoID, setSelectedObietivoID] = useState(longTermId);
  const [loadingSelectedObietivo, setLoadingSelectedObietivo] = useState(false);

  useEffect(() => {
    getActiveXmatrix();
    retrieveLongTermList();
    retrieveGoalStatuses();

    if (routeSelectedXmatrix) {
      setSelectedXmatrixValue(routeSelectedXmatrix);
    }
  }, []);

  useEffect(() => {
    getSelectedObietivoData(selectedObietivoID);
    retrieveLongTermList();
  }, [activeXmatrixInfo, selectedXmatrixValue]);

  const getActiveXmatrix = async () => {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setActiveXmatrixInfo(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const onXmatrixSelect = (selectedXmatrix) => {
    setSelectedXmatrixValue(selectedXmatrix.value);
  };

  const retrieveGoalStatuses = async () => {
    const objectCode = statusPickListCodes.statusObAnnoLong;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setStatusList(resp.responseObject.value);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveLongTermList = async () => {
    if (activeXmatrixInfo) {
      let xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

      setLoadingLongTerm(true);
      await ObiettiviLongService.getObiettiviLongList(xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            let respData = resp.responseObject.value;
            setLongTermList(respData);
            setLoadingLongTerm(false);
          } else {
            message.error(notifyMessages.retrieveFailed);
            setLoadingLongTerm(true);
          }
        })
        .catch((error) => {
          message.error(notifyMessages.retrieveFailed);
          setLoadingLongTerm(true);
        });
    } else {
    }
  };

  const getSelectedObietivoData = (id) => {
    if (activeXmatrixInfo) {
      let xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;
      setLoadingSelectedObietivo(true);
      ObiettiviLongService.getObiettiviLongByiD(id, xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            let respData = resp.responseObject.value;
            setSelectedObietivoData(respData);
            setLoadingSelectedObietivo(false);
          } else {
            message.error(notifyMessages.retrieveFailed);
            setLoadingSelectedObietivo(false);
          }
        })
        .catch((error) => {
          message.error(notifyMessages.retrieveFailed);
          setLoadingSelectedObietivo(false);
        });
    } else {
    }
  };

  const onObLungoSelect = (obLungoId) => {
    setSelectedObietivoID(obLungoId);
    getSelectedObietivoData(obLungoId);
  };

  const handleCommentEditorChange = (editorState) => {
    setComentEditiorValue(editorState);
  };

  const handleCommentEditorSave = async () => {
    let payload = {};
    let fieldValue = BraftEditor.createEditorState(comentEditiorValue).toHTML();
    payload['itemID'] = longTermId;
    payload['nameOrNote'] = fieldValue;
    payload['updateCase'] = longOrAnnualGoalFieldUpdate.longTermGoalNotes;
    if (!isRoleTeamMemberOrTeamLeader) {
      updateFields(payload);
    }
  };

  const onUpdateRelation = (values, annualGoalId) => {
    values['xMatrixID'] = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;
    values['longTermGoalID'] = selectedObietivoID;
    values['annualGoalID'] = annualGoalId;

    setLoadingUpdateRelation(true);
    RelationsService.updateLongAnnualRelation(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setLoadingUpdateRelation(false);
          getSelectedObietivoData(selectedObietivoID);
          updateLongTermData(selectedObietivoID);
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingUpdateRelation(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingUpdateRelation(false);
      });
  };

  const renderUpdateRelation = (record) => {
    return (
      <>
        <Form
          name="edit_relation"
          layout="vertical"
          {...formItemLayout}
          initialValues={{ correlationCoefficient: record.correlationCoefficient }}
          onValuesChange={(values) => onUpdateRelation(values, record.annualGoalID)}>
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <Form.Item
                name="correlationCoefficient"
                style={{ marginBottom: 2 }}>
                <Radio.Group className="tw-flex tw-flex-col tw-gap-1">
                  <Radio
                    key="1"
                    value={1}>
                    {t('xMatrixPage.primaria')}
                  </Radio>
                  <Radio
                    key="2"
                    value={2}>
                    {t('xMatrixPage.secondaria')}
                  </Radio>
                  <Radio
                    key="3"
                    value={3}>
                    {t('xMatrixPage.nessuna')}
                  </Radio>
                </Radio.Group>
              </Form.Item>
              {loadingUpdateRelation && (
                <SyncOutlined
                  style={{ color: '#0f87dc', marginRight: 5, display: 'inherit' }}
                  spin
                />
              )}
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const handleModalClose = () => {
    setNewLongTermModal(false);
    setNewAnnualGoalModal(false);
  };

  const addObietivoLungo = (values) => {
    // values["xMatrixID"] = activeXmatrixInfo?.xMatrixID;
    values['xMatrixID'] = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

    setLoadingSave(true);
    ObLongService.addObiettivoLungoxMartix(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          handleModalClose();
          setLoadingSave(false);
          retrieveLongTermList();
        } else {
          message.error(notifyMessages.addFailed);
          setLoadingSave(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        setLoadingSave(false);
      });
  };

  const addObietvoAnno = (values) => {
    values['xMatrixID'] = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

    setLoadingSave(true);
    ObAnnoService.addObiettivoAnnoxMartix(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          handleModalClose();
          setLoadingSave(false);
          getSelectedObietivoData(selectedObietivoID);
        } else {
          message.error(notifyMessages.addFailed);
          setLoadingSave(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        setLoadingSave(false);
      });
  };

  const longTermColumns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'title',
      ellipsis: {
        showTitle: true,
      },
      width: '95%',
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      key: 'action',
      width: '5%',
      render: (text, record) => (
        <Space>
          <Link to={{ pathname: `/obiettivialungoperiodo/details/id/${record.longTermGoalID}` }}>
            {' '}
            <EditFilled style={{ color: '#023B72' }} />{' '}
          </Link>
        </Space>
      ),
    },
  ];

  const annualGoalColumns = [
    {
      title: `${t('general.nome')}`,
      width: '60%',
      ellipsis: {
        showTitle: true,
      },
      dataIndex: 'description',
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.responsabile')}`,
      dataIndex: 'ownerName',
      align: 'center',
      showSorterTooltip: [false],
    },
    {
      title: `${t('general.relazione')}`,
      align: 'center',
      dataIndex: 'relationship',
      render: (text, record) => {
        return (
          <Popover
            content={() => renderUpdateRelation(record)}
            trigger="click">
            {record.correlationCoefficient === 1 && (
              <span
                className="relationFull margin-center"
                style={{ cursor: 'pointer' }}></span>
            )}
            {record.correlationCoefficient === 2 && (
              <span
                className="relationPartial margin-center"
                style={{ cursor: 'pointer' }}></span>
            )}
            {record.correlationCoefficient === 3 && (
              <span
                className="noRelation margin-center"
                style={{ cursor: 'pointer' }}></span>
            )}
          </Popover>
        );
      },
    },
    {
      key: 'action',
      width: '50px',
      align: 'center',
      render: (text, record) => (
        <Space>
          <Link
            to={{
              pathname: `/obiettiviannuali/details/id/${record.annualGoalID}`,
            }}>
            <EditOutlined />
          </Link>
        </Space>
      ),
    },
  ];

  const progettiColumns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
      width: '80%',
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'projectCode',
    },
    {
      key: 'action',
      width: '50px',
      render: (text, record) => (
        <Space>
          <Link
            to={{
              pathname: `/progetti/id/${record.projectID}`,
            }}>
            <EditOutlined />
          </Link>
        </Space>
      ),
    },
  ];

  const kpiColumns = [
    {
      dataIndex: 'isFavourite',
      width: '40px',
      render: (text, record, index) => {
        if (record.isFavourite) {
          return (
            <StarFilled
              style={{ color: '#f5a645' }}
              title={t('kpiPage.preferita')}
            />
          );
        } else {
          return <StarOutlined title={t('kpiPage.preferita')} />;
        }
      },
    },
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      width: '40%',
      ellipsis: {
        showTitle: true,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.jumpOffPoint')}`,
      dataIndex: 'jumpOffPoint',
    },
    {
      title: `${t('general.target')}`,
      dataIndex: 'target',
    },
    {
      title: `${t('general.actualYtd')}`,
      dataIndex: 'actualYTD',
    },
    {
      title: `${t('general.relazione')}`,
      align: 'center',
      dataIndex: 'relationship',
      render: (text, record) => {
        if (record.correlationCoefficient === 1) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                <span className="relationFull"></span>
              </div>
            </Tooltip>
          );
        } else if (record.correlationCoefficient === 2) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                <span className="relationPartial"></span>
              </div>
            </Tooltip>
          );
        } else {
        }
      },
    },
    {
      key: 'action',
      width: '50px',
      render: (text, record) => (
        <Button
          icon={<EditOutlined />}
          href={`/kpi/id/${record.kpiDetailID}`}></Button>
      ),
    },
  ];

  return (
    <>
      <AddObietivoLungoModal
        showObLungo={newLongTermModal}
        handleObLungoClose={handleModalClose}
        handleObLungoSave={addObietivoLungo}
        loadingButton={loadingSave}
        statusPickList={statusList}
        t={t}
      />

      <AddObietivoAnnoModal
        showObAnno={newAnnualGoalModal}
        handleObAnnoClose={handleModalClose}
        handleObAnnoSave={addObietvoAnno}
        loadingButton={loadingSave}
        statusPickList={statusList}
        t={t}
        annualGoals={[]}
      />

      <TableLayout
        className="tw-mb-4"
        title={t('obiettiviLungoPage.comments')}>
        <TableLayout.Actions>
          {!isRoleTeamMemberOrTeamLeader ? (
            <Form.Item className="tw-mb-0">
              <Button
                htmlType="submit"
                loading={loadingSaveField}
                icon={<SaveOutlined />}
                type="primary">
                {t('buttons.salva')}
              </Button>
            </Form.Item>
          ) : null}
        </TableLayout.Actions>
        <TableLayout.Content>
          <Form
            name="add_ob_lungo"
            {...formItemLayout}
            onFinish={handleCommentEditorSave}>
            <Form.Item>
              <BraftEditor
                contentFormat="html"
                language="en"
                className={`${isPresentationMode ? '[&_.DraftEditor-editorContainer]:tw-text-2xl' : null}`}
                id="editor-text"
                controls={richTectEditoControlls}
                value={BraftEditor.createEditorState(comentEditiorValue)}
                onChange={handleCommentEditorChange}
                onBlur={handleCommentEditorSave}
                disabled={isRoleTeamMemberOrTeamLeader}
              />
            </Form.Item>
          </Form>
        </TableLayout.Content>
      </TableLayout>

      {/* <Typography.Title
        className="!tw-mb-4"
        level={3}>
        {t('obiettiviLungoPage.navigazioneObiettivi')}
      </Typography.Title> */}

      {!isPresentationMode && (
        <Row gutter={24}>
          <Col
            xs={24}
            lg={12}>
            <TableLayout
              title={
                <Typography.Title
                  className="!tw-mb-0"
                  level={4}>
                  {t('obiettiviLungoPage.obiettiviLungo')}
                </Typography.Title>
              }>
              <TableLayout.Actions>
                {activeXmatrixInfo && (
                  <XmatrixDropDownFilter
                    onXmatrixSelect={onXmatrixSelect}
                    activeXmatrixInfo={activeXmatrixInfo}
                    preSelectedXmatrixId={routeSelectedXmatrix}
                    t={t}
                  />
                )}
                <Button
                  type="primary"
                  onClick={() => setNewLongTermModal(true)}
                  icon={<PlusOutlined />}>
                  {t('buttons.aggiungiNuovo')}
                </Button>
              </TableLayout.Actions>
              <TableLayout.Content>
                <Table
                  columns={longTermColumns}
                  dataSource={longTermList}
                  rowKey={(obj) => obj.longTermGoalID}
                  loading={loadingLongTerm}
                  pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        onObLungoSelect(record.longTermGoalID);
                        setSelectedObietivoID(record.longTermGoalID);
                      },
                    };
                  }}
                  rowClassName={(record, index) =>
                    record.longTermGoalID === selectedObietivoID ? 'active-obietivi-row' : ''
                  }
                />
              </TableLayout.Content>
            </TableLayout>
          </Col>
          <Col
            xs={24}
            lg={12}>
            {selectedObietivoData && (
              <TableLayout
                title={
                  <Typography.Title
                    level={4}
                    className="!tw-mb-0">
                    {t('obiettiviLungoPage.selectedLongtermData')}
                  </Typography.Title>
                }>
                <TableLayout.Actions>
                  <Button
                    onClick={() => setNewAnnualGoalModal(true)}
                    type="primary"
                    icon={<PlusOutlined />}>
                    {t('buttons.aggiungiNuovo')}
                  </Button>
                </TableLayout.Actions>
                <TableLayout.Content>
                  <Tabs
                    defaultActiveKey="1"
                    items={[
                      {
                        label: <>{t('headerMenu.obiettiviAnno')}</>,
                        key: '1',
                        children: (
                          <Table
                            columns={annualGoalColumns}
                            dataSource={selectedObietivoData.annualGoalList}
                            rowKey={(obj) => obj.annualGoalID}
                            loading={loadingSelectedObietivo}
                            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
                          />
                        ),
                      },
                      {
                        label: <>{t('headerMenu.progetti')}</>,
                        key: '2',
                        children: (
                          <Table
                            columns={progettiColumns}
                            dataSource={selectedObietivoData.projectList}
                            rowKey={(obj) => obj.projectID}
                            loading={loadingSelectedObietivo}
                            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
                          />
                        ),
                      },
                      {
                        label: <>{t('headerMenu.kpi')}</>,
                        key: '3',
                        children: (
                          <Table
                            columns={kpiColumns}
                            dataSource={selectedObietivoData.kpiList}
                            rowKey={(obj) => obj.kpiid}
                            loading={loadingSelectedObietivo}
                            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
                          />
                        ),
                      },
                    ]}></Tabs>
                </TableLayout.Content>
              </TableLayout>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
export default Workshop;
