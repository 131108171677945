import { Form } from 'antd';
import React from 'react';
import '../../project.scss';

import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, message, Modal, Popconfirm, Row, Select } from 'antd';
import CommonService from '../../../../services/commonService';
import DeliverableService from '../../../../services/pages/projectServices/deliverableServices';
import ResourceWorkloadService from '../../../../services/pages/resourceWorkloadServices';
import Loader2 from '../../../shared/components/loader2/loader2';
import ModalFooterButtons from '../../../shared/components/modal/modalFooterButtons';
import { objectCodes, userProjectCostAccessLevel } from '../../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../../shared/utils/notifyMessages';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class ProjectCostDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
      projectCostDetails: null,
      costTypeList: null,
      deliverablePickList: null,
      projectTeamPickList: [],
    };
  }

  componentDidMount() {
    const { selectedprojectCostDetailID, projectId } = this.props;
    if (selectedprojectCostDetailID) {
      this.getProjectCostDetailsID(selectedprojectCostDetailID);
    }

    if (projectId) {
      this.retrieveDeliverablePickList(projectId);
      this.retrieveProjectMembers(projectId);
    }

    this.retrieveProjectCostTypes();
  }

  async retrieveDeliverablePickList(projectId) {
    await DeliverableService.getDeliverableProjectPicklist(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ deliverablePickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveProjectCostTypes() {
    const objectCode = objectCodes.projectCostTypes;
    await CommonService.getProjectCostType(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ costTypeList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveProjectMembers(projectId) {
    await CommonService.getResponsibleUserList(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let usr = [];
          let respData = resp.responseObject.value;
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          this.setState({ projectTeamPickList: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getProjectCostDetailsID(selectedprojectCostDetailID) {
    this.setState({ loadingData: true });
    await ResourceWorkloadService.getProjectCostDetails(selectedprojectCostDetailID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ projectCostDetails: resp.responseObject.value });
          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  removeProjectCost = (selectedprojectCostDetailID) => {
    this.props.removeProjectCost(selectedprojectCostDetailID);
  };

  saveData = (values, projectCostDetailID) => {
    this.props.handleEditProjectCostDetails(values, projectCostDetailID);
  };

  render() {
    const {
      showProjectCostDetailsModal,
      handleModalClose,
      projectCostName,
      selectedprojectCostDetailID,
      loadingDeleteButton,
      loadingSaveDetailsButton,
      selectedProjectCostData,
      projectCostUserAccessProp,
      userData,
      t,
    } = this.props;
    const { projectCostDetails, costTypeList, projectTeamPickList, deliverablePickList } = this.state;

    let deliverables = [];

    // if (projectCostDetails) {
    //     projectCostDetails.deliverables.forEach((item, index) => {
    //         const value = item.deliverableID;
    //         deliverables.push({
    //             value,
    //             key: index,
    //             label: item.deliverableName
    //         });
    //     }
    //     );
    // } else {
    //     deliverables = [];
    // }

    if (deliverablePickList) {
      deliverablePickList.forEach((item, index) => {
        const value = item.deliverableID;
        deliverables.push({
          value,
          key: index,
          label: item.name,
        });
      });
    } else {
      deliverables = [];
    }

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={`${projectCostName}`}
        open={showProjectCostDetailsModal}
        destroyOnClose={true}
        onCancel={handleModalClose}
        footer={[
          <>
            {!(
              (projectCostUserAccessProp === userProjectCostAccessLevel.view ||
                projectCostUserAccessProp === userProjectCostAccessLevel.limited) &&
              userData.userId === selectedProjectCostData.OwnerID
            ) && (
              <ModalFooterButtons
                disabled={!this.state.fieldsChanged}
                loadingButton={loadingSaveDetailsButton}
                modalClose={handleModalClose}
                formId={'editCostDetail'}
              />
            )}
          </>,
        ]}>
        {!projectCostDetails && <Loader2 />}

        {projectCostDetails && (
          <>
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <div className="button-wrapper">
                  <Popconfirm
                    title={t('proggetiPage.rimuoviCostoProgetto')}
                    icon={<WarningOutlined />}
                    okText={t('general.si')}
                    cancelText={t('general.no')}
                    onConfirm={() => this.removeProjectCost(selectedprojectCostDetailID)}>
                    <Button
                      style={{ marginBottom: 10 }}
                      loading={loadingDeleteButton}
                      icon={<DeleteOutlined />}
                      type="dashed"
                      disabled={
                        (projectCostUserAccessProp === userProjectCostAccessLevel.view ||
                          projectCostUserAccessProp === userProjectCostAccessLevel.limited) &&
                        userData.userId === selectedProjectCostData.OwnerID
                      }>
                      {t('buttons.elimina')}
                    </Button>
                  </Popconfirm>
                </div>
              </Col>
            </Row>

            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form
                  id="editCostDetail"
                  layout="vertical"
                  {...formItemLayout}
                  onFinish={(values) => this.saveData(values, selectedprojectCostDetailID)}
                  // initialValues={data }
                  onValuesChange={() => {
                    this.setState({ fieldsChanged: true });
                  }}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}>
                      <Card
                        title={t('general.detagli')}
                        className="sub-card-body">
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}>
                          <Form.Item
                            label={t('general.nome')}
                            name="name"
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={projectCostName}>
                            <Input
                              disabled={
                                (projectCostUserAccessProp === userProjectCostAccessLevel.view ||
                                  projectCostUserAccessProp === userProjectCostAccessLevel.limited) &&
                                userData.userId === selectedProjectCostData.OwnerID
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}>
                          <Form.Item
                            label={t('general.responsabile')}
                            name="ownerID"
                            rules={[{ required: true, message: requiredFields.required }]}
                            hasFeedback
                            initialValue={projectCostDetails.ownerID}>
                            <Select
                              //allowClear
                              disabled={
                                (projectCostUserAccessProp === userProjectCostAccessLevel.view ||
                                  projectCostUserAccessProp === userProjectCostAccessLevel.limited) &&
                                userData.userId === selectedProjectCostData.OwnerID
                              }
                              placeholder={t('general.seleziona')}
                              style={{ width: '100%' }}
                              options={projectTeamPickList}
                              showSearch
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              loading={!projectTeamPickList}
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}>
                          <Form.Item
                            label={t('proggetiPage.tipologia')}
                            name="type"
                            rules={[{ required: true, message: requiredFields.required }]}
                            hasFeedback
                            initialValue={projectCostDetails.type}>
                            <Select
                              disabled={
                                (projectCostUserAccessProp === userProjectCostAccessLevel.view ||
                                  projectCostUserAccessProp === userProjectCostAccessLevel.limited) &&
                                userData.userId === selectedProjectCostData.OwnerID
                              }
                              style={{ width: '100%' }}
                              placeholder={t('general.seleziona')}>
                              {costTypeList &&
                                costTypeList.map((item, index) => (
                                  <Option
                                    value={item.objectCodeListID}
                                    key={index}>
                                    {item.description}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Card>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}>
                      <Card
                        title={t('proggetiPage.rifDeliverable')}
                        className="sub-card-body">
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}>
                          <>
                            <Form.Item
                              name="deliverableList"
                              hasFeedback
                              initialValue={
                                projectCostDetails.deliverables
                                  ? projectCostDetails.deliverables.map((a) => a.deliverableID)
                                  : []
                              }>
                              <Select
                                options={deliverables}
                                allowClear
                                showSearch={false}
                                maxTagCount="responsive"
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder={t('general.seleziona')}></Select>
                            </Form.Item>
                          </>
                        </Col>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    );
  }
}

export default ProjectCostDetails;
