import { EditOutlined } from '@ant-design/icons';
import { Button, message, Table, Tooltip } from 'antd';
import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import FixedSelectedAsset from 'src/components/xMatrix/FixedSelectedAsset';
import ObKpiService from '../../../../services/matrix/obKpiService';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';
import KpiDetails from './kpiDetailsModal';

interface KpiProps {
  kpi: any[];
  onRowClick: (id: string) => void;
  reloadMatrix: () => void;
  selection: 'primary' | 'secondary';
  selectedIds: string[];
  selectedXmatrix: any;
}

const Kpi: FC<KpiProps> = ({ kpi, onRowClick, reloadMatrix, selection, selectedIds, selectedXmatrix }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const rowRefs = useRef<any[]>([]);

  useEffect(() => {
    if (selection !== 'primary') {
      setSelectedRecord(null);
    }
  }, [selection]);

  const toggleModal = (rowData?: any) => {
    setOpenModal((prev) => !prev);
    setModalData(rowData || {});
  };

  const handleModalCancel = () => {
    setOpenModal(false);
    setLoadingButton(false);
  };

  const onRemoveKpi = (id: string) => {
    setLoadingButton(true);
    ObKpiService.removeKpi(id, selectedXmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          handleModalCancel();
          reloadMatrix();
        } else {
          handleModalCancel();
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch(() => {
        handleModalCancel();
        message.error(notifyMessages.deleteFailed);
      });
  };

  const selectRow = (record: any, index: number) => {
    onRowClick(record.kpiid);
    setSelectedRecord(record);
    setSelectedIndex(index);
  };

  const columns = [
    {
      key: 'combined',
      render: (_: any, record: any, index: number) => ({
        children: (
          <div
            ref={(el) => (rowRefs.current[index] = el)}
            onClick={() => selectRow(record, index)}
            className={`tw-flex tw-py-1 matrix-row-vertical tw-w-[32px] tw-items-center tw-justify-between ${classNames(
              {
                'tw-bg-primary selected-row': selection === 'primary' && selectedIds?.includes(record.kpiid),
                'tw-bg-primary/30 selected-row': selection === 'secondary' && selectedIds?.includes(record.kpiid),
              },
            )}`}>
            <Tooltip title={record.title}>
              <div className="tw-flex tw-items-center tw-truncate">
                <p className="tw-text-sm tw-w-full tw-truncate">{record.title}</p>
              </div>
            </Tooltip>

            <Button
              className="tw-rotate-180"
              type="text"
              icon={<EditOutlined />}
              onClick={() => toggleModal(record)}></Button>
          </div>
        ),
      }),
    },
  ];

  return (
    <>
      <KpiDetails
        show={openModal}
        toggle={toggleModal}
        handleModalCancel={handleModalCancel}
        deleteKpi={onRemoveKpi}
        data={modalData}
        loadingButton={loadingButton}
      />

      {selectedRecord && (
        <FixedSelectedAsset
          side="right"
          listOfRefs={rowRefs}
          selectedIndex={selectedIndex}>
          <p>{selectedRecord.title}</p>
        </FixedSelectedAsset>
      )}

      <Table
        className="[&_table]:tw-h-full"
        dataSource={kpi}
        pagination={false}
        size="small"
        bordered
        showHeader={false}
        columns={columns}
        rowKey={(obj: any) => obj.kpiid}></Table>
    </>
  );
};

export default Kpi;
