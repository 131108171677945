import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonService from '../../../../../services/commonService';
import { objectCodes, statusPickListCodes } from '../../../../shared/utils/constants';

const PrsKanBanFiltersDrawer = ({ onFinish, isOpen, onClose, selectedGroupBy, projectId }) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [priorityPickList, setPriorityPickList] = useState(null);
  const [statusPickList, setStatusPickList] = useState(null);
  const [ownerPickList, setOwnerPickList] = useState(null);

  useEffect(() => {
    retrieveResponsibleOptions(); // to get owner options
    if (isOpen && (!priorityPickList || !statusPickList)) {
      retrievePriorityOptions(); // to get priority options
      retrieveStatusOptions(); // to get status options
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const retrievePriorityOptions = async () => {
    const objectCode = objectCodes.deliverablePriority;

    try {
      const response = await CommonService.getPicklist(objectCode);
      const { success, responseObject } = response.data;

      if (success) {
        setPriorityPickList(responseObject.value);
      }
    } catch (error) {
      // Handle error appropriately (e.g., log it, show a notification)
    }
  };

  const retrieveStatusOptions = async () => {
    const objectCode = statusPickListCodes.delivarbleStatus;

    try {
      const response = await CommonService.getStatusData(objectCode);
      const { success, responseObject } = response.data;

      if (success) {
        setStatusPickList(responseObject.value);
      }
    } catch (error) {
      // Handle error appropriately (e.g., log it, show a notification)
    }
  };

  const retrieveResponsibleOptions = async () => {
    try {
      const response = await CommonService.getResponsibleUserList(projectId);
      const { success, responseObject } = response.data;

      if (success) {
        setOwnerPickList(responseObject.value);
      }
    } catch (error) {
      // Handle error appropriately (e.g., log it, show a notification)
    }
  };

  // const onReset = () => {
  //   form.resetFields();
  //   onClose();
  // };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off">
      <Drawer
        title="Impostazioni vista"
        closable={false}
        form={form}
        getContainer={false}
        width={400}
        classNames={{
          mask: '!tw-bg-transparent',
          wrapper: 'white-shadow !tw-border-l',
        }}
        extra={
          <Button
            onClick={onClose}
            icon={<CloseOutlined />}
          />
        }
        footer={
          <div className="tw-flex tw-items-center tw-gap-2">
            <Button onClick={onClose}>{t('buttons.annulla')}</Button>
            <Button
              htmlType="submit"
              type="primary">
              {t('buttons.applica')}
            </Button>
          </div>
        }
        onClose={onClose}
        open={isOpen}>
        {selectedGroupBy !== 'status' && (
          <Form.Item
            label={t('general.stato')}
            name="status">
            <Select
              mode="multiple"
              className="tw-w-full"
              placeholder={t('general.seleziona')}>
              {statusPickList &&
                statusPickList.map((item) => (
                  <Select.Option
                    value={item.statusID}
                    key={item.statusID}>
                    {item.statusDescription}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}

        {selectedGroupBy !== 'month' && (
          <Form.Item
            label={t('general.start')}
            name="period">
            <DatePicker.RangePicker
              className="tw-w-full"
              format={'DD/MM/YYYY'}
            />
          </Form.Item>
        )}

        <Form.Item
          label={t('general.priorita')}
          name="priority">
          <Select
            mode="multiple"
            className="tw-w-full"
            placeholder={t('general.seleziona')}>
            {priorityPickList &&
              priorityPickList.map((item) => (
                <Select.Option
                  value={item.objectCodeListID}
                  key={item.objectCodeListID}>
                  {item.description}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        {selectedGroupBy !== 'owner' && (
          <Form.Item
            label={t('general.responsabile')}
            name="owner">
            <Select
              placeholder={t('general.seleziona')}
              className="tw-w-full"
              mode="multiple">
              {ownerPickList &&
                ownerPickList.map((item) => (
                  <Select.Option
                    value={item.userID}
                    key={item.userID}>
                    {item.fullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}
      </Drawer>
    </Form>
  );
};

export default PrsKanBanFiltersDrawer;
