import { trackPromise } from 'react-promise-tracker';
import {
  AnnualGoalApi,
  authenticatedApi,
  InsertAnnualGoalRequestDto,
  KPIApi,
  UpdateAnnualGoalRequestDto,
  UpdateKPIAnnualGoalBowlingChartRequestDto,
} from 'src/config/connectors';

const ObiettiviAnnoService = {
  getObiettiviAnnoList: async function (isChecked?: boolean, xmatrixId?: string) {
    const api = await authenticatedApi(AnnualGoalApi);

    return trackPromise(api.annualGoalGetAnnualGoals(isChecked, xmatrixId));
  },

  getObiettiviAnnoByiD: async function (id: string, selectedXmatrix: string) {
    const api = await authenticatedApi(AnnualGoalApi);

    return trackPromise(api.annualGoalGetAnualGoalDetails(id, selectedXmatrix));
  },

  updateAnualGoal: async function (payload: UpdateAnnualGoalRequestDto) {
    const api = await authenticatedApi(AnnualGoalApi);

    return trackPromise(api.annualGoalUpdateAnnualGoal(payload));
  },

  addAnualGoal: async function (payload: InsertAnnualGoalRequestDto) {
    const api = await authenticatedApi(AnnualGoalApi);

    return trackPromise(api.annualGoalInsertAnnualGoal(payload));
  },

  deleteAnualGoal: async function (id: string) {
    const api = await authenticatedApi(AnnualGoalApi);

    return trackPromise(api.annualGoalDeleteAnnuaGoal(id));
  },

  getObjAnnoKpi: async function (id: string, selectedXmatrix: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIByAnnualGoal(id, selectedXmatrix));
  },

  insertObAnnoKpi: async function (kPIDetailID: string, annualGoalID: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIInsertKPIAnnualGoal(kPIDetailID, annualGoalID));
  },

  getAnnualKpiBowlingChart: async function (annualGoalKPIRelationShipID: string, year: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIAnnualGoalBowlingChart(annualGoalKPIRelationShipID, year));
  },

  checkAnnualGoalYear: async function (id: string, type: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPICheckKpiTypePlanningStartDate(id, type));
  },

  updateAnnualKpiBowlingChart: async function (payload: UpdateKPIAnnualGoalBowlingChartRequestDto) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateKPIAnnualGoalBowlingChart(payload));
  },

  deleteKpiObAnual: async function (id: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIDeleteAnnualGoalKPIRelationshipBowlingChart(id));
  },
};

export default ObiettiviAnnoService;
